// Customizable Area Start
//@ts-nocheck
import React from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Image, Progress, Row } from "antd";
import { useHistory } from "react-router-dom";
import { bgImage } from "./assets";
const bestLogo = require("../assets/logo.png");
const chart = require("../assets/slide7a.png");
import TopNav from "../../../components/src/TopNav.js";
import axios from "axios";
import { configJSON } from "./AddCategories.web";
const AllocateAccountWeb = () => {
  const history = useHistory(); 

  return (
    <><TopNav />
      <Row align="middle" justify="center" style={{ 
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        paddingBottom: "20px",
        marginBottom: "30px",
      }}>
        <Col lg={10} md={13} sm={16} xs={21}>
          <Card>
            <Row justify="center">
              <Col span={3}>
                <Image src={bestLogo} preview={false} />
              </Col>
            </Row>
            <Divider />
            <Row justify="center">
              <Col span={5}>
                <Image src={chart} preview={false} />
              </Col>
            </Row>
            <Row style={styles.contentWrapper} justify="center">
              <Col span={24}>
                Allocate every dollar from checking and savings accounts.
              </Col>
            </Row>
            <Row
              justify="center"
              style={{
                textAlign: "center",
                fontSize: "15px",
                color: "rgb(104, 105, 108)", marginBottom: "5em"
              }}
            >
              <Col span={24}>
                Only need to do this once as we'll automatically update your
                accounts and spending categories as time goes by. Only allocate
                to bills (Necessity and Discretionary) that have not yet been
                paid this month. Don't forget to allocate money to your current
                credit card balance.
              </Col>
            </Row>
            <Row justify="center">
              <Col span={18}>
                <Progress percent={100} status="active" showInfo={false} />
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: "2em" }}>
              <Button
                shape="round"
                type="primary"
                style={{ backgroundColor: "#b3b3b3", color: "white" }}
                onClick={() => history.push("/AddDiscretioneryExpensesWeb")}
                icon={<LeftOutlined translate rev />}
              >
                Back
              </Button>
              <Button
                shape="round"
                type="primary"
                onClick={() => history.push("/ReviewCheckingWeb")}
                id="back-btn"
              >
                SET CHECKING AND SAVINGS <RightOutlined translate rev />
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AllocateAccountWeb;

const styles = {
  contentWrapper: {
    textAlign: "center" as const,
    fontSize: 20 as const, 
    fontWeight: "bold" as const,
    color: "rgb(104, 105, 108)",
  },
}
// Customizable Area End