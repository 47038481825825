//@ts-nocheck
import React from "react";
import "antd/dist/antd.css";
import DashboardController, { Props, configJSON } from "./DashboardController";
import { Table, Row, Col, Card, Switch } from "antd";
import { Link, Redirect } from "react-router-dom";
import SideBar from "../../../components/src/SideBar";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import money from "../assets/MoneyReallocate.jpg";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import "./style.css";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  componentWillMount() {
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', (event) => {
      history.pushState(null, document.title, location.href);
    });
  }

  returnRedirect(record) {
    if (
      record.name == "discretionary_expense"
    ) {
      localStorage.setItem(
        "category_id",
        record.key
      );
      this.setState({
        isRedirectToDiscretioneryExpenses: true,
      });
    } else if (
      record.name == "necessity_expense"
    ) {
      localStorage.setItem(
        "category_id",
        record.key
      );
      this.setState({
        isRedirectToAddNecessityExpensesCategory: true,
      });
    } else {
      localStorage.setItem(
        "category_id",
        record.key
      );
      this.setState({
        isRedirectToAvailableBalance: true,
      });
    }
  }
  render() {
    const {
      collapseData,
      collapseDataColumn,
    } = this.state;

    return (
      <>
        {this.state.isRedirectToAvailableBalance ? (
          <Redirect push to="/AvailableBalance" />
        ) : null}
        {this.state.isRedirectToAddNecessityExpensesCategory ? (
          <Redirect push to="/AddNecessityExpensesCategory" />
        ) : null}
        {this.state.isRedirectToDiscretioneryExpenses ? (
          <Redirect push to="/DiscretioneryExpenses" />
        ) : null}
        {this.state.redirect ? (<Redirect push to="/EmailAccountLoginWeb" />) : null}
        <div>
          <div>
            <SideBar />
          </div>
          {this.state.cardLoading ? (
            <LoadingWeb />
          ) : (
            <Row style={{ marginLeft: "79px" }}>
              <Col span={24}>
                <Card
                  style={{
                    margin: "2em",
                  }}
                >
                  <Row align="middle" justify="center">
                    <Col xs={0} sm={0} md={1} lg={1} />
                    <Col style={{ marginTop: "40px", marginBottom: "25px" }} xs={24} sm={24} md={22} lg={22}>
                      <Row
                        align="middle"
                        justify="center"
                      //  style={styles.firstDiv}
                      >
                        <Col xs={24} sm={24} md={24} lg={7} xxl={5} xl={7}
                          // style={styles.BudgetsBalance}
                          style={{
                            marginBottom: "12px",
                            fontSize: "22px",
                            fontWeight: 900,
                            color: "rgb(84, 88, 97)",
                            lineHeight: 1.2,
                          }}
                        >
                          <Row align="middle"
                            justify="start">{configJSON.budgetBal}</Row>
                        </Col>
                        {/* <Col xs={5}/> */}
                        <Col xs={24} sm={24} md={24} lg={3} xxl={1} xl={1}
                          style={{
                            color: "rgb(179, 179, 179)",
                            fontSize: "16px",
                            fontWeight: 500,
                            marginBottom: "15px",
                          }}
                        // style={styles.Dashboard}
                        >
                          <Row align="middle" justify="end">
                            {" "}
                            {configJSON.dashboard}
                          </Row>
                        </Col>

                        <Col xs={14} sm={15} md={17} lg={2} xxl={10} xl={6}
                          style={{
                            marginBottom: "14px",
                          }}
                        >
                          {" "}
                          <Row align="middle" justify="end">
                            <Switch defaultChecked onChange={this.handleSwitch} />
                          </Row>
                        </Col>

                        <Col xs={10} sm={9} md={7} lg={5} xxl={3} xl={4}
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            marginBottom: "16px",
                          }}
                        >

                          <Row align="middle" justify="end">
                            {/* <Row align="middle" justify="end" style={{padding:"4px"}}>
                            <Switch defaultChecked />
                          </Row> */}
                            {configJSON.weeklyBalance}
                          </Row>
                        </Col>

                        <Col xs={24} md={24} lg={7} xxl={5} xl={6}
                          style={{ marginBottom: "15px" }}
                        >
                          <Row align="middle" justify="end">
                            <Link
                              type="primary"
                              style={{
                                borderRadius: "40px",
                                backgroundColor: "#2DADE1",
                                padding: "10px",
                                fontWeight: "bold",
                                width: "200px",
                                color: "#ffffff",
                              }}
                              to="/MoneyReallocate"
                            >
                              <Row align="middle" justify="center">
                                <Col span={5}> <span
                                  style={{
                                    fontSize: 12,
                                  }}
                                >
                                  <img src={money} width="30px" />
                                </span></Col>

                                <Col span={19}>{configJSON.reallocateMoneyCaps}</Col>
                              </Row>

                            </Link>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        align="middle"
                        style={{
                          height: "60px",
                          borderRadius: "6.4px",
                          marginTop: "38px",
                          backgroundColor: "rgb(242, 242, 242)",
                          alignItems: "center",
                          paddingLeft: 20,
                        }}
                      // style={styles.headingDiv}
                      >
                        <Col style={styles.headingDivContent}>
                          {" "}
                          <Row align="middle">
                            {configJSON.category}
                          </Row>
                        </Col>
                        <Col
                          xs={12} sm={12} md={10} lg={8}
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            // marginLeft: "52%",
                          }}
                        >
                          <Row align="middle" >
                            {" "}
                            {configJSON.balance}
                          </Row>
                        </Col>
                      </Row>
                      <Row align="middle" style={{ width: "100%" }}>
                        <Col span={24}>
                          <Table
                            style={{
                              padding: " 0px 20px",
                            }}
                            pagination={{ defaultPageSize: 10 }}
                            columns={this.state.columnData}
                            dataSource={this.state.tableData}
                            expandRowByClick
                            data-test-id={"table-data"}
                            expandable={{
                              expandIconColumnIndex: 5,
                              expandedRowRender: (record) => {
                                const data =
                                  collapseData && collapseData[record.key];

                                return (
                                  <>
                                    {data ? (
                                      <Table
                                        pagination={false}
                                        dataSource={data}
                                        columns={collapseDataColumn}
                                        scroll={{ y: 240 }}
                                      />
                                    ) : (
                                      <p style={{ textAlign: "center" }}>
                                        No Data
                                      </p>
                                    )}
                                  </>
                                );
                              },
                            }}
                            expandIcon={({ expanded, onExpand, record }) =>
                              expanded ? (
                                <DownOutlined
                                  onClick={(e) => onExpand(record, e)}
                                  translate
                                  data-test-id={"down-button"}
                                />
                              ) : (
                                <RightOutlined
                                  style={{
                                    backgroundColor: "#f2f2f2",
                                    height: "28px",
                                    width: "28px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#2dade1",
                                    borderRadius: 4,
                                    fontWeight: 700,
                                  }}
                                  data-test-id={"redirect-button"}
                                  onClick={(e) => {
                                    this.returnRedirect(record)
                                  }}
                                  translate
                                />
                              )
                            }
                          />
                        </Col>


                      </Row>

                    </Col>
                    <Col xs={0} sm={0} md={1} lg={1} />
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
}

const styles = {
  contentBlock: {
    height: "auto",
    padding: "40px",
    backgroundColor: "white",
    marginRight: "32px",
    border: "1px solid white",
    marginLeft: "106px",
    marginTop: "28px",
  },
  headingDiv: {
    width: "100 %",
    display: "flex",
    height: "60px",
    borderRadius: "6.4px",
    marginTop: "38px",
    padding: "20px",
    backgroundColor: "#f2f2f2",
    alignItems: "center",
  },
  firstDiv: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  BudgetsBalance: {
    fontSize: "22px",
    fontWeight: 900,
    color: "#545861",
    lineHeight: 1.2,
  },
  headingDivContent: {
    fontSize: "16px",
    fontWeight: 700,
    minWidth: '58%'
  },
  Dashboard: {
    color: "#b3b3b3",
    fontSize: 16,
    fontWeight: 500,
  },

  secondDiv: {
    marginTop: "32px",
    height: "60px",
    borderRadius: "6px",
    padding: "20px",
    backgroundColor: "#f2f2f2",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
};
