import React from "react"; 
const logo = require("../assets/header_logo.png");
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Card, Image, Divider, Button } from "antd";
import { bgImage } from "./assets";
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
const EmailAccountRegistrationWeb = () => {
  const emailId = useSelector((state: any) => state.users.usersEmail.email);
  let history = useHistory();
  return (
    <>
      <TopNav />
      <Row
        align="middle"
        justify="center"
        style={{
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          position: "fixed",
          overflow: "auto",
          paddingBottom: "20px",
          marginBottom: "30px",
        }}
      >
        <Col lg={10} md={13} sm={16} xs={21}>
          {" "}
          <Card>
            {" "}
            <Row justify="center">
              <Image src={logo} width="100px" preview={false} />
            </Row>
            <Divider />
            <Row>
              <Col lg={2} md={2} sm={1} xs={0} />
              <Col lg={20} md={20} sm={22} xs={24}>
                <Row align="middle" justify="center">
                  <Col span={15} style={{ textAlign: "center" }}>
                    <h1
                      style={{ fontWeight: "bold", color: "rgb(84, 88, 97)" }}
                    >
                      Check your email!
                    </h1>
                  </Col>
                </Row>
                <Row align="middle" justify="center">
                  <Col span={15} style={{ textAlign: "center" }}>
                    If there is an account associated with <br />{" "}
                    <span style={{ fontWeight: "bold" }}>{emailId}</span>,
                    <br />
                    an email was sent to you. <br />
                    Please check your email in a moment.
                  </Col>
                </Row>
                <Row align="middle" justify="center">
                  <Col span={15} style={{ textAlign: "center" }}>
                    <h2
                      style={{ fontWeight: "bold", color: "rgb(84, 88, 97)" }}
                    >
                      You did not receive <br />
                      the email?
                    </h2>
                  </Col>
                </Row>

                <Row style={{ marginTop: "30px" }}>
                  <Button
                    type="primary"
                    onClick={() => history.goBack()}
                    style={{ borderRadius: "15px", width: "100%" }}
                  >
                    USE ANOTHER EMAIL ADDRESS
                  </Button>
                </Row>
              </Col>
              <Col lg={2} md={2} sm={1} xs={0} />
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default EmailAccountRegistrationWeb;
