import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import AsyncStorage from "@react-native-async-storage/async-storage";
// import axios from "axios";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import { openErrorNotification } from "../../../components/src/Notification.web";
import { Platform } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  data?: any;
  history: any;
  route: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  transactionid: any;
  data: any;
  bankData: any;
  tabData: any;
  drawerData: any;
  loading: boolean;
  errorMsg: string;
  isOpen: boolean;
  link_token: any;
  showCal: boolean;
  webData: any;
  webLoading: boolean;
  customDate: string;
  accountDetails: any;
  selectSort: string;
  cardLoading: boolean;
  balance: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

class AccountsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  finicity_customer_id: any;
  token: any;
  plaid_acc_id: any;
  accountPageAPIcallId: any;
  _unsubscribe: any;
  linkedAccAPIcallId: any;
  finicityTokenAPIcallId: any;
  finicity_token: any;
  apiFilterCallId: any;
  accountDetailsAPIcallId: any;
  customerDataApiId: any;
  accountAmountAPICallId: any;
  customerDataAPICallId: any;
  customerId: any;
  accountPageCustomerAPIcallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    this.handleCustomDate = this.handleCustomDate.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationRMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      transactionid: "",
      tabData: null,
      loading: true,
      errorMsg: "",
      drawerData: null,
      isOpen: false,
      data: [
        {
          value: "Bank Of India ",
          id: 1,
        },
        {
          value: "SBI",
          id: 2,
        },
        {
          value: "PNB",
          id: 3,
        },
      ],
      bankData: [],
      link_token: "",
      showCal: false,
      webData: [],
      webLoading: false,
      customDate: "",
      accountDetails: "",
      selectSort: "month_to_date",
      cardLoading: true,
      balance: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    if (Platform.OS === 'web') {
      this.getTabData();
      this.getToken();
      this.getCustomerData();
      this.getAccountAmount();
    } else {
      this.getTabData();
      this.getToken();
      this._unsubscribe = this.props.navigation.addListener("focus", () => {
        this.getToken();
      })
    }

  }

  async componentDidUpdate(prevProps: any, prevState: { selectSort: string; }) {
    if (prevState.selectSort !== this.state.selectSort) this.getCustomerData();
  }

  getToken = async () => {
    try {
      const token = await AsyncStorage.getItem("tokenLogin");
      const plaid_acc_id = await AsyncStorage.getItem("plaid_acc_id");
      const finicityToken = await AsyncStorage.getItem("finicity_token");
      const finicityCustomerId = await AsyncStorage.getItem(
        "finicity_customer_id"
      );
      console.log("token ================>>>>>>>>>>>>>>>>>", token);
      console.log(
        "plaid_acc_id ================>>>>>>>>>>>>>>>>>",
        plaid_acc_id
      );
      this.token = token;
      this.plaid_acc_id = plaid_acc_id;
      this.finicity_token = finicityToken;
      this.finicity_customer_id = finicityCustomerId;
      // ;
      if (Platform.OS === 'web'){
        this.accountPageAPICall()
        this.getCustomerData();
      }
      else {
        this.accountPageAPICallMobile()
      }
    } catch (e) {
      // error reading value
    }
  };

  accountPageAPICallMobile =()=> {
   // Customizable Area Start
    // headers of API
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // ================== Matches ID's ====================================
    this.accountPageAPIcallId = requestMessage.messageId;
    // ================== API URL ====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountPageAPIEndPoint +
        this.finicity_customer_id +
        "/all_accounts"
    );
    // ================== API method =================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    // ================== header ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    console.warn(
      "header ================>>>>>>>>>>>>>>>",
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
    }
  getTabData = () => {
    if (!this.state.tabData) {
      const uploadData = {
        data: "Needed",
      };

      const msg: Message = new Message(
        getName(MessageEnum.NavigationTabMenuMessage)
      );
      msg.addData(getName(MessageEnum.NavigationTMessage), uploadData);

      console.log("Dashboard Broadcasting message");
      runEngine.sendMessage(msg.id, msg);
    }
  };

  async componentWillUnmount() {
    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // Abhijeet
      if (apiRequestCallId === this.accountPageAPIcallId) {
        this.getAccountPageData(responseJson, errorReponse);
      } else if (apiRequestCallId === this.linkedAccAPIcallId) {
        this.getLinkAccountData(responseJson);
      } else if (apiRequestCallId === this.finicityTokenAPIcallId) {
        this.getFinicityTokenData(responseJson);
      } else if (apiRequestCallId === this.accountAmountAPICallId) {
        this.getAccountAmountResponse(responseJson, errorReponse);
      } else if (apiRequestCallId === this.customerDataAPICallId) {
        this.getCustomerResponse(responseJson, errorReponse);
      }
      // else if(apiRequestCallId === this.accountPageCustomerAPIcallId) {
      //   this.getAccountInfo(responseJson);
      // } 
    }

    if (getName(MessageEnum.NavigationRMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationTabDataMessage)
      );

      runEngine.unSubscribeFromMessages(this, [
        getName(MessageEnum.NavigationRMessage),
      ]);
      this.setState({
        tabData: data.data[0].data.attributes,
        drawerData: data.data[2].data.attributes,
      });
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area End
  }

  // Customizable Area Start
  getAccountAmountResponse = (response: any, errorResponse: any) => {
    if (response && !response.errors) {
      this.setState({
        webLoading: false,
        webData: response.data.table,
        balance: response.data.table.balance
      })
    } else {
      console.log("ERROR in API");
      this.setState({
        webLoading: false,
      });
      if (errorResponse.status === 401) window.location.href = "/EmailAccountLoginWeb";
    }
  }

  getAccountAmount = async () => {
    this.setState({
      webLoading: true
    })
    let accountId = this.props.navigation.getParam("accountId");
    const customerId = localStorage.getItem("customerId");
    const accountAmountEndpoint = `${configJSON.baseURL}bx_block_finicity/customer/${customerId}/accounts/${accountId}`;
    // headers of API
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("loginToken"),
      finicity_token: localStorage.getItem("finicityToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // Matches ID's
    this.accountAmountAPICallId = requestMessage.messageId;
    // Added API URL
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      accountAmountEndpoint
    );
    // Attached header in API
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Attached API method in API
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  getCustomerResponse = (response: any, errorResponse: any) => {
    if (response && !response.errors) {
      this.setState({
        webLoading: false,
        cardLoading: false,
        webData: response.data,
      })
    } else {
      console.log("ERROR in API");
      this.setState({
        webLoading: false,
        cardLoading: false,
        webData: []
      });
      if (errorResponse.status === 401) this.props.history.push("/EmailAccountLoginWeb");
      openErrorNotification(errorResponse, "error")
    }
  }

  getCustomerData = async () => {
    this.setState({
      webLoading: true
    })

    let startDate, endDate;

    const response = this.returnDate();
    startDate = response.startDate;
    endDate = response.endDate;

    let accountId = this.props.navigation.getParam("accountId");
    const customerDataEndpoint = `${configJSON.baseURL}bx_block_finicity/customer/${localStorage.getItem("customerId")}/accounts/${accountId}/transactions?fromDate=${startDate}&toDate=${endDate}&start=1&limit=1000&sort=desc&includePending=false&keyword=${this.state.selectSort}&send_by_email=false&date=${endDate}`;
    // headers of API
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("loginToken"),
      finicity_token: localStorage.getItem("finicityToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // Matches ID's
    this.customerDataAPICallId = requestMessage.messageId;
    // Added API URL
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customerDataEndpoint
    );
    // Attached header in API
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Attached API method in API
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;

  };

  returnDate = () => {
    let startDate = moment()
      .startOf("month").unix();
    let endDate = moment().unix();
    if (this.state.selectSort === "year_to_date") {
      startDate = moment()
        .startOf("year").unix();
      endDate = moment().unix();
      return { startDate, endDate };
    } else if (this.state.selectSort === "month_to_date") {
      startDate = moment()
        .startOf("month").unix();
      endDate = moment().unix();
      return { startDate, endDate };
    } else if (this.state.selectSort === "last_month") {
      startDate = moment()
        .subtract(1, "months")
        .startOf("month").unix();
      endDate = moment()
        .subtract(1, "months")
        .endOf("month").unix();
      return { startDate, endDate };
    } else if (this.state.selectSort === "custom") {
      startDate = moment(this.state.customDate[0]).unix()
      endDate = moment(this.state.customDate[1]).unix()
      return { startDate, endDate };
    }
    return { startDate, endDate };
  }

  handleChange = (value: any) => {
    if (value === "custom") {
      this.setState({ showCal: true })
    }
    else {
      this.setState({ selectSort: value, showCal: false })
    }
  };

  handleCustomDate = (dates: any) => {
    this.setState({
      customDate: dates,
      selectSort: "custom"
    })
  }

  getAccountPageData = (response: any, errorResponse: any) => {
    console.log("getAccountPageData",response, errorResponse);
    
    if (response) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false,
        bankData: response.data,
        accountDetails: { balance: response.data[response.data.length - 1].balance }
      });
    }
    if (errorResponse) {
      console.log(errorResponse);
    }
  };

  getLinkAccountData = (response: any) => {
    if (response) {
      this.props.navigation.navigate("LinkedAccounts", {
        res: response.data.table.link,
      });
    }
  };

  getFinicityTokenData = (response: any) => {
    if (response) {
      this.finicity_token = response.data;
      this.saveFinicityToken(response.data);
      this.linkAccountAPIcall();
    }
  };

  saveFinicityToken = async (finicity_token: string) => {
    await AsyncStorage.setItem("finicity_token", finicity_token);
  };

  // Customizable Area End

  getAsset = (item: any): any => {
    switch (item.icon) {
      case "Account":
        return require("../assets/Componente33x.png");
      case "Budget":
        return require("../assets/Componente43x.png");
      case "Transaction":
        return require("../assets/Componente53x.png");
      case "Reports":
        return require("../assets/Componente63x.png");
      default:
        return require("../assets/Componente73x.png");
    }
  };

  getColor = (item: any): any => {
    return item.icon === "Account" ? "rgb(37, 150, 190)" : "rgb(136,136,140)";
  };

  nav = (item: any) => {
    if (item) {
      const uploadData = {
        data: item,
        navigationProp: this.props,
      };

      const msg: Message = new Message(
        getName(MessageEnum.NavigationToMessage)
      );

      msg.addData(getName(MessageEnum.NavigationMenuTargetMessage), uploadData);
      console.log("Dashboard Broadcasting message");
      runEngine.sendMessage(msg.id, msg);
    }
  };

  // Thai
  accountPageAPICall = () => {
    // Customizable Area Start
    // headers of API
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: localStorage.getItem("loginToken"),
    };
    const customerId = localStorage.getItem("customerId");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // ================== Matches ID's ====================================
    this.accountPageAPIcallId = requestMessage.messageId;

    // ================== API URL ====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountPageAPIEndPoint +
      customerId +
      "/all_accounts"
    );

    // ================== API method =================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    // ================== header ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    console.warn(
      "header ================>>>>>>>>>>>>>>>",
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  //Thai
  linkAccountAPIcall = () => {
    // Customizable Area Start
    // headers of API
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: this.token,
      fincity_token: this.finicity_token,
    };

    // httpBody of API
    const LinkAccounthttpBody = {
      customerId: this.finicity_customer_id,
    };

    const LinkAccountrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // ================== Matches ID's ====================================
    this.linkedAccAPIcallId = LinkAccountrequestMessage.messageId;

    // ================== API URL ====================================
    LinkAccountrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.linkedAccAPIEndPoint
    );
    console.warn(
      "API URL ================>>>>>>>>>>>>>> ",
      configJSON.linkedAccAPIEndPoint
    );

    // ================== API method =================================
    LinkAccountrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    console.warn(
      "API Method ================>>>>>>>>>>>>>> ",
      configJSON.postMethod
    );

    // ================== httpBody =====================================
    LinkAccountrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(LinkAccounthttpBody)
    );
    console.warn(
      "httpBody ================>>>>>>>>>>>>>> ",
      JSON.stringify(LinkAccounthttpBody)
    );

    // ================== header ========================================
    LinkAccountrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    console.warn(
      "header ================>>>>>>>>>>>>>>>",
      JSON.stringify(header)
    );

    runEngine.sendMessage(LinkAccountrequestMessage.id, LinkAccountrequestMessage);
    // Customizable Area End
    return true;
  };

  finicityTokenAPIcall = () => {
    // Customizable Area Start
    // headers of API
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // ================== Matches ID's ====================================
    this.finicityTokenAPIcallId = requestMessage.messageId;

    // ================== API URL ====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.finicityTokenAPIEndPoint
    );
    console.warn(
      "API URL ================>>>>>>>>>>>>>> ",
      configJSON.finicityTokenAPIEndPoint
    );

    // ================== API method =================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    console.warn(
      "API Method ================>>>>>>>>>>>>>> ",
      configJSON.getMethod
    );

    // ================== header ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    console.warn(
      "header ================>>>>>>>>>>>>>>>",
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };
}
export default AccountsPageController


// import { IBlock } from "../../../framework/src/IBlock";
// import { Message } from "../../../framework/src/Message";
// import { BlockComponent } from "../../../framework/src/BlockComponent";
// import MessageEnum, {
//   getName,
// } from "../../../framework/src/Messages/MessageEnum";
// import { runEngine } from "../../../framework/src/RunEngine";

// import AsyncStorage from "@react-native-async-storage/async-storage";
// // import axios from "axios";

// // Customizable Area Start
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// // Customizable Area End

// export const configJSON = require("./config");

// export interface Props {
//   navigation: any;
//   id: string;
//   // Customizable Area Start
//   data?: any;
//   // Customizable Area End
// }

// interface S {
//   // Customizable Area Start
//   txtInputValue: string;
//   txtSavedValue: string;
//   enableField: boolean;
//   transactionid: any;
//   data: any;
//   bankData: any;
//   tabData: any;
//   drawerData: any;
//   loading: boolean;
//   errorMsg: string;
//   isOpen: boolean;
//   link_token: any;
//   // Customizable Area End
// }

// interface SS {
//   id: any;
// }

// class AccountsPageController extends BlockComponent<
//   Props,
//   S,
//   SS
// > {
//   finicity_customer_id: any;
//   token: any;
//   plaid_acc_id: any;
//   accountPageAPIcallId: any;
//   _unsubscribe: any;
//   linkedAccAPIcallId: any;
//   finicityTokenAPIcallId: any;
//   finicity_token: any;

//   constructor(props: Props) {
//     super(props);
//     this.receive = this.receive.bind(this);

//     // Customizable Area Start
//     this.subScribedMessages = [
//       getName(MessageEnum.AccoutLoginSuccess),
//       getName(MessageEnum.NavigationRMessage),
//       getName(MessageEnum.RestAPIResponceMessage),
//     ];

//     this.state = {
//       txtInputValue: "",
//       txtSavedValue: "A",
//       enableField: false,
//       transactionid: "",
//       tabData: null,
//       loading: true,
//       errorMsg: "",
//       drawerData: null,
//       isOpen: false,
//       data: [
//         {
//           value: "Bank Of India ",
//           id: 1,
//         },
//         {
//           value: "SBI",
//           id: 2,
//         },
//         {
//           value: "PNB",
//           id: 3,
//         },
//       ],
//       bankData: [],
//       link_token: "",
//     };
//     // Customizable Area End
//     runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
//   }

//   async componentDidMount() {
//     this.getTabData();
//     this.getToken();
//     this._unsubscribe = this.props.navigation.addListener("focus", () => {
//       this.getToken();
//     });
//   }

//   getToken = async () => {
//     try {
//       const token = await AsyncStorage.getItem("tokenLogin");
//       const plaid_acc_id = await AsyncStorage.getItem("plaid_acc_id");
//       const finicityToken = await AsyncStorage.getItem("finicity_token");
//       const finicityCustomerId = await AsyncStorage.getItem(
//         "finicity_customer_id"
//       );
//       console.log("token ================>>>>>>>>>>>>>>>>>", token);
//       console.log(
//         "plaid_acc_id ================>>>>>>>>>>>>>>>>>",
//         plaid_acc_id
//       );
//       this.token = token;
//       this.plaid_acc_id = plaid_acc_id;
//       this.finicity_token = finicityToken;
//       this.finicity_customer_id = finicityCustomerId;
//       this.accountPageAPICall();
//     } catch (e) {
//       // error reading value
//     }
//   };

//   getTabData = () => {
//     if (!this.state.tabData) {
//       const uploadData = {
//         data: "Needed",
//       };

//       const msg: Message = new Message(
//         getName(MessageEnum.NavigationTabMenuMessage)
//       );
//       msg.addData(getName(MessageEnum.NavigationTMessage), uploadData);

//       console.log("Dashboard Broadcasting message");
//       runEngine.sendMessage(msg.id, msg);
//     }
//   };

//   async componentWillUnmount() {
//     runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
//   }

//   async receive(from: string, message: Message) {
//     // Customizable Area Start
//     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
//       const apiRequestCallId = message.getData(
//         getName(MessageEnum.RestAPIResponceDataMessage)
//       );
//       const responseJson = message.getData(
//         getName(MessageEnum.RestAPIResponceSuccessMessage)
//       );
//       const errorReponse = message.getData(
//         getName(MessageEnum.RestAPIResponceErrorMessage)
//       );

//       // Abhijeet
//       if (apiRequestCallId === this.accountPageAPIcallId) {
//         this.getAccountPageData(responseJson, errorReponse);
//       } else if (apiRequestCallId === this.linkedAccAPIcallId) {
//         this.getLinkAccountData(responseJson);
//       } else if (apiRequestCallId === this.finicityTokenAPIcallId) {
//         this.getFinicityTokenData(responseJson);
//       }
//     }

//     if (getName(MessageEnum.NavigationRMessage) === message.id) {
//       const data = message.getData(
//         getName(MessageEnum.NavigationTabDataMessage)
//       );

//       runEngine.unSubscribeFromMessages(this, [
//         getName(MessageEnum.NavigationRMessage),
//       ]);
//       this.setState({
//         tabData: data.data[0].data.attributes,
//         drawerData: data.data[2].data.attributes,
//       });
//     }

//     if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
//       let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

//       this.showAlert(
//         "Change Value",
//         "From: " + this.state.txtSavedValue + " To: " + value
//       );

//       this.setState({ txtSavedValue: value });
//     }

//     // Customizable Area End
//   }

//   // Customizable Area Start
//   getAccountPageData = (response: any, errorResponse: any) => {
//     if (response) {
//       this.setState({
//         errorMsg: "Data Not Found",
//         loading: false,
//         bankData: response.data,
//       });
//     } else {
//       console.warn("I am in Error ===================>>>>>>>>>>>>>>>>>>>>>>");
//     }
//     if (errorResponse) {
//       console.log(errorResponse);
//     }
//   };

//   getLinkAccountData = (response: any) => {
//     if (response) {
//       this.props.navigation.navigate("LinkedAccounts", {
//         res: response.data.table.link,
//       });
//     }
//   };

//   getFinicityTokenData = (response: any) => {
//     if (response) {
//       this.finicity_token = response.data;
//       this.saveFinicityToken(response.data);
//       this.linkAccountAPIcall();
//     }
//   };

//   saveFinicityToken = async (finicity_token: string) => {
//     await AsyncStorage.setItem("finicity_token", finicity_token);
//   };

//   // Customizable Area End

//   getAsset = (item: any): any => {
//     switch (item.icon) {
//       case "Account":
//         return require("../assets/Componente33x.png");
//       case "Budget":
//         return require("../assets/Componente43x.png");
//       case "Transaction":
//         return require("../assets/Componente53x.png");
//       case "Reports":
//         return require("../assets/Componente63x.png");
//       default:
//         return require("../assets/Componente73x.png");
//     }
//   };

//   getColor = (item: any): any => {
//     return item.icon === "Account" ? "rgb(37, 150, 190)" : "rgb(136,136,140)";
//   };

//   nav = (item: any) => {
//     if (item) {
//       const uploadData = {
//         data: item,
//         navigationProp: this.props,
//       };

//       const msg: Message = new Message(
//         getName(MessageEnum.NavigationToMessage)
//       );

//       msg.addData(getName(MessageEnum.NavigationMenuTargetMessage), uploadData);
//       console.log("Dashboard Broadcasting message");
//       runEngine.sendMessage(msg.id, msg);
//     }
//   };

//   // Thai
//   accountPageAPICall = () => {
//     // Customizable Area Start
//     // headers of API
//     const header = {
//       "Content-Type": configJSON.contentTypeApi,
//       token: this.token,
//     };

//     const requestMessage = new Message(
//       getName(MessageEnum.RestAPIRequestMessage)
//     );

//     // ================== Matches ID's ====================================
//     this.accountPageAPIcallId = requestMessage.messageId;

//     // ================== API URL ====================================
//     requestMessage.addData(
//       getName(MessageEnum.RestAPIResponceEndPointMessage),
//       configJSON.accountPageAPIEndPoint +
//         this.finicity_customer_id +
//         "/all_accounts"
//     );

//     // ================== API method =================================
//     requestMessage.addData(
//       getName(MessageEnum.RestAPIRequestMethodMessage),
//       configJSON.getMethod
//     );

//     // ================== header ========================================
//     requestMessage.addData(
//       getName(MessageEnum.RestAPIRequestHeaderMessage),
//       JSON.stringify(header)
//     );
//     console.warn(
//       "header ================>>>>>>>>>>>>>>>",
//       JSON.stringify(header)
//     );

//     runEngine.sendMessage(requestMessage.id, requestMessage);
//     // Customizable Area End
//     return true;
//   };

//   //Thai
//   linkAccountAPIcall = () => {
//     // Customizable Area Start
//     // headers of API
//     const header = {
//       "Content-Type": configJSON.contentTypeApi,
//       token: this.token,
//       fincity_token: this.finicity_token,
//     };

//     // httpBody of API
//     const LinkAccounthttpBody = {
//       customerId: this.finicity_customer_id,
//     };

//     const LinkAccountrequestMessage = new Message(
//       getName(MessageEnum.RestAPIRequestMessage)
//     );

//     // ================== Matches ID's ====================================
//     this.linkedAccAPIcallId = LinkAccountrequestMessage.messageId;

//     // ================== API URL ====================================
//     LinkAccountrequestMessage.addData(
//       getName(MessageEnum.RestAPIResponceEndPointMessage),
//       configJSON.linkedAccAPIEndPoint
//     );
//     console.warn(
//       "API URL ================>>>>>>>>>>>>>> ",
//       configJSON.linkedAccAPIEndPoint
//     );

//     // ================== API method =================================
//     LinkAccountrequestMessage.addData(
//       getName(MessageEnum.RestAPIRequestMethodMessage),
//       configJSON.postMethod
//     );
//     console.warn(
//       "API Method ================>>>>>>>>>>>>>> ",
//       configJSON.postMethod
//     );

//     // ================== httpBody =====================================
//     LinkAccountrequestMessage.addData(
//       getName(MessageEnum.RestAPIRequestBodyMessage),
//       JSON.stringify(LinkAccounthttpBody)
//     );
//     console.warn(
//       "httpBody ================>>>>>>>>>>>>>> ",
//       JSON.stringify(LinkAccounthttpBody)
//     );

//     // ================== header ========================================
//     LinkAccountrequestMessage.addData(
//       getName(MessageEnum.RestAPIRequestHeaderMessage),
//       JSON.stringify(header)
//     );
//     console.warn(
//       "header ================>>>>>>>>>>>>>>>",
//       JSON.stringify(header)
//     );

//     runEngine.sendMessage(LinkAccountrequestMessage.id, LinkAccountrequestMessage);
//     // Customizable Area End
//     return true;
//   };

//   finicityTokenAPIcall = () => {
//     // Customizable Area Start
//     // headers of API
//     const header = {
//       "Content-Type": configJSON.contentTypeApi,
//       token: this.token,
//     };

//     const requestMessage = new Message(
//       getName(MessageEnum.RestAPIRequestMessage)
//     );

//     // ================== Matches ID's ====================================
//     this.finicityTokenAPIcallId = requestMessage.messageId;

//     // ================== API URL ====================================
//     requestMessage.addData(
//       getName(MessageEnum.RestAPIResponceEndPointMessage),
//       configJSON.finicityTokenAPIEndPoint
//     );
//     console.warn(
//       "API URL ================>>>>>>>>>>>>>> ",
//       configJSON.finicityTokenAPIEndPoint
//     );

//     // ================== API method =================================
//     requestMessage.addData(
//       getName(MessageEnum.RestAPIRequestMethodMessage),
//       configJSON.getMethod
//     );
//     console.warn(
//       "API Method ================>>>>>>>>>>>>>> ",
//       configJSON.getMethod
//     );

//     // ================== header ========================================
//     requestMessage.addData(
//       getName(MessageEnum.RestAPIRequestHeaderMessage),
//       JSON.stringify(header)
//     );
//     console.warn(
//       "header ================>>>>>>>>>>>>>>>",
//       JSON.stringify(header)
//     );

//     runEngine.sendMessage(requestMessage.id, requestMessage);
//     // Customizable Area End
//     return true;
//   };
// }
// export default  AccountsPageController
