//@ts-nocheck
// Customizable Area Start
import React from "react";
const logo = require("../assets/header_logo.png");
import { Row, Col, Card, Image, Divider, Input, Form, Button } from "antd";
import { bgImage } from "./assets";
export const configJSON = require("./config");
import TopNav from "../../../components/src/TopNav";
import NewPasswordControllerWeb from "./NewPasswordController.web";

export default class NewPasswordWeb extends NewPasswordControllerWeb {
  render() {
    return (
      <>
        <TopNav />
        <Row
          align="middle"
          justify="center"
          style={{
            height: "100vh",
            width: "100vw",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            position: "fixed",
            overflow: "auto",
            paddingBottom: "20px",
            marginBottom: "30px"
          }}
        >
          <Col lg={10} md={13} sm={16} xs={21} style={{ marginBottom: "50px" }}>
            <Card>
              <Row justify="center">
                <Image src={logo} width="100px" preview={false} />
              </Row>
              <Divider />
              <Row>
                <Col span={2} />
                <Col span={20}>
                  <Form
                    ref={this.formRef}
                    layout="vertical"
                    onFinish={this.onFinish}
                    data-test-id="form"
                  >
                    <Row justify="center">
                      <h1
                        style={{ fontWeight: "bold", color: "rgb(84, 88, 97)" }}
                      >
                        Create a password
                      </h1>
                    </Row>
                    <Row align="middle" justify="center">
                      <Col
                        span={24}
                        style={{ textAlign: "center", marginBottom: "30px" }}
                      >
                        The password must include combination of uppercase,
                        lowercase, numbers and special character and have a
                        minimum of eight characters.
                      </Col>
                    </Row>
                    <Row>
                      <Form.Item
                        data-test-id="newPassword"
                        style={{ width: "100%" }}
                        name="new_password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!"
                          },
                          {
                            pattern: new RegExp(
                              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                            ),
                            message:
                              "Password must be 8 character and contains at least one A-Z, a-z, 0-9, @$%#"
                          }
                        ]}
                        label={<span style={{ color: "gray" }}>Password</span>}
                      >
                        <Input.Password placeholder="Your password" />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        data-test-id="passwordConfirmation"
                        name="password_confirmation"
                        style={{ width: "100%" }}
                        label="Confirm Password"
                        dependencies={["new_password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!"
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("new_password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The confirm password that you entered do not match!"
                                )
                              );
                            }
                          })
                        ]}
                      >
                        <Input.Password placeholder="Re-type your password" />
                      </Form.Item>
                    </Row>
                    <Row style={{ marginTop: "50px" }}>
                      <Form.Item style={{ width: "100%" }} shouldUpdate>
                        {() => (
                          <Button
                            type="primary"
                            data-test-id="passwordConfirmationSubmit"
                            htmlType="submit"
                            loading={this.state.loading}
                            style={{ borderRadius: "15px", width: "100%" }}
                            disabled={
                              this.formRef.current
                                ? !this.formRef.current.isFieldsTouched(true) ||
                                  !!this.formRef.current
                                    .getFieldsError()
                                    .filter(({ errors }: any) => errors.length)
                                    .length
                                : true
                            }
                          >
                            CONTINUE
                          </Button>
                        )}
                      </Form.Item>
                    </Row>
                  </Form>
                </Col>
                <Col span={2} />
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
// Customizable Area End
