//@ts-nocheck
// Customizable Area Start
import React from "react";
const logo = require("../assets/header_logo.png");
import { Row, Col, Card, Image, Divider, Input, Form, Button } from "antd";
import { bgImage } from "./assets";
export const configJSON = require("./config");

import TopNav from "../../../components/src/TopNav";
import ForgotPasswordControllerWeb, {
  Props
} from "./ForgotPasswordController.web";

export default class ForgotPasswordWeb extends ForgotPasswordControllerWeb {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        <TopNav />
        <Row
          align="middle"
          justify="center"
          style={{
            height: "100vh",
            width: "100vw",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            position: "fixed",
            overflow: "auto",
            paddingBottom: "20px",
            marginBottom: "30px"
          }}
        >
          <Col lg={10} md={13} sm={16} xs={21}>
            <Card>
              <Row justify="center">
                <Image src={logo} width="100px" preview={false} />
              </Row>
              <Divider />
              <Row>
                <Col span={2} />
                <Col span={20}>
                  <Form
                    ref={this.formRef}
                    layout="vertical"
                    onFinish={this.onFinish}
                    data-test-id="form"
                  >
                    <Row justify="center">
                      <h1
                        style={{ fontWeight: "bold", color: "rgb(84, 88, 97)" }}
                      >
                        Forgot your Password?
                      </h1>
                    </Row>
                    <Row align="middle" justify="center">
                      <Col span={15} style={{ textAlign: "center" }}>
                        Enter your email address to <br /> receive a password
                        reset link
                      </Col>
                    </Row>
                    <Row>
                      <Form.Item
                        style={{ width: "100%" }}
                        extra={
                          <span style={{ color: "red" }}>
                            {this.state.errMsg ? this.state.errMsg : ""}
                          </span>
                        }
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!"
                          },
                          { type: "email", message: "Please enter valid email" }
                        ]}
                        label={<span style={{ color: "gray" }}>Email</span>}
                      >
                        <Input placeholder="Your email" />
                      </Form.Item>
                    </Row>
                    <Row style={{ marginTop: "50px" }}>
                      <Form.Item
                        style={{ width: "100%" }}
                        shouldUpdate
                        data-test-id="formItem"
                      >
                        {() => (
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.loading}
                            style={{ borderRadius: "15px", width: "100%" }}
                            disabled={
                              this.formRef.current
                                ? !this.formRef.current.isFieldsTouched(true) ||
                                  !!this.formRef.current
                                    .getFieldsError()
                                    .filter(({ errors }: any) => errors.length)
                                    .length
                                : true
                            }
                          >
                            Send email
                          </Button>
                        )}
                      </Form.Item>
                    </Row>
                  </Form>
                </Col>
                <Col span={2} />
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
// Customizable Area End
