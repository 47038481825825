//@ts-nocheck
import React from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Progress,
  Row,
} from "antd";
import bgImage from "../assets/web_bg.png";
const logo = require("../assets/slide1.png");
export const configJSON = require("./config");
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
import OnBoardingScreenWebController from "./OnboardingScreenWebController.web";
import { Props } from "./OnboardingScreenController";

export default class OnboardingScreenWeb extends OnBoardingScreenWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area Endv
  }
  // callback(key: any) {
  //   console.log(key);
  // }
  render() {

    return (
      <><TopNav />
        <Row align="middle" justify="center" style={{
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          position: "fixed",
          overflow: "auto",
          paddingBottom: "20px",
          marginBottom: "30px",
        }}>
          <Col lg={10} md={13} sm={16} xs={21}>
            <Card>
              <Form ref={this.form} layout="vertical" onFinish={() => this.onFinish()} data-test-id={"submit-form"}>
                <Row
                  justify="center"
                  style={{
                    fontSize: 33,
                    fontWeight: "bold",
                    color: "rgb(84, 88, 97)",
                  }}
                >
                  Welcome{" "}
                </Row>
                <Row
                  justify="center"
                  style={{
                    fontWeight: "normal",
                    fontSize: 18,
                    color: "rgb(104, 105, 108)",
                    marginTop: "-13px",
                    marginBottom: "20px",
                  }}
                >
                  to B.E.S.T. Budget that will allow you
                </Row>
                <Divider />
                <Row justify="center">
                  <Col span={10}>
                    <Image src={logo} preview={false} />
                  </Col>
                </Row>
                <Row
                  justify="center"
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "rgb(104, 105, 108)",
                  }}
                >
                  <Col span={24}>
                    Identify Monthly
                    <br /> Take Home Pay
                  </Col>
                </Row>

                <Row
                  justify="center"
                  style={{ marginTop: "1em", marginBottom: "4em" }}
                >
                  <Col span={24} style={{ textAlign: "center" }}>
                    <h3>Enter monthly home pay</h3>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      style={{ width: "100%" }}
                      name="homePay"
                      rules={[
                        {
                          required: true,
                          message: "Please input your homePay!",
                        },
                      ]}
                    >
                      <Input type="number" style={{ borderRadius: "5px", textAlign: "center" }} ref={"homePay"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={18}>
                    <Progress percent={20} status="active" showInfo={false} />
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "1em" }}>
                  <Col>
                    <Button
                      shape="round"
                      disabled
                      ref={this.form}
                      icon={<LeftOutlined translate />}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col>
                    <Form.Item style={{ width: "100%" }} shouldUpdate>
                      {() => (
                        <Button
                          shape="round"
                          type="primary"
                          htmlType="submit"
                          loading={this.state.loading}
                          ref={this.form}
                        // disabled={
                        //   !form.isFieldsTouched(true) ||
                        //   !!form
                        //     .getFieldsError()
                        //     .filter(({ errors }: any) => errors.length).length
                        // }
                        >
                          SAVE & CONTINUE <RightOutlined translate />
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}