const { baseURL } = require("../../../framework/src/config");

Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.baseURL=`${baseURL}`

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "StatisticsReports";
exports.labelBodyText = "StatisticsReports Body";
exports.btnExampleTitle = "CLICK ME";
exports.budgetContentType = "application/json";
exports.budgetMeyhodType = "POST";
exports.budgetEndpoint = "bx_block_categories/get_categories_with_amount";
exports.catagoryEndpoint = "bx_block_categories/get_all_categories";
exports.reportMethodType = "GET";
exports.ContentType = "application/json";
exports.reveniewtUrl = "bx_block_dashboard/get_savings_account_det";
exports.necessityApiMethodType = "POST";
exports.transationurl = "bx_block_transactions/transactions";
exports.dashboarContentType = "application/json";
exports.necessityApiMethodType = "POST";
exports.apihomePayGetUrl = "/bx_block_dashboard/dashboards";
exports.catagoryNameSelection ="Select Category name"
exports.catagoryName ="Select category"
exports.editcatagoryAmountPostUrl =
  `${baseURL}bx_block_categories/update_name_amount_to_subcategory`;
exports.editApiMethodType = "PUT";
exports.deleteurl = "/bx_block_categories/delete_sub_category";
exports.deleteApiMethodType = "DELETE";
exports.postMethod = "POST";
exports.getMethod = "GET";
exports.AddnewSubcategoryPostUrl =
  "/bx_block_categories/add_amount_to_subcategory";
exports.necessityApiMethodType = "POST";
exports.addcatagoryContentType = "application/json";
exports.allowOriginType = "*";
exports.alertMessage = "Take home income must be sum of Necessity Expenses and Descretionary Expenses";

// Customizable Area End
