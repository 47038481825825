export const setUserData = (payload: any) => ({
  type: 'SET_USER_DATA',
  payload
});
 
export const loginToken = (payload: any) => ({ type: 'LOGIN_TOKEN', payload });
export const reallocateFromData = (payload:any)=>({type:'REALLOCATE_FROM_DATA',payload})
export const reallocateToData = (payload:any)=>({type:'REALLOCATE_TO_DATA',payload})
export const amount = (payload:any)=>({type:'AMOUNT',payload})
export const splitTransactionRes = (payload:any)=>({type:'SPLIT_TRANSACTIONS_RES',payload})
