//@ts-nocheck
import React from "react";
import { Button, Card, Col, Divider, Image, Input, Progress, Row } from "antd";
import axios from "axios";
import { Redirect } from "react-router-dom";
import bgImage from "../assets/web_bg.png";
const bestLogo = require("../assets/logo.png");
const chart = require("../assets/slide4.png");
export const configJSON = require("./config");
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import "../src/style.css";
import { openErrorNotification, openNotification } from "../../../components/src/Notification.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";


export interface Props {
  navigation: any;
  id: string;
}
interface S {
  income: string;
  cardLoading: boolean;
  redirect: boolean;
}
interface SS {
  id: any;
}


export default class DiscretioneryIncomesWeb extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      redirect: false,
      cardLoading: true,
      income: null
    };
  }
  setIncome = (income: string) => this.setState({income});
  setCardLoading = (cardLoading: boolean) => this.setState({cardLoading});
  setRedirect = (redirect: boolean) => this.setState({redirect});

  async componentDidMount() {
    this.handleSave();
  }
  handleSave = async () => {
    const data = {
      account_id: localStorage.getItem("accountId"),
    };
    await axios.post(
      `${configJSON.baseURL}bx_block_dashboard/get_discretionary_income`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("loginToken"),
        },
      }
    ).then((res: any) => {
      this.setCardLoading(false)
      this.setIncome(res.data.data.discretionary_income)
    }).catch((err: any) => {
      this.setCardLoading(false)
      if (err?.response?.status == 401 || err?.response?.status == 400){
        Cookies.remove("accountId");
        Cookies.remove("name");
        Cookies.remove("loginToken");
        this.setRedirect(true)
      }
      openErrorNotification(err?.response?.data?.error, "error")
    })
  };

  onFinish = async () => {
    window.location.href = "/BudgetDiscretioneryIncomesWeb";
  };

  render() {
    return (
      <>
        {this.state.redirect ? (<Redirect push to="/EmailAccountLoginWeb" />) : null}
      <TopNav />
        {this.state.cardLoading ?
          <LoadingWeb />
          :
          <Row align="middle" justify="center" style={{
            height: "100vh",
            width: "100vw",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            position: "fixed",
            overflow: "auto",
            paddingBottom: "20px",
            marginBottom: "30px",
          }}>
            <Col lg={10} md={13} sm={16} xs={21}>
              <Card>
                <Row justify="center">
                  <Col span={3}>
                    <Image src={bestLogo} preview={false} />
                  </Col>
                </Row>
                <Divider />
                <Row justify="center">
                  <Col className="discretionary-image" span={8}
                    style={{display:"flex", justifyContent:"center"}} >
                    <Image src={chart} preview={false} />
                  </Col>
                </Row>
                <Row
                  justify="center"
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "rgb(104, 105, 108)",
                  }}
                >
                  <Col span={24}>This is your Discretionary Income .</Col>
                </Row>
                <Row
                  justify="center"
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "rgb(104, 105, 108)",
                  }}
                >
                  <Col span={24}>(Take Home Pay minus Necessity Expenses.)</Col>
                </Row>
                <Row
                  justify="center"
                  style={{ marginTop: "1em", marginBottom: "4em" }}
                >
                  <Col span={24} style={{ textAlign: "center" }}>
                    <h3>Expenses to subtract from Take Home Payment</h3>
                  </Col>
                  <Col span={12}>
                    <Input
                      onChange = {(e) => {
                        this.setIncome(e.target.value)
                      }}
                      type="number"
                      value={this.state.income}
                      style={{ borderRadius: "5px", textAlign: "center" }}
                    />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={18}>
                    <Progress percent={60} status="active" showInfo={false} />
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "1em" }}>
                  <Button
                    shape="round"
                    type="primary"
                    style={{ backgroundColor: "#b3b3b3", color: "white" }}
                    onClick={() => window.location.href = "/AddMonthlyNecessityExpensesWeb"}
                    icon={<LeftOutlined translate />}
                  >
                    Back
                  </Button>
                  <Button
                    shape="round"
                    type="primary"
                    onClick={() => this.onFinish()}
                  >
                    SAVE & CONTINUE <RightOutlined translate />
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        }</>
    );
  }
};
