import React from "react";
import {
  Row,
  Col,
  Card,
  Image,
  Divider,
  Input,
  Form,
  Button,
  Checkbox,
  FormInstance,
} from "antd";
import axios from "axios";
import "antd/dist/antd.css";
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
import { bgImage } from "./assets";
import Cookies from "js-cookie";
import { openErrorNotification } from "../../../components/src/Notification.web";
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
// @ts-ignore
import store from "../../../web/src/store/index.js";
// @ts-ignore
import { loginToken } from "../../../web/src/redux/actions/users";
import { withRouter } from "react-router-dom";
const logo = require("../assets/header_logo.png");
export const configJSON = require("./config");

export class EmailAccountLoginWeb extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  setLoading = (loading: boolean) => this.setState({ loading });
  setFirebaseToken = (firebaseToken: string) =>
    this.setState({ firebaseToken });
  setRemeber = (remeber: boolean) => this.setState({ remeber });
  setForceUpdate = (forceUpdate: any) => this.setState({ forceUpdate });
  // const form = Form.
  // const [firebaseToken, setFirebaseToken] = useState('')
  // const [remeber, setRemeber] = useState(false)
  // const form = Form.create();
  // const [, forceUpdate] = useState({});
  formRef = React.createRef<FormInstance>();
  onFinish = async (value: any) => {
    this.formRef.current?.resetFields();
    this.setLoading(true);
    const data = {
      data: {
        type: "email_account",
        attributes: { ...value, firebase_token: this.state.firebaseToken },
      },
    };
    await axios
      .post(`${configJSON.baseURL}bx_block_login/login`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((result: any) => {
        this.setLoading(false);
        this.formRef.current?.resetFields();
        localStorage.setItem("loginToken", result.data.meta.token);
        localStorage.setItem("accountId", result.data.data.id);
        localStorage.setItem("name", result.data.data.first_name);
        localStorage.setItem("email", result.data.data.email);
        localStorage.setItem(
          "customerId",
          result.data.data.finicity_customer_id
        );
        localStorage.setItem(
          "if_account_linked",
          result.data.if_account_linked
        );
        localStorage.setItem(
          "is_onboarding_completed",
          result.data.is_onboarding_completed
        );
        localStorage.setItem("username", result.data.data.username);
        this.getFinicityToken(
          result.data.meta.token,
          result.data.data.finicity_customer_id,
          result.data.if_new_user
        );
        store.dispatch(loginToken(result.data.meta.token));
        if (this.state.remeber) {
          Cookies.set("loginToken", result.data.meta.token);
          Cookies.set("accountId", result.data.data.id);
          Cookies.set(
            "name",
            result.data.data.first_name + " " + result.data.data.last_name
          );
        }
        if (!result.data.data?.subscription_status) {
          localStorage.setItem(
            "subscription_type",
            result.data.data.subscription_type
          );
          this.props.history.push('/SubscriptionTrialWeb')
          return
        }
        if (result.data.if_account_linked && !result.data.if_new_user)
          this.props.history.push('/Dashboard');
        else {
          this.props.history.push('/LinkedAccountsWelcomeScreenWeb');
        }
      })
      .catch((err: any) => {
        this.setLoading(false);
        openErrorNotification(
          err.response.data.errors[0].failed_login,
          "error"
        );
      });
  };

  getFinicityToken = async (
    value: string,
    finicityCustomerId: string,
    ifNewUser: string
  ) => {
    await axios
      .get(`${configJSON.baseURL}bx_block_finicity/finicity_token`, {
        headers: {
          token: value,
        },
      })
      .then(async (result: any) => {
        localStorage.setItem("finicityToken", result.data.data);
        if (ifNewUser || !finicityCustomerId) {
          await this.createFinicityTestCustomer(result.data.data);
          await this.createFinicityCustomer(result.data.data);
        }
      })
      .catch((err: any) => {
        openErrorNotification(err.response.data.errors[0].token, "error");
      });
  };

  createFinicityTestCustomer = async (value: string) => {
    const body = {
      user_name: localStorage.getItem("email"),
      fincity_token: value,
    };
    await axios
      .post(
        `${configJSON.baseURL}bx_block_finicity/create_test_customer`,
        body,
        {
          headers: {
            token: localStorage.getItem("loginToken"),
            fincity_token: value,
          },
        }
      )
      .then((result: any) => {
        if (result.data.data.table?.code) {
          openErrorNotification(result.data.data.table.message, "error");
        } else {
          localStorage.setItem("customerId", result.data.data.table.id);
        }
      })
      .catch((err: any) => {
          openErrorNotification(err.response.data.errors[0].token, "error");
      });
  };

  createFinicityCustomer = async (value: string) => {
    const body = {
      firstName: localStorage.getItem("username"),
      lastName: "Smith",
      address: "434 W Ascension Way",
      city: "Murray",
      state: "UT",
      zip: "84123",
      phone: "1-801-984-4200",
      ssn: "999-99-9999",
      birthday: {
        year: 1989,
        month: 8,
        dayOfMonth: 13,
      },
      email: localStorage.getItem("email"),
      suffix: "PhD",
    };

    await axios
      .post(
        `${configJSON.baseURL}bx_block_finicity/customer/${localStorage.getItem(
          "customerId"
        )}/create_consumer`,
        body,
        {
          headers: {
            token: localStorage.getItem("loginToken"),
            fincity_token: value,
          },
        }
      )
      .then((result: any) => {})
      .catch((err: any) => {});
  };

  // To disable submit button at the beginning.
  checkAuth = () => {
    if (!window.localStorage.getItem("loginToken")){
      window.localStorage.clear();
      return
    }
    this.props.history.push('/Dashboard');
  };

  async componentDidMount() {
    this.setForceUpdate({});
    // this.getToken(this.setFirebaseToken)
    this.checkAuth();
  }

  render() {
    return (
      <>
        <TopNav />
        <Row
          align="middle"
          justify="center"
          style={{
            height: "100vh",
            width: "100vw",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            position: "fixed",
            overflow: "auto",
            paddingBottom: "20px",
            marginBottom: "30px",
          }}
        >
          <Col lg={10} md={13} sm={16} xs={21}>
            <Card>
              <Row justify="center">
                <Image src={logo} width="100px" preview={false} />
              </Row>
              <Divider />
              <Row>
                <Col span={2} />
                <Col span={20}>
                  <Form
                    ref={this.formRef}
                    layout="vertical"
                    onFinish={this.onFinish}
                  >
                    <Row>
                      <h1
                        style={{ fontWeight: "bold", color: "rgb(84, 88, 97)" }}
                      >
                        Sign in to Continue
                      </h1>
                    </Row>
                    <Row>
                      <Form.Item
                        test-id="email-test"
                        style={{ width: "100%" }}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                          {
                            type: "email",
                            message: "Please enter valid email",
                          },
                        ]}
                        label={<span style={{ color: "gray" }}>Email</span>}
                      >
                        <Input placeholder="Your email" />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        test-id={"pass-test"}
                        style={{ width: "100%" }}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                        label={<span style={{ color: "gray" }}>Password</span>}
                      >
                        <Input.Password placeholder="Your password" />
                      </Form.Item>
                    </Row>
                    <Form.Item
                      valuePropName="checked"
                      noStyle
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Checkbox
                        test-id={"check-box-test"}
                        onChange={(e: any) => this.setRemeber(e.target.checked)}
                      >
                        Remember me
                      </Checkbox>
                    </Form.Item>
                    <Row
                      style={{ marginTop: "50px" }}
                      test-id={"submit-row-test"}
                    >
                      <Form.Item style={{ width: "100%" }} shouldUpdate>
                        {() => (
                          <Button
                            test-id={"submit-test"}
                            type="primary"
                            htmlType="submit"
                            loading={this.state.loading}
                            style={{ borderRadius: "15px", width: "100%" }}
                            disabled={
                              !this.formRef.current?.isFieldsTouched(true) ||
                              !!this.formRef.current
                                ?.getFieldsError()
                                .filter(({ errors }: any) => errors.length)
                                .length
                            }
                          >
                            LOGIN
                          </Button>
                        )}
                      </Form.Item>
                    </Row>
                  </Form>
                  <Row justify="center">
                    <p test-id={"forgot-test"}
                      style={{ color: "rgb(45, 173, 225)", cursor: "pointer" }}
                      onClick={() =>
                        this.props.history.push('/ForgotPasswordWeb')
                      }
                    >
                      Forget your password?
                    </p>
                  </Row>
                </Col>
                <Col span={2} />
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

// @ts-ignore
export default withRouter(EmailAccountLoginWeb);
