import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Alert, Platform } from "react-native";
import moment from "moment";

// Customizable Area Start
import React from 'react';
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  reveniewData: any;
  token: any;
  plaid_acc_id: any;
  catloading: boolean;
  customer: any;
  startdate: any;
  dashboardData: any;
  errorMsg: string;
  homepay: any;
  loading: boolean;
  enddate: any;
  accountId: string;
  selectedValue: string;
  nameValue: string;
  genericNameValue: string;
  catagorys: any;
  report: any;
  report002: any;
  reportDate: string;
  reportAmount: string;
  modalVisible: any;
  fromDate: any;
  toDate: any;
  resultAmount: any;
  resultDate: any;
  totalAmount: any;
  reservedFunds: any;
  tabData: any;
  drawerData: any;
  totalSavings: any;
  isOpen: boolean;
  finicity_token: string;
  cardLoading: any;
  access_token: any;
  tableData: any;
  tableExport: any;
  categoryData: any;
  startDate: any;
  endDate: any;
  reportWithData: any;
  runQueryBtnColor: any;
  StatisticsReports: any;
  columnData: any;
  loadingback: any;
  overflow: any;
  zIndexing: any;
  progress: any;
  displayFlex: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class StatisticsReportsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // apiBudgetCallId: string = "";
  apicatagoryCallId: string = "";
  apiReportCallId: string = "";
  apisendCallId: string = "";
  _unsubscribe: any;
  PickerItem: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionSaveMessage),
      // getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationRMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      dashboardData: [],
      reveniewData: [],
      accountId: "",
      token: "",
      plaid_acc_id: "",
      loading: false,
      catloading: false,
      errorMsg: "",
      homepay: "",
      startdate: "",
      enddate: "",
      selectedValue: "",
      nameValue: "",
      genericNameValue: "",
      modalVisible: false,
      catagorys: [],
      report: [],
      report002: [],
      reportDate: "",
      reportAmount: "",
      fromDate: "",
      toDate: "",
      resultAmount: "",
      resultDate: "",
      customer: "",
      totalAmount: "",
      reservedFunds: "",
      tabData: null,
      drawerData: null,
      totalSavings: "",
      isOpen: false,
      finicity_token: "",
      cardLoading: false,
      access_token: "",
      displayFlex: "flex",
      tableData: [],
      tableExport: [],
      categoryData: [],
      startDate: "",
      endDate: "",
      reportWithData: [],
      runQueryBtnColor: "#00ade2",
      StatisticsReports: [],
      progress: 0,
      loadingback: "none",
      overflow: "hidden",
      zIndexing: 2,
      columnData: [
        {
          title: "",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "",
          dataIndex: "amount",
          key: "amount",
          render: (text: any, record: any, index: any) => (
            <p>
              {text < 0 ? (<span style={{ fontSize: 16, fontWeight: "bold", color: " red" }}>{text}</span>) : (<span style={{ fontSize: 16, fontWeight: "bold", color: " #00a950" }}>{text}</span>)}
            </p>
          )
        },
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {

    if (Platform.OS === "web") {
      this.getAllCategoryApi();
    } else {
      this.tabdataFunction();
      this.getToken();
      this._unsubscribe = this.props.navigation.addListener("focus", () => {
        this.getToken();
      });
    }

  }

  tabdataFunction = () => {
    if (!this.state.tabData) {
      const uploadData = {
        data: "Needed",
      };
      const msg: Message = new Message(
        getName(MessageEnum.NavigationTabMenuMessage)
      );
      msg.addData(getName(MessageEnum.NavigationTMessage), uploadData);

      console.log("Dashboard Broadcasting message");
      runEngine.sendMessage(msg.id, msg);
    }
  };

  getToken = async () => {
    const token = await AsyncStorage.getItem("tokenLogin");
    const plaid_acc_id = await AsyncStorage.getItem("plaid_acc_id");
    const data: any = await AsyncStorage.getItem("userData");
    const userData = JSON.parse(data);
    const finicityToken = await AsyncStorage.getItem("finicity_token");
    console.log(
      "plaid_acc_id ========>>>>>>>>>>>>>>>>>>>>>>",
      plaid_acc_id,
      token
    );
    this.setState(
      {
        token: token,
        plaid_acc_id: plaid_acc_id,
        accountId: userData.data.id,
        catloading: true,
        customer: userData.data.finicity_customer_id,
        finicity_token: finicityToken ? finicityToken : "",
      },
      () => {
        this.getAllcatagory();
      }
    );
  };

  async componentWillUnmount() {
    if (Platform.OS !== "web") {
      this._unsubscribe();
      runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    //runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationRMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationTabDataMessage)
      );
      console.log("TAB DATA!", data.data[1].data.attributes);
      runEngine.unSubscribeFromMessages(this, [
        getName(MessageEnum.NavigationRMessage),
      ]);

      this.setState({
        tabData: data.data[0].data.attributes,
        drawerData: data.data[2].data.attributes,
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apicatagoryCallId) {
          this.getCatagoryData(responseJson, errorReponse);
        } else if (apiRequestCallId === this.apiReportCallId) {
          this.getReportData(responseJson, errorReponse);
        } else if (apiRequestCallId === this.apisendCallId) {
          this.getAPISendData(responseJson, errorReponse);
        }
      } else {
        this.setState({
          loading: false,
          catloading: false,
        });
      }
    }
    // Customizable Area End
  }

  getCatagoryData = (response: any, errorResponse: any) => {
    if (!response.errors) {
      console.log("catddddd ", JSON.stringify(response));
      // subscription_expired?
      if (response["subscription_expired?"] === true) {
        this.props.navigation.navigate("TrialExpiry");
      }
      this.setState({
        catagorys: response.data,
        errorMsg: "No data !!!",
        catloading: false,
      });
    } else {
      console.log("error resp", errorResponse);
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          catloading: false,
        });
      } else {
        this.setState({
          errorMsg: errorResponse,
          catloading: false,
        });
      }
    }
  };

  getReportData = (response: any, errorResponse: any) => {
    console.log(
      "report ===================>>>>>>>>",
      JSON.stringify(response),
      JSON.stringify(errorResponse),
    );
    if (response) {
      if (!response.error) {

        // subscription_expired?
        if (response["subscription_expired?"] === true) {
          this.props.navigation.navigate("TrialExpiry");
        }

        if (response.data === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            catloading: false,
          });
        } else {
          this.setState(
            {
              report002: response,
              report: response.data,

              errorMsg: JSON.stringify(response.message),
              catloading: false,
              // resultAmount: responseJson.data.ta
            },
            () =>
              console.log(
                "this.state.report ======>>>>>>>>>>>>>>>>",
                this.state.report
              )
          );
          console.log("eeooreports", response.data);
        }
      } else {
        console.log("hghg");
        this.setState({
          report: [],
          errorMsg: response.error,
          catloading: false,
        });
      }
    } else {
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          catloading: false,
        });
      } else {
        this.setState({
          errorMsg: errorResponse,
          catloading: false,
        });
      }
    }
  };

  getAPISendData = (response: any, errorResponse: any) => {
    console.log("INDISE SEND EAMIASASASAS");
    if (response) {
      if (!response.error) {
        if (response.status == "OK") {
          this.setState({ modalVisible: false });
          console.log("report ", JSON.stringify(response));
          Alert.alert("Success", "Check your mail !!");
        }
      } else {
        this.setState({ modalVisible: false });
        Alert.alert("Failed", response.error);
      }
    } else {
      console.log("error resp", errorResponse);
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          catloading: false,
        });
        this.showAlert("error", this.state.errorMsg);
      } else {
        this.setState({
          errorMsg: errorResponse,
          catloading: false,
        });
        this.showAlert("error", this.state.errorMsg);
      }
    }
  };


  handleChange(value: any) {
    localStorage.setItem('categoryID', JSON.stringify(value))
  }

  startDate(startDate: any) {
    this.setState({ startDate: startDate })
  }

  endDate(endDate: any) {
    this.setState({ endDate: endDate })
  }

  getAllCategoryApi = async () => {
    const result = await axios.get(
      `${configJSON.baseURL}bx_block_categories/get_all_categories`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "token": localStorage.getItem("loginToken"),
        "plaid-account-id": localStorage.getItem("plaid_acc_id"),
      },
    }
    );
    if (result) {
      this.setState({ categoryData: result?.data?.data })
    }
  };

  runQuery = async () => {
    this.setState({ cardLoading: true })
    const startDate = moment(this.state.startDate).unix()
    const endDate = moment(this.state.endDate).unix()
    const categoryId = localStorage.getItem("categoryID")
    axios.get(
      `${configJSON.baseURL}bx_block_statisticsreports/statistics_report_transactions?fromDate=${startDate}&toDate=${endDate}&start=1&limit=1000&sort=desc&includePending=false&send_by_email=false&category_id=${categoryId}`,
      {
        headers: {
          "token": localStorage.getItem("loginToken")
        },
      }
    ).then((response: any) => {
      this.setState({ reportWithData: response?.data?.data, runQueryBtnColor: "#B2B2B2" })
      this.setState({ StatisticsReports: response?.data?.data, cardLoading: false }, () => {
        const data = response?.data?.data?.map((values: any) => ({
          key: values.account_id,
          name: <span><span style={{ fontWeight: "bold" }}>{values.category_name}</span>
            <br /><span style={{ fontSize: 10 }}>{values.transaction_date}</span></span>,
          amount: values.amount
        }))
        const exportReport = response?.data?.data?.map((values: any) => ({
          ["Account ID"]: values.account_id,
          ["Category Name"]: values.category_name,
          ["Transaction Date"]: values.transaction_date,
          ["Amount"]: values.amount
        }))
        if (data && exportReport) {
          this.setState({ tableData: data, tableExport: exportReport })
        }
        else {
          openNotification(response?.data?.error, "error")
        }
      })
    }).catch((err: any) => {
      if (err?.response?.status == "404") {
        this.setState({ runQueryBtnColor: "#B2B2B2", cardLoading: false });
        openNotification(err?.response?.data?.message, "error")
      }
    });
  }

  getAllcatagory = () => {
    const header = {
      "Content-Type": configJSON.budgetContentType,
      token: this.state.token,
    };
    console.log("header  ", JSON.stringify(header));

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);
    this.apicatagoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.catagoryEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  report = () => {
    console.log("tokennnnnnnnn", this.state.token);

    this.setState({ catloading: true, errorMsg: "" })
    let reportEndpoint;
    if (this.state.selectedValue) {
      reportEndpoint = `bx_block_statisticsreports/statistics_report_transactions?fromDate=${moment(this.state.fromDate, "YYYY-MM-DD").unix()
        }&toDate=${moment(this.state.toDate, "YYYY-MM-DD").unix()
        }&start=1&limit=1000&sort=desc&includePending=false&send_by_email=false&name=${this.state.nameValue}&generic_name=${this.state.genericNameValue}&category_id=${this.state.selectedValue}`;
    } else {
      reportEndpoint = `bx_block_statisticsreports/statistics_report_transactions?fromDate=${moment(this.state.fromDate, "YYYY-MM-DD").unix()
        }&toDate=${moment(this.state.toDate, "YYYY-MM-DD").unix()
        }&start=1&limit=1000&sort=desc&includePending=false&send_by_email=false`;
    }


    const header = {
      "Content-Type": configJSON.budgetContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReportCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      reportEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.reportMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  sendmail = () => {
    let reportEndpoint = `${configJSON.baseURL}bx_block_statisticsreports/statistics_report_transactions?fromDate=${moment(this.state.fromDate, "YYYY-MM-DD").unix()
      }&toDate=${moment(this.state.toDate, "YYYY-MM-DD").unix()
      }&start=1&limit=1000&sort=desc&includePending=false&send_by_email=true&category_id=${this.state.selectedValue}`;

    const header = {
      "Content-Type": configJSON.budgetContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apisendCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      reportEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.reportMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  datestartchange = {
    onDateChange: (date1: any) => {
      this.setState({ startdate: date1 }, () => {
        console.log("date1", date1);

        // yyyy-MM-dd
        const [day, month, year] = date1.split("-");
        // dd/mm/yyyy
        console.log("YY-MM-DD =======>>>>>>>>", `${year}-${month}-${day}`);
        const newDates = `${year}-${month}-${day}`;
        // var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
        //   var newDate = new Date(Date.UTC (myDate[2], myDate[1] - 1, myDate[0],0,0,0));
        //   var newDates=
        // // var newDates = (newDate.getTime() / 1000);
        //  // console.log("eeoo",myDate)
        //
        //  // console.log("eeooaddtime",newDate2)
        this.setState({ fromDate: newDates }, () => {
          console.log("fromdatenew", this.state.fromDate);
          // this.report();
        });
      });
    },
  };
  datesendchange = {
    onDateChange: (date: any) => {
      this.setState({ enddate: date }, () => {
        console.log("date", date);
        const [day, month, year] = date.split("-");
        // dd/mm/yyyy
        console.log("YY-MM-DD =======>>>>>>>>", `${year}-${month}-${day}`);
        const newDates = `${year}-${month}-${day}`;
        this.setState(
          { toDate: newDates },
          () => this.report()
        );
        console.log("todate", this.state.toDate);
        console.log("fromdate", this.state.fromDate);
      });
    },
  };
  sendmails = {
    onPress: () => {
      this.sendmail();
      this.setState({ modalVisible: false });
    },
  };
  pickerProps = {
    onValueChange: (itemValue: any) => {
      let filteredgeneric = this.state.catagorys?.filter((item: any) => {
        return item.id == itemValue
      });
      this.setState({ selectedValue: itemValue, nameValue: filteredgeneric[0]?.attributes?.name, genericNameValue: filteredgeneric[0]?.attributes?.generic_name }, () => {
        console.log("this.state.nameValue, this.state.genericNameValue, this.state.selectedValue", this.state.nameValue, this.state.genericNameValue, this.state.selectedValue);
        this.report()
      });


      // onValueChange: (itemValue: any) => {
      //   this.setState({ selectedValue: itemValue }, () => this.report());
      //   console.log("catagoryId", this.state.selectedValue);
      //   // this.setState({ selectedValue: itemValue })
      // };
    },
    // getCategories() {
    //   throw new Error("Method not implemented.");
    // }
    // Customizable Area End
  };

  handleItemClick = ({ index }: any) => {
    console.log("CLICK: ", index);
  };

  getAsset = (item: any): any => {
    switch (item.icon) {
      case "Account":
        return require("../assets/Componente33x.png");
      case "Budget":
        return require("../assets/Componente43x.png");
      case "Transaction":
        return require("../assets/Componente53x.png");
      case "Reports":
        return require("../assets/Componente63x.png");
      default:
        return require("../assets/Componente73x.png");
    }
  };

  nav = (item: any) => {
    if (item) {
      const uploadData = {
        data: item,
        navigationProp: this.props,
      };

      const msg: Message = new Message(
        getName(MessageEnum.NavigationToMessage)
      );
      msg.addData(getName(MessageEnum.NavigationMenuTargetMessage), uploadData);
      console.log("Dashboard Broadcasting message");
      runEngine.sendMessage(msg.id, msg);
    }
  };
}



