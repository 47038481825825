import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import axios from "axios";
import { createRef } from "react";
import { openErrorNotification, openNotification } from "../../../components/src/Notification.web";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    route: any
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    cardLoading: boolean;
    categoryData: any;
    catNameID: string;
    catName: string;
    data: any;
    showAlert: boolean;
    openAddModal: boolean;
    openCategoryModal: boolean;
    expenseName: string;
    loading: boolean;
    showInput: boolean;
    homeInput: string;
    itemShowInput: boolean;
    itemLoading: boolean;
    openDeleteModal: boolean;
    editData: any;
    showItemInput: boolean;
    editId: any;
    deleteId: any
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class AddCategoriesController extends BlockComponent<
    Props,
    S,
    SS
> {
    updateNameAmountApiId: any;
    getDropDownApiId: any;
    getDataApiId: any;
    submitFormApiId: any;
    takePayApiId: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            cardLoading: false,
            categoryData: [],
            catNameID: "",
            catName: "",
            data: [],
            showAlert: false,
            openAddModal: false,
            openCategoryModal: false,
            expenseName: "",
            loading: false,
            showInput: false,
            homeInput: "",
            itemShowInput: false,
            itemLoading: false,
            openDeleteModal: false,
            editData: "",
            showItemInput: false,
            editId: "",
            deleteId: ""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    form: any = createRef();

    async componentWillUnmount() {
        runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.getData();
        this.dropdownAPI();
    }

    getData = async () => {
        this.setState({
            cardLoading: true
        });

        // Endpoint of update item API
        const apiEndpoint = `${configJSON.baseURL}bx_block_categories/get_categories_with_amount`;

        // headers of API
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
            "plaid-account-id": localStorage.getItem("plaid_acc_id"),
        };

        // httpBody of API
        const httpBody = {
            data: {
                account_id: localStorage.getItem("accountId"),
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // ================== Matches ID's ====================================
        this.getDataApiId = requestMessage.messageId;

        // ================== API URL ====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        // ================== API method =================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        // ================== header ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        // ================== Http Body ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    dropdownAPI = async () => {

        // Endpoint of update item API
        const apiEndpoint = `${configJSON.baseURL}bx_block_categories/get_all_categories`;

        // headers of API
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            "token": localStorage.getItem("loginToken"),
        };

        // httpBody of API

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // ================== Matches ID's ====================================
        this.getDropDownApiId = requestMessage.messageId;

        // ================== API URL ====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        // ================== API method =================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );

        // ================== header ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    change = (value: any, index: any) => {
        this.setState({
            catName: index.key,
            catNameID: value,
        })
        localStorage.setItem('categoryID', JSON.stringify(value))
    }

    checkValidIncome = (datas: any) => {
        datas?.take_home_pay !== datas?.dashboardData[0]?.total_budget_amount + datas?.dashboardData[1]?.total_budget_amount ? this.setState({ showAlert: true }) : this.setState({ showAlert: false })
    }

    handleOk = () => {
        this.setState({
            openAddModal: false
        })
    };
    handleCancel = () => {
        this.setState({
            openAddModal: false
        })
    };

    handleCategoryOk = () => {
        this.setState({
            openCategoryModal: false
        })
    };
    handleCategoryCancel = () => {
        this.setState({
            openCategoryModal: false
        })
    };

    openAddModal = () => {
        this.setState({
            openAddModal: true
        })
    }

    onFinish = async (value: any) => {
        this.setState({
            loading: true,
            cardLoading: true
        })

        // Endpoint of update item API
        const apiEndpoint = `${configJSON.baseURL}bx_block_categories/add_amount_to_subcategory`;

        // headers of API
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        };

        // httpBody of API
        const httpBody = {
            data: {
                attributes: {
                    category_type: this.state.expenseName,
                    name: this.state.catName,
                    amount: 0,
                    account_id: localStorage.getItem("accountId"),
                },
            },
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // ================== Matches ID's ====================================
        this.submitFormApiId = requestMessage.messageId;

        // ================== API URL ====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        // ================== API method =================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        // ================== header ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        // ================== Http Body ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    onFinishItem = async (item: any, value: any) => {
        this.setState({
            itemLoading: true,
            showItemInput: false
        })

        // Endpoint of update item API
        const apiEndpoint = `${configJSON.baseURL}bx_block_categories/update_name_amount_to_subcategory`;

        // headers of API
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        };

        // httpBody of API
        const httpBody = {
            data: {
                attributes: {
                    category_id: item?.category_id,
                    "name": value.name,
                    amount: parseInt(value.amount),
                    account_id: localStorage.getItem("accountId"),
                },
            },
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // ================== Matches ID's ====================================
        this.updateNameAmountApiId = requestMessage.messageId;

        // ================== API URL ====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        // ================== API method =================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        // ================== httpBody =====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        // ================== header ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    getDropDownDataResponse = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            this.setState({
                categoryData: response?.data
            })
        } else {
            console.log("error from updateUser:");
        }
    };

    updateItemResponseData = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            this.setState({
                cardLoading: true,
                editData: "",
                itemShowInput: !this.state.itemShowInput,
                itemLoading: false
            })
            this.getData();
            openNotification(response?.data?.message, "success")
        } else {
            this.setState({
                itemLoading: false
            })
            openErrorNotification(response?.errors?.updated_name[0], "error")
        }
    };

    getDataResponse = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            console.log("DATA ", response)
            this.setState({
                cardLoading: false,
                data: response?.dashboardData
            })
            this.checkValidIncome(response?.data)
        } else {
            console.log(response?.errors?.updated_name[0],)
            if (response?.error?.status == 401) {
                window.location.href = "/EmailAccountLoginWeb"
            }
            openErrorNotification(response?.errors?.updated_name[0], "error")
        }
    };

    submitFormResponse = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            this.getData();
            this.setState({
                openAddModal: false,
                loading: false,
            })
            openNotification("Category added.", "success")
        } else {
            this.setState({
                loading: false,
                cardLoading: false
            })
            openErrorNotification(response?.errors?.updated_name[0], "error")
        }
    };

    takePayResponse = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            this.getData()
            this.setState({
                showInput: false
            })
        } else {
            this.setState({
                showInput: false
            })
            if (response?.errors?.status == 401) {
                window.location.href = "/EmailAccountLoginWeb"
            }
            openErrorNotification(response?.errors?.updated_name[0], "error")
        }
    };

    handleDelete = async (item: any) => {
        this.setState({
            cardLoading: true
        })
        const data = {
            data: {
                attributes: {
                    category_id: this.state.deleteId,
                },
            },
        };
        const result = await axios.delete(
            `${configJSON.baseURL}bx_block_categories/delete_sub_category`,
            {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    token: localStorage.getItem("loginToken"),
                },
                data: data,
            }
        );
        if (result) {
            this.getData();
            this.setState({
                openDeleteModal: false
            });
            if (result?.data?.success) {
                openNotification("Category deleted successfully.", "success")
            }
        }
    };

    handleChangeTakePay = async () => {
        this.setState({
            cardLoading: true
        })

        // Endpoint of update item API
        const apiEndpoint = `${configJSON.baseURL}bx_block_dashboard/dashboards`;

        // headers of API
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        };

        // httpBody of API
        const httpBody = {
            data: {
                "title": "Take Home Pay",
                "value": this.state.homeInput,
                "account_id": localStorage.getItem("accountId"),
            },
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // ================== Matches ID's ====================================
        this.takePayApiId = requestMessage.messageId;

        // ================== API URL ====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        // ================== API method =================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        // ================== header ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        // ================== Http Body ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    }

    onFinishAddCategory = async (value: any) => {
        const filterCategoryName = this.state.categoryData?.filter((x: any) => x.id.includes(value.categoryName))[0]?.attributes?.generic_name;
        const data = {
            category_id: value?.categoryName,
            name: filterCategoryName,
            account_id: localStorage.getItem("accountId")
        };
        await axios.post(
            `${configJSON.baseURL}bx_block_categories/categories`, data,
            {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "token": localStorage.getItem("loginToken"),
                },
            },
        ).then((res) => {
            this.dropdownAPI()
            this.setState({
                openCategoryModal: false
            })
            openNotification("Category added.", "success");
        }).catch((err) => {
            if (err.response?.status == "422") {
                openErrorNotification(err.response?.data?.error, "error")
            }
            this.setState({
                openCategoryModal: false
            })
        }
        );
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.NavigationRMessage) === message.id) {
            message.getData(
                getName(MessageEnum.NavigationTabDataMessage)
            );
            runEngine.unSubscribeFromMessages(this, [
                getName(MessageEnum.NavigationRMessage),
            ]);
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId === this.updateNameAmountApiId) {
                this.updateItemResponseData(responseJson, errorReponse);
            } else if (apiRequestCallId === this.getDropDownApiId) {
                this.getDropDownDataResponse(responseJson, errorReponse)
            } else if (apiRequestCallId === this.getDataApiId) {
                this.getDataResponse(responseJson, errorReponse)
            } else if (apiRequestCallId === this.submitFormApiId) {
                this.submitFormResponse(responseJson, errorReponse)
            } else if (apiRequestCallId === this.takePayApiId) {
                this.takePayResponse(responseJson, errorReponse)
            }
        }
        // Customizable Area End
    }
    // Customizable Area End
}