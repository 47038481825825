import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import axios from "axios";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RightOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  openErrorNotification, openNotification
} from "../../../components/src/Notification.web";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  data: any;
  reallocateFromDataRedux: any;
  reallocateFromData: any;
  reallocateToDataRedux: any;
  transactionReponse: any;
  amount: any;

  // Customizable Area End
}
interface S {
  // Customizable Area Start
  displayFlex: any;
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  switchValue: boolean;
  reveniewData: any;
  accountId: string;
  userName: string;
  customer: string;
  isExpend: boolean;
  subDetails: any;
  tabData: any;
  drawerData: any;
  isOpen: boolean;
  tableData: any;
  columnData: any;
  columnData2: any;
  tableData2: any;
  columnData3: any;
  tableData3: any;
  columnData4: any;
  tableData4: any;
  confirmButton: any;
  active: any;
  reallocateData: any;
  cardLoading: any;
  reallocateFromData: any;
  reallocateToData: any;
  active2: any;
  amount: any;
  responseData: any;
  loadingback: any;
  overflow: any;
  zIndexing: any;
  collapseData: any;
  collapseDataColumn: any;
  collapseDataColumn1: any;
  progress: any;
  showModalCategory: any;
  continueBack: any;
  categoryName: any;
  redirect: boolean;
  showData: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class MoneyReallocateController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiiCallId: string = "";
  apiGetQueryStrinurl: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationRMessage),
    ];

    this.state = {
      showData: [],
      redirect: false,
      categoryName: "",
      cardLoading: true,
      continueBack: "#b3b3b3",
      displayFlex: "flex",
      dashboardData: [],
      collapseData: {},
      errorMsg: "",
      token: "",
      loading: false,
      switchValue: true,
      accountId: "",
      userName: "Guest",
      reveniewData: [],
      customer: "",
      isExpend: false,
      subDetails: [],
      tabData: null,
      drawerData: null,
      isOpen: false,
      progress: 0,
      showModalCategory: false,
      // active: "arrow",
      active: <Checkbox />,
      collapseDataColumn: [
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          width: "46%"
        },
        {
          title: "Budgeted",
          dataIndex: "price",
          key: "price",
          width: "26%"
        },
        {
          title: "Available",
          dataIndex: "available",
          key: "available",
          colSpan: 2,
          width: "18%"
        },
        {
          title: "",
          dataIndex: "arrrow",
          key: "arrow",
          render: (text: any, record: any, index: any) => (
            <div onClick={(e: any) => {
              this.setState({
                active:
                  <Checkbox onChange={(e: any) => {
                    if (e.target.checked) {
                      this.setState({ continueBack: "#31ADE2", reallocateFromData: record })
                    }
                    else {
                      this.setState({ tableData3: [] })
                    }
                  }} />
                , reallocateFromData: record
              });
              console.log("Record2", record)

            }}>
              {this.state.active}
            </div>
          )
        },
      ],
      collapseDataColumn1: [
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          width: "46%"
        },
        {
          title: "Budgeted",
          dataIndex: "price",
          key: "price",
          width: "26%"
        },
        {
          title: "Available",
          dataIndex: "available",
          key: "available",
          colSpan: 2,
          width: "18%"
        },
        {
          title: "",
          dataIndex: "arrrow",
          key: "arrow",
          render: (text: any, record: any, index: any) => (
            <Checkbox onClick={(e: any) => {
              if (e.target.checked) {
                this.setState({ continueBack: "#31ADE2", reallocateToData: record })
                console.log("CHecked", e.target.checked)
              } else {
                this.setState({ continueBack: "#b3b3b3", tableData4: [] })
              }
            }} />
          )
        },
      ],
      columnData: [
        {
          title: "",
          dataIndex: "category",
          key: "category",
          width: "38%",
          render: (text: any) => (
            <a
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: " #000000",
              }}
            >
              {text}
            </a>
          ),
        },
        {
          title: "Budgeted",
          dataIndex: "price",
          key: "price",
          width: "26%",
          render: (text: any) => (
            <a
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: " #000000",
              }}
            >
              {text}
            </a>
          ),
        },

        {
          title: "Available",
          dataIndex: "available",
          key: "available",
          width: "26%",
          render: (text: any) => (

            text < 0 ? <span style={{
              fontSize: 16,
              fontWeight: "bold",
              color: " red"
            }}>
              {text}
            </span> : <span style={{
              fontSize: 16,
              fontWeight: "bold",
              color: " #00a950"
            }}>
              {text}
            </span>
          ),
        },

      ],

      tableData: [],
      columnData2: [

        {
          title: "",
          dataIndex: "category",
          key: "category",
          width: "38%",
          render: (text: any) => (
            <a
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: " #000000",
              }}
            >
              {text}
            </a>
          ),
        },

        {
          title: "Budgeted",
          dataIndex: "price",
          key: "price",
          width: "26%",
          render: (text: any) => (
            <a
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: " #000000",
              }}
            >
              {text}
            </a>
          ),
        },
        {
          title: "Available",
          dataIndex: "available",
          key: "available",
          width: "26%",
          render: (text: any) => (

            text < 0 ? <span style={{
              fontSize: 16,
              fontWeight: "bold",
              color: " red"
            }}>
              {text}
            </span>
              : <span style={{ fontSize: 16, fontWeight: "bold", color: " #00a950" }}>{text}</span>
          ),
        },
      ],
      tableData2: [],
      columnData3: [
        {
          title: "",
          dataIndex: "category",
          key: "category",
          width: "38%",
          render: (text: any) => (
            <a
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: " #000000",
                width: "40%"
              }}
            >
              {text}
            </a>
          ),
        },
        {
          title: "Budgeted",
          dataIndex: "price",
          key: "price",
          width: "26%",
          render: (text: any) => (
            <a
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: " #000000",
              }}
            >
              {text}
            </a>
          ),
        },
        {
          title: "Available",
          dataIndex: "available",
          key: "available",
          width: "26%",
          render: (text: any) => (
            text < 0 ? <span style={{ fontSize: 16, fontWeight: "bold", color: " red", width: "40%" }}>{text}</span> : <span style={{ fontSize: 16, fontWeight: "bold", color: " #00a950" }}>{text}</span>
          ),
        },
      ],
      tableData3: [],
      columnData4: [
        {
          title: "",
          dataIndex: "category",
          key: "category",
          width: "38%",
          render: (text: any) => (
            <a
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: " #000000",
              }}
            >
              {text}
            </a>
          ),
        },
        {
          title: "Budgeted",
          dataIndex: "price",
          key: "price",
          width: "26%",
          render: (text: any) => (
            <a
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: " #000000",
              }}
            >
              {text}
            </a>
          ),
        },
        {
          title: "Available",
          dataIndex: "available",
          key: "available",
          width: "26%",
          render: (text: any) => (
            text < 0 ? <span style={{ fontSize: 16, fontWeight: "bold", color: " red" }}>{text}</span> : <span style={{ fontSize: 16, fontWeight: "bold", color: " #00a950" }}>{text}</span>
          ),
        },
      ],
      tableData4: [],
      confirmButton: "#b3b3b3",
      reallocateData: [],
      reallocateFromData: {},
      reallocateToData: 1,
      // @ts-ignore
      active2: <RightOutlined translate />,
      amount: 1,
      responseData: {},
      loadingback: "none",
      overflow: "hidden",
      zIndexing: 2
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  showCategory = () => {
    this.setState({ showModalCategory: true })
  };

  handleCancel = () => {
    this.setState({ showModalCategory: false })
  };

  getAllCategoryApi = async () => {
    const result = await axios.get(
      `${configJSON.baseURL}bx_block_categories/get_all_categories`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "token": localStorage.getItem("loginToken"),
        "plaid-account-id": localStorage.getItem("plaid_acc_id"),
      },
    }
    ).then((response: any) => {
      this.setState({ showData: response?.data?.data })
    }).catch((error: any) => {
      openNotification(error?.response?.data?.message, "error")
    });
  };
  change = (value: any, index: any) => {
    this.setState({ categoryName: value })
    localStorage.setItem('categoryID', JSON.stringify(value))
  }
  addCategory = async (value: any) => {
    this.setState({ cardLoading: true })
    const data = {
      name: this.state.categoryName
    }
    const result = await axios.post(
      `${configJSON.baseURL}bx_block_categories/categories`,
      data, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "token": localStorage.getItem("loginToken"),
      },
    }
    ).then((res: any) => {
      this.onReallocateMoney();
      this.collapseData();
      this.setState({ showModalCategory: false })
      openNotification("Category created successfully!", "success")
    }).catch((err: any) => {
      this.setState({ showModalCategory: false, cardLoading: false })
      openErrorNotification(err?.response?.data?.error, "error")
    });
  }

  onReallocateMoney = async () => {
    const accountId = localStorage.getItem("accountId") || "0";
    const data =
    {
      data:
      {
        "account_id": parseInt(accountId)
      }
    };
    const result = await axios.post(
      `${configJSON.baseURL}bx_block_dashboard/get_savings_account_det`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "token": localStorage.getItem("loginToken"),
          "plaid-account-id": localStorage.getItem("plaid_acc_id"),
          "access-token": localStorage.getItem("access_token")
        },
      }
    ).then((response: any) => {
      this.setState({ reallocateData: response?.data?.dashboardData }, () => {
        const data = this.state.reallocateData?.map((values: any) => ({
          key: values.category_id,
          category: values.category_name,
          price: values.budgeted_amount,
          available: values.total_available_amount_cat,
        }))
        this.setState({
          tableData: data,
          cardLoading: false
        })
        this.setState({ tableData2: data })
      })
    }).catch((error: any) => {
      this.setState({ reallocateData: error.response?.data?.dashboardData }, () => {
        const data = this.state.reallocateData?.map((values: any) => ({
          key: values.category_id,
          category: values.category_name,
          price: values.budgeted_amount,
          available: values.total_available_amount_cat,
        }))
        this.setState({
          tableData: data,
          cardLoading: false
        })
        this.setState({ tableData2: data })
      })
      if (error?.response?.status == "422") {
        this.setState({ cardLoading: false })
        // openNotification(error?.response?.data?.error_message, "error");
      }
    });
  };

  collapseData = async () => {
    const collapseData = this.state?.reallocateData?.reduce(
      (data: any, current: any) => {
        const subDetails = current.sub_details;

        return subDetails ? {
          ...data,
          [current.category_id]: subDetails.map((subDetail: any) => ({
            key: subDetail.category_id,
            category: subDetail.category_name,
            price: subDetail.budgeted_amount,
            available: subDetail.total_available_amount
          }))
        } : data;
      },
      {}
    );
    this.setState({ collapseData });
  }

  moveMoneyTransaction = async () => {
    if (this.state.reallocateFromData.available !== undefined) {
      if (this.state.reallocateFromData.available >= this.state.amount) {
        this.setState({ cardLoading: true })
        const data =
        {
          transaction: {
            transact_from_id: parseInt(this.state.reallocateFromData.key),
            transact_to_id: parseInt(this.state.reallocateToData.key),
            transaction_type: "Category",
            amount: this.state.amount
          }
        };
        const result = await axios.post(
          `${configJSON.baseURL}bx_block_transactions/transactions`,
          data,
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              "token": localStorage.getItem("loginToken"),
            },
          }
        ).then((response: any) => {
          this.setState({ responseData: response?.data?.data })
        }).catch((error: any) => {
          openErrorNotification(error?.response?.data?.error, "error")
        }).finally(() => {
          this.setState({ redirect: true })
        })
      } else {
        openNotification("Please enter valid amount", "error")
      }
    } else {
      openNotification("Account data is not linked", "error")
    }
  }
  async componentDidMount() {
    await this.onReallocateMoney();
    this.collapseData();
    this.getAllCategoryApi();
  }
  async componentWillMount() {
    // document.body.style.overflow = `${localStorage.getItem("overflow") || ""}`;
  }


  getDashboardData(): boolean {
    // Customizable Area Start
    const reveniewtUrl = `bx_block_dashboard/get_savings_account_det?customerId=${this.state.customer
      }`;
    const moneyreallocateheader = {
      "Content-Type": configJSON.necessityContentType,
      token: this.state.token,
    };
    console.log("header  ", JSON.stringify(moneyreallocateheader));
    const moneyreallocatehttpBody = {
      data: {
        account_id: String(this.state.accountId),
      },
    };
    const moneyreallocaterequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", moneyreallocaterequestMessage);
    this.dashboardApiiCallId = moneyreallocaterequestMessage.messageId;
    moneyreallocaterequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      reveniewtUrl
    );

    moneyreallocaterequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(moneyreallocateheader)
    );
    moneyreallocaterequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(moneyreallocatehttpBody)
    );
    moneyreallocaterequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );
    console.log("moneyreallocaterequestMessage@, ", moneyreallocaterequestMessage);
    runEngine.sendMessage(moneyreallocaterequestMessage.id, moneyreallocaterequestMessage);
    // Customizable Area End
    return true;
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("receive...");
    //runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationRMessage) === message.id) {
      const moneyreallocatedata = message.getData(
        getName(MessageEnum.NavigationTabDataMessage)
      );
      this.setState({
        tabData: moneyreallocatedata.data[0].data.attributes,
        drawerData: moneyreallocatedata.data[1].data.attributes,
      });
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      //runEngine.debugLog("Message Recived", message);
      let moneyreallocatetoken = message.getData(getName(MessageEnum.SessionResponseToken));
      let moneyreallocateaccountId = message.getData(getName(MessageEnum.SessionResponseData));
      console.log("account" + moneyreallocateaccountId);
      let moneyreallocateobj = JSON.parse(moneyreallocateaccountId);
      this.setState({ customer: moneyreallocateobj.data.finicity_customer_id });
      this.setState({ accountId: moneyreallocateobj.data.id });
      if (moneyreallocateobj.data.first_name !== null) {
        this.setState({ userName: moneyreallocateobj.data.first_name });
      }
      this.setState({ token: moneyreallocatetoken, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let moneyreallocateresponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let moneyreallocateerrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.dashboardApiiCallId) {
        if (
          moneyreallocateresponseJson &&
          !moneyreallocateresponseJson.errors &&
          moneyreallocateresponseJson.dashboardData
        ) {
          console.log("moneyreallocateresponseJson  Dashboard ", JSON.stringify(moneyreallocateresponseJson));
          if (moneyreallocateresponseJson.dashboardData.length === 0) {
            this.setState({
              errorMsg: "Data Not Found",
              loading: false,
            });
          } else {
            this.setState({
              reveniewData: moneyreallocateresponseJson.dashboardData,
              errorMsg: "",
              loading: false,
            });
            this.getCategories();
          }
        } else {

          console.log("error resp", moneyreallocateerrorReponse);
          if (moneyreallocateerrorReponse === undefined) {
            this.setState({
              errorMsg: "Something went wrong",
              loading: false,
            });
          } else {
            this.setState({
              errorMsg: moneyreallocateerrorReponse,
              loading: false,
            });
          }
        }
      }
    }
  }
  getCategories() {
    throw new Error("Method not implemented.");
  }
  // Customizable Area Start
  getAsset = (moneyreallocateitem: any): any => {
    switch (moneyreallocateitem.icon) {
      case "Account":
        return require("../assets/Componente33x.png");
      case "Budget":
        return require("../assets/Componente43x.png");
      case "Transaction":
        return require("../assets/Componente53x.png");
      case "Reports":
        return require("../assets/Componente63x.png");
      default:
        return require("../assets/Componente73x.png");
    }
  };

  nav = (moneyreallocateitem: any) => {
    switch (moneyreallocateitem.icon) {
      case "Account":
        this.moneyreallocateonPressAccounts();
        break;
      case "Budget":
        this.moneyreallocateonPressBudgets();
        break;
      case "Transaction":
        this.moneyreallocateonPressTrans();
        break;
      case "Reports":
        this.moneyreallocateonPressReports();
        break;
      case "Settings":
        this.moneyreallocateonPressSettings();
        break;
      case "Contactus":
        this.moneyreallocateonPressContactus();
        break;
      case "Profile":
        this.moneyreallocateonPressProfile();
        break;
      case "Dashboard":
        this.moneyreallocateonPressDashboard();
        break;
      default:
        console.log("Not navigating");
    }
  };

  moneyreallocateonPressBudgets = () => {
    this.props.navigation.navigate("Budget");
  };
  moneyreallocateonPressReports = () => {
    this.props.navigation.navigate("StatisticsReports");
  };
  moneyreallocateonPressAccounts = () => {
    this.props.navigation.navigate("AccountsPage");
  };
  moneyreallocateonPressTrans = () => {
    this.props.navigation.navigate("Transaction");
  };
  moneyreallocateonPressmovemoney = () => {
    this.props.navigation.navigate("MovemoneyFrom");
  };
  moneyreallocateonPressSettings = () => {
    this.props.navigation.navigate("Settings");
  };
  moneyreallocateonPressContactus = () => {
    this.props.navigation.navigate("Contactus");
  };
  moneyreallocateonPressProfile = () => {
    this.props.navigation.navigate("UserProfileBasicBlock");
  };
  moneyreallocateonPressDashboard = () => {
    this.setState({ isOpen: false });
  };

  // Customizable Area End
}

// const mapDispatchToProps = (dispatch:any) => {
//   return {
//       onTransaction: (data:any) => dispatch({type: 'REALLOCATE_FROM_DATA', reallocateFromData: data})
//   }
// };

// export default connect(null,mapDispatchToProps)(MoneyReallocateController);
