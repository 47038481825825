import React from "react";
import "antd/dist/antd.css";
import ConfirmPaymentController, {
  Props,
  configJSON
} from "./ConfirmPaymentController";
import { Button, Table, Row, Col, Card } from "antd";
import SideBar from "../../../components/src/SideBar";
import { Link } from "react-router-dom";
class ConfirmPayment extends ConfirmPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const reallocateFromData = JSON.parse(
      localStorage.getItem("reallocate_from") || JSON.stringify("[]")
    );
    const reallocateToData = JSON.parse(
      localStorage.getItem("reallocate_to") || JSON.stringify("[]")
    );
    const amount = localStorage.getItem("reallocate_amount");
    return (
      <div>
        <div>
          <SideBar />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Row style={{ marginLeft: "79px" }}>
            <Col span={24}>
              <Card style={{ margin: "2em", padding: "40px" }}>
                <div style={{ margin: "32px 0px" }}>
                  <div style={{ textAlign: "center" }}>
                    <div style={styles.sucessfullTransaction}>
                      {" "}
                      {configJSON.sucessfullTransaction}{" "}
                    </div>
                    <div style={styles.transactionAccepted}>
                      This transaction has been
                      <br />
                      successfully accepted
                    </div>
                  </div>
                  <div style={styles.ConfirmTransactionDetail}>
                    Reallocate From
                  </div>
                  <Table
                    pagination={false}
                    columns={this.state.columnData3}
                    dataSource={[reallocateFromData]}
                  />
                  <div style={styles.ConfirmTransactionDetail}>
                    Reallocate To
                  </div>
                  <Table
                    pagination={false}
                    columns={this.state.columnData4}
                    dataSource={[reallocateToData]}
                  />
                  <div style={styles.ConfirmTransactionDetail}>Amount</div>
                  <div
                    style={{
                      marginTop: "12px",
                      display: "flex",
                      justifyContent: "space-around"
                    }}
                  >
                    <div style={{ fontSize: 16, color: "#000000" }}>Amount</div>
                    <div
                      style={{
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: 500
                      }}
                    >
                      ${amount}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "24px"
                    }}
                  >
                    <Button shape="round" style={styles.continueButton}>
                      <Link to="/Dashboard">CONTINUE</Link>
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const styles = {
  block: {
    width: "513.6px",
    // height: "635.7px",
    backgroundColor: "white",
    marginTop: "28px",
    display: "flex",
    justifyContent: "center"
  },
  // contentBlock: {

  // },
  BudgetsBalance: {
    fontSize: "22px",
    fontWeight: 900,
    color: "#545861",
    lineHeight: 1.2
  },
  relocateToButton: {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: "#b3b3b3",
    padding: "20px 40px",
    color: "#ffffff",
    display: "flex",
    border: "none",
    alignItems: "center"
  },
  ConfirmTransactionDetail: {
    width: "333.3px",
    height: "43.7px",
    fontSize: 16,
    fontWeight: 600,
    borderRadius: "6.4px",
    padding: "10px 20px",
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    marginTop: "32px"
  },
  continueButton: {
    width: "333.3px",
    height: "40px",
    fontSize: 16,
    padding: "10.8px 128.6px 10.2px 128.7px",
    fontWeight: 700,
    backgroundColor: "#00ade2",
    color: "#ffffff",
    display: "flex",
    border: "none",
    alignItems: "center"
  },
  sucessfullTransaction: {
    fontSize: 28,
    color: "545861",
    lineHeight: "1.2",
    letterSpacing: "-0.98px"
  },
  transactionAccepted: {
    fontSize: 18,
    color: "#54595f",
    marginTop: "12px"
  }
};

export default ConfirmPayment;
