import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import { openNotification } from "../../../components/src/Notification.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  country: any;
  data: any;
  accountName: any;
  bandkid: any;
  isModalLoader: boolean;
  isMoalSaved: boolean;
  isModalEntercred: boolean;
  username: any;
  password: any;
  isModallinkBankAcnt: boolean;
  ListArray: any;
  isLoadingLinkButton: boolean;
  modalOpen: boolean;
  loading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LinkedAccountsWelcomeScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  finicity_customer_id: any = "null";
  create_date_customer: any = "";
  token: any;
  linkedAccAPIcallId: any;
  finicityTokenAPIcallId: any;
  createTestCustomerAPIcallId: any;
  createCustomerAPIcallId: any;
  fincity_token: any;
  userName: any;
  firstName: any = "";
  email_login: any;
  windows: any = window;
  checkSubscriptionSessionApi: any;
  finicityApi: any;
  linkAccountApi: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationRMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      country: "uk",
      modalOpen: false,
      loading:false,
      data: [
        {
          value: "Bank Of India ",
          id: 1,
        },
        {
          value: "SBI",
          id: 2,
        },
        {
          value: "PNB",
          id: 3,
        },
      ],
      accountName: "",
      bandkid: "",
      isModalLoader: false,
      isMoalSaved: false,
      isModalEntercred: false,
      username: "",
      password: "",
      isModallinkBankAcnt: false,
      ListArray: [] = "",
      isLoadingLinkButton: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // Abhijeet
      if (apiRequestCallId === this.linkedAccAPIcallId) {
        if (responseJson.data) {
          this.props.navigation.navigate("LinkedAccounts", {
            res: responseJson.data.table.link,
          });
        }
      } else if (apiRequestCallId === this.finicityTokenAPIcallId) {
        this.checkGetFinicityTokenData(responseJson);
      } else if (apiRequestCallId === this.createTestCustomerAPIcallId) {
        this.checkGetTestCustomerData(responseJson);
      } else if (apiRequestCallId === this.createCustomerAPIcallId) {
        this.checkGetCustomerData(responseJson);
      } else if (apiRequestCallId === this.finicityApi) {
        this.successCallback(responseJson);
      } else if (apiRequestCallId === this.checkSubscriptionSessionApi) {
        this.SubscriptionSessionApiSuccessCallback(responseJson);
      } else if (apiRequestCallId === this.linkAccountApi) {
        this.linkAccountApiSuccessCallback(responseJson);
      }
    }
  }

  // Customizable Area Start
  async componentDidMount(){
    this.checkSubscriptionSession();
  }

  async componentWillUnmount(){
    if(Platform.OS ==="web")
    this.windows.finicityConnect.destroy();
  }  

  handleOk = () => {
    this.setState({ modalOpen: false });
  };

  handleCancel = () => {
    this.setState({ modalOpen: false });
  };

  handleContinue = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.page === "linking_account") {
      this.props.navigation.navigate("AccountDetailsWeb");
    } else {
      this.props.navigation.navigate("OnboardingScreenWeb");
    }
  };

  connectFinicityUrl = async () => {
    const body = {
      customerId: localStorage.getItem("customerId")
    };
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": configJSON.baseURL,
      token: localStorage.getItem("loginToken"),
      fincity_token: localStorage.getItem("finicityToken")
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.finicityApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseURL}bx_block_finicity/connect_url`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
      successCallback = (result: any) => {
        if (result.data.table.link) {
          this.windows.finicityConnect.launch(result.data.table.link, {
            success: (event: any) => {
              this.setState({ modalOpen: true });
              console.log("Yay! User went through Connect", event);
            },
            cancel: (event: any) => {
              console.log("The user cancelled the iframe", event);
            },
            error: (error: any) => {
              console.error(
                "Some runtime error was generated during insideConnect ",
                error
              );
            },
            loaded: () => {
              console.log(
                "This gets called only once after the iframe has finished loading "
              );
            },
            route: (event: any) => {
              console.log(
                "This is called as the user navigates through Connect ",
                event
              );
            },
            user: (event: any) => {
              console.log(
                "This is called as the user interacts with Connect ",
                event
              );
            }
          });
        }}

  SubscriptionSessionApiSuccessCallback = (response: any) => {
    localStorage.removeItem("subscription_type");
    localStorage.removeItem("if_account_linked");
    localStorage.removeItem("is_onboarding_completed");
    this.handleLinkAccount();
    openNotification(response.data.message, "success");
    this.props.navigation.navigate("LinkedAccountsWelcomeScreenWeb");
  }

  linkAccounts = async () => {
    if (!localStorage.getItem("customerId")) {
      window.location.href = "/OnboardingScreenWeb";
    } else {
      this.connectFinicityUrl();
    }
  };

  checkSubscriptionSession = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let sessionId = params.get("session_id");
    if (sessionId) {
      const body = {
        data: {
          attributes: {
            session_id: sessionId
          }
        }
      };
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem("loginToken")
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.checkSubscriptionSessionApi = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.baseURL}bx_block_subscriptions/subscriptions`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.handleLinkAccount();
    }
  };

  linkAccountApiSuccessCallback = (response: any) => {
    localStorage.setItem("fetchLoginToken", response.link_token);
  }

  handleLinkAccount = async () => {
    let loginToken = localStorage.getItem("loginToken");

    const body = {
      customerId: localStorage.getItem("customerId")
    };

    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: loginToken,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.linkAccountApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseURL}bx_block_plaid/fetch_link_token`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  checkGetFinicityTokenData = (response: any) => {
    if (response.data) {
      this.fincity_token = response.data;
      this.saveFinicityToken(response.data);
      this.checkCallCreatCustomer();
    }
  };

  checkGetCustomerData = (response: any) => {
    if (response.data) {
      this.finicity_customer_id = response.data.table.customerId;
      this.saveFinicityCustomerId(response.data.table.customerId);
      this.saveCreateDateCustomerId(response.data.table.createdDate);
    }
  };

  checkGetTestCustomerData = (response: any) => {
    if (response.data) {
      this.finicity_customer_id = response.data.table.id;
      this.saveFinicityCustomerId(response.data.table.id);
      this.createCustomerAPIcall();
    }
  };

  checkCallCreatCustomer = () => {
    if (this.finicity_customer_id === "null") {
      this.createTestCustomerAPIcall();
    }
  };


  saveFinicityCustomerId = async (finicity_customer_id_props: any) => {
    await AsyncStorage.setItem(
      "finicity_customer_id",
      JSON.stringify(finicity_customer_id_props)
    );
    this.finicity_customer_id = finicity_customer_id_props;
  };

  saveCreateDateCustomerId = async (createDateCustomerId: any) => {
    await AsyncStorage.setItem(
      "create_date",
      JSON.stringify(createDateCustomerId)
    );
    this.create_date_customer = createDateCustomerId;
  };

  getToken = async () => {
    try {
      const value = await AsyncStorage.getItem("finicity_customer_id");
      const token = await AsyncStorage.getItem("tokenLogin");
      const userName = await AsyncStorage.getItem("userName");
      const firstNameLogin = await AsyncStorage.getItem("firstName");
      const emailLogin = await AsyncStorage.getItem("emailLogin");
      this.finicity_customer_id = value;
      this.token = token;
      this.userName = userName;
      this.firstName = firstNameLogin;
      this.email_login = emailLogin;

      this.finicityTokenCallAPI();
    } catch (e) {
      // error reading value
    }
  };

  //Finicity-App-Token

  linkdAccountAPIcallTimer() {
    setTimeout(() => {
      this.linkAccountAPICall();
    }, 1000);
  }

  finicityTokenCallAPI = () => {
    // Customizable Area Start

    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.finicityTokenAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.finicityTokenAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  saveFinicityToken = async (finicity_token: string) => {
    await AsyncStorage.setItem("finicity_token", finicity_token);
  };

  createTestCustomerAPIcall = () => {
    // Customizable Area Start

    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: this.token,
      fincity_token: this.fincity_token,
    };

    // httpBody of API
    const httpBody = {
      user_name: this.userName,
      fincity_token: this.fincity_token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // ================== Matches ID's ====================================
    this.createTestCustomerAPIcallId = requestMessage.messageId;

    // ================== API URL ====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTestCustomerAPIEndPoint + ""
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    // Customizable Area End

    return true;
  };

  createCustomerAPIcall = () => {
    // Customizable Area Start
    // headers of API
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: this.token,
      fincity_token: this.fincity_token,
    };

    // httpBody of API
    const httpBody = {
      user_name: this.userName,
      fincity_token: this.fincity_token,
      firstName: this.firstName,
      lastName: "Smith",
      address: "434 W Ascension Way",
      city: "Murray",
      state: "UT",
      zip: "84123",
      phone: "1-801-984-4200",
      ssn: "999-99-9999",
      birthday: {
        year: 1989,
        month: 8,
        dayOfMonth: 13,
      },
      email: this.email_login,
      suffix: "PhD",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCustomerAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCustomerAPIEndPoint +
        this.finicity_customer_id +
        "/create_consumer"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  linkAccountAPICall = () => {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: this.token,
      fincity_token: this.fincity_token,
    };

    const httpBody = {
      customerId: this.finicity_customer_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.linkedAccAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseURL}bx_block_finicity/connect_url`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  // Customizable Area End
}
