import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from "../../blocks/alert/src/AlertBlock";
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from "../../framework/src/SingletonFactory";

import HomeScreenAdapter from "../../blocks/adapters/src/HomeScreenAdapter";
import InfoPageAdapter from "../../blocks/adapters/src/InfoPageAdapter";
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";
import NavigationMenuAdapter from "../../blocks/adapters/src/NavigationMenuAdapter";
import NavigationToAdapter from "../../blocks/adapters/src/NavigateToAdapter";

//Assembler generated adapters start
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const onboardingAdapter = new OnboardingAdapter();

//Assembler generated adapters end

const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
const navigationMenuAdapter = new NavigationMenuAdapter();
const navigationToAdapter = new NavigationToAdapter();
// Customizable Area End

const restAPIBlock = SingletonFactory.getRestBlockInstance();
// const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter();

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure.",
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {
  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>Welcome to BESTBudgetApp!</Text>
            </View>
            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={"NavigationMenu"}
              onPress={() => navigation.navigate("NavigationMenu")}
            />
            {/* <CustomTextItem
              content={"InfoPage"}
              onPress={() => navigation.navigate("InfoPage")}
            /> */}
            {/* <CustomTextItem
              content={"AlertBlock"}
              // onPress={() => this.showAlert("Example", "This happened")}
              onPress={() => navigation.navigate("AlertBlock")}
            /> */}
            {/* <CustomTextItem
              content={"Categoriessubcategories"}
              onPress={() => navigation.navigate("Categoriessubcategories")}
            /> */}
            <CustomTextItem
              content={"Transaction"}
              onPress={() => navigation.navigate("Transaction")}
            />
            <CustomTextItem
              content={"Dashboard"}
              onPress={() => navigation.navigate("Dashboard")}
            />
            <CustomTextItem
              content={"OnboardingScreen"}
              onPress={() => navigation.navigate("OnboardingScreen")}
            />
            <CustomTextItem
              content={"UserProfileBasicBlock"}
              onPress={() => navigation.navigate("UserProfileBasicBlock")}
            />
            {/* <CustomTextItem
              content={"CountryCodeSelector"}
              onPress={() => navigation.navigate("CountryCodeSelector")}
            /> */}
            <CustomTextItem
              content={"ForgotPassword"}
              onPress={() => navigation.navigate("ForgotPassword")}
            />
            {/* <CustomTextItem
              content={"OTPInputAuth"}
              onPress={() => navigation.navigate("OTPInputAuth")}
            /> */}
            <CustomTextItem
              content={"Contactus"}
              onPress={() => navigation.navigate("Contactus")}
            />
            <CustomTextItem
              content={"EmailAccountLoginBlock"}
              onPress={() => navigation.navigate("EmailAccountLoginBlock")}
            />
            <CustomTextItem
              content={"EmailAccountRegistration"}
              onPress={() => navigation.navigate("EmailAccountRegistration")}
            />
            {/* <CustomTextItem
              content={"Onboardingguide"}
              onPress={() => navigation.navigate("Onboardingguide")}
            /> */}
            {/* <CustomTextItem
              content={"RolesPermissions"}
              onPress={() => navigation.navigate("RolesPermissions")}
            /> */}
            <CustomTextItem
              content={"TouchIdLogin"}
              onPress={() => navigation.navigate("TouchIdLogin")}
            />
            <CustomTextItem
              content={"AccountsPage"}
              onPress={() => navigation.navigate("AccountsPage")}
            />
            <CustomTextItem
              content={"HelpCentre"}
              onPress={() => navigation.navigate("HelpCentre")}
            />
            <CustomTextItem
              content={"Settings"}
              onPress={() => navigation.navigate("Settings")}
            />
            <CustomTextItem
              content={"SignUp"}
              onPress={() => navigation.navigate("SplashScreen")}
            />

            <CustomTextItem
              content={"StatisticsReports"}
              onPress={() => navigation.navigate("StatisticsReports")}
            />
            {/* <CustomTextItem
              content={"AdminConsole"}
              onPress={() => navigation.navigate("AdminConsole")}
            /> */}
            {/* <CustomTextItem
              content={"Subscriptions"}
              onPress={() => navigation.navigate("Subscriptions")}
            /> */}
            {/* <CustomTextItem
              content={"PaymentDetails"}
              onPress={() => navigation.navigate("PaymentDetails")}
            /> */}
            <CustomTextItem
              content={"OrderConfirmed"}
              onPress={() => navigation.navigate("OrderConfirmed")}
            />
            {/* <CustomTextItem
              content={"EmailNotifications"}
              onPress={() => navigation.navigate("EmailNotifications")}
            /> */}

            <CustomTextItem
              content={"PlaidLinkClass"}
              onPress={() => navigation.navigate("PlaidLinkClass")}
            />
            <CustomTextItem
              content={"Budget"}
              onPress={() => navigation.navigate("Budget")}
            />
            <CustomTextItem
              content={"Subscription"}
              onPress={() => navigation.navigate("TrialExpiry")}
            />
            <CustomTextItem
              content={"PlaidSuccess"}
              onPress={() => navigation.navigate("PlaidSuccess")}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? "100vh" : "auto",
    backgroundColor: "#F5FCFF",
  },
  container: {
    flex: 1,
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: 16,

    padding: 10,
  },
  button: {
    backgroundColor: "#6200EE",
    padding: 15,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  header: {
    backgroundColor: "#6200EE",
    padding: 15,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  item: {
    backgroundColor: "#00000000",
    padding: 18,
    color: "#6200EE",
    fontSize: 16,
    fontWeight: "normal",
  },
});
// Customizable Area End
export default HomeScreen;
