//@ts-nocheck
import React from "react";
import "antd/dist/antd.css";
import "../assets/index.css";
import ReviewAndCheckingController, {
  Props
} from "./ReviewAndCheckingController";
import { Table, Progress, Button, Row } from "antd";
import { Link } from "react-router-dom";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { splitTransactionRes } from "../../../web/src/redux/actions/users";
import { connect } from "react-redux";
import bgImage from "../assets/web_bg.png";
// import TopNav from "../../../components/src/TopNav.js";
// import TopNav from "../../../components/src/TopNav.js";
import { View } from "react-native";
import logo from "../assets/logo.png";

export class ReviewAndChecking extends ReviewAndCheckingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      <div>
        {/* <div><TopNav /></div> */}
        <View
          style={{
            width: "100%",
            backgroundColor: `${this.state.loadingback}`,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: this.state.zIndexing
          }}
        >
          <div
            style={{
              borderRadius: 10,
              width: "287px",
              height: "210px",
              display: `${this.state.displayFlex}`,
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div style={{ textAlign: "center", width: "200px" }}>
              <img src={logo} width="60px" />
              <p style={{ marginTop: "10px" }}>LOADING...</p>
              <Progress percent={this.state.progress} status="active" />
            </div>
          </div>
          {/* <Spin spinning={this.state.loading} size="large" style={{ display: "flex", alignItems: "center" }} /> */}
        </View>
        <Row
          align="middle"
          justify="center"
          style={{
            height: "100vh",
            width: "100vw",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            position: "fixed",
            overflow: "auto",
            paddingBottom: "20px",
            marginBottom: "30px"
          }}
        >
          <div style={styles.contentBlock1}>
            <div>
              <div>
                <h1 style={styles.BudgetsBalance}>
                  Review Checking and Savings
                </h1>
                <p>
                  Please allocate every dollar from checking and savings
                  accounts .
                </p>
                <div style={styles.button}>
                  <div
                    style={{
                      fontSize: 16,
                      color: "#000000",
                      textAlign: "center"
                    }}
                  >
                    Total Current Balance :
                  </div>
                  <div
                    style={{
                      color: "#00a950",
                      fontSize: 30,
                      textAlign: "center",
                      fontWeight: 600
                    }}
                  >
                    $ {this.state.totalCurrentBalance}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginRight: 20, marginTop: 20 }}>
              <div>
                <Table
                  test-id="table-outer"
                  pagination={{ defaultPageSize: 5 }}
                  dataSource={this.state.splitData}
                  columns={this.state.splitTransactionColumnData}
                  expandable={{
                    expandIconColumnIndex: 5,
                    expandedRowRender: record => {
                      const data = this.state.collapseData[record.key];
                      return (
                        <>
                          {data ? (
                            <Table
                              test-id="table-inner"
                              style={{ margin: 0 }}
                              pagination={false}
                              dataSource={data}
                              columns={this.state.collapseDataColumn}
                            />
                          ) : (
                            <p>No Data</p>
                          )}
                        </>
                      );
                    }
                  }}
                  expandIcon={({ expanded, onExpand, record }) =>
                    expanded ? (
                      <DownOutlined
                        test-id="down-outlined-icon"
                        onClick={e => onExpand(record, e)}
                        translate
                      />
                    ) : (
                      <RightOutlined
                        test-id="right-outlined-icon"
                        onClick={e => onExpand(record, e)}
                        translate
                      />
                    )
                  }
                />
              </div>

              <div
                style={{
                  marginTop: "24px",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Link
                  test-id="back-link"
                  to="/AllocateAccountWeb"
                  style={styles.relocateToButtons}
                >
                  BACK
                </Link>
                <Button
                  test-id="save-link"
                  shape="round"
                  style={styles.relocateToButtons}
                  onClick={() => {
                    this.beforeSplit();
                  }}
                >
                  SAVE AND FINISH
                </Button>
              </div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

const styles = {
  contentBlock1: {
    width: "40%",
    height: "auto",
    padding: 40,
    backgroundColor: "white",
    marginRight: 32,
    // textAlign: "center",
    marginLeft: 106,
    marginTop: 28,
    marginBottom: "80px"
  },
  BudgetsBalance: {
    fontSize: "22px",
    fontWeight: 900,
    color: "#545861",
    lineHeight: 1.2
  },
  secondDiv: {
    marginTop: "32px"
  },
  relocateToButtons: {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: "#2EADE2",
    padding: "20px 40px",
    color: "#ffffff",
    display: "flex",
    border: "none",
    alignItems: "center",
    height: 32,
    borderRadius: 32
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "510px",
    height: "90px",
    margin: "44px 32px 0 0",
    padding: "12px 40px",
    borderRadius: "6px",
    backgroundColor: "#ededf2"
  }
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    onSplitTransaction: (data: any) => dispatch(splitTransactionRes(data))
  };
};
export default connect(
  null,
  mapDispatchToProps
)(ReviewAndChecking);
