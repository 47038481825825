import React from "react";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import axios from "axios";
import { RightOutlined } from "@ant-design/icons";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Checkbox, Form, Input } from "antd";
// Customizable Area Start
import { Select } from "antd";
import { openErrorNotification, openNotification } from "../../../components/src/Notification.web";

// Customizable Area End
const { Option } = Select;

export const configJSON = require("./config.js");


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    data: any;
    onSplitTransaction: any;
    reallocateFromData: any;
    reallocateToDataRedux: any;
    transactionReponse: any;
    amount: any;
    history: any
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    dashboardData: any;
    token: string;
    errorMsg: string;
    loading: boolean;
    switchValue: boolean;
    reveniewData: any;
    accountId: string;
    userName: string;
    cardLoading: any;
    customer: string;
    count: any,
    isExpend: boolean;
    subDetails: any;
    tabData: any;
    drawerData: any;
    isOpen: boolean;
    tableData: any;
    columnData: any;
    // columnData2: any;
    splitTransactionTableData: any;
    splitTransactionColumnData: any;
    tableData2: any;
    confirmButton: any;
    active: any;
    reallocateData: any;
    reallocateFromData: any;
    reallocateToData: any;
    active2: any;
    amount: any;
    responseData: any;
    loadingback: any;
    overflow: any;
    collapseDataColumn: any;
    splitData: any;
    zIndexing: any;
    collapseData: any;
    progress: any;
    showModalCategory: any;
    categoryData: any;
    inputAmount: any;
    showData: any;
    dropdown: any;
    selectedCategoryID: any;
    amountGroup: any;
    categoryIdGroup: any;
    categoryName: any;
    textInputs: any;
    splitResData: any;
    // Customizable Area End
}
interface SS {
    id: any;
}
class SplitTransactionController extends BlockComponent<Props, S, SS> {
    apiDashboardItemCallId: string = "";
    dashboardApiiCallId: string = "";
    apiGetQueryStrinurl: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationRMessage),
        ];

        this.state = {
            dashboardData: [],
            categoryData: [],
            collapseData: {},
            categoryName: "",
            showData: [],
            errorMsg: "",
            token: "",
            loading: false,
            switchValue: true,
            accountId: "",
            splitData: [],
            userName: "Guest",
            reveniewData: [],
            cardLoading: true,
            customer: "",
            isExpend: false,
            subDetails: [],
            tabData: null,
            drawerData: null,
            isOpen: false,
            progress: 0,
            splitTransactionTableData: [],
            showModalCategory: false,
            inputAmount: "",
            active: <Checkbox />,
            dropdown: "",
            selectedCategoryID: [],
            amountGroup: [],
            categoryIdGroup: [],
            textInputs: {},
            splitResData: {},
            splitTransactionColumnData: [
                {
                    title: "Category",
                    dataIndex: "name",
                    key: "name",
                },
            ],
            columnData: [
                {
                    title: "Category",
                    dataIndex: "Category",
                    key: "Category",
                    render: (text: any) => (
                        <a
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: " #000000",
                            }}
                        >
                            {text}
                        </a>
                    ),
                },

                {
                    title: "Amount",
                    dataIndex: "Amount",
                    key: "Amount",
                    render: (text: any) => (

                        text < 0 ? <span style={{ fontSize: 16, fontWeight: "bold", color: " red" }}>{text}</span> : <span style={{ fontSize: 16, fontWeight: "bold", color: " #00a950" }}>{text}</span>
                    ),
                },
            ],

            tableData: [],
            tableData2: [
                {
                    key: 0,
                    amount: '$500',
                },
            ],
            count: 1,
            confirmButton: "#b3b3b3",
            reallocateData: [],
            reallocateFromData: {},
            reallocateToData: 1,
            // @ts-ignore
            active2: <RightOutlined translate rev={undefined} />,
            amount: 1,
            responseData: {},
            loadingback: "none",
            overflow: "hidden",
            zIndexing: 2,
            collapseDataColumn: [
                {
                    title: "Category",
                    dataIndex: "generic_name",
                    key: "generic_name",
                },
                {
                    title: "",
                    dataIndex: "",
                    key: "",
                    render: (text: any, record: any) => (
                        <Form
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                        >
                            <Form.Item
                                style={{
                                    width: 80
                                }}
                                name="username"
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: 'Please Enter Amount!',
                                        },
                                    ]}
                            >
                                <Input onChange={(e) => {
                                    this.setState({ inputAmount: e.target.value })
                                    let { textInputs } = this.state;
                                    textInputs[record.id] = e.target.value;
                                    this.setState(
                                        {
                                            textInputs,
                                        },
                                        () => {
                                            console.log(
                                                "Text Input::::",
                                                this.state
                                                    .textInputs
                                            );
                                        }
                                    );
                                }}
                                    placeholder="$ 0"
                                    style={{ marginTop: 20, border: "none", borderBottom: "1px solid black" }} />
                            </Form.Item>
                        </Form>
                    ),
                }
            ],
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    showCategory = () => {
        this.setState({ showModalCategory: true })
    };

    handleCancel = () => {
        this.setState({ showModalCategory: false })
    };

    beforeSplit = () => {
        Object.keys(this.state.textInputs).forEach(
            (key: any) =>
                this.state.textInputs[key] == "" && delete this.state.textInputs[key]
        );
        const amtAry = Object.values(this.state.textInputs);
        var sabIdAry = Object.keys(this.state.textInputs);
        this.splitTransactionAPI(sabIdAry, amtAry);

    }

    splitTransactionAPI = async (subID: any, amount: any) => {
        this.setState({ cardLoading: true })
        let body = {
            category_ids: subID,
            amounts: amount
        }
        const result = await axios.post(
            `${configJSON.baseURL}bx_block_transactions/transactions/${localStorage.getItem("transaction_id")}/split_transaction`,
            body, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "token": localStorage.getItem("loginToken")
            },
        }
        ).then((response: any) => {
            this.setState({
                splitResData: response.data,
                cardLoading: false
            }, () => {
                this.props.onSplitTransaction(response.data)
            })
            this.props.navigation.navigate("ConfirmSplitTransaction");
        }).catch((error: any) => {
            openErrorNotification(error?.response?.data?.errors, "error")

            this.setState({ cardLoading: false })
        });
    }
    getAllCategoryApi = async () => {
        // this.setState({ cardLoading: true })
        const result = await axios.get(
            `${configJSON.baseURL}bx_block_categories/get_all_categories`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "token": localStorage.getItem("loginToken"),
                "plaid-account-id": localStorage.getItem("plaid_acc_id"),
            },
        }
        ).then((response: any) => {
            const splitData = response?.data?.data.map((item: any) => item.attributes);
            this.setState({
                categoryData: response?.data?.data,
                //  cardLoading: false
                splitData
            })
        }).catch((error: any) => {
            // this.setState({ cardLoading: false })
            openNotification(error?.response?.data?.message, "error")
        });
    };


    addCategory = async (value: any) => {
        this.setState({ cardLoading: true })
        const data = {
            name: this.state.categoryName,
            amount: parseInt(value.category_amount)

        }
        const result = await axios.post(
            `${configJSON.baseURL}bx_block_categories/categories`,
            data, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "token": localStorage.getItem("loginToken"),
            },
        }
        ).then((response: any) => {
            this.setState({ showModalCategory: false, cardLoading: false })
            openNotification("Category created successfully!", "success")
        }).catch((error: any) => {
            this.setState({ showModalCategory: false, cardLoading: false })
            openErrorNotification(error?.response?.data?.error, "error")
        });
    }
    getTransactionCategoryAndAmount = async () => {
        this.setState({ cardLoading: true })
        const result = await axios.get(
            `${configJSON.baseURL}bx_block_transactions/transactions/${localStorage.getItem("transaction_id")}/get_transaction`,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "plaid-account-id": localStorage.getItem("plaid_acc_id"),
                    "access-token": localStorage.getItem("access_token"),
                    "token": localStorage.getItem("loginToken")
                },
            }
        ).then((response: any) => {
            let data = response?.data?.data?.split_from?.map((values: any) => ({
                Category: values.transaction_from_sub_category,
                Amount: "$" + values.transaction_from_amount,
            }))
            this.setState({
                tableData: data,
                //  cardLoading: false
            })
        }).catch((error: any) => {
            // this.setState({ cardLoading: false })
            openNotification(error?.data?.error, "error");
        });
    }

    handleAdd = () => {
        const { count, tableData2 } = this.state;
        const newData = {
            key: count,
            name: `Edward King`,
            age: '32',
            address: `London, Park Lane no.`,
        };
        this.setState({
            tableData2: [...tableData2, newData],
            count: count + 1,
        });
    };

    splitTransactionData = async () => {

    };

    collapseData = async () => {
        // this.setState({ cardLoading: true })
        const result = await axios.post(
            `${configJSON.baseURL}bx_block_dashboard/get_savings_account_det`,
            {},
            {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "token": localStorage.getItem("loginToken"),
                    "plaid-account-id": localStorage.getItem("plaid_acc_id"),
                    "access-token": localStorage.getItem("access_token")
                },
                validateStatus: function (status) {
                    return status >= 200 && status < 500
                }
            },
        ).then((response: any) => {
            // this.setState({ cardLoading: false })
            const collapseData = response?.data?.dashboardData?.reduce(
                (data: any, current: any) => {
                    const subDetails = current.sub_details;

                    return subDetails ? {
                        ...data,
                        [current.category_id]: subDetails.map((subDetail: any) => ({
                            key: subDetail.category_id,
                            category: subDetail.category_name,
                            price: subDetail.budgeted_amount,
                            available: subDetail.availableamount
                        }))
                    } : data;
                },
                {}
            );
            this.setState({ collapseData, cardLoading: false });
        }).catch((error) => {
            if (error.response?.status == "422") {
                this.setState({ dashboardData: error.response.data.dashboardData, cardLoading: false }, () => {
                    const data = this.state.dashboardData?.map((values: any) => ({
                        key: values.category_id,
                        name: values.category_name,
                        price: values.budgeted_amount,
                        available: values.total_available_amount_cat,
                    }))
                    this.setState({ splitData: data })
                })
                this.setState({ cardLoading: false })
            }
        });
    }

    change = (value: any, index: any) => {
        console.log("Catgory ID", value)
        console.log("Index", index)
        this.setState({ categoryName: index.key })

        localStorage.setItem('categoryID', JSON.stringify(value))
    }
    async componentDidMount() {
        this.getAllCategoryApi();
        this.collapseData();
        this.splitTransactionData();
        this.getTransactionCategoryAndAmount();

    }

    // async receive(from: string, message: Message) {
    //     // Customizable Area Start
    //     console.log("receive...");
    //     //runEngine.debugLog("Message Recived", message);
    //     if (getName(MessageEnum.NavigationRMessage) === message.id) {
    //         const data = message.getData(
    //             getName(MessageEnum.NavigationTabDataMessage)
    //         );
    //         this.setState({
    //             tabData: data.data[0].data.attributes,
    //             drawerData: data.data[1].data.attributes,
    //         });
    //     }

    //     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    //         //runEngine.debugLog("Message Recived", message);
    //         let token = message.getData(getName(MessageEnum.SessionResponseToken));
    //         let accountId = message.getData(getName(MessageEnum.SessionResponseData));
    //         console.log("account" + accountId);
    //         let obj = JSON.parse(accountId);
    //         this.setState({ customer: obj.data.finicity_customer_id });
    //         this.setState({ accountId: obj.data.id });
    //         //console.log("userNamemmmm"+obj.data.first_name);
    //         if (obj.data.first_name !== null) {
    //             this.setState({ userName: obj.data.first_name });
    //         }
    //         this.setState({ token: token, loading: true }, () => {
    //             this.getDashboardData();
    //         });
    //     }

    //     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    //         var responseJson = message.getData(
    //             getName(MessageEnum.RestAPIResponceSuccessMessage)
    //         );
    //         const apiRequestCallId = message.getData(
    //             getName(MessageEnum.RestAPIResponceDataMessage)
    //         );

    //         var errorReponse = message.getData(
    //             getName(MessageEnum.RestAPIResponceErrorMessage)
    //         );
    //         if (apiRequestCallId === this.dashboardApiiCallId) {
    //             if (
    //                 responseJson &&
    //                 !responseJson.errors &&
    //                 responseJson.dashboardData
    //             ) {
    //                 console.log("responseJson  Dashboard ", JSON.stringify(responseJson));
    //                 if (responseJson.dashboardData.length === 0) {
    //                     this.setState({
    //                         errorMsg: "Data Not Found",
    //                         loading: false,
    //                     });
    //                 } else {
    //                     this.setState({
    //                         reveniewData: responseJson.dashboardData,
    //                         errorMsg: "",
    //                         loading: false,
    //                     });
    //                     //const getcata= new Dashboard(this.props);
    //                     this.getCategories();
    //                 }
    //             } else {
    //                 var errorReponse = message.getData(
    //                     getName(MessageEnum.RestAPIResponceErrorMessage)
    //                 );
    //                 console.log("error resp", errorReponse);
    //                 if (errorReponse === undefined) {
    //                     this.setState({
    //                         errorMsg: "Something went wrong",
    //                         loading: false,
    //                     });
    //                 } else {
    //                     this.setState({
    //                         errorMsg: errorReponse,
    //                         loading: false,
    //                     });
    //                 }
    //             }
    //         }
    //     }
    // }
    // getCategories() {
    //     throw new Error("Method not implemented.");
    // }
    // Customizable Area Start
    getAsset = (item: any): any => {
        switch (item.icon) {
            case "Account":
                return require("../assets/Componente33x.png");
            case "Budget":
                return require("../assets/Componente43x.png");
            case "Transaction":
                return require("../assets/Componente53x.png");
            case "Reports":
                return require("../assets/Componente63x.png");
            default:
                return require("../assets/Componente73x.png");
        }
    };

    nav = (item: any) => {
        switch (item.icon) {
            case "Account":
                this.onPressAccounts();
                break;
            case "Budget":
                this.onPressBudgets();
                break;
            case "Transaction":
                this.onPressTrans();
                break;
            case "Reports":
                this.onPressReports();
                break;
            case "Settings":
                this.onPressSettings();
                break;
            case "Contactus":
                this.onPressContactus();
                break;
            case "Profile":
                this.onPressProfile();
                break;
            case "Dashboard":
                this.onPressDashboard();
                break;
            default:
            // console.log("Not navigating");
        }
    };

    onPressBudgets = () => {
        this.props.navigation.navigate("Budget");
    };
    onPressReports = () => {
        this.props.navigation.navigate("StatisticsReports");
    };
    onPressAccounts = () => {
        this.props.navigation.navigate("AccountsPage");
    };
    onPressTrans = () => {
        this.props.navigation.navigate("Transaction");
    };
    onPressmovemoney = () => {
        this.props.navigation.navigate("MovemoneyFrom");
    };
    onPressSettings = () => {
        this.props.navigation.navigate("Settings");
    };
    onPressContactus = () => {
        this.props.navigation.navigate("Contactus");
    };
    onPressProfile = () => {
        this.props.navigation.navigate("UserProfileBasicBlock");
    };
    onPressDashboard = () => {
        this.setState({ isOpen: false });
    };

    // Customizable Area End
}
export default SplitTransactionController