// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { BackHandler, ToastAndroid, Platform } from "react-native";

let count = 0;
// Customizable Area Start
import React from "react";
import { RightOutlined } from '@ant-design/icons';
import { openNotification } from "../../../components/src/Notification.web";
import axios from "axios";
import Cookies from "js-cookie";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  data: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: any;
  plaid_acc_id: any;
  errorMsg: string;
  loading: boolean;
  switchValue: boolean;
  reveniewData: any;
  accountId: string;
  userName: string;
  customer: string;
  isExpend: boolean;
  subDetails: any;
  tabData: any;
  dashboarddrawerData: any;
  isOpen: boolean;
  firstName: any;
  clickcount: any;
  finicity_token: any;
  isRedirectToDiscretioneryExpenses: boolean;
  isRedirectToAddNecessityExpensesCategory: boolean;
  isRedirectToAvailableBalance: boolean;
  active: any;
  redirect: boolean;
  drawerData: any;
  tableData: any;
  columnData: any;
  active2: any;
  loadingback: any;
  overflow: any;
  zIndexing: any;
  progress: any;
  displayFlex: any;
  collapseData: any;
  collapseDataColumn: any;
  cardLoading: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  _unsubscribe: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationRMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      plaid_acc_id: "",
      loading: false,
      switchValue: true,
      accountId: "",
      userName: "Guest",
      reveniewData: [],
      customer: "",
      isExpend: false,
      subDetails: [],
      tabData: null,
      dashboarddrawerData: null,
      isOpen: false,
      firstName: "",
      clickcount: 0,
      finicity_token: "",
      isRedirectToDiscretioneryExpenses: false,
      isRedirectToAddNecessityExpensesCategory: false,
      isRedirectToAvailableBalance: false,
      cardLoading: true,
      redirect: false,
      drawerData: null,
      active: <RightOutlined translate />,
      active2: <RightOutlined translate />,
      loadingback: "none",
      overflow: "hidden",
      zIndexing: 2,
      progress: 0,
      displayFlex: "flex",
      collapseData: [],
      tableData: [],
      collapseDataColumn: [
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          width: '57.9%',
        },
        {
          title: "Budgeted",
          dataIndex: "price",
          width: "20%",
          key: "price",
          render: (budgetText: any, budgetRecord: any, budgetIndex: any) => (
            <p>
              {budgetText < 0 ? (<span style={{ fontSize: 16, fontWeight: "bold", color: " red" }}>{budgetText}</span>) : (<span style={{ fontSize: 16, fontWeight: "bold", color: " #00a950" }}>{budgetText}</span>)}
            </p>
          )
        },
        {
          dataIndex: "available",
          title: "Available",
          key: "available",
          width: "20%",
          render: (availableText: any, availableRecord: any, availableIndex: any) => (
            <p>
              {availableText < 0 ? (<span style={{ fontSize: 16, fontWeight: "bold", color: " red" }}>{availableText}</span>) : (<span style={{ fontSize: 16, fontWeight: "bold", color: " #00a950" }}>{availableText}</span>)}
            </p>
          )
        },
      ],
      columnData: [
        {
          title: "",
          dataIndex: 'name',
          key: 'name',
          width: "60%",
          render: (text: any, record: any, index: any) => (
            <p onClick={(e: any) => {
            }} style={{
              cursor: "pointer",
              fontWeight: "bold"
            }}>
              {text}
            </p >
          )
        },
        {
          title: "Budgeted",
          dataIndex: "price",
          key: "price",
          width: "20%",
          render: (text: any, record: any, index: any) => (
            <p onClick={(e: any) => {
            }}
              style={{ cursor: "pointer" }}>
              {text < 0 ? (<span style={{
                fontSize: 16,
                fontWeight: "bold",
                color: " red"
              }}
              >
                {text}
              </span>
              )
                : (
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: " #00a950"
                    }}
                  >
                    {text}
                  </span>
                )
              }
            </p>
          ),
        },
        {
          title: "Available",
          dataIndex: "available",
          key: "available",
          width: "20%",
          render: (text: any, record: any, index: any) => (
            <p onClick={(e: any) => {
            }} style={{ cursor: "pointer" }}>
              {text < 0 ? (<span style={{
                fontSize: 16,
                fontWeight: "bold",
                color: " red"
              }}>{text}</span>)
                : (<span style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: " #00a950"
                }}>
                  {text}
                </span>)}
            </p>
          )
        },
      ],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // await this.onReallocateMoney();
    // this.collapseData();
    // this.getPlaidAccountsDetails()
    // localStorage.removeItem("subscription_type")
    const authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
    if (!this.state.tabData) {
      const uploadData = {
        data: "Needed",
      };
      const msg: Message = new Message(
        getName(MessageEnum.NavigationTabMenuMessage)
      );
      msg.addData(
        getName(MessageEnum.NavigationTMessage),
        uploadData
      );
      runEngine.sendMessage(msg.id, msg);
    }

    if (Platform.OS !== 'web') {
      this.getTabData();
      this.getToken();

      // disable back button in android
      BackHandler.addEventListener("hardwareBackPress", () => {
        this.setState({ clickcount: this.state.clickcount + 1 });
        this.check001();
        return false;
      });

      this._unsubscribe = this.props.navigation.addListener("focus", () => {
        this.getToken();
      });
    } else {
      this.collapseData();
      this.onReallocateMoney();
      this.getPlaidAccountsDetails()
      await localStorage.removeItem("subscription_type")
      const authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
      this.send(authTokenReq);
    }
  }

  handleSwitch = async () => {
    const data = {
      data: {

      },
    };
    await axios.post(``, data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: localStorage.getItem("loginToken"),
        },
      })
  }

  collapseData = async () => {
    const collapseData = this.state?.dashboardData?.reduce(
      (data: any, current: any) => {
        const subDetails = current.sub_details;

        return subDetails ? {
          ...data,
          [current.category_id]: subDetails.map((subDetail: any) => ({
            key: subDetail.category_id,
            category: subDetail.category_name,
            price: subDetail.budgeted_amount,
            available: subDetail.total_available_amount
          }))
        } : data;
      },
      {}
    );
    this.setState({ collapseData });
  }

  onReallocateMoney = async () => {
    const data =
    {
      data:
      {
        "account_id": localStorage.getItem("accountId")
      }
    };
    await axios.post(
      `${configJSON.baseURL}bx_block_dashboard/get_savings_account_det`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "token": localStorage.getItem("loginToken"),
          "plaid-account-id": localStorage.getItem("plaid_acc_id"),
          "access-token": localStorage.getItem("access_token")
        },
      }
    ).then((response: any) => {
      this.setState({ dashboardData: response.data.dashboardData, cardLoading: false }, () => {
        const data = this.state.dashboardData?.map((values: any) => ({
          key: values.category_id,
          name: values.category_name,
          price: "$" + values.budgeted_amount,
          available: "$" + values.total_available_amount_cat,
        }))
        this.setState({ tableData: data })
      })
      const collapseData = response?.data?.dashboardData?.reduce(
        (data: any, current: any) => {
          const subDetails = current.sub_details;

          return subDetails ? {
            ...data,
            [current.category_id]: subDetails.map((subDetail: any) => ({
              key: subDetail.category_id,
              category: subDetail.category_name,
              price: subDetail.budgeted_amount,
              available: subDetail.total_available_amount
            }))
          } : data;
        },
        {}
      );
      this.setState({ collapseData });
    }).catch((error: any) => {
      if (error.response?.status == "422") {
        this.setState({ dashboardData: error.response.data.dashboardData, cardLoading: false }, () => {
          const data = this.state.dashboardData?.map((values: any) => ({
            key: values.category_id,
            name: values.category_name,
            price: values.budgeted_amount,
            available: values.total_available_amount_cat,
          }))
          this.setState({ tableData: data })
        })
        const collapseData = error.response?.data?.dashboardData?.reduce(
          (data: any, current: any) => {
            const subDetails = current.sub_details;

            return subDetails ? {
              ...data,
              [current.category_id]: subDetails.map((subDetail: any) => ({
                key: subDetail.category_id,
                category: subDetail.category_name,
                price: subDetail.budgeted_amount,
                available: subDetail.total_available_amount
              }))
            } : data;
          },
          {}
        );
        this.setState({ collapseData, cardLoading: false })

        // openNotification(error?.response?.data?.error_message, "error");
      } else {
        if (error?.response?.status === 401 || error?.response?.status === 400) {
          localStorage.clear();
          Cookies.remove("accountId");
          Cookies.remove("name");
          Cookies.remove("loginToken");
          this.setState({ redirect: true });
        }
      }
      this.setState({ cardLoading: false })
    });
  };
  getPlaidAccountsDetails = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let sessionId = params.get('session_id')
    const data = {
      "data": {
        "attributes": {
          "session_id": sessionId
        }
      }
    }
    if (sessionId) {
      await axios.post(
        `${configJSON.baseURL}bx_block_subscriptions/subscriptions`,
        data,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
          },
        }
      ).then((res11) => {
        history.pushState("", "", "/EmailAccountLoginWeb")
        openNotification(res11.data.message, "success")
      }).catch((err) => {
        history.pushState("", "", "/EmailAccountLoginWeb")
      })
    }
    try {
      this.setState({ cardLoading: false });
    }
    catch {
      this.setState({ cardLoading: false });
    }
  }

  check001() {
    if (this.state.clickcount < 2) {
      ToastAndroid.show("Press again to exit !!", ToastAndroid.SHORT);
    } else if (this.state.clickcount == 2) {
      BackHandler.exitApp();
    }
  }

  handleBackButton() {
    return true;
  }

  getTabData = () => {
    if (!this.state.tabData) {
      const uploadData = {
        data: "Needed",
      };

      const msg: Message = new Message(
        getName(MessageEnum.NavigationTabMenuMessage)
      );
      msg.addData(getName(MessageEnum.NavigationTMessage), uploadData);

      console.log("Dashboard Broadcasting message");
      runEngine.sendMessage(msg.id, msg);
    }
  };

  getToken = async () => {
    const token = await AsyncStorage.getItem("tokenLogin");
    const data: any = await AsyncStorage.getItem("userData");
    const userData = JSON.parse(data || "{}");
    const fname: any = await AsyncStorage.getItem("firstName");
    const finicity_customer_id = await AsyncStorage.getItem(
      "finicity_customer_id"
    );
    const finicity_token = await AsyncStorage.getItem("finicity_token");

    this.setState(
      {
        token: token,
        accountId: userData?.data?.id,
        userName: fname ? fname : "",
        customer: finicity_customer_id ? finicity_customer_id : "",
        loading: true,
        finicity_token: finicity_token,
      },
      () => {
        this.getDashboardData();
      }
    );
  };
  async componentWillUnmount() {
    if (Platform.OS !== 'web') {
      this._unsubscribe();
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      BackHandler.removeEventListener("hardwareBackPress", () => {
        this.setState({ clickcount: this.state.clickcount + 1 });
        this.check001();
        return true;
      });
    }
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // const reveniewtUrl = `bx_block_dashboard/get_savings_account_det?customerId=${this.state.customer}`
    const reveniewtUrl = `${configJSON.baseURL}/bx_block_dashboard/get_savings_account_det`;

    const header = {
      "Content-Type": configJSON.necessityContentType,
      token: this.state.token
    };
    const httpBody = {
      "data": {
        "account_id": String(this.state.accountId),
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dashboardApiiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      reveniewtUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationRMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationTabDataMessage)
      );

      runEngine.unSubscribeFromMessages(this, [
        getName(MessageEnum.NavigationRMessage),
      ]);

      this.setState({
        tabData: data.data[0].data.attributes,
        dashboarddrawerData: data.data[2].data.attributes,
        drawerData: data.data[2].data.attributes,
      });
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      ////runEngine.debugLog("Message Recived", message);
      let accountId = message.getData(getName(MessageEnum.SessionResponseData));
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      console.log("accountIdaccountIdaccountId", token, accountId);

      if (accountId !== undefined) {
        let obj = JSON.parse(JSON.stringify(accountId || "{}"));
        this.setState({ customer: obj?.data?.finicity_customer_id });
        this.setState({ accountId: obj.data.id });
        if (obj.data.first_name !== null) {
          this.setState({ userName: obj.data.first_name });
        }
        this.setState({ token: token, loading: true }, () => {
          this.getDashboardData();
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.dashboardApiiCallId) {
        this.getDashboardDataFromAPI(responseJson, errorReponse);
      }
    }
  }

  getDashboardDataFromAPI = (response: any, errorRes: any) => {

    if (!response.errors && response.dashboardData) {
      // subscription_expired?
      if (response["subscription_expired?"] === true) {
        this.props.navigation.navigate("TrialExpiry");
      }
      if (response.dashboardData.length === 0) {
        this.setState({
          errorMsg: "Data Not Found",
          loading: false,
        });
      } else {
        this.setState({
          reveniewData: response.dashboardData,
          errorMsg: "",
          loading: false,
        });

      }
    } else {
      console.log("error resp", errorRes);
      if (errorRes === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false,
        });
      } else {
        this.setState({
          errorMsg: errorRes,
          loading: false,
        });
      }
    }
  };

  // Customizable Area Start
  getAsset = (item: any): any => {
    switch (item.icon) {
      case "Account":
        return require("../assets/Componente33x.png");
      case "Budget":
        return require("../assets/Componente43x.png");
      case "Transaction":
        return require("../assets/Componente53x.png");
      case "Reports":
        return require("../assets/Componente63x.png");
      default:
        return require("../assets/Componente73x.png");
    }
  };

  nav = (item: any) => {
    if (item.icon === "Dashboard") {
      this.onPressDashboard();
    } else if (item) {
      const uploadData = {
        data: item,
        navigationProp: this.props,
      };

      const msg: Message = new Message(
        getName(MessageEnum.NavigationToMessage)
      );

      msg.addData(getName(MessageEnum.NavigationMenuTargetMessage), uploadData);

      console.log("Dashboard Broadcasting message");
      runEngine.sendMessage(msg.id, msg);
    }
    switch (item.icon) {
      case "Account":
        this.onPressAccounts();
        break;
      case "Budget":
        this.onPressBudgets();
        break;
      case "Transaction":
        this.onPressTrans();
        break;
      case "Reports":
        this.onPressReports();
        break;
      case "Settings":
        this.onPressSettings();
        break;
      case "Contactus":
        this.onPressContactus();
        break;
      case "Profile":
        this.onPressProfile();
        break;
      case "Dashboard":
        this.onPressDashboard();
        break;
      default:
        this.props.navigation.navigate("Dashboard");

    }
  };

  onPressTrans = () => {
    this.props.navigation.navigate("Transaction")
  }
  onPressAccounts = () => {
    this.props.navigation.navigate("AccountsPage")
  }
  onPressmovemoney = () => {
    this.props.navigation.navigate("MovemoneyFrom")
  }
  onPressBudgets = () => {
    this.props.navigation.navigate("Budget")
  }
  onPressDashboard = () => {
    this.setState({ isOpen: false });
  };
  onPressSettings = () => {
    this.props.navigation.navigate("Settings");
  }
  onPressContactus = () => {
    this.props.navigation.navigate("Contactus");
  }
  onPressReports = () => {
    this.props.navigation.navigate("StatisticsReports")
  }
  onPressProfile = () => {
    this.props.navigation.navigate("UserProfileBasicBlock");
  }

  // first Name is set in UserProfileBasicController
  // getFirstName = async () => {
  //   const firstName = await AsyncStorage.getItem("firstName");
  //   console.warn("firstName ==================>>>>>>>>>>>>>", firstName);
  //   try {
  //     if (firstName !== null) {
  //       // We have data!!
  //       this.setState({ firstName: firstName });
  //       console.warn(
  //         "firstName ==================>>>>>>>>>>>>>",
  //         this.state.firstName
  //       );
  //     }
  //   } catch (error) {
  //     // Error retrieving data
  //   }
  // };

  // Customizable Area End
}
