import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import Cookies from "js-cookie";
import axios from "axios";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  route: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  cardLoading: boolean;
  visible: boolean;
  modalData: any;
  paginationData: any;
  alertDetails: any[];
  redirect: boolean;
  confirmLoading: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class AlertController extends BlockComponent<Props, S, SS> {
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.state = {
      loading: false,
      cardLoading: true,
      visible: false,
      modalData: {},
      paginationData: {},
      alertDetails: [],
      redirect: false,
      confirmLoading: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getData(1);
  }

  showModal = (data: any) => {
    this.setVisible(true);
    this.setModalData(data);
    if (!data?.attributes?.is_read) this.updateData(data.id);
  };

  handleCancel = () => {
    this.setVisible(false);
    if (!this.state.modalData?.attributes?.is_read) this.getData(1);
  };

  onPageChange = (pageNumber: number) => {
    this.getData(pageNumber);
  };

  async componentWillUnmount() {
    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
  }

  setVisible = (visible: boolean) => this.setState({ visible });
  setModalData = (modalData: any) => this.setState({ modalData });
  setCardLoading = (cardLoading: boolean) => this.setState({ cardLoading });
  setPaginationData = (paginationData: any[]) =>
    this.setState({ paginationData });
  setAlertDetails = (alertDetails: any[]) => this.setState({ alertDetails });
  setRedirect = (redirect: boolean) => this.setState({ redirect });

  getData = async (pageNumber: number) => {
    this.setCardLoading(true);
    await axios
      .get(
        `${configJSON.baseURL}bx_block_notifications/notifications?page=${pageNumber}`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
          },
        }
      )
      .then((result) => {
        this.setAlertDetails(result.data.data.notifications.data);
        this.setPaginationData(result.data.meta.pagination);
        this.setCardLoading(false);
      })
      .catch((err) => {
        this.setCardLoading(false);
        if (err?.response?.status == 401 || err?.response?.status == 400) {
          localStorage.clear();
          Cookies.remove("accountId");
          Cookies.remove("name");
          Cookies.remove("loginToken");
          this.setRedirect(true);
        }
      });
  };

  updateData = async (id: any) => {
    await axios
      .put(
        `${configJSON.baseURL}bx_block_notifications/notifications/${id}`,
        { id: id },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
          },
        }
      )
      .then((result) => {})
      .catch((err) => {console.log(err)});
  };

  // Customizable Area End
}
