export const Logo = require("./assets/header_logo.png");
export const applogo = require("./assets/logo.png");
export const dashboard = require("./assets/dashboard.png");
export const accounts = require("./assets/accounts.png");
export const budget = require("./assets/Budget.png");
export const transcation = require("./assets/transcation.png");
export const report = require("./assets/reports.png");
export const alert = require("./assets/alerts.png");
export const setting = require("./assets/settings.png");
export const logout = require("./assets/logout.png");
export const logo = require("./assets/app_logo.png");
