import React, { useEffect, useState } from "react";
const logo = require("../assets/header_logo.png");
//@ts-ignore
import { Row, Col, Card, Image, Divider, Input, Form, Button, FormInstance } from "antd";
import axios from "axios";
import { setUserData } from "../../../web/src/redux/actions/users";
import { bgImage } from "./assets";
import { openErrorNotification } from "../../../components/src/Notification.web";
import EmailAccountRegistrationController, {Props} from "./EmailAccountRegistrationController";
// @ts-ignore
import store from "../../../web/src/store/index.js";
export const configJSON = require("./config");
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
export default class EmailAccountRegistrationWeb extends  EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  formRef = React.createRef<FormInstance>();
  setLoading = (loading: boolean) => this.setState({loading});
  onFinish = async (value: any) => {
    this.setLoading(true);
    const data = {
      device_type: "web",
      data: {
        type: "email_account",
        attributes: {
          email: value.email
        }
      }
    }
    await axios
      .post(
        `${configJSON.baseURL}account_block/accounts`,
        data,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res: any) => {
        this.setLoading(false);
        store.dispatch(setUserData(value));
        window.location.href = "/CheckYourEmailWeb";
        this.formRef.current?.resetFields();
      })
      .catch((err: any) => {
        this.setLoading(false);
        openErrorNotification(err.response?.data?.errors[0]?.account, "error")
      }); 
  };

  checkAuth =()=>{
    if (window.localStorage.getItem("loginToken")) {
      window.location.href = "/Dashboard";
      return
    }
    window.localStorage.clear()
  }
  // To disable submit button at the beginning.

  async componentDidMount() {
    this.forceUpdate();
    this.checkAuth();
  }

  render () {
      return (
        <>
          <TopNav />
          <Row
            align="middle"
            justify="center"
            style={{
              height: "100vh",
              width: "100vw",
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              position: "fixed",
              overflow: "auto",
              paddingBottom: "20px",
              marginBottom: "30px",
            }}
          >
            <Col lg={10} md={13} sm={16} xs={21}>
              {" "}
              <Card>
                {" "}
                <Row justify="center">
                  <Image src={logo} width="100px" preview={false} />
                </Row>
                <Divider />
                <Row>
                  <Col span={2} />
                  <Col span={20}>
                    <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>
                      <Row>
                        <h1
                          style={{ fontWeight: "bold", color: "rgb(84, 88, 97)" }}
                        >
                          Sign up by email
                        </h1>
                      </Row>
                      <Row >
                        <Form.Item
                          name="email"
                          style={{width:"100%"}}
                          rules={[
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                            { type: "email", message: "Please enter valid email" }
                          ]}
                          label={<span style={{ color: "gray" }}>Email</span>}
                          extra="We will send an email with a link with which you will enter
                        immediatly."
                        >
                          <Input test-id="email-input" onChange={() => this.forceUpdate()} placeholder="Your email" />
                        </Form.Item>
                      </Row>
                      <Row style={{ marginTop: "50px" }}>
                        <Form.Item test-id="form-submit" style={{ width: "100%" }} shouldUpdate>
                          <Button
                            test-id="button-submit"
                            type="primary"
                            htmlType="submit"
                            loading={this.state.loading}
                            style={{ borderRadius: "15px", width: "100%" }}
                            disabled={!this.formRef.current?.isFieldsTouched(true)}
                          >
                            Send email
                          </Button>
                        </Form.Item>
                      </Row>
                    </Form>
                  </Col>
                  <Col span={2} />
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      );
    };
  }
