import React from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { openErrorNotification } from "../../../components/src/Notification.web";
import Cookies from "js-cookie";
import { FormInstance } from "antd";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  firebaseToken: string;
  email: string;
  token: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  username: any;
  navigation: any;
  // Customizable Area End
}

export default class NewPasswordControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  email: any;
  token: any;
  loadingButton: any;
  newaPasswordAPIcallId: any;

  // Customizable Area Start
  formRef = React.createRef<FormInstance<any>>();
  setNewPasswordId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Added
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      //------------------------------------------------------------------------------------
    ];
    this.formRef = React.createRef();
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start

    this.state = {
      loading: false,
      firebaseToken: "",
      email: "",
      token: ""
    };
    // Customizable Area End
  }

  receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.setNewPasswordId) {
        const data = response.data;
        if (data) {
          this.setState({ loading: false });
          if (this.formRef.current) {
            this.formRef.current.resetFields();
          }
          window.location.href = "/EmailAccountLoginWeb";
        } else {
          openErrorNotification(response.errors.errors, "error");
          this.setState({ loading: false });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.init();
  }
  init() {
    this.getUrlParams();
    localStorage.clear();
    Cookies.remove("accountId");
    Cookies.remove("name");
    Cookies.remove("loginToken");
  }
  getUrlParams() {
    const params = window.location.href.split("NewPasswordWeb")[1].split("/");
    const email = params[1];
    const token = params[2];
    this.setState({ token, email });
  }

  onFinish = async (value: any) => {
    this.setState({ loading: true });
    const data = this.state.email
      ? {
          data: { ...value, email: this.state.email, token: this.state.token }
        }
      : {
          data: {
            password: value.new_password,
            password_confirmation: value.password_confirmation,
            firebase_token: this.state.firebaseToken
          }
        };
    const headers = {
      "Content-Type": "application/json;charset=UTF-8"
    };
    this.setNewPasswordId = this.apiCallHandler(
      configJSON.httpPostMethod,
      `${configJSON.baseURL}bx_block_forgot_password/set_new_password`,
      headers,
      data
    );
  };

  apiCallHandler = (
    method: string,
    endPoint: string,
    header: object,
    httpBody?: object
  ) => {
    const RequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const messageId = RequestMessage.messageId;
    RequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    RequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    RequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    RequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(RequestMessage.id, RequestMessage);
    return messageId;
  };
  // Customizable Area End
}
