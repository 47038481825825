// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  openErrorNotification,
  openNotification
} from "../../../components/src/Notification.web";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FormInstance, message } from "antd";
import _ from "lodash";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  route: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  categoryData: any[];
  loading: boolean;
  data: any[];
  cardLoading: boolean;
  catNameID: string;
  catName: string;
  total: number;
  id: string;
  openAddModal: boolean;
  openDeleteModal: boolean;
  editData: any[];
  editBody: any;
  token: string | null;
  navigate: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class AddNecessityCatagoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiNecessityItemCallId: string = "";
  insertNecessityAmountApiCallId: string = "";
  apiAddnewSubcategory: string = "";
  apicatagoryCallId: any;
  NewNecessityExpensesCategoryloadingButton: any;
  NewNecessityExpensesCategoryPickerItem: any;
  customTransactionId: string = "";
  historyBugetId: string = "";
  getCategoryId: string = "";
  getdropdownId: string = "";
  finishCategoryId: string = "";
  deleteCategoryId: string = "";
  saveCategoryId: string = "";
  getDiscretionaryId: string = "";
  updateCaegoryApiId: string = "";
  formRef = React.createRef<FormInstance>();
  formSubmissionId: string = "";
  // history: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      categoryData: [],
      loading: false,
      data: [],
      cardLoading: true,
      catNameID: "",
      catName: "",
      total: 0,
      id: "",
      openAddModal: false,
      openDeleteModal: false,
      editData: [],
      editBody: [],
      token: "",
      navigate: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.getToken();
  }

  getToken = async () => {
    const token = await AsyncStorage.getItem("loginToken");
    await AsyncStorage.getItem("userData");
    await AsyncStorage.getItem("firstName");
    this.setState({
      token: token
    });
    this.getCategoriesData();
    this.dropdownAPI();
  };
  change = (adddisvalue: any, addisindex: any) => {
    this.setState({
      catName: addisindex.key,
      catNameID: adddisvalue
    });
    localStorage.setItem("categoryID", JSON.stringify(adddisvalue));
  };

  dropdownAPI = () => {
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("loginToken"),
      "plaid-account-id": localStorage.getItem("plaid_acc_id")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getdropdownId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.catagoryEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  getCategoriesData = () => {
    this.setState({ cardLoading: true });
    const data = {
      category_type: "discretionary_expense",
      account_id: localStorage.getItem("accountId")
    };
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("loginToken")
    };

    const httpBody = {
      data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.necessityPostUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  onFinish = (value: any) => {
    this.setState({
      loading: true
    });
    const data = {
      data: {
        attributes: {
          category_type: "discretionary_expense",
          amount: value.amount,
          name: this.state.catName,
          account_id: localStorage.getItem("accountId")
        }
      }
    };

    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("loginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.formSubmissionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseURL}bx_block_categories/add_amount_to_subcategory`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  handleDelete = () => {
    this.setState({
      loading: true
    });
    const data = {
      attributes: {
        category_id: this.state.id
      }
    };
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("loginToken")
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCategoryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletecategoryUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  getResponse = (responseJson: any) => {
    if (this.state.navigate) {
      window.location.href = "/AllocateAccountWeb"
    }
  }

  handleSave = async () => {
    const filterData = this.state.editData.filter(
      (x: any) => x.id === this.state.editBody.sub_Details.category_id
    );
    const adddiscamountVal = filterData.length ? filterData[0].amount : 0;
    const adddiscdata1 = {
      data: {
        attributes: {
          category_id: this.state.editBody.sub_Details.category_id,
          amount: adddiscamountVal,
          name: this.state.editBody.sub_Details.name,
          account_id: localStorage.getItem("accountId")
        }
      }
    };
    if (adddiscamountVal) {
      const message1 = new Message(getName(MessageEnum.RestAPIRequestMessage));
      message1.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.baseURL}bx_block_categories/update_name_amount_to_subcategory`
      ); // Use the configured endpoint
      message1.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": "application/json;charset=UTF-8",
          token: localStorage.getItem("loginToken")
        })
      );
      this.updateCaegoryApiId = message1.messageId;
      message1.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(adddiscdata1)
      );
      message1.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putMethod
      );

      const addiscdata = {
        data: {
          attributes: {
            category_type: "discretionary_expense",
            category_name: "discretionary_expense",
            account_id: localStorage.getItem("accountId")
          }
        }
      };
      const message2 = new Message(getName(MessageEnum.RestAPIRequestMessage));
      message2.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.discretionaryIncome
      ); // Use the configured endpoint
      message2.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": "application/json;charset=UTF-8",
          token: localStorage.getItem("loginToken")
        })
      );
      message2.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(addiscdata)
      );
      message2.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
      );

      runEngine.sendMessage(message1.id, message1);
      runEngine.sendMessage(message2.id, message2);
    }
    // Customizable Area End
    return true;
  };

  handleOk = () => {
    this.setState({
      openAddModal: false,
      openDeleteModal: false
    });
  };

  handleCancel = () => {
    this.setState({
      openAddModal: false,
      openDeleteModal: false
    });
  };

  handleChange = (addicitem: any, adddise: any) => {
    adddise.persist();
    const adddiscindex = this.state.editData.findIndex(
      (obj: any) => obj.id === addicitem.sub_Details.category_id
    );
    this.state.editData[adddiscindex].amount = parseInt(adddise.target.value);
    this.setState({
      editData: this.state.editData,
      editBody: addicitem
    });
    const adddiscfilterData = this.state.editData.filter(
      (data: any) => data.id !== this.state.editData[adddiscindex].id
    );
    const adddisclatestValue = adddise.target.value ? adddise.target.value : 0;
    this.setState({
      total:
        _.sum(
          adddiscfilterData.map((data: any) => (data.amount ? data.amount : 0))
        ) + parseInt(adddisclatestValue)
    });
  };

  receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let Categorytoken = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({ token: Categorytoken });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.apiResponseHandler(responseJson, apiRequestCallId);
    }
  }
  // Customizable Area Start

  apiResponseHandler = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getCategoryId) {
      if (responseJson.data) {
        this.recievedCategories(responseJson);
      }
      if (responseJson.status === 401) {
        this.props.navigation.navigate("EmailAccountLoginWeb");
      }
      this.setState({
        cardLoading: false
      });
    }
    if (apiRequestCallId === this.getdropdownId) {
      this.dropdownCategories(responseJson);
    }
    if (apiRequestCallId === this.finishCategoryId) {
      this.addAmout(responseJson);
    }
    if (apiRequestCallId === this.deleteCategoryId) {
      this.deleteCategories();
    }

    if (apiRequestCallId === this.formSubmissionId) {
      this.handleFishedResponse(responseJson);
    }

    if (apiRequestCallId === this.updateCaegoryApiId) {
      this.getResponse(responseJson);
    }
  };

  recievedCategories = (categories: any) => {
    const res = categories.data.map((x: any) => {
      return {
        id: x.sub_Details.category_id,
        name: x.sub_Details.name,
        amount: x.amount
      };
    });
    const ammt = categories.data.map((dd: any) => dd.amount);
    this.setState({
      data: categories.data,
      editData: res,
      total: _.sum(ammt)
    });
  };

  dropdownCategories = (response: any) => {
    if (response && response.data) {
      const data = response.data;
      this.setState({ categoryData: data });
    }
  };

  addAmout = (res: any) => {
    if (res.data) {
      console.log("add amount response", res);
      if (this.formRef.current) {
        this.formRef.current.resetFields();
      }
      openNotification(res.data.message, "success");
    }
    this.setState({
      loading: false,
      openAddModal: false
    });
  };

  deleteCategories = () => {
    this.setState({
      loading: false,
      openDeleteModal: false,
      cardLoading: false
    });
  };
  handleValidation = () => {
    if (this.formRef.current) {
      if (
        !this.formRef.current.isFieldsTouched(true) ||
        this.formRef.current
          .getFieldsError()
          .filter(({ errors }: any) => errors.length).length
      ) {
        return true;
      }
    }
    return false;
  };

  handleFishedResponse = (responseJson: any) => {
    const data = responseJson.data;
    if (data) {
      openNotification(data.message, "success");
    } else {
      const errResponse = responseJson.response;
      if (errResponse) {
        openErrorNotification(errResponse.error, "error");
      }
    }
    this.setState({
      loading: false,
      openAddModal: false,
      cardLoading: false
    });
  };
  // Customizable Area End
}
