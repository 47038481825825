import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import 'firebase/messaging';
import { openNotification, openNotificationWithTitle } from '../../components/src/Notification.web';

const firebaseConfig = {
  apiKey: "AIzaSyCi437j3Re2OHYu_Ytozc-gbxcDqGY51S0",
  authDomain: "best-budget-app-43243.firebaseapp.com",
  projectId: "best-budget-app-43243",
  storageBucket: "best-budget-app-43243.appspot.com",
  messagingSenderId: "328329423909",
  appId: "1:328329423909:web:a8dc3b3eefff175ddde3cc",
  measurementId: "G-SZ5PRHPQN9"
};

firebase.initializeApp(firebaseConfig);
// debugger
// const app = initializeApp(firebaseConfig);
// const messaging = app.firebase_.messaging();
const messaging = firebase.messaging();
export default firebase;
export const getToken = setFirebaseToken => {
  return messaging
    .getToken({
      vapidKey:
        'BPcna_ZpuNvXwV4tRhlm5xNem6oLhU5Vajp447v_SdxE-T8YOEj3quHBBek8bnVlz8Tywfj8XuHp0SWzehLjuEA'
    })
    .then(currentToken => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setFirebaseToken(currentToken)
        // setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        );
        // setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload);
      openNotificationWithTitle(payload.notification.title,payload.notification.body,"success")
      console.log(payload, 'pp');
    });
  });
