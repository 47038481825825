import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef } from "react"
import Cookies from "js-cookie";
import { openErrorNotification, openNotification } from "../../../components/src/Notification.web";
import _, { } from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    data: any,
    loading: boolean;
    categoryData: any;
    cardLoading: boolean;
    redirect: boolean;
    total: any;
    id: string;
    openAddModal: boolean;
    catNameID: string;
    catName: string;
    openDeleteModal: boolean;
    editData: any;
    editBody: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddMonthlyNecessityExpensesController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    form: any = createRef();
    dropdownApiId: string = "";
    getDataApiId: string = "";
    onFinishApiId: string = "";
    handleDeleteApiId: string = "";
    updateNameApiId: string = "";
    saveNecessityApiId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            data: [],
            loading: false,
            categoryData: [],
            cardLoading: true,
            redirect: false,
            total: "",
            id: "",
            openAddModal: false,
            catNameID: "",
            catName: "",
            openDeleteModal: false,
            editData: [],
            editBody: [],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
                this.successCallBack(apiRequestCallId, responseJson)
            } else {
                this.failureCallBack(apiRequestCallId, responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.dropdownAPI();
        this.getData();
    }

    successCallBack = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.getDataApiId) {
            this.getDataSuccess(responseJson)
        } else if (apiRequestCallId === this.dropdownApiId) {
            this.dropdownApiSuccess(responseJson)
        } else if (apiRequestCallId === this.onFinishApiId) {
            this.onFinishSuccess(responseJson)
        } else if (apiRequestCallId === this.handleDeleteApiId) {
            this.handleDeleteSuccess()
        } else if (apiRequestCallId === this.updateNameApiId) {
            this.updateNameSuccess()
        }
    }

    failureCallBack = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.getDataApiId) {
            this.getDataFailure(responseJson)
        } else if (apiRequestCallId === this.onFinishApiId) {
            this.onFinishFailure(responseJson)
        } else if (apiRequestCallId === this.handleDeleteApiId) {
            this.handleDeleteFailure(responseJson)
        }
    }

    getData = () => {
        const body = {
            data: {
                category_type: "necessity_expense",
                account_id: localStorage.getItem("accountId"),
            },
        };
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getDataApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.baseURL}bx_block_categories/get_all_categories_from_cat_type`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    getDataSuccess = (resu: any) => {
        this.setState({ data: resu.data })
        if (Array.isArray(resu.data)) {
            const res = resu.data.map((x: any) => {
                return {
                    id: x.sub_Details?.category_id,
                    name: x.sub_Details?.name,
                    amount: x.amount,
                };
            });
            this.setState({ editData: res });
        }
        const ammt = resu.data.map((dd: any) => dd.amount);
        this.setState({ total: _.sum(ammt) });
        this.setState({ cardLoading: false });
    }

    getDataFailure = (err: any) => {
        this.setState({ cardLoading: false });
        if (err?.response?.status == 401 || err?.response?.status == 400) {
            localStorage.clear();
            Cookies.remove("accountId");
            Cookies.remove("name");
            Cookies.remove("loginToken");
            this.setState({ redirect: true });
        }
    }

    dropdownAPI = () => {
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            "token": localStorage.getItem("loginToken"),
            "plaid-account-id": localStorage.getItem("plaid_acc_id"),
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.dropdownApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.baseURL}bx_block_categories/get_all_categories`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    dropdownApiSuccess = (addmonresult: any) => {
        this.setState({ categoryData: addmonresult.data })
    }

    handleOk = () => {
        this.setState({ openAddModal: false })
        this.setState({ openDeleteModal: false })
    };

    handleCancel = () => {
        this.setState({ openAddModal: false })
        this.setState({ openDeleteModal: false })
    };

    onFinish = (value: any) => {

        this.setState({ loading: true });
        this.setState({ cardLoading: true });

        const body = {
            data: {
                attributes: {
                    category_type: "necessity_expense",
                    amount: value.amount,
                    name: this.state.catName,
                    account_id: localStorage.getItem("accountId"),
                },
            },
        };
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.onFinishApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.baseURL}bx_block_categories/add_amount_to_subcategory`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    onFinishSuccess = (res: any) => {
        this.form.current?.resetFields();
        this.getData();
        this.setState({ loading: false });
        this.setState({ openAddModal: false });
        openNotification(res.data.message, "success")
    }

    onFinishFailure = (err: any) => {
        this.setState({ loading: false });
        this.form.current?.resetFields();
        this.setState({ cardLoading: false });
        openErrorNotification(err?.response?.data?.error, "error")
    }

    handleDelete = () => {
        this.setState({ loading: true });
        this.setState({ cardLoading: true });
        const body = {
            data: {
                attributes: {
                    category_id: this.state.id,
                },
            },
        };
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.handleDeleteApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.baseURL}bx_block_categories/delete_sub_category`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    handleDeleteSuccess = () => {
        this.setState({ loading: false });
        this.getData();
        this.setState({ openDeleteModal: false });
    }

    handleDeleteFailure = (err: any) => {
        this.setState({ loading: false });
        this.setState({ cardLoading: false });
        openErrorNotification(err?.response?.data?.errors[0]?.account, "error")
    }

    change = (value: any, index: any) => {
        this.setState({ catName: index.key });
        this.setState({ catNameID: value });
        localStorage.setItem('categoryID', JSON.stringify(value))
    }

    handleChange = (item: any, e: any) => {
        e.persist();
        const index = this.state.editData.findIndex(
            (obj: any) => obj.id === item.sub_Details.category_id
        );
        this.state.editData[index].amount = parseInt(e.target.value);
        this.setState({ editData: this.state.editData })
        this.setState({ editBody: item })
        const filterData = this.state.editData.filter((data: any) => data.id !== this.state.editData[index].id)
        const latestValue = e.target.value ? e.target.value : 0
        this.setState({ total: (_.sum(filterData.map((data: any) => data.amount ? data.amount : 0)) + parseInt(latestValue)) });
    };

    handleSave = () => {
        const addmontlyamountVal = this.state.editData.filter((x: any) => x.id === this.state.editBody?.sub_Details.category_id)[0]?.amount
        const addmonthlydata1 = {
            data: {
                attributes: {
                    category_id: this.state.editBody.sub_Details.category_id,
                    amount: addmontlyamountVal,
                    name: this.state.editBody.sub_Details.name,
                    account_id: localStorage.getItem("accountId")
                },
            },
        };
        if (addmontlyamountVal) {
            this.updateName(addmonthlydata1);
        }
    };

    submitData = () => {
        if (parseInt(localStorage.getItem("take_home_pay") || "0") / 2 <= this.state.total) {
            openErrorNotification("Please select total Necessity Expense less than half of take home pay !", "error")
        } else {
            this.props.navigation.navigate("DiscretioneryIncomesWeb")
        }
    }

    updateName = (addmonthlydata1: any) => {
        const body = addmonthlydata1
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateNameApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.baseURL}bx_block_categories/update_name_amount_to_subcategory`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    updateNameSuccess = () => {
        this.saveNecessity();
    }

    saveNecessity = () => {
        const body = {
            data: {
                attributes: {
                    category_type: "necessity_expense",
                    category_name: "necessity_expense",
                    account_id: localStorage.getItem("accountId"),
                },
            },
        };
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.saveNecessityApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.baseURL}bx_block_categories/save_necessity_expense`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // Customizable Area End
}