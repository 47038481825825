//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config.js");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: any;
  errorMsg: string;
  loading: boolean;
  switchValue: boolean;
  accountId: string;
  userName: string;
  necessityData: any;
  discretioneryData: any;
  reveniewData: any;
  TotalcurrentBalance: number;
  urlFinicity: string;
  accountsId: string;
  netTotalBudgeted: number;
  netTotalAvailable: number;
  customer: any;
  accountno: any;
  totalamount: any;
  netamount: any;
  //totalBalanceUrl:String;
  fincity_token: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class ReviewCheckingController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiRevenueCallId: string = "";
  apiTotalbalance: any = "";
  apiaccounts: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionSaveMessage),
      // getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      errorMsg: "",
      token: "",
      loading: false,
      switchValue: true,
      accountId: "",
      userName: "username",
      necessityData: [],
      discretioneryData: [],
      dashboardData: [],
      reveniewData: [],
      TotalcurrentBalance: 0,
      totalamount: 0,
      urlFinicity: "",
      accountsId: "",
      netTotalBudgeted: 0,
      netTotalAvailable: 0,
      customer: "",
      accountno: [],
      netamount: 0,
      fincity_token: "",
      //totalBalanceUrl:"",
    };

    //const totalbalanceurl=`https://bestbudgetapp-15556-ruby.15556.dev.ap-southeast-1.aws.svc.builder.cafe/bx_block_finicity/customer/${this.state.accountId}/accounts/${this.state.accountsId}`;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
   await this.getToken();
  }
  getToken = async () => {
    const token = await AsyncStorage.getItem("tokenLogin");
    const data: any = await AsyncStorage.getItem("userData");
    const finicityCustomerId = await AsyncStorage.getItem(
      "finicity_customer_id"
    );
    const finicityToken = await AsyncStorage.getItem("finicity_token");
    const userData = JSON.parse(data);
    this.setState(
      {
        token: token,
        customer: finicityCustomerId,
        accountId: userData.data.id,
        loading: true,
        fincity_token: finicityToken,
      },
      () => {
        this.Reviewchecking();
        this.totalBalance(
          this.state.customer,
          this.state.token,
          this.state.fincity_token
        );
      }
    );
  };

  async componentWillUnmount() {
    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
  }

  //new api for review checking
  Reviewchecking = () => {
    const header = {
      "Content-Type": configJSON.necessityContentType,
      token: this.state.token,
      finicity_customer_id: this.state.customer,
      customerId: this.state.fincity_token,
    };
    console.log("header =======>>>>>>>>>>>>>>>>>>>> ", JSON.stringify(header));
    const httpBody = {
      customerId: this.state.customer,
      finicity_token: this.state.fincity_token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);
    this.apiRevenueCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reviewChecking
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  totalBalance = (customer: any, token: any, fincityToken: any) => {
    const endpont = `bx_block_finicity/customer/${customer}/total_balance`;
    const header = {
      "Content-Type": configJSON.necessityContentType,
      token: token,
      fincity_token: fincityToken,
    };
    console.log("header  ", JSON.stringify(header));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);
    this.apiTotalbalance = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpont
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    console.log("requestMessage@vvvv, ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };
  // accountRetrive = () => {
  //  var endpont =`bx_block_finicity/customer/${this.state.customer}/all_accounts`;
  //   //var endpont = `bx_block_finicity/customer/${this.state.customer}/accounts/5002567097`;
  //   //var requiredUrl =configJSON.totalbalanceurl.concat(endpont.toString());
  //   const header = {
  //     "Content-Type": configJSON.necessityContentType,
  //     token: this.state.token
  //   };
  //   console.log("header  ", JSON.stringify(header));
  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   console.log("requestMessagesss, ", requestMessage);
  //   this.apiaccounts= requestMessage.messageId;
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     endpont
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.dashboarApiMethodType
  //   );
  //   console.log('requestMessage@vvvv, ', requestMessage);
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  //   // Customizable Area End
  //   return true;
  // }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("receive...");
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      //runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      let accountId = message.getData(getName(MessageEnum.SessionResponseData));
      let obj = JSON.parse(accountId);
      //console.log(obj.data.id);
      this.setState({ accountId: obj.data.id });
      this.setState({ customer: obj.data.finicity_customer_id });
      this.setState({ token: token, loading: true }, () => {
        this.Reviewchecking();

        //this.setState({TotalcurrentBalance:this.state.netamount})
        // this.netbalance();
      });
      //this.accountRetrive();
      console.log("customerdata", token);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiRevenueCallId) {
          this.getReveniewData(responseJson, errorReponse);
        } else if (apiRequestCallId === this.apiTotalbalance) {
          this.getTotalBalanceData(responseJson, errorReponse);
        }
      }
    }
  }

  getReveniewData = (response: any, errorResponse: any) => {
    if (response.dashboardData) {
      this.setState(
        {
          reveniewData: response.dashboardData,
          errorMsg: "",
          loading: false,
        },
        () => {
          this.netbalance();
        }
      );
    } else {
      console.log("error resp", errorResponse);
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false,
        });
      } else {
        this.setState({
          errorMsg: errorResponse,
          loading: false,
        });
      }
    }
  };

  getTotalBalanceData = (response: any, errorResponse: any) => {
    if (!response.errors) {
      console.log("accountsr", JSON.stringify(response));
      this.setState({
        TotalcurrentBalance: Number(response.total_balance.toFixed(2)),
        errorMsg: "",
        loading: false,
      });
      console.log("response", response);
      this.netbalance();
    } else {
      console.log("error resptttttttt", errorResponse);
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false,
        });
      } else {
        this.setState({
          errorMsg: errorResponse,
          loading: false,
        });
      }
    }
  };

  getCategories() {
    throw new Error("Method not implemented.");
  }
  
  netbalance() {
    let total = 0;
    let totalavailable = 0;
    console.log("something to display");
    console.log("allaccounts", this.state.netamount);
    console.log("TOTALLLTHINGS", this.state.reveniewData);
    this.state.reveniewData.forEach((element: any) => {
      console.log("AMOUNNNNTTTTT:::", element.budgeted_amount);
      total = total + element.budgeted_amount;
      totalavailable = totalavailable + element.availableamount;
    });

    console.log("totalAmount" + total);
    let netbalance = this.state.TotalcurrentBalance - total;
    let netavailable = this.state.TotalcurrentBalance - totalavailable;
    this.setState({ netTotalBudgeted: netbalance });
    this.setState({ netTotalAvailable: netavailable });
  }
  // Customizable Area Start
  onPressSubmit = {
    onPress: () => {
      this.props.navigation.navigate("Dashboard");
    },
  };
  // Customizable Area End
}
