//@ts-nocheck
// Customizable Area Start
import React from "react";
const logo = require("../assets/slide3.png");
const bestLogo = require("../assets/logo.png"); 
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Image,  Progress, Button } from "antd"; 
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
//@ts-ignore
import bgImage from "../assets/ob_back.png";
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
import MonthlyNecessityExpensesController, { Props } from "./MonthlyNecessityExpensesController";

export default class MonthlyNecessityExpensesWeb extends MonthlyNecessityExpensesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
  return (
    <><TopNav />
      <Row align="middle" justify="center" style={{ 
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          position: "fixed",
          overflow: "auto",
          paddingBottom: "20px",
          marginBottom: "30px",
        }}>
        <Col lg={10} md={13} sm={16} xs={21}>
          <Card>
            <Row justify="center" style={{ marginBottom: "3em" }}>
              <Col span={3}>
                <Image src={bestLogo} preview={false} />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={6}>
                <Image src={logo} preview={false} />
              </Col>
            </Row>
            <Row
              justify="center"
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "rgb(104, 105, 108)",
              }}
            >
              <Col span={24}>Identify Necessity Expenses.</Col>
            </Row>
            <Row justify="center">
              <Col
                span={16}
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  lineHeight: "1.39",
                  letterSpacing: "-0.43px",
                  color: "#68696c",
                  marginBottom: "5em",
                }}
              >
                Plan is to provide user with a form to fill out with various
                types of Necessity Expenses.
              </Col>
            </Row>
            <Row justify="center">
              <Col span={18}>
                <Progress percent={40} status="active" showInfo={false} />
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: "2em" }}>
              <Button
                data-test-id = "back-btn"
                shape="round"
                style={{ backgroundColor: "#b3b3b3", color: "white" }}
                onClick={() => this.handleBack()}
                icon={<LeftOutlined translate />}
              >
                Back
              </Button>
              <Button data-test-id = "save-continue-btn" shape="round" type="primary" onClick={() => this.handleSave()}>
                SAVE & CONTINUE <RightOutlined translate />
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}}
// Customizable Area End
