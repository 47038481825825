const { baseURL } = require("../../../framework/src/config");

Object.defineProperty(exports, "__esModule", {
  value: true,
});


exports.baseURL=`${baseURL}`

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LinkedAccounts";
exports.labelBodyText = "LinkedAccounts Body";
exports.btnExampleTitle = "CLICK ME";

exports.linkedAccAPIEndPoint =
  `${baseURL}bx_block_finicity/connect_url`;
exports.finicityTokenAPIEndPoint =
  `${baseURL}bx_block_finicity/finicity_token`;
exports.createTestCustomerAPIEndPoint =
  `${baseURL}bx_block_finicity/create_test_customer`;
exports.createCustomerAPIEndPoint =
  `${baseURL}bx_block_finicity/customer/`;
exports.plaidSuccessAPIEndPoint =
  `${baseURL}bx_block_plaid/item/public_token/exchange`;
exports.getLinkTokenAPIEndPoint =
  `${baseURL}bx_block_plaid/fetch_link_token`;
exports.getPlaidAccIdAPIEndPoint =
  `${baseURL}bx_block_plaid/fetch_db_accounts`;
exports.postMethod = "POST";
exports.getMethod = "GET";
exports.contentTypeApi = "application/json";
// Customizable Area End
