//@ts-nocheck
import React from "react";
import "antd/dist/antd.css";
import {
  Divider,
  Form,
  Select,
  Table,
  Progress,
  DatePicker,
  Row,
  Col,
} from "antd";
const { Option } = Select;
import DiscretioneryExpensesController, {
  Props,
} from "./DiscretioneryExpensesController";
import SideBar from "../../../components/src/SideBar";
import { View } from "react-native";
import logo from "../assets/logo.png";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
const { RangePicker } = DatePicker;
export default class DiscretioneryExpenses extends DiscretioneryExpensesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      <div>
        <div>
          <SideBar />
        </div>
        {this.state.cardLoading ? (
          <LoadingWeb />
        ) : (
          <div style={styles.block}>
            <div style={styles.firstDiv}>
              <div
                style={{
                  color: "#545861",
                  fontSize: 22,
                  fontWeight: 900,
                  marginRight: "1em",
                }}
              >
                Discretionary Expenses
              </div>
              <div
                style={{
                  color: "#b3b3b3",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Budgets Balance • Discretionary Expenses
              </div>
            </div>

            <Row>
              <Col
                sm={10}
                xs={24}
                style={{ paddingRight: "20px", marginBottom: "2em" }}
              >
                <Row
                  style={{
                    backgroundColor: "#ededf2",
                    marginBottom: "15px",
                    textAlign: "center",
                    borderRadius: "7px",
                  }}
                >
                  <Col
                    span={24}
                    style={{
                      color: "#999999",
                      marginBottom: "10px",
                      marginTop: "5px",
                    }}
                  >
                    Available balance
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                    className="balance_text"
                  >
                    $ {this.state.availableAmount}
                  </Col>
                </Row>
                <Row
                  justify="center"
                  align="middle"
                  style={{ color: "#999999", fontWeight: "bold" }}
                >
                  Budgeted: $ {this.state.budgetedAmount}
                </Row>
                <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
              </Col>
              <Col sm={14} xs={24}>
                <Row
                  style={{
                    color: "#545861",
                    fontSize: "22px",
                    fontWeight: 700,
                    lineHeight: " 1.2",
                  }}
                >
                  History
                </Row>
                <Divider />
                <Row>
                  <div style={{ width: "200px" }}>
                    <Form>
                      <Form.Item>
                        <Select
                          size="large"
                          placeholder="Month to date"
                          allowClear
                          onChange={this.keywordFilter}
                        >
                          <Option value="month_to_date">Month to date</Option>
                          <Option value="year_to_date">Year to date</Option>
                          <Option value="last_month">Last Month</Option>
                          <Option value="custom">Custom</Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </div>
                  <span
                    style={{
                      width: "auto",
                    }}
                  >
                    {this.state.showCustom ? (
                      <RangePicker
                        size="large"
                        style={{
                          marginLeft: "5px",
                        }}
                        onChange={this.handlerCustomDate}
                        format={"YYYY-MM-DD"}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Row>
                <Row
                  align="middle"
                  style={{
                    fontSize: 16,
                    color: "#000000",
                    fontWeight: 600,
                    height: "60px",
                    paddingLeft: "20px",
                    borderRadius: "6.4px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Col span={24}>Transactions</Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#f7f7f7",
                      }}
                    >
                      <Col
                        span={16}
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#000000",
                        }}
                      >
                        Category
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Table
                      style={{
                        padding: " 0px 20px",
                      }}
                      columns={this.state.columnData}
                      dataSource={this.state.tableData}
                      pagination={{ defaultPageSize: 10 }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  block: {
    height: "auto",
    padding: "40px",
    backgroundColor: "white",
    marginRight: "32px",
    border: "1px solid white",
    marginLeft: "106px",
    marginTop: "28px",
  },
  firstDiv: {
    display: "flex",
    alignItems: "center",
    width: "589px",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  firstDivContent: {
    width: "372px",
  },
  secondDiv: {
    display: "flex",
  },
  button: {
    width: "372.1px",
    height: "90px",
    margin: "43.2px 30px 0 0",
    padding: "6.7px 113px 14.8px 113.1px",
    borderRadius: "6.4px",
    backgroundColor: "#ededf2",
  },
  second: {
    height: "auto",
    padding: "34px 24px",
  },
};
