import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { AsyncStorage } from 'react-native';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  route:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  country: any;
  data: any;
  accountName: any;
  bandkid: any;
  isModalLoader: boolean;
  isMoalSaved: boolean;
  isModalEntercred: boolean;
  username: any;
  password: any;
  isModallinkBankAcnt: boolean;
  ListArray: any;
  visible: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LinkedAccountsController extends BlockComponent<
  Props,
  S,
  SS
> {

  finicity_customer_id: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // this.ContinueLink();
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {

      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      country: "uk",
      data: [
        {
          value: "Bank Of India ",
          id: 1,
        },
        {
          value: "SBI",
          id: 2,
        },
        {
          value: "PNB",
          id: 3,
        },
      ],
      accountName: "",
      bandkid: "",
      isModalLoader: false,
      isMoalSaved: false,
      isModalEntercred: false,
      username: "",
      password: "",
      isModallinkBankAcnt: false,
      ListArray: [] = "",
      visible: false,
      loading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  navigatetoAccDetails() {
    this.props.navigation.navigate("LinkAccountsDetail002");
  }
  changeText = (text: any, value: any) => {
    if (value == "accountName") {
      this.setState({ accountName: text });
    } else if (value == "bankid") {
      // alert(JSON.stringify(text));
    } else if (value == "username") {
      this.setState({ username: text });
    } else if (value == "password") {
      this.setState({ password: text });
    }
  };
  valueExtractor1 = (val: { data: { attributes: { id: any } } }): any => {
    // alert('1'+JSON.stringify(val))
    // return val.data.attributes.id;
  };

  onValueHanndler = (val: { data: { attributes: { name: any } } }): any => {
    // alert('2'+JSON.stringify(val))
    // return val.data.attributes.name;
  };
  DropDownProps = {
    onChangeText: (text: string) => {
      // alert(JSON.stringify(text))
      this.setState({ bandkid: text });
      //@ts-ignore
      this.DropDownProps.value = text;
    },
  };
  ExitModel = () => {
    this.setState({ isModalLoader: false });
  };
  enableLoader = () => {
    this.setState({ isModalLoader: true });
  };
  Addaccount = () => {
    this.setState({ isModalLoader: true });
    setTimeout(() => {
      this.setState({ isMoalSaved: true, isModalEntercred: false });
      this.SignInModal();
    }, 1000);
    setTimeout(() => {
      this.setState({ isModalLoader: false });
    }, 3000);
  };
  SignInModal() {
    setTimeout(() => {
      this.setState({ isMoalSaved: false, isModalEntercred: true });
    }, 3000);
  }
  SignIn = () => {
    this.setState({ isModalEntercred: false, isModallinkBankAcnt: true });
  };
  ContinueLink() {
    this.setState({ isModallinkBankAcnt: false });
  }
  getToken = async () => {
    try {
      const finicity_customer_id = await AsyncStorage.getItem("finicity_customer_id");
      this.finicity_customer_id = finicity_customer_id;
      if (this.finicity_customer_id !== null) {
        // We have data!!
        console.log(
          "this.finicity_customer_id ######################>>>>>>>>>>>>>>>>>>",
          this.finicity_customer_id
        );
      }
    } catch (error) {
      // Error retrieving data
    }
  };
  // Customizable Area End
}
