import React from "react";
import { SafeAreaView } from "react-native";
// Customizable Area Start
// import { WebView } from "react-native-webview";
// Customizable Area End
import { Breadcrumb } from 'antd';
import { Typography, Space } from 'antd';
import { Menu, Dropdown, Button } from 'antd';
import { Input, Radio } from 'antd';
import { Form, Select, Divider, Modal } from 'antd';
const { Option } = Select;

import LinkedAccountsController, { Props } from "./LinkedAccountsController";
import SideBar from "../../../components/src/SideBar";
import { logo } from "./assets";
import 'antd/dist/antd.css';

export default class LinkedAccounts extends LinkedAccountsController {

  // state = {
  //   loading: false,
  //   visible: false,
  // };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { visible, loading } = this.state;
    // let link = this.props.route.params.res;
    // let link = this.props.route.params.res;
    return (
      <>
        <div>
          <div><SideBar /></div>
          <div >
            <div style={styles.contentBlock}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: "#545861", fontSize: "32px", fontWeight: 700 }}>
                    Link Accounts
                  </div>
                  <div style={{ marginLeft: "10px", fontSize: "14px", color: "#DBDBDB", fontWeight: 600 }}>
                    Accounts
                  </div>
                  <div style={{ marginLeft: "10px", fontSize: "14px", color: "#DBDBDB", fontWeight: 600 }}>
                    Link accounts
                  </div>
                </div>
                <div>
                  <Button type="primary"
                    style={styles.addAccountButton}
                    onClick={this.showModal}>
                    <h4 style={{ fontWeight: 700, transform: "translateY(-10px)", color: "white" }}>
                      ADD ACCOUNT
                    </h4>
                  </Button>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", width: "60vw" }}>
                <div>
                  <div style={{ fontWeight: 600, fontSize: "20px" }}>
                    Link Bank & Credit Card Account
                  </div>
                  <div style={styles.dropdown}>
                    <p style={{ color: "#CCCCCC", fontSize: "12px" }}>
                      Bank accounts
                    </p>
                    <Form>
                      <Form.Item>
                        <Select
                          placeholder="Select a option and change input text above"
                          allowClear>
                          <Option value="user">
                            user
                          </Option>
                          <Option value="admin">
                            admin
                          </Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
                <div>
                  Or
                </div>
                <div>
                  <div style={{ fontWeight: 600, fontSize: "20px" }}>
                    Manually Enter Bank & CC Accounts
                  </div>
                  <div style={styles.dropdown}>
                    <p style={{ color: "#CCCCCC", fontSize: "12px" }}>
                      Account Name
                    </p>
                    <Form>
                      <Form.Item >
                        <Input placeholder="Enter Account Name" />
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
              <div style={{ backgroundColor: "#F2F2F2", color: "#030303", fontWeight: 600, fontSize: "20px", padding: "16px", border: "1px solid rgb(242, 242, 242)", borderRadius: "6px" }}>Linked Bank Accounts
              </div>
              <Divider style={{ marginTop: "60px" }} />
              <Divider style={{ marginTop: "60px" }} />
              <Divider style={{ marginTop: "60px" }} />
              <div>
                <Modal
                  visible={visible}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                >
                  <div style={{ textAlign: "center" }}>
                    <img src={logo} alt="" style={{ height: "50px" }} />
                    <Divider />
                    <div style={{ padding: "0px 60px" }}>
                      <h1 style={{ fontSize: "32px", color: "#545861", fontWeight: 700 }}>
                        Sign in your account bank to continue
                      </h1>
                      <Form>
                        <Form.Item >
                          <Input placeholder="Enter Your Username" />
                        </Form.Item>
                        <Form.Item
                          name="password"
                        >
                          <Input.Password />
                        </Form.Item>
                      </Form>
                      <Button type="primary" style={styles.loginButton}>
                        <h4 style={{ color: "white", fontWeight: 700, transform: "translateY(-10px)" }}>
                          LOGIN
                        </h4>
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  contentBlock: {
    height: "auto",
    padding: "40px",
    backgroundColor: "white",
    marginRight: "24px",
    border: "1px solid white",
    marginLeft: "106px",
    marginTop: "28px",
  },
  dropdown: {
    marginTop: "20px",
  },
  dropdownButton: {
    width: "268px",
    textAlign: "start",
  },
  addAccountButton: {
    border: "1px solid B3B3B3",
    borderRadius: "40px",
    backgroundColor: "#B3B3B3",
    padding: "20px 40px",
  },
  loginButton: {
    width: "282px",
    padding: "20px 36px",
    backgroundColor: "#2DADE1!important",
    borderRadius: "40px",
    border: "1px solid #2DADE1",
  },
}
