const { baseURL } = require("../../../framework/src/config");

Object.defineProperty(exports, "__esModule", {
  value: true,
});
 
exports.STRIPE_PUBLISH_KEY="pk_test_9zmRAOfXiqopv6mJbinOXvvz"
exports.baseURL=`${baseURL}`
// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.editcatagoryAmountPostUrl =
  "/bx_block_categories/update_name_amount_to_subcategory";
exports.necessityContentType = "application/json";
exports.necessityPostUrl =
  "bx_block_categories/get_all_categories_from_cat_type";
exports.necessityApiMethodType = "POST";
exports.saveNecessityturl = "bx_block_categories/save_necessity_expense";
exports.editApiMethodType = "PUT";
exports.addcatagoryContentType = "application/json";
exports.addcatagoryAmountPostUrl =
  "/bx_block_categories/update_name_amount_to_subcategory";
exports.addcatagoryApiMethodType = "POST"; 
exports.AddnewSubcategoryPostUrl =
  "/bx_block_categories/add_amount_to_subcategory";
exports.apihomePayGetUrl = "bx_block_dashboard/dashboards";
exports.discretionaryIncome = "bx_block_dashboard/get_discretionary_income";
exports.reviewChecking = "bx_block_dashboard/review_and_checking_list";
// Customizable Area End
exports.reveniewtUrl = "/bx_block_dashboard/get_savings_account_det";
exports.editUrl = "/bx_block_categories/delete_sub_category";
exports.deleteApiMethodType = "DELETE";
exports.deleteurl = "/bx_block_categories/delete_sub_category";
exports.getIdurl = "bx_block_categories/get_cat_id_from_name";
exports.getCatIdUrl="bx_block_categories/get_all_subcategories_from_cat_id";
exports.accountPageAPIEndPoint =
  `${baseURL}bx_block_finicity/customer/`;
exports.historyCatAPIEndPoint =
  `${baseURL}bx_block_categories/history_for_budgets?`;
exports.postMethod = "POST";
exports.getMethod = "GET";
exports.contentTypeApi = "application/json";

exports.catagoryEndpoint = "bx_block_categories/get_all_categories";

exports.updateBalAPIEndPoint =
  `${baseURL}bx_block_categories/update_available_balance`;
exports.putMethod = "PUT";

exports.getPlaidAccIdAPIEndPoint =
  `${baseURL}bx_block_plaid/fetch_db_accounts`;
exports.postMethod = "POST";
exports.getMethod = "GET";
exports.discretionaryAPI=`${baseURL}bx_block_dashboard/dashboards`
// Customizable Area Start
// exports.validationApiContentType = "application/json";
// exports.validationApiMethodType = "GET";
// exports.exampleAPiEndPoint = "EXAMPLE_URL";
// exports.exampleAPiMethod = "POST";
// exports.exampleApiContentType = "application/json";
// exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Save";
exports.type = "primary";
exports.htmltype = "submit";
exports.labelBodyText = "Description";
exports.labelBodyText1 ="Amount"
exports.btnExampleTitle = "Please input your Amount!";
exports.sucessfullTransaction = "Successfull Transaction";

exports.reallocateMoney = "Reallocate Money";
exports.addCategoryCaps = "ADD CATEGORY";
exports.addCategory = "Add Category";
exports.saveCaps = "SAVE";
exports.reallocateFrom = "Reallocate From";
exports.category = "Category";
exports.balance = "Balance";
exports.noData = "No Data";
exports.reallocateTo = "Reallocate To";
exports.back = "Back";
exports.continue = "Continue";
exports.confirmTransaction = "Confirm Transaction Details";
exports.amount = "Amount";
exports.edit = "Edit";
exports.confirm = "Confirm";

exports.categoryA = "Category A";
exports.budgetsBalance = "Budgets Balance • Category A";
exports.availableBalance = "Available balance";
exports.history = "History";
exports.transaction = "Transactions";

exports.categoryName = "Your Category Name";
exports.confirmDelete = "Confirm delete";
exports.deleteBtn = "DELETE";
exports.cancelCaps = "CANCEL";
exports.confirmDeleteMsg = "Do you confirm that you want to delete this item?";
exports.admin = "admin";
exports.categories = "Categories";
exports.yourAmount = "Your Amount";
exports.budgetBal = "Budgets Balance";
exports.dashboard = "Dashboard";
exports.weeklyBalance = "Weekly Balanced View";
exports.reallocateMoneyCaps = "REALLOCATE MONEY";
exports.addDicritionary = "Monthly Discretionary Expenses";
exports.enterDiscExpenses = "Please enter your monthly discretionary Expenses";
exports.total = "Total:";
exports.saveAndContinue = "SAVE & CONTINUE";
exports.monthlyNeccesityExp = "Monthly Necessity Expenses";
exports.enterMonthlyNessExp = "Please enter your monthly Necessity Expenses";

// Customizable Area End
