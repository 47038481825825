//@ts-nocheck
import React from 'react';
import 'antd/dist/antd.css';
import '../assets/index.css';
import SplitTransactionController, { Props } from "./SplitTransactionController";
import SideBar from "../../../components/src/SideBar";
import { Divider, Form, Table, Progress, Input, Modal, Button, Row, Col, Typography, Select, Card } from "antd";
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { splitTransactionRes } from "../../../web/src/redux/actions/users";
import { connect } from 'react-redux';
import { LoadingWeb } from "../../../components/src/LoadingWeb";
const { Option } = Select;

export class SplitTransaction extends SplitTransactionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            <div>
                <div><SideBar /></div>
                {this.state.cardLoading ? (<LoadingWeb />) :
                    (
                        <Row style={{ marginLeft: "79px" }}>
                            <Col span={24}>
                                <Card style={{ margin: "2em", padding: "40px" }}>

                                    {/* <div style={styles.firstDiv}> */}
                                    <Row justify="space-between">
                                        <Col> <h1 style={styles.BudgetsBalance}> Split Transaction </h1>
                                        </Col><Col>  <Button type="primary" shape="round"
                                            onClick={this.showCategory}
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 700, backgroundColor: "#f2f2f2", padding: "20px",
                                                color: "#00ade2",
                                                display: "flex",
                                                border: "none",
                                                alignItems: "center"
                                            }}>
                                            ADD CATEGORY
                                        </Button>
                                        </Col>
                                    </Row>
                                    <Modal
                                        visible={this.state.showModalCategory}
                                        onCancel={this.handleCancel}
                                    >
                                        <div style={{}}>
                                            <div style={{ padding: "0px 60px" }}>
                                                <h1 style={{ fontSize: "32px", color: "#545861", fontWeight: 700, textAlign: "center" }}>Add Category</h1>

                                                <div>
                                                    <Row>


                                                        <Form onFinish={this.addCategory} style={{ width: "300px" }} >
                                                            <Form.Item style={{ width: "100%" }}>
                                                                <Select

                                                                    size="large"
                                                                    placeholder="Select Category"
                                                                    allowClear onChange={this.change}>
                                                                    {this.state.categoryData && this.state.categoryData.map((values: any) => (
                                                                        <Option key={values?.attributes?.generic_name} value={values?.attributes?.id}>{values?.attributes?.generic_name}</Option>
                                                                    ))}
                                                                    {/* <Option value="admin">admin</Option> */}
                                                                </Select>
                                                            </Form.Item>
                                                            <Typography style={{ marginTop: "10px", fontSize: 25, fontWeight: 600 }}>Balance :</Typography>
                                                            <Form.Item name="category_amount" label="Category Amount" style={{ display: "flow-root" }} rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Category Amount is required"
                                                                }
                                                            ]}>
                                                                <Input type="number" style={{ marginTop: "10px", borderRadius: 5 }} placeholder="Amount" />
                                                            </Form.Item>
                                                            <Button type="primary" style={{ width: "300px", borderRadius: 20, marginTop: 30, marginBottom: 30, backgroundColor: "#2EADE2" }} htmlType="submit" ><h4 style={{ color: "white", fontWeight: 700 }} >SAVE</h4></Button>
                                                        </Form>


                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>

                                    {/* <div style={{ display: "flex", alignItems: "center"  }}>
                                            <div>
                                            </div>
                                        </div> */}
                                    {/* <div>
                                        </div> */}
                                    {/* </div> */}
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: "42%" }}>
                                            <div style={{
                                                fontSize: "22px",
                                                fontWeight: 900,
                                                color: "#545861",
                                                marginTop: "24px",
                                                lineHeight: 1.2,
                                                marginBottom: 20
                                            }}>Split Money From</div>

                                            <Table
                                                dataSource={this.state.tableData}
                                                columns={this.state.columnData}
                                                pagination={false} />
                                        </div>
                                        <div
                                            style={{
                                                width: "42%",
                                                marginRight: 20
                                            }}>
                                            <div style={{
                                                fontSize: "22px",
                                                fontWeight: 900,
                                                color: "#545861",
                                                marginTop: "24px",
                                                lineHeight: 1.2,
                                                marginBottom: 25
                                            }}>
                                                Split Money To
                                            </div>
                                            <div>
                                                <Table
                                                    dataSource={this.state.splitData}
                                                    columns={this.state.collapseDataColumn}
                                                    expandIcon={({ expanded, onExpand, record }) =>
                                                        expanded
                                                            // @ts-ignore
                                                            ? <DownOutlined onClick={e => onExpand(record, e)} translate rev={undefined} />
                                                            // @ts-ignore
                                                            : < RightOutlined onClick={e => onExpand(record, e)} translate rev={undefined} />

                                                    }
                                                    data-test-id={"split-to-table"}
                                                />
                                            </div>

                                            <div style={{ marginTop: "24px" }}>
                                                <Button
                                                    shape="round"
                                                    style={styles.relocateToButtons}
                                                    onClick={() => { this.beforeSplit() }}
                                                    data-test-id={"apply-split-btn"}
                                                >
                                                    Apply Split
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    )}



            </div >

        )
    }
}


const styles = {
    contentBlock1: {
        width: "90%",
        height: "auto",
        padding: "40px",
        backgroundColor: "white",
        marginRight: "32px",
        marginLeft: "106px",
        marginTop: "28px",
    },
    firstDiv: {
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    BudgetsBalance: {
        fontSize: "22px",
        fontWeight: 900,
        color: "#545861",
        lineHeight: 1.2,

    },
    secondDiv: {
        marginTop: "32px",
    },
    relocateToButtons: {
        fontSize: 16,
        fontWeight: 700,
        backgroundColor: "#2EADE2",
        padding: "20px 40px",
        color: "#ffffff",
        display: "flex",
        border: "none",
        alignItems: "center"
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSplitTransaction: (data: any) => dispatch(splitTransactionRes(data))
    }
};
export default connect(null, mapDispatchToProps)(SplitTransaction);