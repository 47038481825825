import React from "react";
const logo = require("./assets/Componente.png");
import { Row, Col, Card, Image, Button } from "antd";
import bgImage from "./assets/web_bg.png";
// @ts-ignore
import TopNav from "./TopNav.js";
import { BlockComponent } from "../../framework/src/BlockComponent";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class HomeScreenWeb extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area Endv
  }

  checkAuth = () => {
    window.localStorage.getItem("loginToken") ? window.location.href = "/Dashboard" : window.localStorage.clear()
  }

  async componentDidMount() {
    this.checkAuth();
  }
  render() {
    return (
      <><TopNav />
        <Row
          align="middle"
          justify="center"
          style={{
            height: "100vh",
            width: "100vw",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            position: "fixed",
            overflow: "auto",
            paddingBottom: "20px",
            marginBottom: "30px",
          }}
        >
          <Col span={10}>
            <Card>
              <Row
                justify="center"
                style={{
                  fontSize: 33,
                  fontWeight: "bold",
                  color: "rgb(84, 88, 97)",
                }}
              >
                Welcome{" "}
              </Row>
              <Row
                justify="center"
                style={{
                  fontWeight: "normal",
                  fontSize: 18,
                  color: "rgb(104, 105, 108)",
                  marginTop: "-13px",
                  marginBottom: "20px",
                }}
              >
                to B.E.S.T. Budget
              </Row>
              <Row justify="center">
                <Image src={logo} preview={false} />
              </Row>
              <Row style={{ marginTop: "5em" }} justify="center">
                <Button
                  onClick={() => window.location.href = "/EmailAccountRegistrationWeb"}
                  style={{
                    borderRadius: "15px",
                    width: "50%",
                    backgroundColor: "#2dade1",
                    color: "white",
                  }}
                >
                  SIGN UP
                </Button>
              </Row>
              <Row justify="center">
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    marginTop: "15px",
                    marginBottom: "50px",
                  }}
                >
                  By continuing, I accept the{" "}
                  <span
                    style={{
                      color: "rgb(45, 173, 225)",
                      fontSize: "15px",
                      cursor: "pointer"
                    }} onClick={() => { window.location.href = "/TermsAndServices" }}
                  >
                    terms of service <br /> and I read the privacy policy of
                    B.E.S.T. Budget
                  </span>
                </span>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
