//@ts-nocheck
//@ts-nocheck
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Platform, ToastAndroid } from "react-native";
import { openNotification } from "../../../components/src/Notification.web";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Customizable Area Start
export const columnData = [
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    width: "50%",
    render: (text: any) =>
      <a test-id="test-category" style={
        {
          fontSize: 15,
          fontWeight: 500, color: " #000000",
        }
      }>{text}
      </a>,
  },
  {
    title: 'Budgeted',
    dataIndex: 'available',
    key: 'available',
    width: "21%",
    render: (text: any) =>
      <a
        test-id="test-budgeted"
        style={{
          fontSize: 16,
          fontWeight: "bold", color: '#00a950',
        }}
      >
        {text}
      </a>,
  },
  {
    title: 'Available',
    dataIndex: 'available',
    key: 'available',
    width: "21%",
    render: (text: any) =>
      <a
        test-id="test-available"
        style={{
          fontSize: 16,
          fontWeight: "bold", color: " #00a950",
        }}
      >
        {text}
      </a>
  }
]
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  route: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  cardLoading: any;
  errorMsg: string;
  loading: boolean;
  fromDate: any;
  accountId: any;
  Category: string;
  Categoryamt: any;
  data: any;
  subcatId: any;
  catagorys: any;
  selectedValue: any;
  nameValue: any;
  showCustom: any;
  availableAmount: any;
  budgetedAmount: any;
  columnData: any;
  tableData: any;
  toDate: any;
  filterKeyword: any;

  // Customizable Area End
}
interface SS {
  id: any;
}

export default class AddNecessityCatagoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiNecessityItemCallId: string = "";
  insertNecessityAmountApiCallId: string = "";
  apiAddnewSubcategory: string = "";
  apicatagoryCallId: any;
  NewNecessityExpensesCategoryloadingButton: any;
  NewNecessityExpensesCategoryPickerItem: any;
  customTransactionId: string = "";
  historyBugetId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      showCustom: false,
      fromDate: "",
      availableAmount: "",
      data: {},
      budgetedAmount: "",
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      Category: "",
      toDate: "",
      Categoryamt: 0,
      accountId: 0,
      subcatId: "",
      catagorys: [],
      selectedValue: 0,
      nameValue: "",
      filterKeyword: "",
      columnData: columnData
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    if (Platform.OS !== "web") {
      this.getTokenMobile();
    } else {
      this.getToken();
      this.handlerCustomDate("", "")
    }

  }
  getTokenMobile = async () => {
    const token = await AsyncStorage.getItem("tokenLogin");
    const data: any = await AsyncStorage.getItem("userData");
    const userData = JSON.parse(data);
    await AsyncStorage.getItem("firstName");
    this.setState({
      token: token,
      accountId: userData.data.id,
      subcatId: this.props.route.params.catagoryId,
    });
    this.NewNecessityExpensesCategorygetAllcatagoryMobile();
  };


  getToken = async () => {
    this.setState({
      token: localStorage.getItem("loginToken"),
      accountId: localStorage.getItem("accountId"),
      subcatId: localStorage.getItem("subCategory_id")
    });
    this.onMount();
  };
  keywordFilter = (values: any) => {
    if (values == "custom") {
      this.setState({ showCustom: true });
    } else {
      this.setState(
        { filterKeyword: values, showCustom: false, cardLoading: true },
        () => {
          this.onMount();
        }
      );
    }
  };
  handlerCustomDate = (dates: any, dateString: any) => {
    this.setState(
      { fromDate: dateString[0], toDate: dateString[1], cardLoading: true },
      () => {
        this.getAllCustomTransaction(this.state.fromDate, this.state.toDate);
      }
    );
  };

  NewNecessityExpensesCategoryaddcategoryDataMobile(): boolean {
    // Customizable Area Start
    this.setState({ Category: "" });
    const header = {
      "Content-Type": configJSON.addcatagoryContentType,
      token: this.state.token
    };
    console.log("header  ", JSON.stringify(header));

    const httpBody = {
      data: {
        attributes: {
          // category_id: this.state.subcatId,
          // name: this.state.Category,
          category_id: this.state.selectedValue,
          name: this.state.nameValue,
          amount: this.state.Categoryamt,
          account_id: this.state.accountId,
          category_type: "necessity_expense",
        },
      },
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);
    this.apiAddnewSubcategory = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddnewSubcategoryPostUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );
    //console.log('requestMessage@, ',requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  NewNecessityExpensesCategoryaddcategoryData(): boolean {
    // Customizable Area Start
    this.setState({ Category: "" });
    const header = {
      "Content-Type": configJSON.addcatagoryContentType,
      token: localStorage.getItem("accountId")
    };

    const httpBody = {
      data: {
        attributes: {
          // category_id: this.state.subcatId,
          // name: this.state.Category,
          category_id: this.state.selectedValue,
          name: this.state.nameValue,
          amount: this.state.Categoryamt,
          account_id: localStorage.getItem("accountId"),
          category_type: "necessity_expense"
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddnewSubcategory = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddnewSubcategoryPostUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  onMount = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.historyBugetId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseURL
      }/bx_block_categories/history_for_budgets?category_id=${localStorage.getItem(
        "category_id"
      )}&keyword=${this.state.filterKeyword}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("loginToken"),
        "plaid-account-id": localStorage.getItem("plaid_acc_id")
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  getAllCustomTransaction = async (fromDate: any, toDate: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.customTransactionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseURL
      }/bx_block_categories/history_for_budgets?fromDate=${fromDate}&toDate=${toDate}&category_id=${localStorage.getItem("category_id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Access-Control-Allow-Origin": "*",
        "plaid-account-id": localStorage.getItem("plaid_acc_id"),
        "access-token": localStorage.getItem("access_token"),
        token: localStorage.getItem("loginToken")
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let NewNecessityExpensesCategorytoken = message.getData(getName(MessageEnum.SessionResponseToken));
      let NewNecessityExpensesCategoryaccountId = message.getData(getName(MessageEnum.SessionResponseData));
      let NewNecessityExpensesCategoryobj = JSON.parse(JSON.stringify(NewNecessityExpensesCategoryaccountId));
      this.setState({ accountId: NewNecessityExpensesCategoryobj.data.id });
      this.setState({ token: NewNecessityExpensesCategorytoken });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const NewNecessityExpensesCategoryapiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const NewNecessityExpensesCategoryresponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const NewNecessityExpensesCategoryerrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (
        NewNecessityExpensesCategoryapiRequestCallId &&
        NewNecessityExpensesCategoryresponseJson
      ) {
        if (
          NewNecessityExpensesCategoryapiRequestCallId ===
          this.apiAddnewSubcategory
        ) {
          this.NewNecessityExpensesCategorygetaddNewSubCategoryData(
            NewNecessityExpensesCategoryresponseJson
          );
        } else if (
          NewNecessityExpensesCategoryapiRequestCallId ===
          this.apicatagoryCallId
        ) {
          this.NewNecessityExpensesCategorygetcatagoryCallData(
            NewNecessityExpensesCategoryresponseJson,
            NewNecessityExpensesCategoryerrorReponse
          );
        }
      }
      this.customTransitionResponseHandler(
        NewNecessityExpensesCategoryapiRequestCallId,
        NewNecessityExpensesCategoryerrorReponse,
        NewNecessityExpensesCategoryresponseJson
      );
      this.historyBugetResponseHandler(
        NewNecessityExpensesCategoryapiRequestCallId,
        NewNecessityExpensesCategoryerrorReponse,
        NewNecessityExpensesCategoryresponseJson
      );
    }
  }
  // Customizable Area Start
  NewNecessityExpensesCategorygetaddNewSubCategoryData = (
    NewNecessityExpensesCategoryreponse: any
  ) => {
    if (!NewNecessityExpensesCategoryreponse.errors) {
      console.log("I am in add category =====>>>>>>>>", NewNecessityExpensesCategoryreponse.error);
      if (NewNecessityExpensesCategoryreponse.error == "This category is already exist.") {
        console.log("I am in if =====>>>>>>>>");
        this.NewNecessityExpensesCategoryloadingButton?.showLoading(false);

        Platform.OS === "ios"
          ? alert("This category is already exist !!!")
          : ToastAndroid.show(
            "This category is already exist !!!",
            ToastAndroid.LONG
          );
      } else {
        this.props.navigation.goBack();
      }
      //this.props.navigation.navigate("MonthlyNecessityExpenses")
    } else {
      //Check Error Response
      this.parseApiErrorResponse(NewNecessityExpensesCategoryreponse);
    }
  };

  NewNecessityExpensesCategorygetcatagoryCallData = (
    NewNecessityExpensesCategoryresponse: any,
    errorNewNecessityExpensesCategoryResponse: any
  ) => {
    if (!NewNecessityExpensesCategoryresponse.errors) {
      // subscription_expired?
      if (
        NewNecessityExpensesCategoryresponse["subscription_expired?"] === true
      ) {
        this.props.navigation.navigate("TrialExpiry");
      }
      this.setState({
        catagorys: NewNecessityExpensesCategoryresponse.data
      });
    } else {
      console.warn(
        "I am in Error ===================>>>>>>>>>>>>>>>>>>>>>>",
        errorNewNecessityExpensesCategoryResponse
      );
    }
  };

  onPressSubmit = {
    onPress: () => this.NewNecessityExpensesCategoryaddcategoryData()
  };
  setCategoryTxt = (NewNecessityExpensesCategorytext: string) => {
    this.setState({ Category: NewNecessityExpensesCategorytext });
  };
  setCategoryAmt = (NewNecessityExpensesCategorytext: any) => {
    this.setState({ Categoryamt: NewNecessityExpensesCategorytext });
  };

  pickerProps = {
    onValueChange: (NewNecessityExpensesCategoryitem: any) => {
      console.log('Before state update:', this.state.selectedValue);

      this.state.catagorys.map((NewNecessityExpensesCategoryelement: any) => {
        if (NewNecessityExpensesCategoryelement.attributes?.id == NewNecessityExpensesCategoryitem) {
          this.setState(
            { selectedValue: NewNecessityExpensesCategoryitem, nameValue: NewNecessityExpensesCategoryelement?.attributes?.generic_name },
            () =>
              console.log(
                "catagoryId =====================>>>>>>>>>>>>>>>>",
                this.state.selectedValue,
                this.state.nameValue
              )
          );
        }
      });
    }
    // Customizable Area End
  };

  NewNecessityExpensesCategorygetAllcatagoryMobile = () => {
    console.log("I am in getCatagory");
    const NewNecessityExpensesCategoryheader = {
      "Content-Type": configJSON.budgetContentType,
      token: this.state.token,
    };
    console.log("header  ", JSON.stringify(NewNecessityExpensesCategoryheader));

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);
    this.apicatagoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.catagoryEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(NewNecessityExpensesCategoryheader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };


  NewNecessityExpensesCategorygetAllcatagory = () => {
    const NewNecessityExpensesCategoryheader = {
      "Content-Type": configJSON.budgetContentType,
      token: localStorage.getItem("loginToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apicatagoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.catagoryEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(NewNecessityExpensesCategoryheader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  _onPressHandler() {
    this.NewNecessityExpensesCategoryloadingButton.showLoading(true);
  }
  customTransitionResponseHandler(
    requestCallId: string,
    errorReponse: any,
    responseJson: any
  ) {
    if (requestCallId === this.customTransactionId) {
      if (errorReponse) {
        if (errorReponse.status == "404") {
          this.setState({ cardLoading: false });
          openNotification(errorReponse.data.error || "", "error");
        }
      } else {
        const data = responseJson.data.map((values: any) => ({
          key: values.id,
          account: (
            <div>
              Account {values.account_id}
              <br />
              <span>{values.transaction_date}</span>
            </div>
          ),
          desc:
            values.description == null ? "No Description" : values.description,
          amount: values.amount
        }));
        this.setState({
          tableData: data,
          cardLoading: false,
          showCustom: false
        });
      }
    }
  }

  historyBugetResponseHandler(
    requestCallId: string,
    errorReponse: any,
    responseJson: any
  ) {
    if (requestCallId === this.historyBugetId) {
      if (errorReponse) {
        if (errorReponse.status == "404") {
          this.setState({ cardLoading: false });
          openNotification(errorReponse.data.error || "", "error");
        }
      } else {
        let available_amount = responseJson.total_available_amount_cat;
        let budgeted_amount = responseJson.budgeted_amount;
        this.setState({ availableAmount: available_amount });
        this.setState({ budgetedAmount: budgeted_amount });
        const data = responseJson.data.map((values: any) => ({
          key: values.transaction_date,
          category: values.category_name,
          budgeted: values.budgeted_amount,
          available: values.amount
        }));
        this.setState({
          tableData: data,
          cardLoading: false
        });
      }
    }
  }
  // Customizable Area End
}
