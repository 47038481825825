// @ts-nocheck
import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { Switch } from 'antd';
import StatisticsReportsController, { Props } from "./StatisticsReportsController";
import { Row, Col, Button, DatePicker, Divider, Form, Select, Card } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { Table, Tag, Space } from 'antd';
import SideBar from "../../../components/src/SideBar";
import { Spin, Progress } from 'antd';
import { View } from "react-native";
// import { logoLoading } from "./assets";
import { CSVDownload, CSVLink } from "react-csv";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import moment from 'moment';
const { Option } = Select;

export default class StatisticsReports extends StatisticsReportsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { progress } = this.state;

        return (
            < div >
                <div><SideBar /></div>
                {this.state.cardLoading ?
                    (<LoadingWeb />) : (

                        <Row align="middle" justify="center" style={{ marginLeft: "79px" }}>
                            <Col span={24}>
                                <Card style={{
                                    margin: "2em",
                                }}>
                                    <Row align="middle" justify="center">
                                        <Col xs={0} sm={0} md={1} lg={1} />
                                        <Col xs={24} sm={24} md={22} lg={22}
                                            style={{ marginTop: "40px", marginBottom: "25px" }}

                                        >
                                            <Row>
                                                <Col xs={24} sm={15} md={17}
                                                    style={styles.BudgetsBalance}
                                                >
                                                    <Row align="middle" justify="start" style={{ marginBottom: "20px" }}>Reports</Row>
                                                </Col>

                                                <Col xs={24} sm={9} md={7}

                                                // style={styles.exportButton}
                                                >
                                                    <Row align="middle" justify="end"  >
                                                        <CSVLink
                                                            className='export-btn'
                                                            data={this.state.tableExport}
                                                            style={{
                                                                // color: "#ffffff",
                                                                marginLeft: "12px",
                                                                // width: "57px",
                                                                fontSize: 16,
                                                                fontWeight: 700,
                                                                backgroundColor: "#b3b3b3",
                                                                padding: "5px 15px",
                                                                color: "#ffffff",
                                                                marginBottom: "25px",
                                                                // display: "flex",
                                                                border: "none",
                                                                borderRadius: "40px",
                                                                // alignItems: "center",
                                                                lineHeight: "36px",
                                                                maxWidth: "127px",
                                                            }}
                                                            filename={'Statistics-report.csv'}
                                                            target="_blank"
                                                        >
                                                            <span>
                                                                <ArrowUpOutlined
                                                                    translate
                                                                />
                                                            </span>  EXPORT
                                                        </CSVLink>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} sm={24} md={14} lg={12}>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={16}
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight: "bold",
                                                                color: "#404040"
                                                            }}>
                                                            <Row align="middle" justify="start">Set report date range</Row>
                                                        </Col>
                                                        <Col xs={24} sm={12} md={11}>

                                                            <Row align="middle" justify="start" style={{
                                                                fontSize: 12,
                                                                color: "#999999",
                                                                marginTop: " 18px",
                                                                fontWeight: "bold",
                                                                width: "148px"
                                                            }}>
                                                                Start Date
                                                                <DatePicker
                                                                    placeholder="DD/MM/YYYY"
                                                                    style={{
                                                                        padding: "8.7px 9px 10.3px 12.1px",
                                                                        borderRadius: "5px",
                                                                        border: "solid 1px #d9d9d9",
                                                                        marginBottom: "18px"
                                                                    }}
                                                                    format={"YYYY-MM-DD"}
                                                                    onChange={(date: any, dateString: string) => { this.startDate(dateString) }}
                                                                />

                                                            </Row>
                                                        </Col>
                                                        <Col md={1} />
                                                        <Col xs={24} sm={12} md={11}>

                                                            <Row align="middle" justify="end" style={{
                                                                fontSize: 12,
                                                                color: "#999999",
                                                                // marginTop: "42px",
                                                                marginTop: "18px",
                                                                fontWeight: "bold"
                                                            }}> <Col span={24}>  End Date</Col>
                                                                <Col span={24}>  <DatePicker
                                                                    placeholder="DD/MM/YYYY"
                                                                    format={"YYYY-MM-DD"}
                                                                    style={{
                                                                        padding: "8.7px 9px 10.3px 12.1px",
                                                                        borderRadius: "5px",
                                                                        border: "solid 1px #d9d9d9",
                                                                        marginBottom: "18px"
                                                                    }}
                                                                    onChange={(date: any, dateString: string) => {
                                                                        this.endDate(dateString)

                                                                    }}
                                                                    disabledDate={(current) => {
                                                                        console.log("date", current, this.state.startDate)
                                                                        return current && moment(moment(current).format("YYYY-MM-DD")).isBefore(moment(this.state.startDate).format("YYYY-MM-DD"), "days");
                                                                    }}
                                                                /></Col> </Row>

                                                        </Col>    </Row>
                                                </Col>
                                                <Col xs={24} sm={24} md={8} lg={9}><Row>
                                                    <Col xs={24} sm={24} md={24}
                                                        style={{
                                                            fontSize: 16,
                                                            fontWeight: "bold",
                                                            color: "#404040",
                                                            // marginTop: "16px",
                                                            // marginBottom: "10px"
                                                        }}>
                                                        <Row align="middle" justify="start">  Set report category</Row>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={20}
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#999999",
                                                            marginTop: "18px",
                                                            fontWeight: "bold"
                                                        }}>
                                                        <Row align="middle" justify="start">   Set category </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row align="middle" justify="start">
                                                            <Form>
                                                                <Form.Item >
                                                                    <Select
                                                                    style={{minWidth:"200px"}}
                                                                        size="large"
                                                                        placeholder="Select Category"
                                                                        allowClear onChange={(e: any) => this.handleChange(e)}>
                                                                        {this.state.categoryData?.map((values: any) => (
                                                                            <Option value={values?.attributes?.id}>{values?.attributes?.generic_name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Form></Row>
                                                    </Col>

                                                </Row>
                                                </Col>
                                                <Col xs={24} lg={3} >
                                                    <Row align="middle" justify="end" style={{ marginTop: "18px" }}>

                                                        {/* <div style={{ marginTop: "60px", marginLeft: "56px" }}> */}
                                                        <Button type="primary"
                                                            shape="round"
                                                            style={{
                                                                backgroundColor: `${this.state.runQueryBtnColor}`,
                                                                padding: "20px 40px",
                                                                color: "#ffffff",
                                                                display: "flex",
                                                                border: "none",
                                                                alignItems: "center",
                                                                fontSize: 16,
                                                                fontWeight: "bold",
                                                                marginTop: "38px"
                                                            }}
                                                            onClick={() => {
                                                                this.runQuery()
                                                            }}
                                                        >
                                                            RUN REPORT
                                                        </Button></Row>
                                                    {/* </div> */}
                                                </Col>







                                            </Row>
                                            {/* <Col xs={24} sm={24} 
                                                style={{ backgroundColor: "white",}}
                                                // style={styles.firstDiv}
                                                > */}
                                            <Row style={styles.thirdDiv}>
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        fontWeight: "bold",
                                                        color: "#000000"
                                                    }}
                                                >
                                                    Report results
                                                </div>
                                            </Row>
                                            {this?.state?.tableData?.length > 0 ? <Table
                                                pagination={{ defaultPageSize: 10 }}
                                                columns={this.state.columnData}
                                                dataSource={this.state.tableData}
                                            /> : <Row style={{ padding:"40px", display: "flex", justifyContent: "center"}}>
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        fontWeight: "bold",
                                                        color: "#000000"
                                                    }}
                                                >
                                                    No records
                                                </div>
                                            </Row>}







                                        </Col>
                                        <Col xs={0} sm={0} md={1} lg={1} />
                                    </Row>



                                </Card>
                            </Col>
                        </Row>

                    )}
            </div >
        )
    }
}

const styles = {
    contentBlock: {
        height: "auto",
        padding: "40px",
        backgroundColor: "white",
        marginRight: "32px",
        border: "1px solid white",
        marginLeft: "106px",
        marginTop: "28px",
    },
    firstDiv: {
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    BudgetsBalance: {
        fontSize: "22px",
        fontWeight: 900,
        color: "#545861",
        lineHeight: 1.2,

    },
    secondDiv: {
        // display: "flex",
        marginTop: "40px",
    },
    secondDivContentDivs: {
        // width: "206px"
    },
    thirdDiv: {
        marginTop: "22px",
        height: "60px",
        borderRadius: "6.4px",
        padding: "16px 14px",
        backgroundColor: "#f2f2f2"
    },
    fourthDiv: {
        marginTop: "16px"
    },
    exportButton: {
        fontSize: 16,
        fontWeight: 700,
        backgroundColor: "#b3b3b3",
        // padding: "10px 32px",
        color: "#ffffff",
        // display: "flex",
        border: "none",
        borderRadius: "40px",
        // alignItems: "center",
        lineHeight: "42px",
        // Mwidth: "57px",
        paddingLeft: "21px",
    }
}

