//@ts-nocheck
import React, { useState } from "react";
import { Row, Col, Card, Image, Button } from "antd";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  openErrorNotification,
  openNotification,
} from "../../../components/src/Notification.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
export const configJSON = require("./config");
const stripePromise = loadStripe(configJSON.STRIPE_PUBLISH_KEY);


export interface Props {
  listData: any[],
  setListData: (listData: any[]) => void;
}
interface S {
  cardLoading: boolean;
  loading: boolean;
}
interface SS {
  id: any;
}

export default class SubscribePlanCard extends BlockComponent<Props, S, SS>{
  setListData: (listData: any[]) => void;
  listData: any[];
  constructor(props: Props) {
    super(props);
    this.setListData = props.setListData;
    this.listData = props.listData;

    this.state = {
      cardLoading: false,
      loading: false
    };
  }
  setCardLoading = (cardLoading: boolean) => this.setState({cardLoading});
  setLoading = (loading: boolean) => this.setState({loading});

  getPrice = async (id: any) => {
    this.setCardLoading(true);
    const result = await axios
      .get(
        `${configJSON.baseURL
        }bx_block_stripes/price_listing/${id}`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("loginToken"),
          },
        }
      )
      .then((result) => {
        this.setListData(result.data.prices.data);
        this.setCardLoading(false)
        openNotification(result.data.message, "success")
      })
      .catch((err) => {
        openErrorNotification(err?.response?.data?.error, "error")
        this.setCardLoading(false)
      });
  };

  handleSubscribe = async (id: any) => {
    this.setCardLoading(true)
    let successUrl
    const accountLinked = localStorage.getItem("if_account_linked")
    const onbordingLinked = localStorage.getItem("is_onboarding_completed")
    accountLinked == "true" ?
      onbordingLinked == "true" ?
        successUrl = "https://bestbudgetapp-15556-react-native.15556.dev.us-east-1.aws.svc.builder.cafe/Dashboard?session_id={CHECKOUT_SESSION_ID}" :
        successUrl = "https://bestbudgetapp-15556-react-native.15556.dev.us-east-1.aws.svc.builder.cafe/OnboardingScreenWeb?session_id={CHECKOUT_SESSION_ID}" :
      successUrl = "https://bestbudgetapp-15556-react-native.15556.dev.us-east-1.aws.svc.builder.cafe/LinkedAccountsWelcomeScreenWeb?session_id={CHECKOUT_SESSION_ID}"
    const data = {
      success_url: successUrl,
      cancel_url: "https://bestbudgetapp-15556-react-native.15556.dev.us-east-1.aws.svc.builder.cafe/SubscriptionTrialWeb",
      price_id: id,
      mode: "subscription",
    }
    const resu = await axios.post(
      `${configJSON.baseURL}bx_block_stripes/create_stripe_session?device_type=web`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("loginToken"),
        },
      }
    ).then(async (res: any) => {
      const stripe: any = await stripePromise;
      // eslint-disable-next-line
      const { error }: any = await stripe
        .redirectToCheckout({
          sessionId: res.data.session_id.id,
        })
        .then(async function (result: any) {
          openNotification(result.error.message, "success")
        });
      openNotification(res.data.message, "success")
      this.setCardLoading(false)
    }).catch((err: any) => {
      openErrorNotification(err?.response?.data?.error, "error")
      this.setCardLoading(false)
    })

  }
  render() {
    if (this.state.cardLoading) {
      return <LoadingWeb />;
    }

    return (
      <div test-id="list-data-id">
        {this.listData && this.listData.map((item: any) => (
          <Col key={item.key}>
            <Card
              test-id={item.key}
              style={{
                marginBottom: "7px",
                backgroundColor: "white",
                width: "280px",
                height: "350px",
                borderRadius: "13px",
                boxSizing: "border-box",
                boxShadow: "0px 0px 3.4em -0.9em rgb(21 20 20)",
              }}
            >
              <Row align="middle" justify="center" style={{ fontSize: "18px", fontWeight: 600 }}>
                {item.recurring ? `${item.unit_amount / 100} ${item.currency} per/${item.recurring.interval}` : item.name}
              </Row>
              <Row>
                <Button
                  test-id={`button-${item.key}`}
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    color: "white",
                    backgroundColor: "rgb(243 74 74)",
                    marginBottom: "30px",
                  }}
                  onClick={item.recurring ? () => this.handleSubscribe(item.id) : () => this.getPrice(item.key)}
                >
                  {item.recurring ? "Subscribe" : "Choose Plan"}
                </Button>
              </Row>
            </Card>
          </Col>
        ))}
      </div>
    );
  }
}
