// Customizable Area Start
import {
  Card,
  Col,
  Row,
  Select,
  Pagination,
  Divider, DatePicker
} from "antd";
import React from "react";
import SideBar from "../../../components/src/SideBar";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import AccountsPageController, { Props } from "./AccountsPageController";
export const configJSON = require("./config");
const { Option } = Select;
const { RangePicker } = DatePicker;


export default class AccountsPageWeb extends AccountsPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  returnShowCal(showCal: any) {
    if (showCal) {
      return (<RangePicker style={{ height: "32px", marginLeft: "5px" }}
        onChange={(dates: any) => this.handleCustomDate(dates)}
        data-test-id={"change-date"}
        // defaultValue={[moment('2015/01/01', "YYYY-MM-DD"), moment('2015/01/01', "YYYY-MM-DD")]}
        format={"YYYY-MM-DD"}
      />)
    } else {
      return "";
    }
  }

  returnClassName(amount: any) {
    if (amount > 0) {
      return "balance_text";
    } else {
      return "err_text";
    }
  }

  returnPagination(total: any, range: any) {
    if (range[0] > 0) {
      return `Showing ${range[0]}-${range[1]} of ${total} `;
    } else {
      return `Showing 1-${total > 10 ? 10 : total} of ${total} `;
    }
  }

  returnWebData(webData: any) {
    if (webData?.length > 0) {
      return (
        this.state.webData.map((item: any) => (
          <Row
            key={item.id}
            style={{
              borderTop: "1px solid #d9d9d9",
              color: "black",
              fontWeight: "bold",
              padding: "12px 16px",
              paddingRight: "40px",
            }}
          >
            <Col span={16}>{item.description}</Col>
            <Col span={8} className={this.returnClassName(item.amount)} >
              {" "}
              ${item.amount}
            </Col>
          </Row>
        ))
      )
    } else {
      return (
        <Row style={{ padding: "40px", display: "flex", justifyContent: "center" }}>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: "#000000"
            }}
          >
            No Transactions
          </div>
        </Row>
      )
    }
  }

  returnWebLoading(webLoading: any) {
    if (webLoading) {
      return <LoadingWeb />;
    } else {
      return (<Col span={24}>
        <Row
          style={{
            borderTop: "1px solid #d9d9d9",
            fontWeight: "bolder",
            backgroundColor: "#f2f2f2",
            padding: "12px 16px",
            paddingRight: "40px",
            color: "black",
          }}
        >
          <Col span={16}>Transactions</Col>
          <Col span={8}>Amount</Col>
        </Row>
        {this.returnWebData(this.state.webData)}
      </Col>)
    }
  }

  returnLoading(cardLoading: any) {
    if (cardLoading) {
      return <LoadingWeb />;
    } else {
      return (<><Row>
        <h2 style={{ color: "#545861", fontWeight: 900 }}>
          {this.state.accountDetails.name}
        </h2>
      </Row>
        <Row>
          <Col sm={8} xs={24} style={{ paddingRight: "20px", marginBottom: "2em" }}>
            <Row
              style={{
                backgroundColor: "#ededf2",
                marginBottom: "15px",
                textAlign: "center", borderRadius: "7px",
              }}
            >
              <Col span={24} style={{ color: "#999999", marginBottom: "10px", marginTop: "5px" }}>
                Available balance
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
                className="balance_text"
              >
                ${this.state.balance}
              </Col>
            </Row>
            <Row justify="center" align="middle">
              Withdrawals of the day: $ 0.00
            </Row>
            <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
            <Row justify="center" align="middle">
              Deposits of the day: $ 0.00
            </Row>
            <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
          </Col>
          <Col sm={16} xs={24}>
            <Row
              style={{
                color: "#545861",
                fontSize: "22px",
                fontWeight: 700,
                lineHeight: " 1.2",
                paddingBottom: "2em",
              }}
            >
              Transactions
            </Row>
            <Row>
              <Select
                defaultValue="month_to_date"
                style={{ width: 220, marginBottom: "20px" }}
                data-test-id={"navigate-linked-acc"}
                onChange={(value: any) => this.handleChange(value)}
              >
                <Option value="month_to_date">Month to date</Option>
                <Option value="year_to_date">Year to date</Option>
                <Option value="last_month">Last Month </Option>
                <Option value="custom">Custom</Option>
              </Select>
              {this.returnShowCal(this.state.showCal)}
            </Row>
            <Row style={{ marginBottom: "5em" }}>
              {this.returnWebLoading(this.state.webLoading)}
            </Row>
            <Row justify="end">
              <Pagination
                total={this.state.webData?.length}
                showTotal={(total, range) =>
                  this.returnPagination(total, range)
                }
                showSizeChanger
                pageSizeOptions={["10", "20", "30", "40"]}
                defaultPageSize={10}
                defaultCurrent={1}
                data-test-id={"pagination"}
              />
            </Row>
          </Col>
        </Row></>)
    }
  }
  // Customizable Area End
  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        <div>
          <div><SideBar /></div>
          <div><Row style={{ marginLeft: "79px" }}>
            <Col span={24}>
              <Card style={{ margin: "2em" }}>
                {this.returnLoading(this.state.cardLoading)}
              </Card>
            </Col></Row>
          </div></div>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}
// Customizable Area End