//@ts-nocheck
import React from 'react';
import 'antd/dist/antd.css';
import '../assets/index.css';
import ConfirmSplitTransactionController, { Props } from "./ConfirmSplitTransactionController";
import SideBar from "../../../components/src/SideBar";
import { Button, Table, Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

export class ConfirmSplitTransaction extends ConfirmSplitTransactionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    console.log("Data from confirm", this.props.splitTransactionResRedux);
    // this.setState({split_from:this.props.splitTransactionResRedux.split_from})
    return (
      <div>
        <div>
          <SideBar />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Row style={{ marginLeft: "79px" }}>
            <Col span={24}>
              <Card style={{ margin: "2em", padding: "40px" }}>
                <div style={{ margin: "32px 0px" }}>
                  <div style={{ textAlign: "center" }}>
                    <div style={styles.splitDetails}>
                      {" "}
                      Split Transaction Successful{" "}
                    </div>
                    <div style={styles.transactionDetails}>
                      Transaction Details
                    </div>
                  </div>
                  <div style={styles.ConfirmTransactionDetails}>
                    Split Money From
                  </div>
                  <Table
                    dataSource={this.state.tableData3}
                    columns={this.state.columnData3}
                    pagination={false}
                  />
                  <div style={styles.ConfirmTransactionDetails}>
                    Split Money To
                  </div>
                  <Table
                    dataSource={this.state.tableData4}
                    columns={this.state.columnData4}
                    pagination={false}
                  />
                  <div style={styles.continueButtonWrap}>
                    <Button shape="round" style={styles.continueButton}>
                      <Link to="/Transaction">CONTINUE</Link>
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const styles = {
  block: {
    width: "513.6px",
    // height: "635.7px",
    backgroundColor: "white",
    marginTop: "28px",
    display: "flex",
    justifyContent: "center"
  },
  // contentBlock: {

  // },
  BudgetsBalance: {
    fontSize: "22px",
    fontWeight: 900,
    color: "#545861",
    lineHeight: 1.2
  },
  ConfirmTransactionDetails: {
    width: "361.3px",
    height: "43.7px",
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 0,
    padding: "10px 20px",
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    marginTop: "32px"
  },
  continueButton: {
    width: "333.3px",
    height: "40px",
    fontSize: 16,
    padding: "10.8px 128.6px 10.2px 128.7px",
    fontWeight: 700,
    backgroundColor: "#00ade2",
    color: "#ffffff",
    display: "flex",
    border: "none",
    alignItems: "center"
  },
  relocateToButtons: {
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: "#b3b3b3",
    padding: "20px 40px",
    color: "#ffffff",
    display: "flex",
    border: "none",
    alignItems: "center"
  },
  continueButtonWrap: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "24px"
  },
  splitDetails: {
    fontSize: 28,
    color: "545861",
    lineHeight: "1.2",
    letterSpacing: "-0.98px"
  },
  transactionDetails: {
    fontSize: 18,
    color: "#54595f",
    marginTop: "12px"
  }
};

let mapStateToProps = (state: any) => ({
  splitTransactionResRedux: state.users.splitTransactionRes.data
  // console.log("State from the split", state.users.splitTransactionRes.data.split_from)
  // console.log("State from the split", state.users.splitTransactionRes.data.split_to)
  //     transaction_to_amount: 1
  // transaction_to_sub_category: "Taxi"

  // return {
  //     splitTransactionResRedux: (state: any) => state.users.splitTransactionRes
  // };
});

export default connect(mapStateToProps)(ConfirmSplitTransaction);
