// Customizable Area Start
// @ts-nocheck
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Progress,
  Row,
  Select
} from "antd";
import React from "react";
const webBg = require("../assets/web_bg.png");
//@ts-ignore
import TopNav from "../../../components/src/TopNav.js";
import {
  DeleteFilled,
  LeftOutlined,
  PlusOutlined,
  RightOutlined
} from "@ant-design/icons";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import AddDiscretioneryExpensesController, {
  Props, configJSON
} from "./AddDiscretioneryExpensesController";
const { Option } = Select;


export default class AddDiscretioneryExpensesWeb extends AddDiscretioneryExpensesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <>
        <TopNav />
        {this.state.cardLoading ? (
          <LoadingWeb />
        ) : (
          <Row
            style={{
              backgroundSize: "cover",
              position: "fixed",
              overflow: "auto",
              paddingBottom: "20px",
              marginBottom: "30px",
              height: "100vh",
              width: "100vw",
              backgroundImage: `url(${webBg})`
            }}
            align="middle"
            justify="center"
          >
            <Col
              md={13}
              sm={16}
              lg={10}
              style={{ marginBottom: "50px" }}
              xs={21}
            >
              <Card style={{ marginTop: "50px", marginBottom: "50px" }}>
                <Row
                  justify="center"
                  style={{
                    fontWeight: "bold",
                    color: "rgb(104, 105, 108)",
                    textAlign: "center",
                    fontSize: 20
                  }}
                >
                  <Col span={24}>{configJSON.addDicritionary}</Col>
                </Row>
                <Row justify="center">
                  <Col
                    span={24}
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      lineHeight: "1.39",
                      letterSpacing: "-0.43px",
                      color: "#68696c"
                    }}
                  >
                    {configJSON.enterDiscExpenses}
                  </Col>
                </Row>
                <Divider />
                <Row justify="end">
                  <Button
                    icon={<PlusOutlined translate rev />}
                    shape="round"
                    style={{
                      backgroundColor: "#f2f2f2",
                      color: "#2dade1",
                      fontWeight: "bolder",
                      marginBottom: "20px",
                      border: "none"
                    }}
                    data-test-id="modal"
                    onClick={() => this.setState({ openAddModal: true })}
                  >
                    {configJSON.addCategoryCaps}
                  </Button>
                  <Modal
                    data-test-id="ctg"
                    centered
                    title={
                      <Row
                        justify="center"
                        style={{
                          color: "#545861",
                          fontSize: "28px",
                          fontWeight: "bold"
                        }}
                      >
                        {configJSON.addCategory}
                      </Row>
                    }
                    footer={false}
                    visible={this.state.openAddModal}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                    width="35%"
                  // closeIcon={true}
                  >
                    <Row justify="center">
                      <Col span={18}>
                        {" "}
                        <Form
                          data-test-id="form"
                          ref={this.formRef}
                          layout="vertical"
                          onFinish={this.onFinish}
                        >
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Select
                                  size="large"
                                  placeholder="Select Category"
                                  allowClear
                                  onChange={this.change}
                                >
                                  {this.state.categoryData &&
                                    this.state.categoryData.map(
                                      (values: any) => (
                                        <Option
                                          key={
                                            values &&
                                            values.attributes &&
                                            values.attributes.generic_name
                                          }
                                          value={
                                            values &&
                                            values.attributes &&
                                            values.attributes.id
                                          }
                                        >
                                          {values &&
                                            values.attributes &&
                                            values.attributes.generic_name}
                                        </Option>
                                      )
                                    )}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <p>Balance</p>
                          <Row>
                            <Form.Item
                              style={{ width: "100%" }}
                              name="amount"
                              rules={[
                                {
                                  required: true,
                                  message: configJSON.btnExampleTitle
                                }
                              ]}
                              label={
                                <span style={{ color: "gray" }}>{configJSON.amount}</span>
                              }
                            >
                              <Input type="number" placeholder="Your Amount" />
                            </Form.Item>
                          </Row>
                          <Row style={{ marginTop: "2em" }}>
                            <Form.Item
                              data-test-id="item"
                              style={{ width: "100%" }}
                              shouldUpdate
                            >
                              {() => (
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  loading={this.state.loading}
                                  style={{
                                    borderRadius: "15px",
                                    width: "100%"
                                  }}
                                  disabled={this.handleValidation()}
                                >
                                  {configJSON.labelTitleText}
                                </Button>
                              )}
                            </Form.Item>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Modal>
                </Row>
                <Row style={{ marginBottom: "5em" }}>
                  <Col span={24}>
                    <List
                      data-test-id="list"
                      size="large"
                      header={
                        <Row
                          justify="space-around"
                          style={{
                            fontWeight: "bolder",
                            backgroundColor: "#f2f2f2",
                            padding: "15px",
                            color: "black"
                          }}
                        >
                          <Col>{configJSON.category}</Col>
                          <Col>{configJSON.amount}</Col>
                        </Row>
                      }
                      dataSource={this.state.data}
                      renderItem={(item: any) => (
                        <>
                          <List.Item>
                            <Row
                              justify="space-between"
                              style={{ width: "100%" }}
                            >
                              <Col span={15}>
                                {item.sub_Details ? item.sub_Details.name : ""}
                              </Col>
                              <Col
                                span={9}
                                style={{ textAlign: "center" }}
                                className="placeholderColor"
                              >
                                <Input
                                  onChange={e => this.handleChange(item, e)}
                                  onBlur={() => {
                                    this.handleSave();
                                  }}
                                  data-test-id="amount-input"
                                  name="amount"
                                  placeholder={
                                    "$" + (item.amount ? item.amount : "0.00")
                                  }
                                  style={{ width: "80px" }}
                                />
                                <DeleteFilled
                                  data-test-id="delete-button"
                                  onClick={() =>
                                    this.setState({
                                      id: item.sub_Details.category_id,
                                      openDeleteModal: true
                                    })
                                  }
                                  style={{
                                    color: "#b3b3b3",
                                    fontSize: "20px",
                                    paddingLeft: "12px"
                                  }}
                                  translate
                                  rev
                                />
                              </Col>
                            </Row>
                          </List.Item>
                        </>
                      )}
                    />
                    <Row style={{ width: "100%", padding: "16px 24px" }}>
                      <Col
                        span={15}
                        style={{
                          textAlign: "end",
                          paddingRight: "15px",
                          color: "black",
                          fontWeight: "bolder"
                        }}
                      >
                        {configJSON.total}
                      </Col>
                      <Col span={9}>
                        <Input
                          value={"$ " + this.state.total}
                          style={{
                            width: "80px",
                            color: "black",
                            fontWeight: "bolder"
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Modal
                  centered
                  title={
                    <Row
                      justify="center"
                      style={{
                        color: "#545861",
                        fontSize: "28px",
                        fontWeight: "bold"
                      }}
                    >
                      {configJSON.confirmDelete}
                    </Row>
                  }
                  footer={false}
                  visible={this.state.openDeleteModal}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  width="35%"
                  closeIcon={true}
                >
                  <Row justify="center">
                    <Col span={18}>
                      <Form
                        data-test-id="form1"
                        layout="vertical"
                        onFinish={this.handleDelete}
                      >
                        <Row justify="center">
                          <Col style={{ textAlign: "center" }} span={18}>
                            {configJSON.confirmDeleteMsg}
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "4em" }}>
                          <Form.Item style={{ width: "100%" }}>
                            <Button
                              htmlType="submit"
                              loading={this.state.loading}
                              style={{
                                borderRadius: "15px",
                                color: "#2dade1",
                                border: "none",
                                fontWeight: "bold",
                                width: "100%",
                                backgroundColor: "#f2f2f2"
                              }}
                            >
                              {configJSON.deleteBtn}
                            </Button>
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item style={{ width: "100%" }}>
                            <Button
                              type="primary"
                              onClick={() =>
                                this.setState({ openDeleteModal: false })
                              }
                              style={{ borderRadius: "15px", width: "100%" }}
                            >
                              {configJSON.cancelCaps}
                            </Button>
                          </Form.Item>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Modal>
                <Row justify="center">
                  <Col span={18}>
                    <Progress percent={80} status="active" showInfo={false} />
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "2em" }}>
                  <Button
                    shape="round"
                    style={{ backgroundColor: "#b3b3b3", color: "white" }}
                    onClick={() =>
                      window.location.href = "/BudgetDiscretioneryIncomesWeb"
                    }
                    icon={<LeftOutlined translate rev />}
                  >
                    {configJSON.back}
                  </Button>
                  <Button
                    shape="round"
                    type="primary"
                    loading={this.state.loading}
                    onClick={() => { this.setState({ navigate: true }); this.handleSave() }}
                  >
                    {configJSON.saveAndContinue} <RightOutlined translate rev />
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </>
    );
  }
}
// Customizable Area End
