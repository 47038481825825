import React from "react";
import { withRouter, useHistory, useLocation, Link } from "react-router-dom";
import { Row, Button, Image, Col } from "antd";
import logo from "./assets/web_app_logo.png";
const TopNav = () => {
  let history = useHistory();
  let location = useLocation();
  return (
    <Row
      align="middle"
      style={{
        backgroundColor: "#00ADE2",
        width: "100%",
        padding: "1vh",
        height: "70px",
        // marginBottom: "2em",
      }}
    >
      <Col span={1} />
      <Col lg={11} md={8} sm={7} xs={6}>
        <Link to={localStorage.getItem("loginToken") ? "/Dashboard" : "/"}>
          <Image src={logo} preview={false} style={{ cursor: "pointer" }}/>
        </Link>
      </Col>
      <Col lg={12} md={15} sm={16} xs={17}>
        <Row justify="end">
          {location.pathname == "/EmailAccountLoginWeb" ? (
            <Button
              type="default"
              style={{
                backgroundColor: "white",
                color: "#63BFDB",
                borderRadius: "15px",
                fontWeight: "500",
                padding: "0px 30px",
              }}
              onClick={() => history.push("/EmailAccountRegistrationWeb")}
            >
              REGISTER
            </Button>
          ) : location.pathname == "/EmailAccountRegistrationWeb" ||
            location.pathname == "/CheckYourEmailWeb" ? (
            <Button
              type="default"
              style={{
                backgroundColor: "white",
                color: "#63BFDB",
                borderRadius: "15px",
                fontWeight: "500",
                padding: "0px 30px",
              }}
              onClick={() => history.push("/EmailAccountLoginWeb")}
            >
              SIGN IN
            </Button>
          ) : location.pathname == "/"||location.pathname == "/ForgotPasswordWeb" ? (
            <Button
              type="default"
              style={{
                backgroundColor: "white",
                color: "#63BFDB",
                borderRadius: "15px",
                fontWeight: "500",
                padding: "0px 30px",
              }}
              onClick={() => history.push("/EmailAccountLoginWeb")}
            >
              SIGN IN
            </Button>
          ) : (
            ""
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default withRouter(TopNav);
