import { v4 as uuidv4 } from "uuid";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import NavigationMenuController from "../../NavigationMenu/src/NavigationMenuController";

export default class NavigationMenuAdapter {
  send: (message: Message) => void;

  constructor() {
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationTabMenuMessage),
    ]);
    
    const navController = new NavigationMenuController({
      navigation: null,
      id: "",
    });
  }

  convert = (from: Message): Message => {
    const to = new Message(getName(MessageEnum.NavigationTabMessage));

    to.addData(
      getName(MessageEnum.NavigationTMessage),
      from.getData(getName(MessageEnum.NavigationTMessage))
    );

    console.log("INSIDE ADAPTER: ", to.getData(getName(MessageEnum.NavigationTMessage)))

    return to;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
