//@ts-ignore
import React, { Component, useState } from "react";
import SideBar from "../../../components/src/SideBar";
import "antd/dist/antd.css";
import ContactusController, { Props } from "./ContactusController";
// import bellicon from "../../contactus/assets/Componente_196_1.webp";
import "antd/dist/antd.css";
import {
  Switch,
  Tabs,
  Form,
  Input,
  Button,
  Collapse,
  Row,
  Col,
  Card,
} from "antd";
import { bellIcon, profile } from "./assets";
import "./Contactus.css";
import axios from "axios";
import { Divider } from "antd";
// import bellicon from "../assets/profile.png";
import { Spin, Space, Progress } from "antd";
import { View } from "react-native";
// import logo from "../../../blocks/dashboard/assets/logo.png";
import { Upload } from "antd";
import { LoadingWeb } from "../../../components/src/LoadingWeb";

const { Panel } = Collapse;

const { TabPane } = Tabs;

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const { progress } = this.state;
    const Demo = () => (
      <Tabs
        
        activeKey={this.state.profile}
        style={{
          paddingLeft: "28px",
        }}
        onChange={(key: any) => {
          this.setState({ profile: key });
        }}
      >
        <TabPane tab="Profile" key="1">
          <div style={styles.profile}>Profile</div>
          <div style={{ display: "flex" }}>
            <div className="profilebox">
              <div>
                <h4 className="John-Smith profile-card-name">
                  {this.state.first_name} {this.state.last_name}
                </h4>
                <p>{this.state.email}</p>

                {this.state.previewImage !== null ? (
                  <img
                    style={{
                      width: "130px",
                      marginTop: "10px",
                    }}
                    src={this.state.previewImage}
                    alt=""
                  />
                ) : (
                  <img src={profile} alt="" />
                )}

                <div>
                  {/* <Upload
                    onChange={(info: any) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(info.file.originFileObj);
                      reader.onload = () => {
                        this.setState({ previewImage: reader.result });
                        // this.imageProfileUpload(reader.result)
                      }
                    }}>
                    <Button style={{ marginTop: "15px", borderRadius: "20px", display: "flex", alignItems: "center", color: "#00ade2", fontWeight: "bold", backgroundColor: "#f2f2f2" }}>EDIT PROFILE PICTURE</Button>
                  </Upload> */}

                  {/* <Upload
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                  >
                    abc
                  </Upload> */}

                  {/* <input type="file" onChange={this.fileSelectedHandler} />
                  <button onClick={this.fileUploadhandler}>upload</button> */}
                  <Button
                   //@ts-ignore
                    testID="clickble"
                    onClick={(e) => {
                      if (this.inputRef.current) {
                        this.inputRef.current.click();
                      }
                    }}
                    style={{
                      marginTop: "15px",
                      borderRadius: "20px",
                      border: "none",
                      color: "#00ade2",
                      fontWeight: "bold",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    EDIT PROFILE PICTURE
                  </Button>
                  <input
                    type="file"
                    accept="image/jpeg,image/png,image/gif"
                    ref={this.inputRef}
                    onChange={this.handleChange}
                    style={{
                      display: "none",
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "50px" }}>
              <Form onFinish={this.onFinishProfile}>
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                  ]}
                  initialValue={this.state.first_name}
                  style={{
                    display: "flow-root",
                    marginBottom: "10px",
                  }}
                  label="First Name"
                >
                  <Input placeholder={"Your First Name"} />
                </Form.Item>
                <Form.Item
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                  ]}
                  initialValue={this.state.last_name}
                  style={{
                    display: "flow-root",
                    marginBottom: "10px",
                  }}
                  label="Last Name"
                >
                  <Input placeholder="Your Last Name" />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="E-mail"
                  style={{
                    display: "flow-root",
                    marginBottom: "10px",
                  }}
                >
                  <Input
                    placeholder="Your E-Mail"
                    defaultValue={this.state.email}
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flow-root",
                    marginBottom: "10px",
                  }}
                  name="password"
                  label="Password"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your password!',
                  //   },
                  // ]}
                  hasFeedback
                >
                  <Input.Password placeholder="password" />
                </Form.Item>
                <Form.Item
                  name="password_confirmation"
                  style={{ display: "flow-root" }}
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: false,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="password" />
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{
                      width: "397px",
                      backgroundColor: "#b3b3b3",
                      border: 0,
                      padding: "10.8px 168.1px 10.2px 170.9px",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                  >
                    SAVE
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </TabPane>
        <TabPane tab="FAQ's" key="2">
          <div style={styles.contactus}>FAQ's</div>
          <div>
            <p
              style={{
                fontSize: "20px",
                color: "#2dade1",
                marginTop: "27px",
                fontWeight: 600,
              }}
            >
              Title or subject
            </p>
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
              <Panel
                className="title1"
                header="Consectetuer adipiscing elit?"
                key="1"
              >
                <div className="panel-description">
                  A dog is a type of domesticated animal. Known for its loyalty
                  and faithfulness, it can be found as a welcome guest in many
                  households across the world.
                </div>
              </Panel>
              <Divider />
              <Panel className="title1" header="Sed diam nonummy?" key="2">
                <div className="panel-description">
                  A dog is a type of domesticated animal. Known for its loyalty
                  and faithfulness, it can be found as a welcome guest in many
                  households across the world.
                </div>
              </Panel>
              <Divider />
              <Panel
                className="title1"
                header="Ipsum dolor sit amet, consectetuer adipiscing elit."
                key="3"
              >
                <div className="panel-description">
                  A dog is a type of domesticated animal. Known for its loyalty
                  and faithfulness, it can be found as a welcome guest in many
                  households across the world.
                </div>
              </Panel>
              <Divider />
            </Collapse>
            <p
              style={{
                fontSize: "20px",
                color: "#2dade1",
                marginTop: "27px",
                fontWeight: 600,
              }}
            >
              Title or subject
            </p>
          </div>
        </TabPane>
        <TabPane tab="Terms of service" key="3">
          {/* <div>
            <div style={styles.contactus}>Terms of Service</div>
            <div style={styles.description}><p>B.E.S.T. Buget is a company... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam odio pretium mi suscipit, sed placerat enim blandit. <br /></p>

              <p style={{ marginTop: "20px" }}>Proin mi massa, convallis in faucibus vel, malesuada ut velit. Proin tincidunt, diam eget placerat efficitur, metus risus commodo erat, vitae scelerisque sem sapien ac mauris. Maecenas varius pretium lectus, eu semper leo aliquam eget. Integer id mauris nibh. Vestibulum ultrices fringilla orci, at sollicitudin dui condimentum sit amet. Donec pharetra imperdiet congue.<br /></p>

              <p style={{ marginTop: "20px" }}>Eget ultricies scelerisque, sem leo congue magna, et luctus libero lorem quis eros. Mauris iaculis rhoncus elementum. Nulla rhoncus tortor massa. Sed rhoncus neque finibus lacus rutrum finibus. Nullam sodales feugiat risus, quis cursus quam laoreet at. Donec bibendum libero ac ligula semper sollicitudin.<br /></p>

              <p style={{ marginTop: "20px" }}>Aenean et rutrum velit, at dictum libero. Donec blandit est sit amet suscipit pellentesque. Donec non neque porta, accumsan elit quis, bibendum est. Mauris tempor sed ex sed auctor. Sed tortor ante, ornare non diam a, pulvinar viverra purus. Donec non porttitor est. Mauris pellentesque consectetur ornare. Donec malesuada nulla orci, a luctus nisi tempus et. Curabitur vehicula efficitur massa. Nulla nunc enim, ornare eget rutrum id, suscipit ut nisi. Sed sapien diam, interdum eu dolor in, bibendum egestas dui. Sed non mi libero. Nulla facilisi. Suspendisse augue turpis, ornare in lectus vitae, tempus aliquam libero. Praesent feugiat, ligula et mattis pharetra, massa odio hendrerit dolor, bibendum eleifend tellus velit nec ante. Mauris consequat efficitur orci, eget egestas lorem posuere vitae.<br /></p>

              <p style={{ marginTop: "20px" }}>Quisque ut pretium eros. Pellentesque odio sapien, laoreet et orci et, porttitor lacinia nisl. Morbi laoreet ligula id nunc placerat, quis ornare eros mattis. Cras in commodo turpis. Suspendisse potenti. Aliquam a placerat leo.</p> </div>
          </div> */}
          <div
            style={{
              padding: "32px 0px",
              paddingRight: "28px",
            }}
          >
            <h1
              style={{
                fontSize: 22,
                color: "#545861",
              }}
            >
              Terms of Services
            </h1>
            <h3>General</h3>
            <p>
              These Terms of Service, together with any documents they expressly
              incorporate by reference (collectively, these “Terms of Service”),
              set forth the terms and conditions that govern your access to and
              use of the budgeting software available through the B.E.S.T.
              Wealth Management mobile application, software, and website,
              together with any associated content, including educational
              materials and communications regarding your account, subscription,
              functionality and services offered by B.E.S.T. Wealth Management,
              LLC (“B.E.S.T. Wealth Management” or “we” or “us”). As used in
              these Terms of Service, the term “Site” refers to and includes all
              B.E.S.T. Wealth Management websites, pages that are associated or
              within each website and all devices, applications or services that
              we operate or offer that link to these Terms of Service. Please
              read the Terms of Service carefully before you start to use the
              Site. By clicking to accept or agree to the Terms of Service,
              installing, accessing, or using the Site, you accept and agree to
              be bound and abide by these Terms of Service and our Privacy
              Policy, incorporated herein by reference, all as they may be
              amended from time to time in the future. If you do not agree to
              these Terms of Service or the Privacy Policy, you may not access
              or use the Site. You may not use or access the Site and you may
              not accept these Terms of Service if you are not legally
              authorized to accept and be bound by these terms or are not at
              least 18 years of age and, in any event, of a legal age to form a
              binding contract with B.E.S.T. Wealth Management. Before you
              continue, you should print or save a local copy of these Terms of
              Service for your records.
            </p>
            <h3>Modifications</h3>
            <p>
              We reserve the right at any time to modify or discontinue, either
              temporarily or permanently and with or without notice, the Site,
              or any portion thereof. We will not be liable if for any reason
              all or any part of the Site is unavailable at any time or for any
              period. Your use of the Site after you are notified of any
              modifications will constitute your acceptance of and agreement to
              such modifications. We will not be liable to you or to any third
              party for any modifications or discontinuations of the Site. We
              may revise and update these Terms of Service from time to time in
              our sole discretion. Any revisions will be provided to you by
              electronic means (either by email or by posting the information on
              the Sites). All changes are effective immediately upon such
              electronic notice and apply to all access to and use of the Site
              thereafter. Your use of the Site following the posting of revised
              Terms of Service means that you accept and agree to the changes.
            </p>
            <h3>Accessing the Website and Account Security</h3>
            <p>
              To access the Site or some of the resources it offers, you may be
              asked to provide certain registration details or other
              information. It is a condition of your use of the Site that all
              the information you provide on the Site is correct, current and
              complete. You agree that all information you provide to register
              with this Site or otherwise, including but not limited to using
              any interactive features on the Site, is governed by our Privacy
              Policy, and you consent to all actions we take with respect to
              your information consistent with our Privacy Policy. Any username,
              password or any other piece of information created by or issued to
              you as part of our security procedures, must be treated by you as
              confidential, and you disclose any such confidential information
              solely at your own risk and discretion. Should you, at your
              discretion, choose to share your username, password, or other
              security information with any other person or entity in order to
              share access, we disclaim any and all liability for anything that
              person or entity may do to compromise the security of your
              account. You agree to notify us immediately of any unauthorized
              access to or use of your username or password or any other breach
              of security. You also agree to ensure that you exit from your
              account at the end of each session. You should use caution when
              accessing your account from a public or shared computer so that
              others are not able to view or record your password or other
              personal information. We have the right to disable any username,
              password or other identifier, whether chosen by you or provided by
              us, at any time if, in our opinion, you have violated any
              provision of these Terms of Service. We are not responsible for
              the accuracy of financial data obtained from third-party sites
              that are displayed or reported through the Site or any of our
              services. We are not responsible for and cannot guarantee the
              accuracy or timeliness of financial account we retrieve on your
              behalf directly, from third party technology providers, from third
              party financial institutions where your accounts are held, or from
              third party research/market data providers used to provide our
              services. The Company assumes no responsibility for the
              timeliness, accuracy, deletion, non-delivery or failure to store
              any user data, loss of user data, communications or
              personalization settings or for any technical or other
              difficulties which may result in failure to obtain data,
              personalization settings or service interruptions.
            </p>
            <h3>Rights You Grant to B.E.S.T. Wealth Management</h3>
            <p>
              By submitting information, data, passwords, usernames, PINs, other
              log-in information, materials and other content to us through the
              Site, you are licensing that content to us for the purpose of
              providing our services to you through the Site. We may use and
              store the content in accordance with these Terms of Service and
              our Privacy Policy. You represent that you are entitled to submit
              such information to us for use for this purpose, without any
              obligation by us to pay any fees or be subject to any restrictions
              or limitations. By using the Site, you expressly authorize us to
              access your account information (and any personally identifying
              information) maintained by identified third parties, on your
              behalf as your agent, and you expressly authorize such third
              parties to disclose your information to us. When you use that
              feature of the Site, you will be directly connected to the website
              for the third party you have identified. We will submit
              information including usernames and passwords that you provide to
              log into such third party’s website. You hereby authorize and
              permit us to use and store information submitted by you to
              accomplish the foregoing and to configure the Site so that it is
              compatible with the third-party websites for which you submit your
              information. For purposes of these Terms of Service and solely to
              provide the account information to you as part of the services
              available through the Site, you grant us a limited power of
              attorney, and appoint us as your attorney-in-fact and agent, to
              access third party websites, retrieve and use your information
              with the full power and authority to do and perform each thing
              necessary in connection with such activities, as you could do in
              person. YOU ACKNOWLEDGE AND AGREE THAT WHEN WE ARE ACCESSING AND
              RETRIEVING ACCOUNT INFORMATION FROM THIRD PARTY WEBSITES, WE ARE
              ACTING AS YOUR AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF THE
              THIRD PARTY THAT OPERATES THE THIRD-PARTY WEBSITE. You understand
              and agree that the Site is not sponsored or endorsed by any third
              parties accessible through the Site. We are not responsible for
              any payment processing errors or fees or other Site-related
              issues, including those issues that may arise from inaccurate
              account information.
            </p>
            <h3>Intellectual Property Rights</h3>
            <p>
              The Site and its entire contents, features and functionality
              (including but not limited to all information, software, text,
              displays, images, video and audio, and the design, selection and
              arrangement thereof), are owned by the Company, its licensors or
              other providers of such material and are protected under both
              United States and other applicable copyright, trademark, and other
              laws. These Terms of Service grant you the right to view and use
              the Site subject to these Terms of Service and for your personal,
              non-commercial use. You may download or print a copy of
              information for the Site for your personal, internal and
              non-commercial use only. You cannot otherwise reproduce,
              distribute, modify, create derivative works of, publicly display,
              publicly perform, republish, download, store or transmit any of
              the material on our Site. All rights not expressly granted herein
              are reserved by the Company. Any distribution, reprint or
              electronic reproduction of any content from the Site in whole or
              in part for any purpose other than authorized in these Terms of
              Services is expressly prohibited without our prior written
              consent. If you print, copy, modify, download or otherwise use or
              provide any other person with access to any part of the Site in
              breach of the Terms of Service, your right to use the Site will
              cease immediately and you must, at our option, return or destroy
              any copies of the materials you have made.
            </p>
            <h3>Use of the Site</h3>
            <p>
              Your right to access and use the Site is personal to you and is
              not transferable by you to any other person or entity. You are
              only entitled to access and use the Site for lawful purposes and
              in accordance with these Terms of Service. You must provide true,
              accurate, current and complete information about your accounts
              maintained at other websites, as requested in our setup forms, and
              you may not misrepresent your registration and account
              information. For the Site to function effectively, you must also
              keep your registration and account information up to date and
              accurate. If you do not do this, the accuracy and effectiveness of
              the Site will be affected. You represent that you are a legal
              owner of, and that you are authorized to provide us with, all
              registration and account information and other information
              necessary to facilitate your use of the Site. Your access and use
              of the Site may be interrupted from time to time for any of
              several reasons, including, without limitation, the malfunction of
              device operating environment or other equipment, periodic
              updating, maintenance or repair of the Site or other actions that
              we, in our sole discretion, may elect to take. In no event will we
              be liable to any party for any loss, cost, or damage that results
              from any scheduled or unscheduled downtime or use of a rooted,
              jailbroken or incompatible mobile device. Use of the Site (or a
              portion thereof) may be available through a compatible mobile
              device, Internet and/or network access and may require software.
              You are solely responsible for these requirements, including any
              applicable changes, updates and fees as well as the terms of your
              agreement with your mobile device and telecommunications provider.
              WE MAKE NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS,
              STATUTORY OR IMPLIED AS TO: (i) THE AVAILABILITY OF
              TELECOMMUNICATION SERVICES FROM YOUR PROVIDER AND ACCESS TO THE
              SERVICES AT ANY TIME OR FROM ANY LOCATION; (ii) ANY LOSS, DAMAGE,
              OR OTHER SECURITY INTRUSION OF THE TELECOMMUNICATION SERVICES;
              (iii) ANY LOSS, DAMAGE, OR OTHER SECURITY INTRUSION FROM THE USE
              OF ROOTKIT MOBILE DEVICE OR YOUR DEVICE OPERATING ENVIRONMENT; AND
              (iv) ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES OR FAILURE TO
              TRANSMIT ANY DATA, COMMUNICATIONS OR SETTINGS CONNECTED WITH THE
              SITE. Your sole and exclusive remedy for any failure or
              non-performance of the Site, including any associated software or
              other materials supplied in connection with such portion of the
              Site, will be for us to use commercially reasonable efforts to
              effectuate an adjustment or repair of the applicable portion of
              the Site.
            </p>
            <h3>Access and Interference</h3>
            <p>
              You may use the Site only in accordance with these Terms of
              Service. You shall not use the Site: In any way that violates any
              applicable federal, state, local or international law or
              regulation (including, without limitation, any laws regarding the
              export of data or software to and from the US or other countries).
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with any standards set out in these
              Terms of Service. To engage in any other conduct that restricts or
              inhibits anyone’s use or enjoyment of the Site, or which, as
              determined by us, may harm B.E.S.T. Wealth Management or users of
              the Site or expose them to liability. Further, you shall not:
              Engage in behavior that will put your personal information at
              unnecessary risk, such as leaving, transmitting, or publishing
              your login or passwords. Use the Site in any manner that could
              disable, overburden, damage, or impair the Site or interfere with
              any other party’s use of the Site. Use any robot, spider or other
              automatic device, process or means to access the Site for any
              purpose, including monitoring or copying any of the material on
              the Site. Monitor or copy any of the material on the Site without
              our prior written consent. Use any device, software or routine
              that interferes with the proper working of the Site. Introduce any
              viruses, trojan horses, worms, logic bombs or other material which
              is malicious or technologically harmful. Attempt to gain
              unauthorized access to, interfere with, damage or disrupt any
              parts of the Site, the server on which the Site is stored, or any
              server, computer or database connected to the Site. Otherwise
              attempt to interfere with the proper working of the Site.
            </p>
            <h3>Monitoring and Enforcement; Termination</h3>
            <p>
              We have the right to: • Disclose your identity or other
              information about you to any third party who claims that material
              posted by you violates their rights, including their intellectual
              property rights or their right to privacy. • Take appropriate
              legal action, including without limitation, referral to law
              enforcement, for any illegal or unauthorized use of the Site. •
              Terminate or suspend your access to all or part of the Website for
              any or no reason, including without limitation, any violation of
              these Terms of Service. Without limiting the foregoing, we have
              the right to fully cooperate with any law enforcement authorities
              or court order requesting or directing us to disclose the identity
              or other information of anyone posting any materials on or through
              the Site. YOU WAIVE AND HOLD HARMLESS B.E.S.T. WEALTH MANAGEMENT
              AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY
              CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY/ANY OF THE
              FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND
              FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY
              EITHER THE COMPANY/SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <h3>
              Reliance on Information Posted; Financial Information is not
              Financial Planning, Legal or Tax Advice
            </h3>
            <p>
              The information presented on or through the Site is made available
              solely for general information purposes. We do not warrant the
              accuracy, completeness or usefulness of this information. Any
              reliance you place on such information is strictly at your own
              risk. We disclaim all liability and responsibility arising from
              any reliance placed on such materials by you or any other visitor
              to the Site, or by anyone who may be informed of any of its
              contents. THE INFORMATION PRESENTED ON THE SITE IS NOT INTENDED TO
              PROVIDE LEGAL, TAX OR INVESTMENT/RETIREMENT PLANNING ADVICE OR
              INTENDED TO SERVE AS TAX PREPARATION SERVICES. The information
              presented on the Site is intended only to assist you in your
              financial organization and decision-making and is broad in scope.
              Your personal financial situation is unique, and any information
              and advice obtained through the Site may not be appropriate for
              your situation. Accordingly, before making any final decisions or
              implementing any financial strategy, you should consider obtaining
              additional information and advice from your financial and/or legal
              advisers who are fully aware of your individual circumstances.
              This Site may include content provided by third parties, including
              materials provided by thirdparty licensors, syndicators,
              aggregators and/or reporting services. All statements and/or
              opinions expressed in these materials, and all articles and
              responses to questions and other content are solely the opinions
              and the responsibility of the person or entity providing those
              materials. These materials do not necessarily reflect our opinion.
              We are not responsible, or liable to you or any third party, for
              the content or accuracy of any materials provided by any third
              parties.
            </p>
            <h3>Information About You and Your Visits to the Website</h3>
            <p>
              All information we collect on this Site is subject to our Privacy
              Policy. By using the Site, you consent to all actions taken by us
              with respect to your information in compliance with the Privacy
              Policy.
            </p>
            <h3>Links from the Site</h3>
            <p>
              If the Site contains links to other sites and resources provided
              by third parties, these links are provided for your convenience
              only. This includes links contained in advertisements, including
              banner advertisements and sponsored links. We have no control over
              the contents of those sites or resources and accept no
              responsibility for them or for any loss or damage that may arise
              from your use of them. If you decide to access any of the
              third-party websites linked to this Site, you do so entirely at
              your own risk and subject to the terms and conditions of use for
              such websites.
            </p>
            <h3>Disclaimer of Representations and Warranties</h3>
            <p>
              THE SITE TOGETHER WITH ALL INFORMATION, DATA, FEATURES, AND ALL
              CONTENT AND ALL SERVICES AND PRODUCTS ASSOCIATED WITH THE SITE OR
              PROVIDED THROUGH THE SITE (WHETHER OR NOT SPONSORED) ARE PROVIDED
              TO YOU ON AN “AS-IS” AND “AS AVAILABLE” BASIS. B.E.S.T. WEALTH
              MANAGEMENT, ITS AFFILIATES, AND ITS THIRD-PARTY PROVIDERS,
              LICENSORS, DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY, "SUPPLIERS")
              MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
              IMPLIED, AS TO THE CONTENT OR OPERATION OF THE SITE. YOU EXPRESSLY
              AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. NEITHER
              B.E.S.T. WEALTH MANAGEMENT OR ITS SUPPLIERS MAKE ANY
              REPRESENTATIONS, WARRANTIES OR GUARANTEES, EXPRESS OR IMPLIED,
              REGARDING THE ACCURACY, RELIABILITY OR COMPLETENESS OF THE CONTENT
              ON THE SITE (WHETHER OR NOT SPONSORED), AND EXPRESSLY DISCLAIMS
              ANY WARRANTIES OF NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR
              PURPOSE. NEITHER B.E.S.T. WEALTH MANAGEMENT OR ITS SUPPLIERS MAKE
              ANY REPRESENTATION, WARRANTY OR GUARANTEE THAT THE CONTENT THAT
              MAY BE AVAILABLE THROUGH THE SITE IS FREE OF INFECTION FROM ANY
              VIRUSES OR OTHER CODE OR ROOT KITS OR COMPUTER PROGRAMMING
              ROUTINES THAT CONTAIN CONTAMINATING OR DESTRUCTIVE PROPERTIES OR
              THAT ARE INTENDED TO DAMAGE, SURREPTITIOUSLY INTERCEPT OR
              EXPROPRIATE ANY SYSTEM, DEVICE OPERATING ENVIRONMENT, DATA OR
              PERSONAL INFORMATION. SOME JURISDICTIONS DO NOT ALLOW THE
              EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF
              LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. IN SUCH STATES
              LIABILITY IS LIMITED TO THE EXTENT PERMITTED BY LAW. ACCORDINGLY,
              SOME OF THE ABOVE LIMITATIONS OF THIS PROVISION MAY NOT APPLY TO
              YOU.
            </p>
            <h3>Limitation on Liability</h3>
            <p>
              IN NO EVENT WILL B.E.S.T. WEALTH MANAGEMENT, ITS AFFILIATES OR
              THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS,
              DIRECTORS OR MANAGERS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
              LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
              INABILITY TO USE, THE SITE, ANY SITES LINKED TO IT, ANY CONTENT ON
              THE SITE OR SUCH OTHER SITES OR ANY SERVICES OR ITEMS OBTAINED
              THROUGH THE SITE OR SUCH OTHER SITES, INCLUDING ANY DIRECT,
              INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
              INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
              EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
              BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
              LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
              BREACH OF CONTRACT OR OTHERWISE, ARISING IN WHOLE OR IN PART FROM
              YOUR ACCESS TO OR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
              CONTRARY IN THIS AGREEMENT, B.E.S.T. WEALTH MANAGEMENT’S LIABILITY
              TO YOU FOR ANY CAUSE WHATEVER AND REGARDLESS OF THE FORM OF THE
              ACTION, WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF $500.00 (FIVE
              HUNDRED UNITED STATES DOLLARS). THE FOREGOING DOES NOT AFFECT ANY
              LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
              LAW.
            </p>
            <h3>Indemnification</h3>
            <p>
              You shall defend, indemnify and hold harmless B.E.S.T. Wealth
              Management, its affiliates, licensors and service providers, and
              its and their respective officers, directors, employees,
              contractors, agents, licensors, suppliers, successors and assigns
              from and against any claims, liabilities, damages, judgments,
              awards, losses, costs, expenses or fees (including reasonable
              attorneys’ fees) arising out of or relating to your violation of
              these Terms of Service or your use of the Site, any use of the
              Site’s content, services and products other than as expressly
              authorized in these Terms of Service or your use of any
              information obtained from the Site.
            </p>
            <h3>Governing Law and Jurisdiction</h3>
            <p>
              All matters relating to the Website and these Terms of Service and
              any dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              the State of Missouri without giving effect to any choice or
              conflict of law provision or rule. Any legal suit, action or
              proceeding arising out of, or related to, these Terms of Service
              or the Site shall be instituted exclusively in the federal courts
              of the United States or the courts of the State of Missouri, in
              each case located in St. Louis County, Missouri, although we
              retain the right to bring any suit, action or proceeding against
              you for breach of these Terms of Service in your country of
              residence or any other relevant country. You waive any and all
              objections to the exercise of jurisdiction over you by such courts
              and to a venue in such courts
            </p>
            <h3>Waiver and Severability</h3>
            <p>
              No waiver of by the Company of any term or condition set forth in
              these Terms of Service shall be deemed a further or continuing
              waiver of such term or condition or a waiver of any other term or
              condition, and any failure of the Company to assert a right or
              provision under these Terms of Service shall not constitute a
              waiver of such right or provision. If any provision of these Terms
              of Service is held by a court or other tribunal of competent
              jurisdiction to be invalid, illegal or unenforceable for any
              reason, such provision shall be eliminated or limited to the
              minimum extent such that the remaining provisions of the Terms of
              Service will continue in full force and effect.
            </p>
            <h3>Entire Agreement</h3>
            <p>
              The Terms of Service and our Privacy Policy and Terms of Sale
              constitute the sole and entire agreement between you and B.E.S.T.
              Wealth Management with respect to the Site and supersede all prior
              and contemporaneous understandings, agreements, representations
              and warranties, both written and oral, with respect to the Site.
            </p>
          </div>
        </TabPane>
        <TabPane tab="Privacy Policy" key="4">
          {/* <div>
            <div style={styles.contactus}>Private Policy</div>
            <div style={styles.description}><p>B.E.S.T. Buget is a company... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam odio pretium mi suscipit, sed placerat enim blandit. <br /></p>

              <p style={{ marginTop: "20px" }}>Proin mi massa, convallis in faucibus vel, malesuada ut velit. Proin tincidunt, diam eget placerat efficitur, metus risus commodo erat, vitae scelerisque sem sapien ac mauris. Maecenas varius pretium lectus, eu semper leo aliquam eget. Integer id mauris nibh. Vestibulum ultrices fringilla orci, at sollicitudin dui condimentum sit amet. Donec pharetra imperdiet congue.<br /></p>

              <p style={{ marginTop: "20px" }}>Eget ultricies scelerisque, sem leo congue magna, et luctus libero lorem quis eros. Mauris iaculis rhoncus elementum. Nulla rhoncus tortor massa. Sed rhoncus neque finibus lacus rutrum finibus. Nullam sodales feugiat risus, quis cursus quam laoreet at. Donec bibendum libero ac ligula semper sollicitudin.<br /></p>

              <p style={{ marginTop: "20px" }}>Aenean et rutrum velit, at dictum libero. Donec blandit est sit amet suscipit pellentesque. Donec non neque porta, accumsan elit quis, bibendum est. Mauris tempor sed ex sed auctor. Sed tortor ante, ornare non diam a, pulvinar viverra purus. Donec non porttitor est. Mauris pellentesque consectetur ornare. Donec malesuada nulla orci, a luctus nisi tempus et. Curabitur vehicula efficitur massa. Nulla nunc enim, ornare eget rutrum id, suscipit ut nisi. Sed sapien diam, interdum eu dolor in, bibendum egestas dui. Sed non mi libero. Nulla facilisi. Suspendisse augue turpis, ornare in lectus vitae, tempus aliquam libero. Praesent feugiat, ligula et mattis pharetra, massa odio hendrerit dolor, bibendum eleifend tellus velit nec ante. Mauris consequat efficitur orci, eget egestas lorem posuere vitae.<br /></p>

              <p style={{ marginTop: "20px" }}>Quisque ut pretium eros. Pellentesque odio sapien, laoreet et orci et, porttitor lacinia nisl. Morbi laoreet ligula id nunc placerat, quis ornare eros mattis. Cras in commodo turpis. Suspendisse potenti. Aliquam a placerat leo.</p> </div>
          </div> */}
          <div
            style={{
              padding: "32px 0px",
              paddingRight: "28px",
            }}
          >
            <h1
              style={{
                fontSize: 22,
                color: "#545861",
              }}
            >
              Private Policy
            </h1>
            <p>
              B.E.S.T. Wealth Management, LLC (“we” or “us” or “our”) respects
              the privacy of our users (“user” or “you”). This Privacy Policy
              explains how we collect, use, disclose, and safeguard your
              information when you visit our mobile application (the “App”).
              Please read this Privacy Policy carefully. IF YOU DO NOT AGREE
              WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE
              APP. We reserve the right to make changes to this Privacy Policy
              at any time and for any reason. We will alert you about any
              changes by updating the “Last updated” date of this Privacy
              Policy. You should periodically review this Privacy Policy to stay
              informed of updates. You will be deemed to have been made aware
              of, will be subject to, and will be deemed to have accepted the
              changes in any revised Privacy Policy by your continued use of the
              App after the date such revised Privacy Policy is posted. This
              Privacy Policy does not apply to the third-party online/mobile
              store from which you install the App or make payments, which may
              also collect and use data about you. We are not responsible for
              any of the data collected by any such third party.
            </p>

            <p>
              WHAT INFORMATION WE COLLECT AND HOW WE COLLECT YOUR INFORMATION:
              We may collect personally identifiable information (“PII”) in a
              variety of ways. The PII we may collect via the App depends on the
              content and materials you use, and includes:
            </p>

            <p>
              <strong>Personal Data:</strong>
              We collect and store all personal information related to your App
              profile, which you voluntarily give us either upon sign-up or
              through continued use of the App including, but not limited to,
              first and last name, postal address, email address, telephone
              number(s).
            </p>

            <p>
              Derivative Data and Uploaded Media: Information our servers
              automatically collect when you access the App, such as your native
              actions that are integral to the App, actions taken when creating
              entries of information to the app, editing entries and uploading
              media or information to the App. As such, we may also request
              access to your device’s photo roll or camera in order for us to
              upload your media to the App. Any media uploaded in this fashion,
              will be collected and stored on our servers. If you wish to change
              our access or permissions, you may do so in your device’s
              settings.
            </p>

            <p>
              Data From Financial Institutions: User information from financial
              institutions, including your name, your username, location, email
              address, and financial account information, if you connect your
              App account to such financial institutions.
            </p>

            <p>
              <strong>Geo-Location Information:</strong>
              We may request access or permission to and track location-based
              information from your mobile device, either continuously or while
              you are using the App, to provide location-based services. If you
              wish to change our access or permissions, you may do so in your
              device’s settings.
            </p>

            <p>
              <strong>Mobile Device Access:</strong>
              We may request access or permission to certain features from your
              mobile device, including your mobile device’s reminders, and other
              features. If you wish to change our access or permissions, you may
              do so in your device’s settings.
            </p>

            <p>
              <strong>Mobile Device Data:</strong>
              Device information such as your mobile device ID number, model,
              and manufacturer, version of your operating system, phone number,
              country, location, and any other data you choose to provide.
            </p>

            <p>
              <strong>Push Notifications:</strong>
              We may request to send you push notifications regarding your
              account or the App. If you wish to opt-out from receiving these
              types of communications, you may turn them off in your device’s
              settings.
            </p>

            <p>
              USE OF YOUR INFORMATION Having accurate information about you
              permits us to provide you with a smooth, efficient, and customized
              experience. Specifically, we may use information collected about
              you via the App to: To provide services to you via the App. For
              internal research and analysis in order to improve services to its
              customers. Generate a personal profile about you to make future
              visits to the App more personalized. Monitor and analyze usage and
              trends to improve your experience with the App. Notify you of
              updates to the App. Request feedback and contact you about your
              use of the App. To improve our products and services. We may
              periodically send promotional emails about new products, special
              offers or other information which we think you may find
              interesting using the email address which you have provided. We
              will also require you to link either your iTunes Account, ApplePay
              Account or Google Play Account with our App if you wish to
              purchase one of our Premium subscriptions. This does not give us
              access to your credit card or banking information, but is needed
              so that we can process your purchases/subscriptions.
            </p>

            <p>
              DISCLOSURE OF YOUR INFORMATION We may share information we have
              collected about you in certain situations. Your information may be
              disclosed as follows: We will not sell, distribute, or lease your
              personal information to unrelated third parties unless we have
              your permission or are required by law to do so. We may need to
              send your personal information to our third-party vendors, who are
              under contractual obligations to keep this information
              confidential. We may also use your personal information to send
              you promotional information about third parties which we think you
              may find interesting if you tell us that you wish this to happen.
              Finally, if we ever sell the App, or participate in a merger or
              consolidation, and our User data is one of the assets involved in
              the sale, your personal information will be part of this sale or
              transfer. We may also disclose your personal information: To
              comply with any court order, law or legal process, including to
              respond to any government or regulatory request. To enforce or
              apply our Terms of Use and other agreements, including for billing
              and collection purposes. If we believe disclosure is necessary or
              appropriate to protect the rights, property, or safety of B.E.S.T.
              Wealth Management, LLC, our customers, or others. This may include
              exchanging information with other companies and organizations for
              the purposes of fraud protection and credit risk reduction.
            </p>

            <h2>TRACKING TECHNOLOGIES</h2>

            <p>
              <strong>Cookies and Web Beacons:</strong>
              We may use cookies, web beacons, tracking pixels, and other
              tracking technologies on the App to help customize the App and
              improve your experience. For more information on how we use
              cookies, please refer to our Cookie Policy posted on the App,
              which is incorporated into this Privacy Policy. By using the App,
              you agree to be bound by our Cookie Policy.
            </p>

            <p>
              <strong>Website Analytics:</strong>
              We may also partner with selected third-party vendors, such as
              Google Analytics, Facebook Business Manager, to allow tracking
              technologies and remarketing services on the App through the use
              of first party cookies and third-party cookies, to, among other
              things, analyze and track users’ use of the App, determine the
              popularity of certain content, and better understand online
              activity. By accessing the App, you consent to the collection and
              use of your information by these third-party vendors. You are
              encouraged to review their privacy policy and contact them
              directly for responses to your questions. We do not transfer
              personal information to these third-party vendors. However, if you
              do not want any information to be collected and used by tracking
              technologies, you can visit the third-party vendor or Network
              Advertising Initiative Opt-Out Tool or Digital Advertising
              Alliance Opt-Out Tool. You should be aware that getting a new
              computer, installing a new browser, upgrading an existing browser,
              or erasing or otherwise altering your browser’s cookies files may
              also clear certain opt-out cookies, plug-ins, or settings.
            </p>

            <p>
              THIRD-PARTY WEBSITES The App may contain links to third-party
              websites and applications of interest, including advertisements
              and external services, that are not affiliated with us. Once you
              have used these links to leave the App, any information you
              provide to these third parties is not covered by this Privacy
              Policy, and we cannot guarantee the safety and privacy of your
              information. Before visiting and providing any information to any
              third-party websites, you should inform yourself of the privacy
              policies and practices (if any) of the third party responsible for
              that website, and should take those steps necessary to, in your
              discretion, protect the privacy of your information. We are not
              responsible for the content or privacy and security practices and
              policies of any third parties, including other sites, services or
              applications that may be linked to or from the App. You should
              exercise caution and look at the privacy statement applicable to
              the website in question.
            </p>

            <p>
              SECURITY OF YOUR INFORMATION We use administrative, technical, and
              physical security measures to help protect your personal
              information. While we have taken reasonable steps to secure the
              personal information you provide to us, please be aware that
              despite our efforts, no security measures are perfect or
              impenetrable, and no method of data transmission can be guaranteed
              against any interception or other type of misuse. Any information
              disclosed online is vulnerable to interception and misuse by
              unauthorized parties. Therefore, we cannot guarantee complete
              security if you provide personal information.
            </p>

            <p>
              CHILDREN UNDER THE AGE OF 18. Our service is not intended for
              children under 18 years of age. No one under the age of 18 may
              provide any personal information to, or on, the App. We do not
              knowingly collect personal information from children under 18. If
              you are under 18, do not use or provide any information on this
              App, or on or through any of its features/functionality, use any
              of the interactive features that may be available on this App, or
              provide any information about yourself to us, including your name,
              address, telephone number, e-mail address or any screen name or
              username you may use. If we learn that we have collected or
              received personal information from a child under 18 without
              verification of parental consent, we will delete that information.
              If you believe we might have any information from or about a child
              under 18, please contact us at [insert email address].
            </p>

            <p>
              CONTROLS FOR DO-NOT-TRACK FEATURES Most web browsers and some
              mobile operating systems include a Do-Not-Track (“DNT”) feature or
              setting you can activate to signal your privacy preference not to
              have data about your online browsing activities monitored and
              collected. No uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this Privacy Policy.
            </p>

            <h2>OPTIONS REGARDING YOUR INFORMATION</h2>

            <p>
              <strong>Account Information:</strong>
              You can review and change your personal information by logging
              into your profile through the App and visiting your account
              profile page or settings page. You may also send us an e-mail at
              [insert email address] to request access to, correct or delete any
              personal information that you have provided to us. We may not be
              able to delete your personal information except by also deleting
              your user account. We also may not accommodate a request to change
              information if we believe the change would violate any law or
              legal requirement, or cause the information to be incorrect. Upon
              your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, some information may be retained in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with legal requirements.
            </p>

            <p>
              <strong>Emails and Communications:</strong>
              If you no longer wish to receive correspondence, emails, or other
              communications from us, you may opt-out by: Contacting us using
              the contact information provided below. Clicking the opt-out
              button at the bottom of any promotional email you receive. If you
              no longer wish to receive correspondence, emails, or other
              communications from third parties, you are responsible for
              contacting the third party directly.
            </p>

            <p>
              NOTICE TO USERS FROM THE EUROPEAN ECONOMIC AREA If you are
              visiting and using the Services from the European Economic Area
              ('EEA'), please be aware that you are sending information
              (including personal data) to the United States where our partner
              platform’s servers are located. Our partners and/or staff
              operating outside the EEA may process this information. The
              information may then be transferred within the United States or
              back out of the United States to other countries outside of your
              country of residence, depending on the type of information and how
              we store it. These countries (including the United States) may not
              necessarily have data protection laws as comprehensive or
              protective as those in your country of residence; however, our
              collection, storage and use of your personal data will at all
              times continue to be governed by this Privacy Policy. BY SUPPLYING
              YOUR PERSONAL INFORMATION TO US YOU EXPRESSLY AGREE TO THE
              TRANSFER OF YOUR PERSONAL INFORMATION OUT OF THE EEA, AND TO THE
              PROCESSING OF YOUR INFORMATION IN THE U.S., SUBJECT TO THIS
              PRIVACY POLICY. YOUR CONSENT By using our site or app, you consent
              to our privacy policy.
            </p>

            <p>
              CONTACT US If you have questions or comments about this Privacy
              Policy, please contact us at: [insert email address]
            </p>
          </div>
        </TabPane>
        <TabPane tab="Contact Us" key="5">
          <div
            style={{
              margin: "0 auto",
              width: "397px",
              marginBottom: "40px",
            }}
          >
            <div style={styles.contactus}>Contact Us</div>
            <Form onFinish={this.onFinish} style={{ marginTop: 20 }}>
              <Form.Item
                style={{
                  display: "flow-root",
                }}
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your First Name!",
                  },
                ]}
              >
                <Input placeholder="Your Name" />
              </Form.Item>
              <Form.Item
                style={{
                  display: "flow-root",
                }}
                label="E-Mail"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Last Name!",
                  },
                ]}
              >
                <Input placeholder="Your E-Mail" />
              </Form.Item>
              <Form.Item
                style={{
                  display: "flow-root",
                }}
                label="Message"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input your Message!",
                  },
                ]}
              >
                <Input.TextArea placeholder="Your Message" />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    width: "397px",
                    backgroundColor: "#b3b3b3",
                    border: 0,
                    padding: "10.8px 168.1px 10.2px 170.9px",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  SUBMIT
                </Button>
              </Form.Item>
            </Form>
          </div>
        </TabPane>
      </Tabs>
    );

    return (
      <>
        {this.state.cardLoading ? (
          <LoadingWeb />
        ) : (
          <div>
            <div>
              <SideBar />
            </div>
            <Row style={{ marginLeft: "79px" }}>
              <Col span={24}>
                <Card style={{ margin: "2em", padding: "40px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 28px",
                    }}
                  >
                    <div
                      style={{
                        color: "#545861",
                        fontSize: "32px",
                        fontWeight: 700,
                      }}
                    >
                      Settings
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <img src={bellIcon} alt="" />
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginRight: "20px",
                        }}
                      >
                        <Switch
                          defaultChecked={this.state.notification_status}
                          onChange={this.handleSwitch}
                        />
                      </div>
                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#000000",
                          marginRight: "20px",
                        }}
                      >
                        Enable Notification
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <Demo />
                    </div>
                    <div />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

const styles = {
  contentBlock: {
    height: "auto",
    padding: "20px",
    backgroundColor: "white",
    marginRight: "32px",
    border: "1px solid white",
    marginLeft: "106px",
    marginTop: "28px",
  },
  contactus: {
    marginTop: 40,
    fontFamily: "HelveticaNeueLTStd- Hv",
    color: "#545861",
    fontWeight: 600,
    fontSize: 22,
  },
  description: {
    marginTop: "20px",
    fontFamily: "HelveticaNeueLTStd- Roman",
    fontSize: 16,
    lineHeight: 1.38,
    letterSpacing: "-0.38px",
    color: "#000000",
  },
  profile: {
    marginTop: "10px",
    fontFamily: "HelveticaNeueLTStd- Hv",
    color: "#545861",
    fontWeight: 600,
    fontSize: 22,
  },
};
