//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import React, { Component } from 'react';
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RightOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    data: any;
    state:any;
    amount:any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    dashboardData: any;
    token: string;
    errorMsg: string;
    loading: boolean;
    switchValue: boolean;
    reveniewData: any;
    accountId: string;
    userName: string;
    customer: string;
    isExpend: boolean;
    subDetails: any;
    tabData: any;
    drawerData: any;
    isOpen: boolean;
    tableData: any;
    columnData: any;
    columnData2: any;
    tableData2: any;
    columnData3: any;
    tableData3: any;
    columnData4: any;
    tableData4: any;
    confirmButton: any;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class ConfirmPaymentController extends BlockComponent<Props, S, SS> {
    apiDashboardItemCallId: string = "";
    dashboardApiiCallId: string = "";
    apiGetQueryStrinurl: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationRMessage)
        ];

        this.state = {
            dashboardData: [],
            errorMsg: "",
            token: "",
            loading: false,
            switchValue: true,
            accountId: "",
            userName: "Guest",
            reveniewData: [],
            customer: "",
            isExpend: false,
            subDetails: [],
            tabData: null,
            drawerData: null,
            isOpen: false,
            columnData: [
                {
                    title: 'Category',
                    dataIndex: 'category',
                    key: 'category',
                    render: (text: any) => <a style={{
                        fontSize: 14,
                        fontWeight: 500, color: " #000000",
                    }}>{text}</a>,
                },
                {
                    title: "Budgeted",
                    dataIndex: "price",
                    key: "price",
                    render: (text: any) => <a style={{
                        fontSize: 14,
                        fontWeight: 500, color: " #000000",
                    }}>{text}</a>,
                },
                {
                    title: "Available",
                    dataIndex: "available",
                    key: "available",
                    render: (text: any) => <a style={{
                        fontSize: 16,
                        fontWeight: "bold", color: " #00a950",
                    }}>{text}</a>,
                },
                {
                    title: "",
                    dataIndex: "arrrow",
                    key: "arrow",
                    render: (text: any) => <a style={{ border: "1px solid #F7F7F7", borderRadius: "2px", padding: "6px", backgroundColor: "#F7F7F7" }}><RightOutlined translate style={{ color: "#2dade1" }} /></a>
                },
            ],
            tableData: [
                {
                    key: '1',
                    category: 'Category A',
                    price: "$ 6,000",
                    available: "$ 2,000",

                },
                {
                    key: '2',
                    category: 'Category B',
                    price: "$ 10,000",
                    available: "$ 5,000"
                },
                {
                    key: '3',
                    category: 'Category C',
                    price: "$ 3,000",
                    available: "$ 1,000"
                },
                {
                    key: '4',
                    category: 'Category D',
                    price: "$ 2,500",
                    available: "$ 26,000"
                },
            ],
            columnData2: [
                {
                    title: '    ',
                    dataIndex: 'category',
                    key: 'category',
                    render: (text: any) => <a style={{
                        fontSize: 14,
                        fontWeight: 500, color: " #000000",
                    }}>{text}</a>,
                },
                {
                    title: "Budgeted",
                    dataIndex: "price",
                    key: "price",
                    render: (text: any) => <a style={{
                        fontSize: 14,
                        fontWeight: 500, color: " #000000",
                    }}>{text}</a>,
                },
                {
                    title: "Available",
                    dataIndex: "available",
                    key: "available",
                    render: (text: any) => <a style={{
                        fontSize: 16,
                        fontWeight: "bold", color: " #00a950",
                    }}>{text}</a>,
                },
                {
                    title: "",
                    dataIndex: "arrrow",
                    key: "arrow",
                    render: (text: any) => <a style={{ border: "1px solid #F7F7F7", borderRadius: "2px", padding: "6px", backgroundColor: "#F7F7F7" }}><Checkbox style={{ color: "#2dade1" }} /></a>
                },
            ],
            tableData2: [
                {
                    key: '1',
                    category: 'Category A',
                    price: "$ 6,000",
                    available: "$ 2,000",

                },
                {
                    key: '2',
                    category: 'Category B',
                    price: "$ 10,000",
                    available: "$ 5,000"
                },
                {
                    key: '3',
                    category: 'Category C',
                    price: "$ 3,000",
                    available: "$ 1,000"
                },
                {
                    key: '4',
                    category: '',
                    price: "",
                    available: ""
                },
            ],
            columnData3: [
                {
                    title: '',
                    dataIndex: 'category',
                    key: 'category',
                    render: (text: any) => <a style={{
                        fontSize: 14,
                        fontWeight: 500, color: " #000000",
                    }}>{text}</a>,
                },
                {
                    title: "Budgeted",
                    dataIndex: "price",
                    key: "price",
                    render: (text: any) => <a style={{
                        fontSize: 14,
                        fontWeight: 500, color: " #000000",
                    }}>{text}</a>,
                },
                {
                    title: "Available",
                    dataIndex: "available",
                    key: "available",
                    render: (text: any) => <a style={{
                        fontSize: 16,
                        fontWeight: "bold", color: " #00a950",
                    }}>{text}</a>,
                }
            ],
            tableData3: [
                {
                    key: '1',
                    category: 'Category D',
                    price: "$ 26,000",
                    available: "$ 6,000",
                }
            ],
            columnData4: [
                {
                    title: '',
                    dataIndex: 'category',
                    key: 'category',
                    render: (text: any) => <a style={{
                        fontSize: 14,
                        fontWeight: 500, color: " #000000",
                    }}>{text}</a>,
                },
                {
                    title: "Budgeted",
                    dataIndex: "price",
                    key: "price",
                    render: (text: any) => <a style={{
                        fontSize: 14,
                        fontWeight: 500, color: " #000000",
                    }}>{text}</a>,
                },
                {
                    title: "Available",
                    dataIndex: "available",
                    key: "available",
                    render: (text: any) => <a style={{
                        fontSize: 16,
                        fontWeight: "bold", color: " #00a950",
                    }}>{text}</a>,
                }
            ],
            tableData4: [
                {
                    key: '1',
                    category: 'Category A',
                    price: "$ 26,000",
                    available: "$ 6,000",
                }
            ],
            confirmButton: "#b3b3b3"
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        console.log("componentDidMount...");
        var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(authTokenReq);
        if (!this.state.tabData) {
            const uploadData = {
                data: "Needed",
            };

            const msg: Message = new Message(
                getName(MessageEnum.NavigationTabMenuMessage)
            );
            msg.addData(
                getName(MessageEnum.NavigationTMessage),
                uploadData
            );

            console.log("Dashboard Broadcasting message");
            runEngine.sendMessage(msg.id, msg);
        }
    }

    getDashboardData(): boolean {
        // Customizable Area Start
        const reveniewtUrl = `bx_block_dashboard/get_savings_account_det?customerId=${this.state.customer}`
        const confirmpaymentheader = {
            "Content-Type": configJSON.necessityContentType,
            token: this.state.token
        };
        console.log("header  ", JSON.stringify(confirmpaymentheader));
        const confirmpaymenthttpBody = {
            "data":
            {
                "account_id": String(this.state.accountId),
            },
        };
        const confirmPaymentrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        console.log("requestMessage, ", confirmPaymentrequestMessage);
        this.dashboardApiiCallId = confirmPaymentrequestMessage.messageId;
        confirmPaymentrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            reveniewtUrl
        );

        confirmPaymentrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(confirmpaymentheader)
        );
        confirmPaymentrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(confirmpaymenthttpBody)
        );
        confirmPaymentrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.necessityApiMethodType
        );
        console.log('confirmPaymentrequestMessage@, ', confirmPaymentrequestMessage);
        runEngine.sendMessage(confirmPaymentrequestMessage.id, confirmPaymentrequestMessage);
        // Customizable Area End
        return true;
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        console.log("receive...");
        //runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.NavigationRMessage) === message.id) {
            const data = message.getData(getName(MessageEnum.NavigationTabDataMessage));
            this.setState({ tabData: data.data[0].data.attributes, drawerData: data.data[1].data.attributes });
        }

        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            //runEngine.debugLog("Message Recived", message);
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            this.setState({ token: token, loading: true }, () => {
                this.getDashboardData();
            });
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if ((apiRequestCallId === this.dashboardApiiCallId)) {
                if (responseJson && !responseJson.errors && responseJson.dashboardData) {
                    console.log("responseJson  Dashboard ", JSON.stringify(responseJson));
                    if (responseJson.dashboardData.length === 0) {
                        this.setState({
                            errorMsg: "Data Not Found",
                            loading: false
                        });
                    } else {
                        this.setState({
                            reveniewData: responseJson.dashboardData,
                            errorMsg: "",
                            loading: false
                        });
                        //const getcata= new Dashboard(this.props);
                        this.getCategories();
                    }
                } else {
                    var errorReponse = message.getData(
                        getName(MessageEnum.RestAPIResponceErrorMessage)
                    );
                    console.log("error resp", errorReponse);
                    if (errorReponse === undefined) {
                        this.setState({
                            errorMsg: "Something went wrong",
                            loading: false
                        });
                    } else {
                        this.setState({
                            errorMsg: errorReponse,
                            loading: false
                        });
                    }
                }
            }
        }
    }
    getCategories() {
        throw new Error("Method not implemented.");
    }
    // Customizable Area Start
    getAsset = (item: any): any => {
        switch (item.icon) {
            case "Account":
                return require("../assets/Componente33x.png");
            case "Budget":
                return require("../assets/Componente43x.png");
            case "Transaction":
                return require("../assets/Componente53x.png");
            case "Reports":
                return require("../assets/Componente63x.png");
            default:
                return require("../assets/Componente73x.png");
        }
    }

    nav = (item: any) => {
        switch (item.icon) {
            case "Account":
                this.onPressAccounts();
                break;
            case "Budget":
                this.onPressBudgets();
                break;
            case "Transaction":
                this.onPressTrans();
                break;
            case "Reports":
                this.onPressReports();
                break;
            case "Settings":
                this.onPressSettings();
                break;
            case "Contactus":
                this.onPressContactus();
                break;
            case "Profile":
                this.onPressProfile();
                break;
            case "Dashboard":
                this.onPressDashboard();
                break;
            default:
                console.log("Not navigating");
        }
    }

    onPressBudgets = () => {
        this.props.navigation.navigate("Budget")
    }
    onPressReports = () => {
        this.props.navigation.navigate("StatisticsReports")
    }
    onPressAccounts = () => {
        this.props.navigation.navigate("AccountsPage")
    }
    onPressTrans = () => {
        this.props.navigation.navigate("Transaction")
    }
    onPressmovemoney = () => {
        this.props.navigation.navigate("MovemoneyFrom")
    }
    onPressSettings = () => {
        this.props.navigation.navigate("Settings");
    }
    onPressContactus = () => {
        this.props.navigation.navigate("Contactus");
    }
    onPressProfile = () => {
        this.props.navigation.navigate("UserProfileBasicBlock");
    }
    onPressDashboard = () => {
        this.setState({ isOpen: false });
    }

    // Customizable Area End
}
