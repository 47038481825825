//@ts-nocheck
import React from 'react';
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import TransactionController, { Props } from "./TransactionController";
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, Table } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import SideBar from "../../../components/src/SideBar";
import "../assets/index.css";
//@ts-ignore
import transactionbtn from "../assets/transactionbtn.jpg"
import { configJSON } from "./AddCategories.web";
const { RangePicker } = DatePicker;
const { Option } = Select;
export default class Transaction extends TransactionController {
  constructor(props: Props) {
    super(props);


    // Customizable Area Start
    // Customizable Area End
  }

  onFinish = async (value: any) => {
      this.setState({ openAddModal: false });
      const data = {
        transaction: {
          transaction_type: "Category",
          amount: value.amount,
          transact_from_id: value.categoryName,
          transact_to_id: value.categoryName,
          date: value.date ?? null,
          description: value.description
        },
      };
      await axios.post(
        `${configJSON.baseURL}bx_block_transactions/transactions`,
        data,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("loginToken"),
          },
        }
      ).then((res: any) => {
        this.getAllTransaction();
        console.log(res, "success")
      }).catch((err: any) => {
      })
    };

    handleOk = () => {
      this.setState({ openAddModal: false });
    };

    change = (value: any, index: any) => {
      localStorage.setItem('categoryID', JSON.stringify(value))
    }

  render() {
    return (
      <div>
        <div>
          <SideBar />
        </div>

        {this.state.cardLoading ? (
          <LoadingWeb />
        ) : (
          <Row style={{ marginLeft: "79px" }}>
            <Col span={24}>
              <Card style={{ margin: "2em" }}>
                <Row align="middle" justify="center">
                  <Col xs={0} sm={0} md={1} lg={1} />
                  <Col xs={24} sm={24} md={22} lg={22}
                    style={{ marginTop: "40px", marginBottom: "25px" }}

                  >
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        xl={6}
                        xxl={11}
                        style={styles.BudgetsBalance}
                      >
                        {" "}
                        <Row
                          align="middle"
                          justify="start"
                          style={{ marginBottom: "16px" }}
                        >
                          Transactions
                        </Row>{" "}
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={6} xl={5} xxl={4}>
                        <Row
                          align="middle"
                          justify="end"
                          style={{ marginBottom: "16px" }}
                        >
                          <button
                            onClick={this.showCategory}
                            style={styles.exportButton}
                          >
                            <CSVLink
                              data={this.state.tableDataEx}
                              style={{
                                color: "rgb(45, 173, 225)",
                              }}
                              filename={"Transaction-report.csv"}
                              target="_blank"
                            >
                              <DownloadOutlined
                                translate
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                              EXPORT
                            </CSVLink>
                          </button>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={9} xl={6} xxl={4}>
                        <Row
                          align="middle"
                          justify="end"
                          style={{ marginBottom: "16px" }}
                        >
                          <button
                            test-id="add-category"
                            onClick={this.showCategory}
                            style={styles.addCategoryButton}
                          >
                            <Link
                              to="/AddCategories"
                              style={{
                                color: "rgb(45, 173, 225)",
                              }}
                            >
                              ADD CATEGORY
                            </Link>
                          </button>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={5}>
                        <Row
                          align="middle"
                          justify="end"
                          style={{ marginBottom: "16px" }}
                        >
                          <Button
                            test-id="add-transaction-btn"
                            type="primary"
                            onClick={() => this.setState({ openAddModal: true })}
                            style={styles.addTransactionButton}
                          >
                            <img
                              src={transactionbtn}
                              style={{
                                width: "30px",
                                marginRight: "10px",
                              }}
                            />
                            <span
                              style={{
                                color: "#ffffff",
                              }}
                            >
                              ADD TRANSACTION
                            </span>
                          </Button>
                          <Modal
                            centered
                            test-id="add-transaction-modal"
                            title={
                              <Row
                                justify="center"
                                style={{
                                  color: "#545861",
                                  fontSize: "28px",
                                  fontWeight: "bold",
                                }}
                              >
                                Add Transaction
                              </Row>
                            }
                            closeIcon={true}
                            destroyOnClose
                            footer={false}
                            visible={this.state.openAddModal}
                            onOk={this.handleOk}
                            onCancel={this.handleOk}
                            width="35%"
                          >
                            <Row justify="center">
                              <Col span={18}>
                                <Form
                                  // form={form}
                                  name="control-ref"
                                  layout="vertical"
                                  onFinish={this.onFinish}
                                >
                                  <Row>
                                    <Form.Item
                                      style={{ width: "100%" }}
                                      name="categoryName"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input your Category Name!",
                                        },
                                      ]}
                                      label={<span style={{ color: "gray" }}>Category Name</span>}
                                    >
                                      <Select
                                        test-id="select-category"
                                        size="large"
                                        placeholder="Select Category"
                                        allowClear onChange={this.change}>
                                        {this.state.categoryData?.map((transactionsvalues: any) => (
                                          <Option key={transactionsvalues?.attributes?.generic_name} value={transactionsvalues?.attributes?.id}>{transactionsvalues?.attributes?.generic_name}</Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Row>
                                  <Row>
                                    <Form.Item
                                      style={{ width: "100%" }}
                                      name="amount"
                                      rules={[
                                        {
                                          required: true,
                                          message: configJSON.btnExampleTitle,
                                        },
                                      ]}
                                      label={<span style={{ color: "gray" }}>{configJSON.labelBodyText1}</span>}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Row>
                                  <Row>
                                    <Form.Item
                                      style={{ width: "100%" }}
                                      name="date"
                                      rules={[
                                        {
                                          message: "Please input your Date!",
                                        },
                                      ]}
                                      label={<span style={{ color: "gray" }}>Date</span>}
                                    >
                                      <Input type="date" />
                                    </Form.Item>
                                  </Row>
                                  <Row>
                                    <Form.Item
                                      style={{ width: "100%" }}
                                      name="description"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input your Description!",
                                        },
                                      ]}
                                      label={<span style={{ color: "gray" }}>{configJSON.labelBodyText}</span>}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Row>
                                  <Row style={{ marginTop: "2em" }}>
                                    <Form.Item style={{ width: "100%" }} shouldUpdate>
                                      {() => (
                                        <Button
                                          test-id="form-button"
                                          type={configJSON.type}
                                          htmlType={configJSON.htmltype}
                                          loading={this.state.loading}
                                          style={{ borderRadius: "15px", width: "100%" }}
                                        >
                                          {configJSON.labelTitleText}
                                        </Button>
                                      )}
                                    </Form.Item>
                                  </Row>
                                </Form>
                              </Col>
                            </Row>
                          </Modal>
                        </Row>
                      </Col>
                    </Row>

                    <Col>
                      <Row>
                        <Col xs={14} sm={10} md={9} lg={7} xl={6} xxl={5}>
                          <Form>
                            <Form.Item>
                              <Select
                                size={this.state.large}
                                placeholder="Month to date"
                                allowClear
                                onChange={this.keywordFilter}
                                value={this.state.selectedValue}
                              >
                                <Option value="month_to_date">
                                  Month to date
                                </Option>
                                <Option value="year_to_date">
                                  Year to date
                                </Option>
                                <Option value="last_month">Last Month</Option>
                                <Option value="custom">Custom</Option>
                              </Select>
                              <Row />
                            </Form.Item>
                          </Form>
                        </Col>
                        <Col span={6}>
                          {this.state.showCustom ? (
                            <RangePicker
                              size="large"
                              style={{
                                marginLeft: "5px",
                              }}
                              onChange={this.handlerCustomDate}
                              // defaultValue={[moment('2015/01/01', "YYYY-MM-DD"), moment('2015/01/01', "YYYY-MM-DD")]}
                              format={"YYYY-MM-DD"}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col span={2} />
                      </Row>
                    </Col>

                    <Row style={styles.headingDiv}>
                      <Col span={24}>
                        <Row>
                          <Col span={9} style={styles.headingDivContent}>
                            Account
                          </Col>
                          <Col span={9} style={styles.headingDivContent}>
                            Description
                          </Col>
                          <Col span={3} style={styles.headingDivContent}>
                            Amount
                          </Col>
                          <Col span={3} />
                        </Row>
                      </Col>
                    </Row>
                    <Table
                      style={{
                        padding: " 0px 20px",
                      }}
                      pagination={{ defaultPageSize: 10 }}
                      columns={this.state.columnData}
                      dataSource={this.state?.tableData}
                      className="table-striped-rows"
                    />

                  </Col>

                  <Col xs={0} sm={0} md={1} lg={1} />
                </Row>




              </Card>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const styles = {
  contentBlock: {
    height: "auto",
    padding: "40px",
    backgroundColor: "white",
    marginRight: "32px",
    border: "1px solid white",
    marginLeft: "106px",
    marginTop: "28px",
  },
  headingDiv: {
    width: "100 %",
    display: "flex",
    // justifyContent: "space-between",
    height: "60px",
    borderRadius: "6.4px",
    padding: "20px",
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    marginTop: "10px"
  },
  headingDivContent: {
    fontSize: "16px",
    fontWeight: 700,
  },
  firstDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  BudgetsBalance: {
    fontSize: "22px",
    fontWeight: 900,
    color: "#545861",
    lineHeight: 1.2,
  },
  exportButton: {
    fontSize: 16,
    fontWeight: 700,
    padding: "10px 32px",
    backgroundColor: "#f2f2f2",
    display: "flex",
    border: "none",
    borderRadius: "40px",
    alignItems: "center",
    cursor: "pointer",
  },
  Dashboard: {
    color: "#b3b3b3",
    fontSize: 16,
    fontWeight: 500,
  },
  secondDiv: {
    marginTop: "32px",
  },
  thirdDiv: {
    marginTop: "32px",
    height: "60px",
    borderRadius: "6.4px",
    padding: "20px",
    backgroundColor: "#f2f2f2",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  thirdDivContentDivs: {
    fontSize: 16,
    fontWeight: 700,
    color: "#000000",
  },
  fourthDiv: {
    marginTop: "10px",
  },
  addCategoryButton: {
    fontSize: 16,
    fontWeight: 700,
    padding: "10px 32px",
    backgroundColor: "#f2f2f2",
    display: "flex",
    border: "none",
    borderRadius: "40px",
    alignItems: "center",
    cursor: "pointer",
  },
  addTransactionButton: {
    fontSize: 16,
    borderRadius: "40px",
    fontWeight: 600,
    backgroundColor: "#2DADE1",
    padding: "20px",
    display: "flex",
    alignItems: "center",
  },
};
