//@ts-nocheck
import React from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Input,
  Row
} from "antd";
import { Redirect, withRouter } from "react-router-dom";
//@ts-ignore
import bgImage from "../assets/web_bg.png";
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
import {
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import axios from "axios";
import _ from "lodash";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import { openErrorNotification } from "../../../components/src/Notification.web";
const { Panel } = Collapse;
import ReviewCheckingController, { Props } from "./ReviewCheckingController";
export const configJSON = require("./config");

export class ReviewCheckingWeb extends ReviewCheckingController {
  constructor(props: Props) {
    super(props);
  }

  setNecessityTotal = (necessityTotal: any) => this.setState({ necessityTotal })
  setDiscretionaryTotal = (discretionaryTotal: any) => this.setState({ discretionaryTotal })
  setLoading = (loading: boolean) => this.setState({ loading })
  setRedirect = (redirect: boolean) => this.setState({ redirect })
  setTotalBalance = (totalBalance: any) => this.setState({ totalBalance })
  setData = (data: any) => this.setState({ data })

  getData = async () => {
    const data = {
      customerId: localStorage.getItem("customerId"),
      finicity_token: localStorage.getItem("finicityToken")
    };
    await axios.post(
      `${configJSON.baseURL}bx_block_dashboard/review_and_checking_list`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("loginToken"),
          finicity_customer_id: localStorage.getItem("finicityToken"),
          customerid: localStorage.getItem("customerId")
        },
      }
    ).then((res: any) => {
      this.setData(res.data);
      this.setDiscretionaryTotal(res.data.dashboardData[1].availableamount)
      this.setNecessityTotal(res.data.dashboardData[0].availableamount)
      this.setState(
        {
          reveniewData: res.data.dashboardData,
        },
      );
      this.totalBalance(localStorage.getItem("customerId"), localStorage.getItem("loginToken"), localStorage.getItem("finicityToken"));
    }).catch((err: any) => {
      console.log(err)
      openErrorNotification(err?.response?.data?.error, "error")
    })
  };

  handleInputChange = (item: any, e: any, dd: any) => {
    e.persist();
    const filterData = dd.sub_details.filter((data: any) => data.category_id !== item.category_id)
    const latestValue = parseFloat(e.target.value.replace(/\$|,/g, '')) ? parseFloat(e.target.value.replace(/\$|,/g, '')) : 0
    if (dd.category_name == "discretionary_expense") {
      const name = this.state.data.dashboardData.find((dd: any) => dd.category_name == "discretionary_expense")
      name.sub_details.filter((data: any) => data.category_id == item.category_id)[0].availableamount = latestValue
      this.setDiscretionaryTotal(_.sum(filterData.map((data: any) => data.availableamount)) + latestValue)

    }
    else {
      const name = this.state.data.dashboardData.find((dd: any) => dd.category_name == "necessity_expense")
      name.sub_details.filter((data: any) => data.category_id == item.category_id)[0].availableamount = latestValue
      this.setNecessityTotal(_.sum(filterData.map((data: any) => data.availableamount)) + latestValue)
    }
  };


  handleSubmit = async () => {
    this.setLoading(true);
    const values = _.unionBy(this.state.data?.dashboardData?.map((item: any) => (item.sub_details))[0], this.state.data?.dashboardData?.map((item: any) => (item.sub_details))[1], 'category_id');
    const cIds = values.map((dd: any) => dd.category_id)
    const cAmount = values.map((dd: any) => dd.availableamount)
    const datas = {
      "category_ids": cIds,
      "available_amounts": cAmount
    };
    const result = await axios.put(
      `${configJSON.baseURL}bx_block_categories/update_available_balance`,
      datas,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("loginToken"),
          "plaid-account-id": localStorage.getItem("plaid_acc_id"),
        },
      }
    ).then((res: any) => {
      this.setLoading(false);
      window.location.href = "/Dashboard";
    }).catch((err: any) => {
      this.setLoading(false);
      openErrorNotification(err?.response?.data?.error, "error")
    })

  }

  submitData = async () => {
    const amount = this.state.reveniewData.filter((value) => {
      return value.category_name === "necessity_expense"
    });
    const httpBody = {
      data: {
        title: "Take Home Pay",
        value: parseInt(localStorage.getItem("take_home_pay") || "0") - amount[0].budgeted_amount,
        account_id: localStorage.getItem("accountId"),
      },
    };
    localStorage.setItem("take_home_pay", JSON.stringify(parseInt(localStorage.getItem("take_home_pay") || "0") - amount[0].budgeted_amount))
    await axios.post(
      `${configJSON.baseURL}bx_block_dashboard/dashboards`,
      httpBody,
      {
        headers: {
          token: localStorage.getItem("loginToken"),
        },
      }
    ).then((res: any) => {
      this.props.history.push("/Dashboard")
    }).catch((err: any) => {
      console.log(err)
      openErrorNotification(err?.response?.data?.error, "error")
    })
  }

  async componentDidMount() {
    this.setLoading(true);
    await this.getData();
  }

  render() {
    return (
      <>
        {this.state.redirect ? (<Redirect push to="/EmailAccountLoginWeb" />) : null}
        <TopNav />
        {this.state.loading ?
          <LoadingWeb />
          : <Row
            align="middle"
            justify="center"
            style={{
              height: "100vh",
              width: "100vw",
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              position: "fixed",
              overflow: "auto",
              paddingBottom: "20px",
              marginBottom: "30px",
            }}
          >
            <Col xs={21} sm={17} md={14} lg={11} xl={10} style={{ marginBottom: "50px" }}>
              <Card style={{ marginBottom: "50px", marginTop: "50px" }}>
                <>
                  <Row
                    justify="center"
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bolder",
                      color: "rgb(104, 105, 108)",
                    }}
                  >
                    <Col span={24}>Review Checking And Savings</Col>
                  </Row>

                  <Row justify="center">
                    <Col
                      span={15}
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        lineHeight: "1.39",
                        letterSpacing: "-0.43px",
                        color: "#68696c",
                      }}
                    >
                      Please allocate every dollar from checking and savings accounts.
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: "16.2px 30.7px 15.2px 33px",
                      backgroundColor: "#e0e0e0",
                      fontWeight: "bold",
                    }}
                  >
                    <Col>Total Current Balance:</Col>
                    <Col
                      style={{
                        textAlign: "right",
                        color: "#00a950",
                        fontSize: "25px",
                      }}
                    >
                      ${this.state.TotalcurrentBalance}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5em" }}>
                    <Col span={24}>
                      <Row
                        justify="space-around"
                        style={{
                          fontWeight: "bolder",
                          backgroundColor: "#f2f2f2",
                          padding: "15px",
                          color: "black",
                        }}
                      >
                        <Col>Category</Col>
                        <Col>Amount</Col>
                      </Row>
                      <Row
                        justify="end"
                        style={{ color: "black", fontWeight: "bold", width: "90%" }}
                      >
                        <Col span={12} />
                        <Col span={6}>Budgeted</Col>
                        <Col span={6}>Available</Col>
                      </Row>

                      <Collapse
                        test-id="collapse"
                        accordion
                        bordered={false}
                        expandIconPosition="right"
                        onChange={() => console.log("Collapse")}
                      >
                        {this.state.data &&
                          this.state.data.dashboardData.map((dd: any) => (
                            <>
                              <Panel
                                test-id="panel"
                                header={
                                  <Row style={{ color: "black", fontWeight: "bold" }}>
                                    <Col span={12}>{dd.display_name}</Col>
                                    <Col span={6}> ${dd.budgeted_amount}</Col>
                                    <Col span={6} className="balance_text">
                                      ${dd.category_name == "necessity_expense" ? this.state.necessityTotal : this.state.discretionaryTotal}
                                      {/* ${dd.availableamount} */}
                                    </Col>
                                  </Row>
                                }
                                key={dd.category_id}
                              >
                                {dd.sub_details.map((item: any) => (
                                  <Row
                                    test-id="row-id"
                                    style={{
                                      padding: "10px",
                                      borderTop: "1px solid #d9d9d9",
                                    }}
                                  >
                                    <Col span={12}>{item.category_name}</Col>
                                    <Col span={6}> ${item.budgeted_amount}</Col>
                                    <Col span={6}>
                                      <Input
                                        className="balance_text"
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                        }}
                                        type="number"
                                        onChange={(e: any) => this.handleInputChange(item, e, dd)}
                                        placeholder={`$${item.availableamount}`}
                                      />
                                    </Col>
                                  </Row>
                                ))}
                              </Panel>
                            </>
                          ))}
                      </Collapse>
                      <Row
                        test-id="net-total-row"
                        style={{
                          paddingLeft: "16px",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        <Col span={11}>Net total current balance</Col>
                        <Col span={6}>
                          ${Math.abs(Number(this.state.netTotalBudgeted)).toFixed(2)}
                        </Col>
                        <Col span={6} className="balance_text" style={{ wordBreak: 'break-all', marginLeft: '-11px' }}>
                          ${Math.abs(Number(this.state.netTotalAvailable)).toFixed(2)}
                        </Col>
                      </Row>

                    </Col>
                  </Row>
                  <Row justify="space-between" style={{ marginTop: "2em" }}>
                    <Button
                      test-id="button-back"
                      shape="round"
                      style={{ backgroundColor: "#b3b3b3", color: "white" }}
                      onClick={() => window.location.href = "/BudgetDiscretioneryIncomesWeb"}
                      icon={<LeftOutlined translate />}
                    >
                      Back
                    </Button>
                    <Button
                      data-test-id={"button-dashboard"}
                      shape="round"
                      type="primary"
                      loading={this.state.loading}
                      onClick={() => this.submitData()}
                    >
                      SAVE & FINISH <RightOutlined translate />
                    </Button>
                  </Row>
                </></Card>
            </Col>
          </Row>
        } </>
    );
  }
};
// @ts-ignore
export default withRouter(ReviewCheckingWeb);
