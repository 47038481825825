import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import axios from "axios";
import { Image } from "antd";
import "antd/dist/antd.css";
import { Avatar } from "antd";
import { Menu, Button, Breadcrumb } from "antd";
import {
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  LogoutOutlined,
  SettingOutlined,
  DeliveredProcedureOutlined,
} from "@ant-design/icons";
// import "./assets/style.css";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import {Logo, applogo,dashboard,accounts ,budget,transcation,report,alert,setting,logout} from "./assets"; 
import "./style.css";
import { Link, Redirect } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";

const { SubMenu } = Menu;

export interface Props {
  // navigation: any;
  // id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  name: any;
  keyValue: any;
  previewImage: any;
  redirect: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}
class AppSidebar extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      name: "",
      keyValue: "1",
      previewImage: "",
      redirect: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  getProfileImage = async () => {
    const result = await axios.get(`${process.env.REACT_APP_API_PATH}/bx_block_profile/get_profile_image`,
      {
        headers: {
          token: localStorage.getItem("loginToken")
        }
      }
    ).then((result) => {
      this.setState({ previewImage: result?.data?.image_url }, () => {
        // localStorage.setItem("imagePic", result?.data?.image_url)
      })
    }).catch((err) => {
      err?.response?.status == 401 || 400 ? (localStorage.clear(),
        Cookies.remove("accountId"),
        Cookies.remove("name"),
        Cookies.remove("loginToken"),
        this.setState({ redirect: true })
        // this.context.router.history.push("/EmailAccountLoginWeb")  
      ) : ""

    })
  }

  async componentDidUpdate() {
    if (this.state.name !== localStorage.getItem("name")) {
      this.setState({ name: localStorage.getItem("name") })
    }
    // if (this.state.picture !== localStorage.getItem("imagePic")) {
    //   this.setState({ picture: localStorage.getItem("naimagePicme") })
    // }
  }

  onMenuClick(a: any, e: any) {
    this.setState({ keyValue: e.key })
  }

  render() {
    return (
      <>
        {this.state.redirect ? (<Redirect push to="/EmailAccountLoginWeb" />) : null}
        <div style={styles.appbar}>
          <div style={styles.logo}>
            <Link to="/Dashboard">
              <Image style={styles.img} src={Logo} preview={false} />
            </Link>
          </div>
          <Link to="/Contactus?profileTab=1" style={{ marginRight: 35 }}>
            <span
              style={{ marginRight: 15, color: "#ffffff", fontWeight: 600 }}
            >
              Hello {this.state.name}
            </span>
            {this.state.previewImage !== "" ?
              <Avatar src={this.state.previewImage} />
              :
              //@ts-ignore
              <Avatar icon={<UserOutlined translate />} />
            }
          </Link>
        </div>
        <div>
          <div style={{ width: 72 }}>
            <Menu
              defaultSelectedKeys={[this.state.keyValue]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              style={{
                height: "100vh",
                backgroundColor: "#54595F",
                top: "0",
                position: "fixed",
                width: "80px",
              }}
              onClick={(e) => this.onMenuClick.bind(this, e)}
            >
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                {" "}
                <img src={applogo} alt="" />{" "}
              </div>
              <Menu.Item
                className="abc"
                style={{ display: "grid", paddingLeft: "0", marginTop: "20px" }}
                key="1"
                icon={<img src={dashboard} style={styles.sidebarlogo} />}
              >
                <Link
                  to="/Dashboard"
                  style={{
                    fontSize: "9px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Dashboard
                </Link>
              </Menu.Item>
              <Menu.Item
                className="abc"
                style={{ display: "grid", paddingLeft: "0", marginTop: "20px" }}
                key="2"
                icon={<img src={accounts} style={styles.sidebarlogo} />}
              >
                <Link
                  to="/AccountDetailsWeb"
                  style={{
                    fontSize: 9,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Accounts
                </Link>
              </Menu.Item>
              <Menu.Item
                className="abc"
                style={{ display: "grid", paddingLeft: "0", marginTop: "20px" }}
                key="3"
                icon={<img src={budget} style={styles.sidebarlogo} />}
              >
                <Link
                  to="/BudgetWeb"
                  style={{
                    fontSize: "9px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Budget
                </Link>
              </Menu.Item>
              <Menu.Item
                className="abc"
                style={{ display: "grid", paddingLeft: "0", marginTop: "20px" }}
                key="4"
                icon={<img src={transcation} style={styles.sidebarlogo} />}
              >
                <Link
                  to="/Transaction"
                  style={{
                    fontSize: "9px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Transcations
                </Link>
              </Menu.Item>
              <Menu.Item
                className="abc"
                style={{ display: "grid", paddingLeft: "0", marginTop: "20px" }}
                key="5"
                icon={<img src={report} style={styles.sidebarlogo} />}
              >
                <Link
                  to="/StatisticsReports"
                  style={{
                    fontSize: "9px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Report
                </Link>
              </Menu.Item>
              <Menu.Item
                className="abc"
                style={{ display: "grid", paddingLeft: "0", marginTop: "20px" }}
                key="6"
                icon={<img src={alert} style={styles.sidebarlogo} />}
              >
                <Link
                  to="/AlertWeb"
                  style={{
                    fontSize: "9px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Alerts
                </Link>
              </Menu.Item>
              <div style={{
                position: "absolute",
                bottom: "32px",

              }}>
                <Menu style={{
                  backgroundColor: "#54595F",
                  width: "80px",
                }}>
                  <Menu.Item
                    className="abc"
                    style={{
                      display: "grid",
                      paddingLeft: "0",
                      marginTop: "20px",
                    }}
                    key="7"
                    icon={<img src={setting} style={styles.sidebarlogo} />}
                  >
                    <Link
                      to="/Contactus"
                      style={{
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Settings
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    className="abc"
                    style={{
                      display: "grid",
                      paddingLeft: "0",
                      marginTop: "20px",
                    }}
                    key="8"
                    icon={<img src={logout} style={styles.sidebarlogo} />}
                  >
                    <Link
                      to="/EmailAccountLoginWeb"
                      style={{
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        localStorage.clear(),
                          Cookies.remove("accountId"),
                          Cookies.remove("name"),
                          Cookies.remove("loginToken");
                      }}
                    >
                      Logout
                    </Link>
                  </Menu.Item>
                </Menu>
              </div>
            </Menu>
          </div>
        </div >
      </>
    );
  }
}

const styles = {
  appbar: {
    backgroundColor: "#00ADE2",
    display: "flex",
    alignItems: "center",
    height: 70,
    justifyContent: "space-between",
  },
  logo: {
    marginLeft: 100,
  },
  img: {
    width: "80px",
    height: "40px",
    filter: "brightness(0) invert(1)",
    // transform: "translate(70, 10)",
  },
  sidebarlogo: {
    margin: "0px 0px 0px 24px",
  },
};
// ReactDOM.render(<AppSidebar />, mountNode);
export default AppSidebar;
