//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import React, { Component } from 'react';
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import axios from "axios";
import { runEngine } from "../../../framework/src/RunEngine";
import Dashboard from "./Dashboard";
import { RightOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
// Customizable Area Start
import NavigationMenuAdapter from "../../adapters/src/NavigationMenuAdapter";
import { columnData } from "./AddNecessityExpensesCategoryController";
import { openNotification } from "../../../components/src/Notification.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    data: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    showCustom: any;
    dashboardData: any;
    token: string;
    errorMsg: string;
    loading: boolean;
    switchValue: boolean;
    reveniewData: any;
    accountId: string;
    userName: string;
    customer: string;
    isExpend: boolean;
    subDetails: any;
    tabData: any;
    drawerData: any;
    isOpen: boolean;
    tableData: any;
    columnData: any;
    fromDate: any;
    toDate: any;
    transactions: any;
    cardLoading: any,
    availableAmount: any,
    budgetedAmount: any,
    loadingback: any;
    overflow: any;
    zIndexing: any;
    progress: any;
    displayFlex: any;
    filterKeyword: any;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class MoneyReallocate extends BlockComponent<Props, S, SS> {
    apiDashboardItemCallId: string = "";
    dashboardApiiCallId: string = "";
    apiGetQueryStrinurl: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationRMessage)
        ];

        this.state = {
            showCustom: false,
            dashboardData: [],
            errorMsg: "",
            token: "",
            loading: false,
            switchValue: true,
            cardLoading: true,
            accountId: "",
            userName: "Guest",
            reveniewData: [],
            customer: "",
            isExpend: false,
            subDetails: [],
            tabData: null,
            drawerData: null,
            isOpen: false,
            availableAmount: "",
            budgetedAmount: "",
            fromDate: "",
            toDate: "",
            transactions: [],
            displayFlex: "flex",
            progress: 0,
            loadingback: "none",
            overflow: "hidden",
            zIndexing: 2,
            filterKeyword: "",
            columnData: columnData,
            tableData: []
        }

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.onMount();
        // this.getHistoryForDashboard();
        // var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
        // this.send(authTokenReq);
        // if (!this.state.tabData) {
        //     const navigationMenuAdapter = new NavigationMenuAdapter();
        //     const uploadData = {
        //         data: "Needed",
        //     };

        //     const msg: Message = new Message(
        //         getName(MessageEnum.NavigationTabMenuMessage)
        //     );
        //     msg.addData(
        //         getName(MessageEnum.NavigationTMessage),
        //         uploadData
        //     );

        //     console.log("Dashboard Broadcasting message");
        //     runEngine.sendMessage(msg.id, msg);
        // }
    }

    keywordFilter = (values: any) => {
        console.log("Filter", values)
        if (values == "custom") {
            this.setState({ showCustom: true })
        } else {
            this.setState({ filterKeyword: values, showCustom: false, cardLoading: true }, () => {
                this.onMount();
            })
        }
    }
    handlerCustomDate = (dates: any, dateString: any) => {
        console.log("Dates", dates)
        console.log("Dates dateString", dateString)
        this.setState({ fromDate: dateString[0], toDate: dateString[1], cardLoading: true }, () => {
            this.getAllCustomTransaction(this.state.fromDate, this.state.toDate);
        })
    }

    getAllCustomTransaction = async (fromDate: any, toDate: any) => {
        const result = await axios.get(
            `${configJSON.baseURL}bx_block_categories/history_for_budgets?fromDate=${fromDate}&toDate=${toDate}&category_id=1`,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "plaid-account-id": localStorage.getItem("plaid_acc_id"),
                    "access-token": localStorage.getItem("access_token"),
                    "token": localStorage.getItem("loginToken")
                },
            }
        ).then((response: any) => {
            const data = response?.data?.data?.map((values: any) => ({
                key: values.updated_at,
                account: <div>Account {values.account_id}
                    <br />
                    <span>{values.transaction_date}</span>
                </div>,
                desc: values.description == null ? "No Description" : values.description,
                available: values.availableamount,
                budgeted: values.budgeted_amount,
            }))
            this.setState({
                tableData: data, cardLoading: false, showCustom: false
            })
        }).catch((error: any) => {
            if (error.response.status == "404") {
                this.setState({ cardLoading: false });
                openNotification(error?.response?.data?.error, "error")

            }
        });
    }

    // getHistoryForDashboard = async () => {
    //     // this.setState({ loading: true, loadingback: "#000000eb" }, () => {
    //     //     document.body.style.overflow = `${this.state.overflow}`
    //     // })
    //      await axios.get(
    //         `${process.env.REACT_APP_API_PATH}/bx_block_categories/history_for_budgets?category_id=${localStorage.getItem("category_id") || ""}&keyword=${this.state.filterKeyword}&token=${localStorage.getItem("loginToken")}`,
    //         {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //                 "plaid-account-id": localStorage.getItem("plaid_acc_id"),
    //                 "access-token": localStorage.getItem("access_token"),
    //                 "token": localStorage.getItem("loginToken")
    //             },
    //         }
    //     ).then((response: any) => {
    //            console.log("Res")
    //     }).catch((error: any) => {
    //         if (error.response.status == "404") {
    //             this.setState({ cardLoading: false });
    //             openNotification(error?.response?.data?.error, "error")

    //         }
    //     });
    // }

    onMount = async () => {
        this.setState({ cardLoading: true })
        const result = await axios.get(
            `${configJSON.baseURL}/bx_block_categories/history_for_budgets?category_id=${localStorage.getItem("category_id")}&keyword=${this.state.filterKeyword}`,
            {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "token": localStorage.getItem("loginToken"),
                    "plaid-account-id": localStorage.getItem("plaid_acc_id"),
                },
            }
        ).then((response: any) => {
            let available_amount = response.data.availableamount;
            let budgeted_amount = response.data.budgeted_amount;
            this.setState({ availableAmount: available_amount })
            this.setState({ budgetedAmount: budgeted_amount })
            const data = response.data.data?.map((values: any) => ({
                key: values.updated_at,
                category: values.category_name,
                available: values.availableamount,
                budgeted: values.budgeted_amount,
            }))
            this.setState({
                tableData: data,
                cardLoading: false
            })
        }).catch((error: any) => {
            if (error.response.status == "404") {
                this.setState({ cardLoading: false });
                openNotification(error?.response?.data?.error, "error")

            }
        });
    };

    // getDashboardData(): boolean {
    //     // Customizable Area Start
    //     const reveniewtUrl = `bx_block_dashboard/get_savings_account_det?customerId=${this.state.customer}`
    //     const header = {
    //         "Content-Type": configJSON.necessityContentType,
    //         token: this.state.token
    //     };
    //     console.log("header  ", JSON.stringify(header));
    //     const httpBody = {
    //         "data":
    //         {
    //             "account_id": String(this.state.accountId),
    //         },
    //     };
    //     const requestMessage = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );
    //     console.log("requestMessage, ", requestMessage);
    //     this.dashboardApiiCallId = requestMessage.messageId;
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         reveniewtUrl
    //     );

    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         JSON.stringify(header)
    //     );
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestBodyMessage),
    //         JSON.stringify(httpBody)
    //     );
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         configJSON.necessityApiMethodType
    //     );
    //     console.log('requestMessage@, ', requestMessage);
    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    //     // Customizable Area End
    //     return true;
    // }

    // async receive(from: string, message: Message) {
    //     // Customizable Area Start
    //     console.log("receive...");
    //     //runEngine.debugLog("Message Recived", message);
    //     if (getName(MessageEnum.NavigationRMessage) === message.id) {
    //         const data = message.getData(getName(MessageEnum.NavigationTabDataMessage));
    //         this.setState({ tabData: data.data[0].data.attributes, drawerData: data.data[1].data.attributes });
    //     }

    //     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    //         //runEngine.debugLog("Message Recived", message);
    //         let token = message.getData(getName(MessageEnum.SessionResponseToken));
    //         let accountId = message.getData(getName(MessageEnum.SessionResponseData));
    //         console.log("account" + accountId)
    //         let obj = JSON.parse(accountId);
    //         this.setState({ customer: obj.data.finicity_customer_id });
    //         this.setState({ accountId: obj.data.id });
    //         //console.log("userNamemmmm"+obj.data.first_name);
    //         if (obj.data.first_name !== null) {
    //             this.setState({ userName: obj.data.first_name });
    //         }
    //         this.setState({ token: token, loading: true }, () => {
    //             this.getDashboardData();
    //         });
    //     }

    //     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    //         var responseJson = message.getData(
    //             getName(MessageEnum.RestAPIResponceSuccessMessage)
    //         );
    //         const apiRequestCallId = message.getData(
    //             getName(MessageEnum.RestAPIResponceDataMessage)
    //         );

    //         var errorReponse = message.getData(
    //             getName(MessageEnum.RestAPIResponceErrorMessage)
    //         );
    //         if ((apiRequestCallId === this.dashboardApiiCallId)) {
    //             if (responseJson && !responseJson.errors && responseJson.dashboardData) {
    //                 console.log("responseJson  Dashboard ", JSON.stringify(responseJson));
    //                 if (responseJson.dashboardData.length === 0) {
    //                     this.setState({
    //                         errorMsg: "Data Not Found",
    //                         loading: false
    //                     });
    //                 } else {
    //                     this.setState({
    //                         reveniewData: responseJson.dashboardData,
    //                         errorMsg: "",
    //                         loading: false
    //                     });
    //                     //const getcata= new Dashboard(this.props);
    //                     this.getCategories();
    //                 }
    //             } else {
    //                 var errorReponse = message.getData(
    //                     getName(MessageEnum.RestAPIResponceErrorMessage)
    //                 );
    //                 console.log("error resp", errorReponse);
    //                 if (errorReponse === undefined) {
    //                     this.setState({
    //                         errorMsg: "Something went wrong",
    //                         loading: false
    //                     });
    //                 } else {
    //                     this.setState({
    //                         errorMsg: errorReponse,
    //                         loading: false
    //                     });
    //                 }
    //             }
    //         }
    //     }
    // }
    // getCategories() {
    //     throw new Error("Method not implemented.");
    // }
    // // Customizable Area Start
    // getAsset = (item: any): any => {
    //     switch (item.icon) {
    //         case "Account":
    //             return require("../assets/Componente33x.png");
    //         case "Budget":
    //             return require("../assets/Componente43x.png");
    //         case "Transaction":
    //             return require("../assets/Componente53x.png");
    //         case "Reports":
    //             return require("../assets/Componente63x.png");
    //         default:
    //             return require("../assets/Componente73x.png");
    //     }
    // }

    // nav = (item: any) => {
    //     switch (item.icon) {
    //         case "Account":
    //             this.onPressAccounts();
    //             break;
    //         case "Budget":
    //             this.onPressBudgets();
    //             break;
    //         case "Transaction":
    //             this.onPressTrans();
    //             break;
    //         case "Reports":
    //             this.onPressReports();
    //             break;
    //         case "Settings":
    //             this.onPressSettings();
    //             break;
    //         case "Contactus":
    //             this.onPressContactus();
    //             break;
    //         case "Profile":
    //             this.onPressProfile();
    //             break;
    //         case "Dashboard":
    //             this.onPressDashboard();
    //             break;
    //         default:
    //             console.log("Not navigating");
    //     }
    // }

    // onPressBudgets = () => {
    //     this.props.navigation.navigate("Budget")
    // }
    // onPressReports = () => {
    //     this.props.navigation.navigate("StatisticsReports")
    // }
    // onPressAccounts = () => {
    //     this.props.navigation.navigate("AccountsPage")
    // }
    // onPressTrans = () => {
    //     this.props.navigation.navigate("Transaction")
    // }
    // onPressmovemoney = () => {
    //     this.props.navigation.navigate("MovemoneyFrom")
    // }
    // onPressSettings = () => {
    //     this.props.navigation.navigate("Settings");
    // }
    // onPressContactus = () => {
    //     this.props.navigation.navigate("Contactus");
    // }
    // onPressProfile = () => {
    //     this.props.navigation.navigate("UserProfileBasicBlock");
    // }
    // onPressDashboard = () => {
    //     this.setState({ isOpen: false });
    // }

    // Customizable Area End
}
