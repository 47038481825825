//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import React from "react";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RightOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
// Customizable Area Start
import { Select } from "antd";

// Customizable Area End
const { Option } = Select;

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    data: any;
    splitTransactionResRedux: any;
    reallocateFromData: any;
    reallocateToDataRedux: any;
    transactionReponse: any;
    amount: any;
    route: any;
    params: any;
    location: any;

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isExpend: boolean;
    subDetails: any;
    dashboardData: any;
    token: string;
    errorMsg: string;
    loading: boolean;
    switchValue: boolean;
    tableData: any;
    reveniewData: any;
    accountId: string;
    userName: string;
    customer: string;
    tableData2: any;
    columnData3: any;
    tabData: any;
    drawerData: any;
    isOpen: boolean;
    tableData3: any;
    columnData4: any;
    reallocateData: any;
    tableData4: any;
    active2: any;
    amount: any;
    responseData: any;
    confirmButton: any;
    active: any;
    overflow: any;
    zIndexing: any;
    reallocateFromData: any;
    reallocateToData: any;
    collapseData: any;
    loadingback: any;
    split_from: any;
    split_to: any;
    progress: any;
    showModalCategory: any;
    
    // Customizable Area End
}
interface SS {
    id: any;
}
export default class ConfirmSplitTransactionController extends BlockComponent<Props, S, SS> {
    dashboardApiiCallId: string = "";
    apiDashboardItemCallId: string = "";
    apiGetQueryStrinurl: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationRMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            
        ];

        this.state = {
            dashboardData: [],
            collapseData: {},
            errorMsg: "",
            token: "",
            loading: false,
            switchValue: true,
            accountId: "",
            userName: "Guest",
            reveniewData: [],
            customer: "",
            isExpend: false,
            subDetails: [],
            tabData: null,
            drawerData: null,
            isOpen: false,
            progress: 0,
            showModalCategory: false,
            active: <Checkbox />,
            split_from: [],
            split_to: [],
            tableData: [],          
            tableData2: [
                {
                    category: 'Vegetables',
                    amount: '$500',
                },
                {
                    category: 'Toys',
                    amount: '$236',
                },
                {
                    category: 'Others',
                    amount: '$269',
                },
            ],
            columnData3: [
                {
                    title: "Category",
                    dataIndex: "category",
                    key: "category",
                    render: (confirmsplittext3: any) => (
                        <a
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: " #000000",
                            }}
                        >
                            {confirmsplittext3}
                        </a>
                    ),
                },
                {
                    title: "Amount",
                    dataIndex: "amount",
                    key: "amount",
                    render: (confirmsplittext3: any) => (
                        confirmsplittext3 < 0 ? <span style={{ fontSize: 16, fontWeight: "bold", color: " red" }}>{confirmsplittext3}</span> : <span style={{ fontSize: 16, fontWeight: "bold", color: " #00a950" }}>{confirmsplittext3}</span>
                    ),
                },
            ],
            tableData3: [],
            columnData4: [
                {
                    title: "Category",
                    dataIndex: "category",
                    key: "category",
                    render: (confirmsplittext4: any) => (
                        <a
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: " #000000",
                            }}
                        >
                            {confirmsplittext4}
                        </a>
                    ),
                },
                {
                    title: "Amount",
                    dataIndex: "amount",
                    key: "amount",
                    render: (confirmsplittext6: any) => (
                        confirmsplittext6 < 0 ? <span style={{ fontSize: 16, fontWeight: "bold", color: " red" }}>{confirmsplittext6}</span> : <span style={{ fontSize: 16, fontWeight: "bold", color: " #00a950" }}>{confirmsplittext6}</span>
                    ),
                },
            ],
            tableData4: [],
            confirmButton: "#b3b3b3",
            reallocateData: [],
            reallocateFromData: {},
            reallocateToData: 1,
            active2: <RightOutlined translate rev={undefined} />,
            amount: 1,
            responseData: {},
            loadingback: "none",
            overflow: "hidden",
            zIndexing: 2
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        let split_from = this.props.splitTransactionResRedux?.split_from?.map((values: any) => ({
            category: values.transaction_from_sub_category,
            amount: values.transaction_from_amount
        }))
        let split_to = this.props.splitTransactionResRedux?.split_to?.map((values: any) => ({
            category: values.transaction_to_sub_category,
            amount: values.transaction_to_amount
        }))
        this.setState({tableData3:split_from,tableData4:split_to})
    }
  
    // Customizable Area Start
    // Customizable Area End
}



