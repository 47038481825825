//@ts-nocheck
import React from 'react';
import 'antd/dist/antd.css';
import '../assets/index.css';
import MoneyReallocateController, { Props, configJSON } from "./MoneyReallocateController";
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import SideBar from "../../../components/src/SideBar";
import { DownOutlined, RightOutlined } from '@ant-design/icons';
//@ts-ignore
import { reallocateFromData, reallocateToData, amount } from "../../../web/src/redux/actions/users";
import { Form, Select, Card, Table, Input, Modal, Button, Row, Col } from "antd";
import "./style.css";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
const { Option } = Select;
export class MoneyReallocate extends MoneyReallocateController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End

    }

    returnContinueButton() {
        if (this.state.continueBack == "#31ADE2") {
            return false;
        } else {
            return true;
        }
    }

    returnRedirect(redirect: any) {
        if (redirect) {
            return <Redirect push to="/ConfirmPayment" />;
        } else {
            return null
        }
    }

    returnShowData(showData: any) {
        if (showData) {
            return (
                this.state.showData.map((values: any) => (
                    <Option key={values?.attributes?.id} value={values?.attributes?.generic_name}>{values?.attributes?.generic_name}</Option>
                ))
            )
        }
    }

    returnCollapseData(collapseData: any, record: any) {
        if (collapseData) {
            return this.state.collapseData[record.key]
        }
    }

    returnTableData(data: any) {
        if (data) {
            return (<Table
                pagination={false}
                dataSource={data}
                columns={this.state.collapseDataColumn}
                scroll={{ y: 240 }}
            />)
        } else {
            return <p>{configJSON.noData}</p>;
        }
    }

    returnTableData2(data: any) {
        if (data) {
            return (<Table
                pagination={false}
                dataSource={data}
                columns={this.state.collapseDataColumn1}
                scroll={{ y: 240 }}
            />)
        } else {
            return <p>{configJSON.noData}</p>;
        }
    }

    returnExpandTable(expanded: any, onExpand: any, record: any) {
        if (expanded) {
            return (
                //@ts-ignore
                <DownOutlined
                    onClick={e => onExpand(record, e)}
                    translate />
            )
        } else {
            return (
                //@ts-ignore
                < RightOutlined
                    style={styles.rightArrow}
                    onClick={e =>
                        onExpand(record, e)}
                    translate
                />
            )
        }
    }

    setOnChangeValue(e: any) {
        this.setState({
            amount: parseFloat(e.target.value)
        })

        if (e.target.value !== '') {
            this.setState({
                confirmButton: "#00ade2"
            })
        } else {
            this.setState({
                confirmButton: "#b3b3b3"
            })
        }
    }

    returnLoading(cardLoading: any) {
        if (cardLoading) {
            return <LoadingWeb />;
        } else {
            return (
                <>
                    <Col xs={24} xl={16}>
                        <Card style={{ margin: "1.5rem", marginTop: "35px" }}>
                            <Row style={styles.contentBlock1}>

                                <Col xs={24} sm={12}>
                                    <h1
                                        style={styles.BudgetsBalance}
                                    >
                                        {configJSON.reallocateMoney}
                                    </h1>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Row align="middle" justify="end"><button
                                        onClick={this.showCategory}
                                        style={styles.addCategoryButton}
                                    >
                                        <span>
                                            {configJSON.addCategoryCaps}
                                        </span>
                                    </button></Row>
                                </Col>
                                <Col xs={24}>
                                    <Modal
                                        visible={this.state.showModalCategory}
                                        onCancel={this.handleCancel}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    padding: "0px 60px"
                                                }}
                                            >
                                                <h1
                                                    style={styles.addCategoryHeading}
                                                >
                                                    {configJSON.addCategory}
                                                </h1>
                                                <Form onFinish={this.addCategory}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item>
                                                                <Select
                                                                    size="large"
                                                                    placeholder="Select Category"
                                                                    allowClear onChange={this.change}>
                                                                    {this.returnShowData(this.state.showData)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Button
                                                        type="primary"
                                                        style={{
                                                            width: "300px",
                                                            borderRadius: 20,
                                                            marginTop: 30,
                                                            marginBottom: 30,
                                                            backgroundColor: "#2EADE2"
                                                        }}
                                                        htmlType="submit"
                                                    >
                                                        <h4
                                                            style={{
                                                                color: "white",
                                                                fontWeight: 700
                                                            }}
                                                        >
                                                            {configJSON.saveCaps}
                                                        </h4>
                                                    </Button>
                                                </Form>
                                            </div>
                                        </div>
                                    </Modal>
                                </Col>

                                <Col xs={24} lg={11}
                                    xl={11}

                                    style={{ width: "48%" }}>
                                    <div
                                        style={styles.reallocateFrom}>
                                        {configJSON.reallocateFrom}
                                    </div>
                                    <Row style={styles.headingDiv}>
                                        <Col span={24}>
                                            <Row>
                                                <Col
                                                    span={12}
                                                    style={styles.headingDivContent}
                                                >
                                                    {configJSON.category}
                                                </Col>
                                                <Col
                                                    span={12}
                                                    style={styles.headingDivContent}
                                                >
                                                    {configJSON.balance}
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                    <Table
                                        pagination={{ defaultPageSize: 10 }}
                                        columns={this.state.columnData}
                                        dataSource={this.state.tableData}
                                        scroll={{ x: 650 }}
                                        expandable={{
                                            expandIconColumnIndex: 5,
                                            expandedRowRender: record => {
                                                const data = this.returnCollapseData(this.state.collapseData, record);

                                                return (
                                                    <>
                                                        {this.returnTableData(data)}
                                                    </>
                                                );
                                            }
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            this.returnExpandTable(expanded, onExpand, record)}
                                        data-test-id={"reallocate-from-table"}
                                    />
                                </Col>
                                <Col lg={1} xl={1} />
                                <Col xs={24} lg={11}
                                    xl={11}
                                    style={{
                                        width: "48%",
                                        // marginRight: 20
                                    }}>
                                    <div style={styles.reallocateTo}>
                                        {configJSON.reallocateTo}
                                    </div>
                                    <Row style={styles.headingDiv}>
                                        <Col span={24}>
                                            <Row>
                                                <Col
                                                    span={12}
                                                    style={styles.headingDivContent}
                                                >
                                                    {configJSON.category}
                                                </Col>
                                                <Col
                                                    span={12}
                                                    style={styles.headingDivContent}
                                                >
                                                    {configJSON.balance}
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                    <Table

                                        pagination={{ defaultPageSize: 10 }}
                                        columns={this.state.columnData2}
                                        dataSource={this.state.tableData2}
                                        scroll={{ x: 650 }}
                                        expandable={{
                                            expandIconColumnIndex: 5,
                                            expandedRowRender: record => {
                                                const data = this.returnCollapseData(this.state.collapseData, record);

                                                return (
                                                    <>
                                                        {this.returnTableData2(data)}
                                                    </>
                                                );
                                            }
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            this.returnExpandTable(expanded, onExpand, record)}
                                        data-test-id={"reallocate-to-table"}
                                    />

                                </Col>
                                <Col lg={14} sm={2} xl={13} xxl={15} md={11} />
                                <Col xs={24} sm={10} md={6} lg={5} xxl={4} xl={5}
                                    style={{
                                        marginTop: "18px"

                                    }}
                                ><Row align="middle" justify="center" >
                                        <Button
                                            shape="round"
                                            style={styles.backButton}
                                            onClick={() => window.history.back()}
                                            data-test-id={"back-button"}
                                        >
                                            {configJSON.back}
                                        </Button> </Row>
                                </Col>
                                <Col xs={24} sm={10} md={6} lg={4} xxl={5} xl={6} style={{
                                    marginTop: "18px"

                                }}>
                                    <Row align="middle" justify="center">
                                        <Button
                                            shape="round"
                                            disabled={this.returnContinueButton()}
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 700,
                                                backgroundColor: `${this.state.continueBack}`,
                                                color: "#ffffff",
                                                border: "none",
                                                height: "41px",
                                                width: "140px",

                                            }}
                                            onClick={() => {
                                                if (!this.state.tableData3.includes(this.state.reallocateFromData)) {
                                                    this.setState({
                                                        tableData3: [...this.state.tableData3, this.state.reallocateFromData]
                                                    })
                                                }
                                                if (!this.state.tableData4.includes(this.state.reallocateToData)) {
                                                    this.setState({
                                                        tableData4: [...this.state.tableData4, this.state.reallocateToData]
                                                    })
                                                }
                                                localStorage.setItem("reallocate_from", JSON.stringify(this.state.reallocateFromData));
                                                localStorage.setItem("reallocate_to", JSON.stringify(this.state.reallocateToData));
                                                this.props.reallocateFromDataRedux(this.state.reallocateFromData);
                                                this.props.reallocateToDataRedux(this.state.reallocateToData)
                                            }}
                                            data-test-id={"continue-button"}
                                        >
                                            {configJSON.continue}
                                        </Button>
                                    </Row>

                                </Col>
                                <Col md={1} sm={2} />
                            </Row >
                        </Card>
                    </Col>
                    {/* <Col xs={1} sm={1} md={1} lg={1} xl={1}/>
                                <Col xs={1} sm={1} md={1} lg={1} xl={0}/> */}
                    <Col xs={24} xl={8}>
                        <Card style={{ margin: "1.5rem", marginTop: "35px" }}>
                            <Row style={styles.contentBlock2}>
                                <Col xs={24}
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: 900,
                                        color: "#545861",
                                        padding: "0px 20px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    {configJSON.confirmTransaction}
                                </Col>

                                <Col xs={24} xl={24} xxl={24} lg={11}

                                >
                                    <Col span={24} style={styles.ConfirmTransactionDetails}> Reallocate From </Col>
                                    <Table
                                        style={{
                                            // padding: " 0px 20px"
                                        }}
                                        scroll={{ x: 650 }}
                                        pagination={false}
                                        columns={this.state.columnData3}
                                        dataSource={this.state.tableData3}
                                    />
                                </Col>


                                <Col lg={1} />
                                <Col xs={24} xl={24} lg={11} xxl={24}

                                >
                                    <Col span={24} style={styles.ConfirmTransactionDetails}> Reallocate To</Col>
                                    <Table
                                        style={{
                                            // padding: " 0px 20px"
                                        }}
                                        pagination={false}
                                        scroll={{ x: 650 }}
                                        columns={this.state.columnData4}
                                        dataSource={this.state.tableData4}
                                    />
                                </Col>

                                <Col xs={0} lg={12} sm={0} xl={0} md={0} xxl={0} />
                                <Col xs={24} lg={11} md={24} xl={24}
                                    style={styles.ConfirmTransactionDetails}
                                >
                                    {configJSON.amount}
                                </Col>
                                <Col lg={1} />
                                <Col xs={0} lg={12} sm={0} xl={0} md={0} xxl={0} />
                                <Col xs={24} sm={24} lg={12} md={24} xl={24}>
                                    <Row
                                        style={{
                                            marginTop: "24px"
                                        }}
                                    >
                                        <Col span={24}
                                            style={{
                                                fontSize: "12px",
                                                color: "#999999"
                                            }}
                                        >
                                            {configJSON.amount}
                                        </Col>
                                        <Col xs={15} xl={24}>
                                            <Input
                                                placeholder="Enter Amount"
                                                style={{
                                                    border: "1px solid #00ade2",
                                                    // width:"122px"
                                                }}
                                                onChange={(e) => this.setOnChangeValue(e)}
                                                data-test-id={"input-amount"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>


                                <Col lg={14} sm={2} xl={0} md={12} xxl={8} />
                                <Col xs={24} md={5} sm={10} lg={5} xxl={7} xl={12} style={{ marginTop: "18px", }}>
                                    <Row align="middle" justify="center" ><Button
                                        shape="round"
                                        style={styles.editButton}
                                    >
                                        {configJSON.edit}
                                    </Button></Row>
                                </Col>
                                <Col xs={24} md={6} sm={10} lg={4} xxl={9} xl={12} style={{ marginTop: "18px" }}>
                                    <Row align="middle" justify="center" >
                                        <Button
                                            shape="round"
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 700,
                                                backgroundColor: this.state.confirmButton,
                                                height: "41px",
                                                width: "140px",
                                                color: "#ffffff",
                                                border: "none",

                                            }}
                                            onClick={() => {
                                                console.log(this.state.reallocateFromData, this.state.reallocateToData, this.state.amount)
                                                localStorage.setItem("reallocate_from", JSON.stringify(this.state.reallocateFromData));
                                                localStorage.setItem("reallocate_to", JSON.stringify(this.state.reallocateToData));
                                                localStorage.setItem("reallocate_amount", this.state.amount)
                                                this.moveMoneyTransaction();
                                                this.props.amount(this.state.amount)
                                            }}
                                            data-test-id={"confirm-payment-button"}
                                        >
                                            {configJSON.confirm}
                                        </Button></Row>

                                </Col>
                                <Col md={1} sm={2} />
                            </Row>
                        </Card>
                    </Col>
                </>
            )
        }
    }

    render() {

        return (
            <div>
                {this.returnRedirect(this.state.redirect)}
                <div><SideBar /></div>
                <Row style={{ marginLeft: "79px", display: "flex", flexWrap: "wrap" }}>
                    {this.returnLoading(this.state.cardLoading)}
                </Row>
            </div >
        )
    }
}

const styles = {

    contentBlock1: {
        height: "auto",
        // padding: "40px",
        backgroundColor: "white",
        marginTop: "20px"
    },
    contentBlock2: {
        height: "auto",
        // padding: " 20px 40px",
        // width: "370px",
        // marginBottom: "42px",
        marginTop: "20px"

    },
    headingDiv: {
        width: "100 %",
        display: "flex",
        height: "60px",
        borderRadius: "6.4px",
        marginTop: "38px",
        padding: "20px",
        backgroundColor: "#f2f2f2",
        alignItems: "center"
    },
    headingDivContent: {
        fontSize: "16px",
        fontWeight: 700
    },
    firstDiv: {
        backgroundColor: "white",
        // display: "flex",
        // justifyContent: "space-between",
        // alignItems: "center"
    },
    BudgetsBalance: {
        fontSize: "22px",
        fontWeight: 900,
        color: "#545861",
        lineHeight: 1.2,
    },
    Dashboard: {
        color: "#b3b3b3",
        fontSize: 16,
        fontWeight: 500,
    },
    ConfirmTransactionDetails: {
        fontSize: 16,
        fontWeight: 700,
        borderRadius: "6.4px",
        padding: "10px 20px",
        backgroundColor: "#f2f2f2",
        alignItems: "center",
        marginTop: "20px",

    },
    editButton: {
        fontSize: 16,
        fontWeight: 700,
        backgroundColor: "#f2f2f2",

        color: "#00ade2",

        border: "none",
        height: "41px",
        width: "140px",
    },
    addCategoryButton: {
        fontSize: 16,
        fontWeight: 700,
        padding: "10px 20px",
        backgroundColor: "#f2f2f2",
        display: "flex",
        color: "rgb(45, 173, 225)",
        border: "none",
        borderRadius: "40px",
        alignItems: "center",
        cursor: "pointer"
    },
    relocateToButtons: {
        fontSize: 16,
        fontWeight: 700,
        backgroundColor: "#b3b3b3",

        color: "#ffffff",
        border: "none",
        height: "41px",
        width: "140px",


    },
    backButton: {
        backgroundColor: "#31ADE2",
        fontSize: 16,
        fontWeight: 700,
        color: "#ffffff",
        border: "none",
        height: "41px",
        width: "140px",
    },
    addCategoryHeading: {
        fontSize: 32,
        color: "#545861",
        fontWeight: 700,
        display: "flex",
        justifyContent: "center"
    },
    reallocateFrom: {
        fontSize: "22px",
        fontWeight: 900,
        color: "#545861",
        marginTop: "24px",
        lineHeight: 1.2,
        // marginBottom: 20
    },
    reallocateTo: {
        fontSize: "22px",
        fontWeight: 900,
        color: "#545861",
        marginTop: "24px",
        lineHeight: 1.2,
        marginBottom: 25
    },
    rightArrow: {
        backgroundColor: "#f2f2f2",
        height: "28px",
        width: "28px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#2dade1"
    }
}
export let mapStateToProps = (state: any) => {
    return {
        reallocateFromData: state.users.reallocateFromData,
    };
};
export const mapDispatchToProps = (dispatch: any) => {
    return {
        reallocateFromDataRedux: (data: any) => dispatch(reallocateFromData(data)),
        reallocateToDataRedux: (data: any) => dispatch(reallocateToData(data)),
        amount: (data: any) => dispatch(amount(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MoneyReallocate);
