//@ts-nocheck
import React from 'react';
import { DeleteOutlined } from "@ant-design/icons";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import axios from "axios";
import moment from "moment";
import { Link } from 'react-router-dom';
import { openNotification } from "../../../components/src/Notification.web";
//@ts-ignore
import spliticon from "../assets/split.jpg";

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    data: any;
    history:any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    dashboardData: any;
    token: string;
    cardLoading: any;
    categoryData: any;
    errorMsg: string;
    loading: boolean;
    openAddModal: boolean;
    openDeleteModal: boolean;
    switchValue: boolean;
    reveniewData: any;
    accountId: string;
    userName: string;
    customer: string;
    isExpend: boolean;
    subDetails: any;
    tabData: any;
    drawerData: any;
    isOpen: boolean;
    tableData: any;
    columnData: any;
    loadingback: any;
    overflow: any;
    zIndexing: any;
    progress: any;
    showModalCategory: any;
    filterKeyword: any;
    showCustom: any;
    displayFlex: any;
    large: any;
    fromDate: any;
    toDate: any;
    tableDataEx: any;
    selectedValue:any;
    // addCategory: any;
    // getAllCategoryApi: any;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class TransactionController extends BlockComponent<Props, S, SS> {
    apiDashboardItemCallId: string = "";
    dashboardApiiCallId: string = "";
    apiGetQueryStrinurl: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationRMessage),
        ];

        this.state = {
            fromDate: "",
            toDate: "",
            showCustom: false,
            large: "large",
            cardLoading: true,
            displayFlex: "flex",
            dashboardData: [],
            categoryData: [],
            errorMsg: "",
            token: "",
            loading: false,
            openAddModal: false,
            openDeleteModal: false,
            switchValue: true,
            accountId: "",
            userName: "Guest",
            reveniewData: [],
            customer: "",
            isExpend: false,
            subDetails: [],
            tabData: null,
            drawerData: null,
            isOpen: false,
            loadingback: "none",
            overflow: "hidden",
            zIndexing: 2,
            progress: 0,
            showModalCategory: false,
            filterKeyword: "month_to_date",
            selectedValue:"month_to_date",
            columnData: [
                {
                    title: "",
                    dataIndex: "transaction_date",
                    width: "37%",
                    key: "transaction_date",
                    render: (text: any) => (
                        <a
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: " #000000",
                            }}
                        >
                            {text}
                        </a>
                    ),
                },
                {
                    title: "",
                    dataIndex: "desc",
                    key: "desc",
                    width: "37%",
                    render: (text: any) => (
                        <a
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: " #000000",
                            }}
                        >
                            {text}
                        </a>
                    ),
                },
                {
                    title: "",
                    dataIndex: "amount",
                    key: "amount",
                    width: "14%",
                    render: (text: any) => (
                        text < 0 ? <span style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: " red"
                        }}>{text}</span>
                            : <span style={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: " #00a950"
                            }}>
                                {text}
                            </span>
                    ),
                },

                {
                    title: "",
                    dataIndex: "arrrow",
                    width: "14%",
                    key: "arrow",
                    render: (text: any, record: any) => (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly"
                            }}
                        >
                            <Link
                                style={{
                                    height: 40,
                                    width: 40,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#f2f2f2",
                                    borderRadius: 6
                                }}
                                to="/SplitTransaction"
                                onClick={() => {
                                    localStorage.setItem("transaction_id", record.key)
                                }}
                            >
                                <img
                                    src={spliticon}
                                    style={{
                                        color: "#47AAD0",
                                        fontSize: 24,
                                        width: "30px"
                                    }}
                                />
                            </Link>
                            <div
                                style={{
                                    height: 40,
                                    width: 40,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#f2f2f2",
                                    borderRadius: 6
                                }}
                            >
                                <DeleteOutlined
                                    style={{
                                        color: "#47AAD0",
                                        fontSize: 24
                                    }}
                                    translate
                                    onClick={() => {
                                        console.log("Values", record)
                                        this.transactionDestroy(record);
                                    }}
                                />
                            </div>
                        </div>
                    ),
                },
            ],
            tableData: [],
            tableDataEx: []
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    showCategory = () => {
        this.setState({ showModalCategory: true })
    };

    handleCancel = () => {
        this.setState({ showModalCategory: false })
    };

    keywordFilter = (values: any) => {
        if (values == "custom") {
            this.setState({ showCustom: true })
        } else {
            this.setState({ filterKeyword: values, showCustom: false, cardLoading: true }, () => {
                this.getAllTransaction();
            })
        }
        this.setState({
            selectedValue:values
        })
    }
    handlerCustomDate = (dates: any, dateString: any) => {
        console.log('dates', dates,dateString, moment(dateString[0]).unix(), moment(dateString[1]).unix())
        this.setState({ fromDate: dateString[0], toDate: dateString[1], cardLoading: true }, () => {
            this.getAllCustomTransaction(moment(dateString[0]).unix(),moment(dateString[1]).unix());
        })
    }

    getAllCustomTransaction = async (fromDate: any, toDate: any) => {
        const result = await axios.get(
            `${configJSON.baseURL}bx_block_finicity/customer/${localStorage.getItem('customerId')}/transactions?fromDate=${fromDate}&toDate=${toDate}&start=1&limit=1000&sort=desc&includePending=false&send_by_email=false&keyword=custom&date=${toDate}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "token": localStorage.getItem("loginToken"),
                    "finicity_token": localStorage.getItem("finicityToken")
                },
            }
        ).then((response: any) => {
            const data = response?.data?.data?.map((values: any) => ({
                key: values.id,
                transaction_date: <span>Account: <br />{values.category_name} <br />{values.transaction_date}</span>,
                desc: values.description == null ? "No Description" : values.description,
                amount: values.amount,
            }))
            const tableExportFunc = response?.data?.data?.map((values: any) => ({
                ["Transaction ID"]: values.id,
                ["Account ID"]: "Account",
                ["Category"]: values.category_name,
                ["Transaction Date"]: values.transaction_date,
                ["Description"]: values.description == null ? "No Description" : values.description,
                ["Amount"]: values.amount,
            }))
            this.setState({
                tableData: data, cardLoading: false, showCustom: false, tableDataEx: tableExportFunc
            })
        }).catch((error: any) => {
            if (error.response.status == "404") {
                this.setState({ cardLoading: false });
            }
        });
    }
    getAllTransaction = async () => {
        let startDate = moment().startOf("month").unix();
        let endDate = moment().unix();
        if (this.state.filterKeyword === "year_to_date") {
          startDate = moment().startOf("year").unix();
          endDate = moment().unix();
        } else if (this.state.filterKeyword === "month_to_date") {
          startDate = moment().startOf("month").unix();
          endDate = moment().unix();
        } else if (this.state.filterKeyword === "last_month") {
          startDate = moment().subtract(1, "months").startOf("month").unix();
          endDate = moment().subtract(1, "months").endOf("month").unix()
        }
        
        const result = await axios.get(
            `${configJSON.baseURL}bx_block_finicity/customer/${localStorage.getItem('customerId')}/transactions?send_by_email=false&fromDate=${startDate}&limit=25&sort=desc&includePending=true&toDate=${endDate}&keyword=${this.state.filterKeyword}`,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "token": localStorage.getItem("loginToken")
                },
            }
        ).then(async (response: any) => {
            response.data = {data: response?.data?.data.filter((tr) => !tr.is_deleted) || []};
            const data = await response?.data?.data?.map((values: any) => ({
                key: values?.id,
                transaction_date: <span>Account: <br />{values?.category_name} <br />{values?.updated_at}</span>,
                desc: values?.description == null ? "No Description" : values?.description,
                amount: values?.amount,
            }))
            console.log('*enter1')
            const tableExportFunc = response?.data?.data?.map((values: any) => ({
                ["Transaction ID"]: values?.id,
                ["Account ID"]: "Account",
                ["Category"]: values?.category_name,
                ["Transaction Date"]: values?.transaction_date,
                ["Description"]: values?.description == null ? "No Description" : values?.description,
                ["Amount"]: values?.amount,
            }))
            console.log('*enter2', data)
            this.setState({
                tableData: data, cardLoading: false, tableDataEx: tableExportFunc
            });
        }).catch((error: any) => {
            this.setState({ cardLoading: false });
        })
    }
    transactionDestroy = async (transId: any) => {
        this.setState({ cardLoading: true });
        const data = {
            id: parseInt(transId.key)
        }
        const result = await axios.put(
            `${configJSON.baseURL}bx_block_transactions/transactions/destroy`,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "token": localStorage.getItem("loginToken")
                },
            }
        ).then(async (response: any) => {
            await this.getAllTransaction();
            this.setState({ cardLoading: false });
            openNotification(response?.data?.message, "success")
        }).catch((error: any) => {
            if (error.response.status == "404") {
                this.setState({ cardLoading: false });
                openNotification(error?.response?.data?.message, "error")
            }
        })

    console.log('tableData', this.state.tableData)
    }
    categoryAPI = async () => {
        const result = await axios.get(
          `${configJSON.baseURL}bx_block_categories/get_all_categories`,
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              "token": localStorage.getItem("loginToken"),
              "plaid-account-id": localStorage.getItem("plaid_acc_id"),
            },
          }
        );
        if (result) {
          this.setState({categoryData : result?.data?.data});
        } else {
          console.log("error from updateUser:");
        }
      };

    async componentDidMount() {
        this.getAllTransaction();
        this.categoryAPI()
        // var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
        // this.send(authTokenReq);
        // if (!this.state.tabData) {
        //     const navigationMenuAdapter = new NavigationMenuAdapter();
        //     const uploadData = {
        //         data: "Needed",
        //     };

        //     const msg: Message = new Message(
        //         getName(MessageEnum.NavigationTabMenuMessage)
        //     );
        //     msg.addData(getName(MessageEnum.NavigationTMessage), uploadData);

        //     console.log("Dashboard Broadcasting message");
        //     runEngine.sendMessage(msg.id, msg);
        // }
    }

    getDashboardData(): boolean {
        // Customizable Area Start
        const reveniewtUrl = `bx_block_dashboard/get_savings_account_det?customerId=${this.state.customer
            }`;
        const Transactionheader = {
            "Content-Type": configJSON.necessityContentType,
            token: this.state.token,
        };
        console.log("header  ", JSON.stringify(Transactionheader));
        const TransactionhttpBody = {
            data: {
                account_id: String(this.state.accountId),
            },
        };
        const TransactionrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        console.log("requestMessage, ", TransactionrequestMessage);
        this.dashboardApiiCallId = TransactionrequestMessage.messageId;
        TransactionrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            reveniewtUrl
        );

        TransactionrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(Transactionheader)
        );
        TransactionrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(TransactionhttpBody)
        );
        TransactionrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.necessityApiMethodType
        );
        console.log("TransactionrequestMessage@, ", TransactionrequestMessage);
        runEngine.sendMessage(TransactionrequestMessage.id, TransactionrequestMessage);
        // Customizable Area End
        return true;
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        console.log("receive...");
        //runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.NavigationRMessage) === message.id) {
            const transactiondata = message.getData(
                getName(MessageEnum.NavigationTabDataMessage)
            );
            this.setState({
                tabData: transactiondata.data[0].data.attributes,
                drawerData: transactiondata.data[1].data.attributes,
            });
        }

        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            //runEngine.debugLog("Message Recived", message);
            let transactiontoken = message.getData(getName(MessageEnum.SessionResponseToken));
            let transactionaccountId = message.getData(getName(MessageEnum.SessionResponseData));
            console.log("account" + transactionaccountId);
            let transactionobj = JSON.parse(transactionaccountId);
            this.setState({ customer: transactionobj.data.finicity_customer_id });
            this.setState({ accountId: transactionobj.data.id });
            //console.log("userNamemmmm"+obj.data.first_name);
            if (transactionobj.data.first_name !== null) {
                this.setState({ userName: transactionobj.data.first_name });
            }
            this.setState({ token: transactiontoken, loading: true }, () => {
                this.getDashboardData();
            });
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let transactionresponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.dashboardApiiCallId) {
                if (
                    transactionresponseJson &&
                    !transactionresponseJson.errors &&
                    transactionresponseJson.dashboardData
                ) {
                    console.log("responseJson  Dashboard ", JSON.stringify(transactionresponseJson));
                    if (transactionresponseJson.dashboardData.length === 0) {
                        this.setState({
                            errorMsg: "Data Not Found",
                            loading: false,
                        });
                    } else {
                        this.setState({
                            reveniewData: transactionresponseJson.dashboardData,
                            errorMsg: "",
                            loading: false,
                        });
                        //const getcata= new Dashboard(this.props);
                        this.getCategories();
                    }
                } else {
                    let transactionerrorReponse = message.getData(
                        getName(MessageEnum.RestAPIResponceErrorMessage)
                    );
                    console.log("error resp", transactionerrorReponse);
                    if (transactionerrorReponse === undefined) {
                        this.setState({
                            errorMsg: "Something went wrong",
                            loading: false,
                        });
                    } else {
                        this.setState({
                            errorMsg: transactionerrorReponse,
                            loading: false,
                        });
                    }
                }
            }
        }
    }
    getCategories() {
        throw new Error("Method not implemented.");
    }
    // Customizable Area Start
    getAsset = (transactionitem: any): any => {
        switch (transactionitem.icon) {
            case "Account":
                return require("../assets/Componente33x.png");
            case "Budget":
                return require("../assets/Componente43x.png");
            case "Transaction":
                return require("../assets/Componente53x.png");
            case "Reports":
                return require("../assets/Componente63x.png");
            default:
                return require("../assets/Componente73x.png");
        }
    };

    nav = (transactionitem: any) => {
        switch (transactionitem.icon) {
            case "Account":
                this.transactiononPressAccounts();
                break;
            case "Budget":
                this.transactiononPressBudgets();
                break;
            case "Transaction":
                this.transactiononPressTrans();
                break;
            case "Reports":
                this.transactiononPressReports();
                break;
            case "Settings":
                this.transactiononPressSettings();
                break;
            case "Contactus":
                this.transactiononPressContactus();
                break;
            case "Profile":
                this.transactiononPressProfile();
                break;
            case "Dashboard":
                this.transactiononPressDashboard();
                break;
            default:
                console.log("Not navigating");
        }
    };

    transactiononPressBudgets = () => {
        this.props.navigation.navigate("Budget");
    };
    transactiononPresDas = () => {
        this.props.navigation.navigate("AddCategories");
    };

    transactiononPressReports = () => {
        this.props.navigation.navigate("StatisticsReports");
    };
    transactiononPressAccounts = () => {
        this.props.navigation.navigate("AccountsPage");
    };
    transactiononPressTrans = () => {
        this.props.navigation.navigate("Transaction");
    };
    transactiononPressmovemoney = () => {
        this.props.navigation.navigate("MovemoneyFrom");
    };
    transactiononPressSettings = () => {
        this.props.navigation.navigate("Settings");
    };
    transactiononPressContactus = () => {
        this.props.navigation.navigate("Contactus");
    };
    transactiononPressProfile = () => {
        this.props.navigation.navigate("UserProfileBasicBlock");
    };
    transactiononPressDashboard = () => {
        this.setState({ isOpen: false });
    };

    // Customizable Area End
}
