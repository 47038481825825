// Customizable Area Start
import React, { useEffect, useState } from "react";
const logo = require("../assets/Componente.png");
const linkedLogo = require("../assets/linked_acc_logo.png");
const linkLogoIcon = require("../assets/linked_acc__btn_logo.png");
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Image, Divider, Modal, Button } from "antd";
import { useSelector } from "react-redux";
import { configJSON } from "./LinkedAccountsController";
import axios from "axios";
import { bgImage } from "./assets";
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import {
  openErrorNotification,
  openNotification
} from "../../../components/src/Notification.web";
import LinkedAccountsWelcomeScreenController, { Props } from "./LinkedAccountsWelcomeScreenController";

declare const window: any;

export default class LinkedAccountsWelcomeScreenWeb extends LinkedAccountsWelcomeScreenController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <TopNav />
        {this.state.loading ? (
          <LoadingWeb />
        ) : (
          <Row
            align="middle"
            justify="center"
            style={{
              height: "100vh",
              width: "100vw",
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              position: "fixed",
              overflow: "auto",
              paddingBottom: "20px",
              marginBottom: "30px"
            }}
          >
            <Col lg={10} md={13} sm={16} xs={21}>
              <Card>
                <Row justify="center">
                  <Image src={logo} preview={false} height="50px" width="52px" />
                </Row>
                <Divider style={{ marginBottom: "0px" }} />
                <Row
                  justify="center"
                  style={{
                    fontSize: 33,
                    fontWeight: "bold",
                    color: "rgb(84, 88, 97)"
                  }}
                >
                  Welcome{" "}
                </Row>
                <Row
                  justify="center"
                  style={{
                    fontWeight: "normal",
                    fontSize: 18,
                    color: "rgb(104, 105, 108)",
                    marginTop: "-13px",
                    marginBottom: "20px"
                  }}
                >
                  to B.E.S.T. Budget
                </Row>
                <Row justify="center">
                  <Col span={12}>
                    <Image src={linkedLogo} preview={false} />
                  </Col>
                </Row>
                <Row
                  justify="center"
                  style={{
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: "normal",
                    color: "rgb(104, 105, 108)"
                  }}
                >
                  <Col span={24}>
                    You are now getting ready to input your checking,
                    <br />
                    savings, and credit card accounts. <br /> <br />
                    You can manually add your accounts <br /> or electronically
                    link them.
                  </Col>{" "}
                </Row>
                <Row style={{ marginTop: "1em" }} justify="center">
                  <Row
                    style={{
                      padding: "0px 10px",
                      background: "rgb(45, 173, 225)"
                    }}
                  >
                    <Button type="link" data-test-id = "linkacc-fn" onClick={() => this.linkAccounts()}>
                      <Col
                        span={21}
                        style={{
                          fontWeight: "normal",
                          fontSize: 15,
                          color: "white",
                          display: "contents"
                        }}
                      >
                        <Image
                          src={linkLogoIcon}
                          preview={false}
                          height="15px"
                          width="15px"
                          style={{ marginTop: "3px", marginRight: "10px" }}
                        />
                        <span style={{ marginLeft: "10px" }}>LINK ACCOUNTS</span>
                      </Col>
                    </Button>
                  </Row>
                  <Modal
                    centered
                    destroyOnClose
                    footer={false}
                    visible={this.state.modalOpen}
                    data-test-id = "handleOk-fn"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    closeIcon={true}
                    bodyStyle={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <Row justify="center">
                      <Col span={18}>
                        <Row style={{ textAlign: "center", marginBottom: "5px" }}>
                          <Col span={24}>Link Bank accounts</Col>
                        </Row>
                        <Row justify="center">
                          <Image
                            src={logo}
                            preview={false}
                            height="50px"
                            width="52px"
                          />
                        </Row>
                        <Row style={{ textAlign: "center", marginTop: "20px" }}>
                          <Col span={24}>
                            Have you finished adding or linking all your accounts?
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Divider style={{ marginBottom: "0px" }} />
                    <Row justify="center">
                      <Col span={18}>
                        <Row style={{ textAlign: "center" }}>
                          <Col span={24}>
                            <Button
                              data-test-id = "modalOpen-fn"
                              type="link"
                              onClick={() => this.setState({ modalOpen: false })}
                            >
                              No, add or link another account
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
                    <Row justify="center">
                      <Col span={18}>
                        <Row style={{ textAlign: "center" }}>
                          <Col span={24}>
                            <Button
                              type="link"
                              style={{ fontWeight: 700 }}
                              data-test-id = "handlecontinue-fn"
                              onClick={this.handleContinue}
                            >
                              Yes, Continue
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Modal>
                </Row>
                <Row justify="center">
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      marginTop: "15px",
                      marginBottom: "50px"
                    }}
                  >
                    By continuing, I accept the{" "}
                    <span
                      style={{
                        color: "rgb(45, 173, 225)",
                        fontSize: "15px",
                        cursor: "pointer"
                      }}
                      data-test-id = "navigation-fn"
                      onClick={() => {
                        this.props.navigation.navigate("TermsAndServices");
                      }}
                    >
                      terms of service <br /> and I read the privacy policy of
                      B.E.S.T. Budget
                    </span>
                  </span>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </>
    );
  }
}
// Customizable Area End