// import "./assets/notification.css"
import { notification } from "antd";

export const openNotification = (messages:any,types:any) => {
  notification.open({
    message: types,
    type: types,
    duration: 3,
    description: messages,
  });
};
export const openNotificationWithTitle = (title:any,messages:any,types:any) => {
  notification.open({
    message: title,
    type: types,
    // duration: 3,
    description: messages,
  });
};
export const openErrorNotification = (error:any,types:any) => {
  notification.open({
    message: types,
    type: types,
    duration: 3,
    description: error,
  });
};
 
