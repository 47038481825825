//@ts-nocheck
import React from 'react';
import 'antd/dist/antd.css';
import AvailableBalanceController, { Props, configJSON } from "./AvailableBalanceController";
import { Form, Select, Button, Input, Table, Divider, Progress, DatePicker, Row, Col } from 'antd';
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import SideBar from "../../../components/src/SideBar";
const { RangePicker } = DatePicker;
const { Option } = Select;


export default class AvailableBalance extends AvailableBalanceController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div>
                <div><SideBar /></div>
                {this.state.cardLoading ?
                    (<LoadingWeb />)
                    :
                    (
                        <div style={styles.block}>
                            <div style={styles.firstDiv}>
                                <div
                                    style={{
                                        color: "#545861",
                                        fontSize: 22,
                                        fontWeight: 900
                                    }}
                                >
                                    {configJSON.categoryA}
                                </div>
                                <div
                                    style={{
                                        color: "#b3b3b3",
                                        fontSize: 16,
                                        fontWeight: 500
                                    }}
                                >
                                    {configJSON.budgetsBalance}
                                </div>
                            </div>
                            <div style={styles.secondDiv}>
                                <div>
                                    <div style={styles.firstDivContent}>
                                        <div style={styles.button}>
                                            <div
                                                style={{
                                                    fontSize: 16,
                                                    color: "#999999",
                                                    textAlign: "center"
                                                }}
                                            >
                                                {configJSON.availableBalance}
                                            </div>
                                            <div
                                                style={{
                                                    color: "#00a950",
                                                    fontSize: 30,
                                                    textAlign: "center"
                                                }}
                                            >
                                                $ {this.state.availableAmount}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: "376px",
                                                fontSize: 18,
                                                color: "#999999",
                                                textAlign: "center",
                                                fontWeight: 500,
                                                margin: "16px 0px"
                                            }}
                                        >
                                            Budgeted: $ {this.state.budgetedAmount}
                                        </div>
                                        <Divider />
                                    </div>
                                </div>
                                <div style={styles.second}>
                                    <div
                                        style={{
                                            fontSize: 22,
                                            fontWeight: 900,
                                            color: "#545861",
                                            paddingLeft: "12px"
                                        }}
                                    >
                                        {configJSON.history}
                                    </div>
                                    <Divider />
                                    <Row>
                                        <div style={{ width: "200px" }}>
                                            <Form>
                                                <Form.Item>
                                                    <Select
                                                        size="large"
                                                        placeholder="Month to date"
                                                        allowClear onChange={this.keywordFilter}>
                                                        <Option value="month_to_date">Month to date</Option>
                                                        <Option value="year_to_date">Year to date</Option>
                                                        <Option value="last_month">Last Month</Option>
                                                        <Option value="custom">Custom</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Form>


                                        </div>
                                        <span
                                            style={{
                                                width: "auto"
                                            }}
                                        >
                                            {this.state.showCustom ?
                                                <RangePicker
                                                    size="large"
                                                    style={{
                                                        marginLeft: "5px"
                                                    }}
                                                    onChange={this.handlerCustomDate}
                                                    format={"YYYY-MM-DD"}
                                                />
                                                :
                                                ""}
                                        </span>
                                    </Row>
                                    <div
                                        style={{
                                            fontSize: 16,
                                            color: "#000000",
                                            width: "100%",
                                            fontWeight: 600,
                                            height: "60px",
                                            padding: "20.5px 672px 20.5px 17px",
                                            borderRadius: "6.4px",
                                            backgroundColor: "#f2f2f2",
                                        }}
                                    >
                                        {configJSON.transaction}
                                    </div>
                                    <Row>
                                        <Col span={24}>
                                            <Table
                                                style={{
                                                    padding: " 0px 20px"
                                                }}
                                                columns={this.state.columnData}
                                                dataSource={this.state.tableData}
                                                pagination={{ defaultPageSize: 10 }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div >
        )
    }
}

const styles = {
    block: {
        height: "auto",
        padding: "40px",
        backgroundColor: "white",
        marginRight: "32px",
        border: "1px solid white",
        marginLeft: "106px",
        marginTop: "28px",
    },
    firstDiv: {
        display: "flex",
        alignItems: "center",
        width: "368px",
        justifyContent: "space-between"
    },
    firstDivContent: {
        width: "372px"
    },
    secondDiv: {
        display: "flex",
    },
    button: {
        width: "372.1px",
        height: "90px",
        margin: "43.2px 30px 0 0",
        padding: "6.7px 113px 14.8px 113.1px",
        borderRadius: "6.4px",
        backgroundColor: "#ededf2"
    },
    second: {
        // width: "890px",
        // marginLeft: "55px",
        height: "auto",
        padding: "34px 24px",
    }
}

