import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import AsyncStorage from "@react-native-async-storage/async-storage";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Alert } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;

  plaid_acc_id: any;

  tokenInfo: any;
  fcmTokenInfo: any;
  loading: boolean;
  firebaseToken: string;
  remeber: boolean;
  forceUpdate: any

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  firstNameLogin: any = "";
  fcmToken: any;
  userName: any;

  login_time: any;
  token_expire_time: any;

  tokenLogin: any;
  access_token: any;
  finicityIdLogin: any = null;
  emailLogin: any;
  userData: any;
  accounId: any;
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  loadingButton: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",

      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,

      plaid_acc_id: "",

      tokenInfo: "",
      fcmTokenInfo: "",
      loading: false,
      firebaseToken: "",
      remeber: false,
      forceUpdate: {}
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    color: "#6200EE",
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            this.setState({
              tokenInfo: responseJson.meta.token,
              fcmTokenInfo: this.fcmToken,
            });
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();

            setTimeout(() => {
              this.openInfoPage(responseJson);
            }, 1000);
          } else {
            //Check Error Response
            Alert.alert(responseJson.errors[0].failed_login);
            this.loadingButton?.showLoading(false);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  async saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      // Added
      this.tokenLogin = responseJson.meta.token;
      this.access_token = responseJson.data.plaid_access_token;
      this.finicityIdLogin = responseJson.data.finicity_customer_id;
      this.emailLogin = responseJson.data.email;
      this.accounId = responseJson.data.id;
      this.userData = responseJson;
      this.firstNameLogin = responseJson.data.first_name;
      this.userName = responseJson.data.username;

      this.login_time = responseJson.login_time;
      this.token_expire_time = responseJson.token_expire_time;
      console.warn("login_time =============>>>>>>>>>>>>>>", this.login_time);
      console.warn(
        "expiry_time =============>>>>>>>>>>>>>>",
        this.token_expire_time
      );

      await AsyncStorage.setItem("firstName", this.firstNameLogin);
      await AsyncStorage.setItem(
        "finicity_customer_id",
        JSON.stringify(this.finicityIdLogin)
      );
      await AsyncStorage.setItem("userName", this.userName);

      this.setToken(responseJson);
      this.setToken002(responseJson);

      this.send(msg);
    }
  }

  // Added
  setToken = async (responseJson: any) => {
    let tokenLogin = this.tokenLogin;
    let emailLogin = this.emailLogin;
    let accounId = this.accounId;

    console.log(
      "tokenLogin >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
      tokenLogin
    );
    try {
      await AsyncStorage.setItem("tokenLogin", tokenLogin);
      await AsyncStorage.setItem("emailLogin", emailLogin);
      await AsyncStorage.setItem("accounId", JSON.stringify(accounId));
    } catch (error) {
      // Error saving data
      console.log(error);
    }
  };

  setToken002 = async (responseJson: any) => {
    let userData = this.userData;
    let login_time = this.login_time;
    let token_expire_time = this.token_expire_time;

    await AsyncStorage.setItem("USER_TOKEN_LOGIN_TIME", login_time); // expiry date get in response, must have from auth TOKEN
    await AsyncStorage.setItem("USER_TOKEN_EXPIRY_TIME", token_expire_time); // expiry date get in response, must have from auth TOKEN

    console.log(
      "token_expire_time >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
      token_expire_time
    );

    try {
      await AsyncStorage.setItem("userData", JSON.stringify(userData));
    } catch (error) {
      // Error saving data
      console.log(error);
    }
  };

  getToken = async () => {
    console.warn("getToken ===========================>>>>>>>>>>>>>>>>>>>>>>>");
    try {
      const tokenLogin = await AsyncStorage.getItem("tokenLogin");
      this.tokenLogin = tokenLogin;
      if (this.tokenLogin !== null) {
        // We have data!!
        console.log(
          "tokenLogin ==================>>>>>>>>>>>>>>>>>>",
          tokenLogin
        );
      }
    } catch (error) {
      // Error retrieving data
    }
  };

  openInfoPage(responseJson: any) {
    if (
      // !responseJson.if_new_user &&
      responseJson.data?.subscription_status &&
      responseJson.data?.subscription_type === "trial"
    ) {
      this.checkKindOfUser(responseJson);
    } else if (
      // !responseJson.if_new_user &&
      responseJson.data?.subscription_status &&
      responseJson.data?.subscription_type === "paid"
    ) {
      this.checkKindOfUser(responseJson);
    } else {
      this.props.navigation.replace("TrialExpiry");
    }
  }

  checkKindOfUser = (response: any) => {
    if (!response.if_account_linked) {
      this.props.navigation.replace("LinkedAccountsWelcomeScreen");
    } else {
      this.props.navigation.replace("Dashboard");
    }

  };

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    this.loadingButton?.showLoading(false);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
     Alert.alert("Enter a email address !!");
      this.loadingButton?.showLoading(false);

      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      Alert.alert("Enter a Password !!");
      this.loadingButton?.showLoading(false);

      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
      firebase_token: this.fcmToken,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  _onPressHandler() {
    this.loadingButton?.showLoading(true);
  }

  goToDashboard = async () => {
    let plaid_acc_id = this.state.plaid_acc_id;
    try {
      await AsyncStorage.setItem("plaid_acc_id", plaid_acc_id);
      console.warn(
        "plaid_acc_id ==================>>>>>>>>>>>>>>>>>>>>>>",
        plaid_acc_id
      );
      this.props.navigation.replace("Dashboard");
    } catch (error) {
      // Error saving data
      console.log(error);
    }
  };

}
