//@ts-nocheck
// Customizable Area Start
import React from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Progress,
  Row,
  Select
} from "antd";
import { Redirect } from "react-router-dom";
//@ts-ignore
import bgImage from "../assets/ob_back.png";
const logo = require("../assets/slide3.png");
const bestLogo = require("../assets/logo.png");
const linkLogoIcon = "";
const { Option } = Select;
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
import {
  DeleteFilled,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import AddMonthlyNecessityExpensesController, { Props, configJSON } from "./AddMonthlyNecessityExpensesController";
export default class AddMonthlyNecessityExpensesWeb extends AddMonthlyNecessityExpensesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        {this.state.redirect ? (<Redirect push to="/EmailAccountLoginWeb" />) : null}
        <TopNav />
        {this.state.cardLoading ?
          <LoadingWeb />
          :
          <Row
            align="middle"
            justify="center"
            style={{
              // marginTop: "20%",
              height: "100vh",
              width: "100vw",
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              position: "fixed",
              overflow: "auto",
              paddingBottom: "20px",
              marginBottom: "30px",
            }}
          >
            <Col lg={10} md={13} sm={16} xs={21} style={{ marginBottom: "50px" }}>
              <Card style={{ marginBottom: "50px", marginTop: "50px" }}>
                <Row
                  justify="center"
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    // fontFamily: "HelveticaNeueLTStd-Roman",
                    fontWeight: "bold",
                    color: "rgb(104, 105, 108)",
                  }}
                >
                  <Col span={24}>{configJSON.monthlyNeccesityExp}</Col>
                </Row>
                <Row justify="center">
                  <Col
                    span={24}
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      lineHeight: "1.39",
                      letterSpacing: "-0.43px",
                      color: "#68696c",
                    }}
                  >
                    {configJSON.enterMonthlyNessExp}
                  </Col>
                </Row>
                <Divider />
                <Row justify="end">
                  <Button
                    icon={<PlusOutlined translate />}
                    shape="round"
                    style={{
                      backgroundColor: "#f2f2f2",
                      color: "#2dade1",
                      fontWeight: "bolder",
                      marginBottom: "1em",
                      border: "none",
                    }}
                    data-test-id="open-modal-button"
                    onClick={() => this.setState({ openAddModal: true })}
                  >
                    {configJSON.addCategoryCaps}
                  </Button>
                  <Modal
                    data-test-id="addCategoryModal"
                    centered
                    title={
                      <Row
                        justify="center"
                        style={{
                          color: "#545861",
                          fontSize: "28px",
                          fontWeight: "bold",
                        }}
                      >
                        {configJSON.addCategory}
                      </Row>
                    }
                    destroyOnClose
                    footer={false}
                    visible={this.state.openAddModal}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    width="35%"
                    closeIcon={true}
                  >
                    <Row justify="center">
                      <Col span={18}>
                        <Form
                          ref={this.form}
                          name="control-ref"
                          layout="vertical"
                          data-test-id="onfinish-function"
                          onFinish={(value: any) => this.onFinish(value)}
                        >
                          <Row>
                            <Form.Item style={{ width: "100%" }}>
                              <Select
                                data-test-id="change-function"
                                size="large"
                                placeholder="Select Category"
                                allowClear onChange={(value: any, index: any) => this.change(value, index)}>
                                {this.state.categoryData?.map((values: any) => (
                                  <Option key={values?.attributes?.generic_name} value={values?.attributes?.id}>{values?.attributes?.generic_name}</Option>
                                ))}
                                {/* <Option value="admin">admin</Option> */}
                              </Select>
                            </Form.Item>
                          </Row>
                          <p>{configJSON.balance}</p>
                          <Row>
                            <Form.Item
                              style={{ width: "100%" }}
                              name="amount"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Amount!",
                                },
                              ]}
                              label={<span style={{ color: "gray" }}>{configJSON.amount}</span>}
                            >
                              <Input type="number" placeholder="Your Amount" />
                            </Form.Item>
                          </Row>
                          <Row style={{ marginTop: "2em" }}>
                            <Form.Item
                              data-test-id="save-button"

                              style={{ width: "100%" }} shouldUpdate>
                              {() => (
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  loading={this.state.loading}
                                  style={{ borderRadius: "15px", width: "100%" }}
                                  disabled={
                                    !this.form.current?.isFieldsTouched(true) ||
                                    !!this.form.current?.getFieldsError()
                                      .filter(({ errors }: any) => errors.length)
                                      .length
                                  }
                                >
                                  {configJSON.labelTitleText}
                                </Button>
                              )}
                            </Form.Item>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Modal>
                </Row>
                <Row style={{ marginBottom: "5em" }}>
                  <Col span={24}>
                    <List
                      size="large"
                      header={
                        <Row
                          justify="space-around"
                          style={{
                            fontWeight: "bolder",
                            backgroundColor: "#f2f2f2",
                            padding: "15px",
                            color: "black",
                          }}
                        >
                          <Col>{configJSON.category}</Col>
                          <Col>{configJSON.amount}</Col>
                        </Row>
                      }
                      data-test-id="renderitemsubmit"
                      dataSource={this.state.data}
                      renderItem={(item: any) => (
                        <>
                          <List.Item>
                            <Row justify="space-between" style={{ width: "100%" }}>
                              <Col span={15}>{item.sub_Details.name}</Col>
                              <Col span={9} style={{ textAlign: "center" }} className="placeholderColor">
                                <Input
                                  data-test-id="renderbutton"
                                  type="number"
                                  // type="submit"
                                  // name={item.sub_Details.name}
                                  // onChange={handleChange} 
                                  onChange={(e: any) => this.handleChange(item, e)}
                                  onBlur={() => this.handleSave()}
                                  name="amount"
                                  placeholder={"$" + (item.amount ? item.amount : "0.00")}
                                  style={{ width: "80px" }}
                                />
                                <DeleteFilled
                                  data-test-id="delete-filled"
                                  onClick={(id: any) => (
                                    this.setState({ id: item.sub_Details.category_id, openDeleteModal: true })
                                  )}
                                  style={{
                                    color: "#b3b3b3",
                                    fontSize: "20px",
                                    paddingLeft: "12px",
                                  }}
                                  translate
                                />
                              </Col>
                            </Row>
                          </List.Item>
                        </>
                      )}
                    />
                    <Row style={{ width: "100%", padding: "16px 24px" }}>
                      <Col
                        span={15}
                        style={{
                          textAlign: "end",
                          paddingRight: "15px",
                          color: "black",
                          fontWeight: "bolder",
                        }}
                      >
                        {configJSON.total}
                      </Col>
                      <Col span={9}>
                        <Input
                          value={"$ " + this.state.total}
                          style={{
                            width: "80px",
                            color: "black",
                            fontWeight: "bolder",
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Modal
                  centered
                  title={
                    <Row
                      justify="center"
                      style={{
                        color: "#545861",
                        fontSize: "28px",
                        fontWeight: "bold",
                      }}
                    >
                      {configJSON.confirmDelete}
                    </Row>
                  }
                  footer={false}
                  visible={this.state.openDeleteModal}
                  data-test-id="confirm-delete-modal"
                  onOk={() => this.handleOk()}
                  onCancel={() => this.handleCancel()}
                  width="35%"
                  closeIcon={true}
                >
                  <Row justify="center">
                    <Col span={18}>
                      <Form ref={this.form} layout="vertical" onFinish={() => this.handleDelete()}
                        data-test-id="handle-delete-function">
                        <Row justify="center">
                          <Col style={{ textAlign: "center" }} span={18}>
                            {configJSON.confirmDeleteMsg}
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "4em" }}>
                          <Form.Item style={{ width: "100%" }}>
                            <Button
                              // type="primary"
                              htmlType="submit"
                              loading={this.state.loading}
                              style={{
                                borderRadius: "15px",
                                color: "#2dade1",
                                border: "none",
                                fontWeight: "bold",
                                width: "100%",
                                backgroundColor: "#f2f2f2",
                              }}
                            >
                              {configJSON.deleteBtn}
                            </Button>
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item style={{ width: "100%" }}>
                            <Button
                              type="primary"
                              // htmlType="submit"
                              data-test-id="cancel-button"
                              onClick={() => this.setState({ openDeleteModal: false })}
                              style={{ borderRadius: "15px", width: "100%" }}
                            >
                              {configJSON.cancelCaps}
                            </Button>
                          </Form.Item>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Modal>
                <Row justify="center">
                  <Col span={18}>
                    <Progress percent={40} status="active" showInfo={false} />
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "2em" }}>
                  <Button
                    shape="round"
                    style={{ backgroundColor: "#b3b3b3", color: "white" }}
                    data-test-id="back-button"
                    onClick={() => this.props.navigation.navigate("MonthlyNecessityExpensesWeb")}
                    icon={<LeftOutlined translate />}
                  >
                    {configJSON.back}
                  </Button>
                  <Button
                    shape="round"
                    loading={this.state.loading}
                    type="primary"
                    // onClick={handleSave}
                    data-test-id="save-and-continue-button"
                    onClick={() => this.submitData()}
                  >
                    {configJSON.saveAndContinue} <RightOutlined translate />
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        }</>
    );
  }
};
// Customizable Area End

