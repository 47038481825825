import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Progress } from "antd";
import { logo } from "./assets";
export const LoadingWeb = () => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    let counter = 1;
    const interval = setInterval(() => {
      counter++;
      setProgress(counter);
      if (counter == 100) {
        clearInterval(interval);
      }
    }, 100);
  }, []);
  return (
    <Modal centered visible={true} closeIcon>
      <Row justify="center" align="middle">
        <Col>
          <img src={logo} width="90px" />
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: "1em" }}>
        LOADING...
      </Row>
      <Row>
        <Progress percent={progress} status="active" />
      </Row>
    </Modal>
  );
};
