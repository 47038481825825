const { baseURL } = require("../../../framework/src/config");

Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.baseURL = `${baseURL}`;
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getType = "GET";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AccountsPage";
exports.labelBodyText = "AccountsPage Body";
exports.filterApiEndPoint = "bx_block_finicity/customer/";
exports.btnExampleTitle = "CLICK ME";

exports.accountPageAPIEndPoint =
  `${baseURL}bx_block_finicity/customer/`;
exports.finicityTokenAPIEndPoint =
  `${baseURL}bx_block_finicity/finicity_token`;
exports.accountDetailsAPIEndPoint =
  `${baseURL}bx_block_finicity/customer`;
exports.linkedAccAPIEndPoint =
  `${baseURL}bx_block_finicity/connect_url`;
exports.postMethod = "POST";
exports.getMethod = "GET";
exports.contentTypeApi = "application/json";

exports.accounts = "Accounts";
exports.linkAccount = "LINK ACCOUNTS";
exports.monthToDate = "Month to date";
exports.yearToDate = "Year to date";
exports.lastMonth = "Last Month";
exports.custom = "Custom";
exports.account = "Account";
exports.availableBalance = "Available balance";
// Customizable Area End
