// Customizable Area Start
// @ts-nocheck
import React from "react";
const bestLogo = require("../assets/logo.png");
const chart = require("../assets/slide5.png");
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Image, Divider, Progress, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
//@ts-ignore
import TopNav from "../../../components/src/TopNav.js";

import { bgImage } from "./assets";
const BudgetDiscretioneryIncomesWeb = () => {
  const history = useHistory();

  return (
    <>
      <TopNav />
      <Row
        align="middle"
        justify="center"
        style={{
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          position: "fixed",
          overflow: "auto",
          paddingBottom: "20px",
          marginBottom: "30px"
        }}
      >
        <Col lg={10} md={13} sm={16} xs={21}>
          <Card>
            <Row justify="center">
              <Col span={3}>
                <Image src={bestLogo} preview={false} />
              </Col>
            </Row>
            <Divider />
            <Row justify="center">
              <Col span={5}>
                <Image src={chart} preview={false} />
              </Col>
            </Row>
            <Row
              justify="center"
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "rgb(104, 105, 108)"
              }}
            >
              <Col span={24}>
                Budget Discretionary Income for whatever goals you have.
              </Col>
            </Row>
            <Row
              justify="center"
              style={{
                textAlign: "center",
                fontSize: 20,
                color: "rgb(104, 105, 108)",
                marginBottom: "5em"
              }}
            >
              <Col span={24}>
                (i.e. emergency fund, vacation fund, entertainment, dining out,
                gift fund, investing, credit card debt, etc). Plan is to provide
                user with a form to fill out with various types of goals.
              </Col>
            </Row>
            <Row justify="center">
              <Col span={18}>
                <Progress percent={80} status="active" showInfo={false} />
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: "2em" }}>
              <Button
                className="back-btn"
                shape="round"
                type="primary"
                style={{ backgroundColor: "#b3b3b3", color: "white" }}
                onClick={() => history.push("/DiscretioneryIncomesWeb")}
                icon={<LeftOutlined translate rev />}
              >
                Back
              </Button>
              <Button
                shape="round"
                type="primary"
                onClick={() => history.push("/AddDiscretioneryExpensesWeb")}
              >
                SET DISCRETIONARY EXPENSES <RightOutlined translate rev />
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default BudgetDiscretioneryIncomesWeb;
// Customizable Area End
