// @ts-nocheck
import {
  Card,
  Col,
  Button,
  Select,
  Row,
  Collapse,
  Modal,
  Form,
  Input,
  Pagination,
} from "antd";
import {
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React from "react";
import { Prompt } from "react-router-dom";
import SideBar from "../../../components/src/SideBar";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import BudgetWebController, { Props } from "./BudgetWebController";
const configJSON = require("./config");
const { Option } = Select;
const { Panel } = Collapse;

export default class BudgetWeb extends BudgetWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  returnRenderData(cardLoading: any) {
    if (cardLoading) {
      return this.returnLoading();
    } else {
      return this.returnData()
    }
  }

  returnLoading() {
    return <LoadingWeb />;
  }

  returnRowAccordingtoData(data: any) {
    if (data) {
      return (
        <>
          <Col span={16}>Take home income</Col>
          <Col span={8} style={{ display: "flex" }} className="balance_text" data-test-id={"showInput"} onClick={() => this.setState({ showInput: true })}>
            ${this.state.data.take_home_pay}
          </Col>
        </>
      )
    }
  }

  returnTotalSaving(data: any) {
    if (data) {
      return (
        <>
          <Col span={16}>Total Savings</Col>
          <Col span={8} className="balance_text">
            {" "}
            ${this.state.data.total_savings}
          </Col>
        </>
      )
    }
  }

  returnDisplayOrCategory(data: any) {
    if (data?.display_name) {
      return data.display_name;
    } else if (data?.category_name) {
      return data.category_name;
    }
  }

  returnOpenModalClassName(openModal: any) {
    if (openModal) {
      return "d-none";
    } else {
      return "";
    }
  }

  returnTotalBudgetAmount(item: any) {
    if (item.total_budget_amount) {
      return item.total_budget_amount;
    } else {
      return 0;
    }
  }

  returnSubDetailShowInput(showInput: any, item: any) {
    if (this.state.showItemInput && parseInt(this.state.editId) == item.category_id) {
      return (
        <>
          <Form
            layout="vertical"
            style={{ width: "100%" }}
          // onFinish={(event: any) => }
          >
            <Row>
              <Col span={16}>
                <Form.Item style={{ width: "80%" }} name="name" initialValue={item.category_name} >
                  <Input
                    placeholder="Your Category Name"
                    id={"category_name" + item.category_id.toString()}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={10}>
                    <Form.Item style={{ width: "80%" }} name="amount" initialValue={item.total_budget_amount} >
                      <Input
                        className="balance_text"
                        type="number"
                        placeholder="Your Amount"
                        id={"category_amount" + item.category_id.toString()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    <Row
                      justify="space-around"
                      align="middle"
                      style={{ fontSize: "20px" }}
                    >
                      <Button
                        onClick={(e) => {
                          this.onFinishItem({
                            amount: document.getElementById("category_amount" + e.target.parentElement.id.split("category_save")[1]).value,
                            name: document.getElementById("category_name" + e.target.parentElement.id.split("category_save")[1]).value
                          },
                            { category_id: e.target.parentElement.id.split("category_save")[1] }
                          )
                        }}
                        id={"category_save" + item.category_id.toString()}
                        loading={this.state.itemLoading}
                        type="primary"
                        data-test-id={"submitForm"}
                      >
                        SAVE
                      </Button>
                      <FormOutlined
                        disabled
                        translate
                        style={{
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                          color: "gray",
                        }}
                      />
                      <DeleteOutlined
                        disabled
                        translate
                        style={{
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                          color: "gray",
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      );
    } else {
      return (
        <>
          <Col span={16}>{item.category_name}</Col>
          <Col span={8}>
            <Row id={item.category_id.toString()}>
              <Col span={10} className="balance_text">
                ${this.returnTotalBudgetAmount(item)}
              </Col>
              <Col span={14}>
                <Row
                  justify="space-around"
                  align="middle"
                  style={{ fontSize: "20px" }}
                >
                  <Button disabled>
                    SAVE
                  </Button>
                  <FormOutlined
                    id={item.category_id.toString()}
                    onClick={(e) => (
                      this.setState({
                        editData: item,
                        showItemInput: true,
                        editId: e.target.parentElement.id,
                        itemLoading: false
                      })
                    )}
                    translate
                    style={{
                      padding: "5px",
                      backgroundColor: "#f2f2f2",
                      color: '#2dade1',
                    }}
                    data-test-id={"editData"}
                    rev={undefined} />
                  <Button
                    id={item.category_id.toString()}
                    onClick={(e) => (
                      this.setState({
                        editData: item,
                        openDeleteModal: true,
                        deleteId: e.target.parentElement.parentElement.id
                      })
                    )}
                    type="text"
                    icon={
                      <DeleteOutlined
                        translate
                        style={{
                          padding: "5px",
                          backgroundColor: "#f2f2f2",
                          color: "#2dade1",
                        }}
                        rev={undefined}
                      />
                    }
                    data-test-id={"deleteData"}
                  />
                  <Modal
                    centered
                    title={
                      <Row
                        justify="center"
                        style={{
                          color: "#545861",
                          fontSize: "28px",
                          fontWeight: "bold",
                        }}
                      >
                        Confirm delete
                      </Row>
                    }
                    footer={false}
                    visible={this.state.openDeleteModal}
                    // onOk={() => this.handleOk()}
                    // onCancel={() => this.handleCancel()}
                    width="35%"
                    closeIcon={true}
                    data-test-id={"confirmDelete"}
                  >
                    <Row justify="center">
                      <Col span={18}>
                        <Row justify="center">
                          <Col style={{ textAlign: "center" }} span={18}>
                            Do you confirm that you want to delete this item?
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "4em" }}>
                          <Button
                            onClick={() => this.handleDelete(item)}
                            htmlType="submit"
                            style={{
                              borderRadius: "15px",
                              color: "#2dade1",
                              border: "none",
                              fontWeight: "bold",
                              width: "100%",
                              backgroundColor: "#f2f2f2",
                              marginBottom: "2em",
                            }}
                            data-test-id={"deleteBtn"}
                          >
                            DELETE
                          </Button>
                        </Row>
                        <Row>
                          <Button
                            type="primary"
                            style={{
                              borderRadius: "15px",
                              width: "100%",
                            }}
                            onClick={() => this.setState({ openDeleteModal: false })}
                            data-test-id={"cancelBtn"}
                          >
                            CANCEL
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </Modal>
                </Row>
              </Col>
            </Row>
          </Col>
        </>
      )
    }
  }

  returnSubDetailsRow(dd: any) {
    if (dd?.sub_details?.length > 0) {
      return (
        dd?.sub_details?.map((item: any) => {
          return (
            <Row
              key={item.category_id}
              style={{
                padding: "10px",
                borderTop: "1px solid #d9d9d9",
              }}
              data-test-id={"tesingRow"}
            >
              {this.returnSubDetailShowInput(this.state.showInput, item)}
            </Row >
          );
        })
      )
    }
  }

  returnDashboard(data: any) {
    if (this.state.data && this.state.data.dashboardData.length > 0) {
      return (
        this.state.data.dashboardData.map((dd: any) => (
          <>
            <Panel
              header={
                <Row style={{ color: "black", fontWeight: "bold", width: "100%" }} data-test-id={"addCategory"}>
                  <Col span={16}>{this.returnDisplayOrCategory(dd)}</Col>
                  <Col span={7} data-test-id={"addCategoryCol"}>
                    <Row justify="space-between"> <Col><span className="balance_text">
                      ${dd?.total_available_amount_cat}
                    </span></Col><Col>
                        <Button
                          style={{
                            color: "#00ade2",
                            border: "none",
                            marginLeft: "10px",
                            borderRadius: "5px",
                            fontWeight: 600,
                            backgroundColor: "#f2f2f2",
                          }}
                          data-test-id={"addCategoryBtn"}
                          onClick={() => this.addCategoryDataUpdate(dd.category_name)}
                        >
                          ADD CATEGORY
                        </Button>
                        <Modal
                          centered
                          title={
                            <Row
                              justify="center"
                              style={{
                                color: "#545861",
                                fontSize: "28px",
                                fontWeight: "bold",
                              }}
                            >
                              Add Category
                            </Row>
                          }
                          footer={false}
                          visible={this.state.openAddModal}
                          onOk={() => this.handleOk()}
                          destroyOnClose={true}
                          onCancel={() => this.handleCancel()}
                          data-test-id={"addCategoryModal"}
                          width="35%"
                        >
                          <Row justify="center">
                            <Col span={18}>
                              <Form
                                ref={this.form}
                                layout="vertical"
                                onFinish={(value: any) => this.onFinish(value)}
                              >
                                <Row>
                                  <Form.Item style={{ width: "100%" }} ref={this.form}>
                                    <Select
                                      size="large"
                                      placeholder="Select Category"
                                      allowClear onChange={(value: any, index: any) => this.change(value, index)}>
                                      {this.state.categoryData?.map((values: any) => (
                                        <Option key={values?.attributes?.generic_name} value={values?.attributes?.id}>{values?.attributes?.generic_name}</Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Row>

                                <p>Balance</p>
                                <Row>
                                  <Form.Item
                                    style={{ width: "100%" }}
                                    name="amount"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please input your Amount!",
                                      },
                                    ]}
                                    label={
                                      <span style={{ color: "gray" }}>
                                        Amount
                                      </span>
                                    }
                                  >
                                    <Input
                                      type="number"
                                      placeholder="Your Amount"
                                    />
                                  </Form.Item>
                                </Row>
                                <Row style={{ marginTop: "2em" }}>
                                  <Form.Item
                                    style={{ width: "100%" }}
                                    shouldUpdate
                                  >
                                    {() => (
                                      <Button
                                        type="primary"
                                        loading={this.state.cardLoading}
                                        htmlType="submit"
                                        style={{
                                          borderRadius: "15px",
                                          width: "100%",
                                        }}

                                      >
                                        Save
                                      </Button>
                                    )}
                                  </Form.Item>
                                </Row>
                              </Form>
                            </Col>
                          </Row>
                        </Modal>
                      </Col></Row>
                  </Col>
                </Row>
              }
              className={this.returnOpenModalClassName(this.state.openAddModal)}
              key={dd?.category_id}
            >
              {this.returnSubDetailsRow(dd)}
            </Panel>
          </>
        ))
      )
    }
  }

  showTotalAndRange(total: any, range: any) {
    if (range[0] > 0) {
      return `Showing ${range[0]}-${range[1]} of ${total} `;
    } else {
      return `Showing 1-5 of ${total} `;
    }
  }

  returnData() {
    return (<><Row style={{ marginBottom: "5em" }}>
      <Col span={24}>
        <Row
          style={{
            borderTop: "1px solid #d9d9d9",
            fontWeight: "bolder",
            backgroundColor: "#f2f2f2",
            padding: "12px 16px",
            paddingRight: "40px",
            color: "black",
          }}
        >
          <Col span={16}>Category</Col>
          <Col span={8}>Available</Col>
        </Row>
        <Row
          style={{
            borderTop: "1px solid #d9d9d9",
            borderBottom: "1px solid #d9d9d9",
            color: "black",
            fontWeight: "bold",
            padding: "12px 16px",
            paddingRight: "40px",
          }}
        >
          {this.returnRowAccordingtoData(this.state.data)}
        </Row>
        <Row
          style={{
            borderTop: "1px solid #d9d9d9",
            borderBottom: "1px solid #d9d9d9",
            color: "black",
            fontWeight: "bold",
            padding: "12px 16px",
            paddingRight: "40px",
          }}
        >
          {this.returnTotalSaving(this.state.data)}
        </Row>
        <Collapse
          accordion
          collapsible="header"
          bordered={false}
          expandIconPosition="right"
        >
          {this.returnDashboard(this.state.data)}
        </Collapse>
        <Row
          style={{
            color: "black",
            fontWeight: "bold",
            padding: "12px 16px",
            paddingRight: "40px",
            borderBottom: "1px solid #d9d9d9",
          }}
        >
          <Col span={16}>Unallocated Income</Col>
          <Col span={8} className="balance_text">
            ${0}
          </Col>
        </Row>
      </Col>
    </Row>
      <Row justify="end">
        <Pagination
          total={5}
          showTotal={(total, range) => this.showTotalAndRange(total, range)}
          defaultPageSize={20}
          defaultCurrent={1}
          data-test-id={"pagination"}
        />
      </Row>
    </>);
  }
  // Customizable Area End  

  render() {
    return (
      <>
        <div>
          <div><SideBar /></div>
          <div><Row style={{ marginLeft: "79px" }}>
            <Col span={24}>
              <Card style={{ margin: "2em" }}>
                <Row align="middle" justify="center">
                  <Col xs={0} sm={0} md={1} lg={1} />
                  <Col style={{ marginTop: "40px", marginBottom: "25px" }} xs={24} sm={24} md={22} lg={22}>
                    <Row
                      style={{
                        color: "#545861",
                        fontSize: "22px",
                        fontWeight: 700,
                        lineHeight: " 1.2",
                        paddingBottom: "2em",
                      }}
                    >
                      Budgets
                    </Row>
                    {this.returnRenderData(this.state.cardLoading)}
                  </Col>
                  <Col xs={0} sm={0} md={1} lg={1} />
                </Row>
              </Card></Col> </Row>
          </div>
        </div>
      </>
    )
  }
}
