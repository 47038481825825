import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import AsyncStorage from '@react-native-async-storage/async-storage';
import NavigationResponseAdapter from "../../adapters/src/NavigationResponseAdapter";
import AccountsAdapter from "../../adapters/src/AccountsAdapter";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // screenProps: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token:string;
  errorMsg:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  navigationcallApi:string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationTabMessage),
      getName(MessageEnum.NavigationMenuToMessage)
    ]; 

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token:"ss",
      errorMsg: "",

    };
    new AccountsAdapter();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
  }

  async componentWillUnmount() {
    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
  }
  
  getNavigation(tokens:any): boolean {  
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokens
    };
    console.log("header  ", JSON.stringify(header));
    
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);
    this.navigationcallApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.navigationUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.ApiMethodType
    );
    // console.log('requestMessage@, ',requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // //runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      ////runEngine.debugLog("Message Recived", message);
      let tokend:any = message.getData(getName(MessageEnum.SessionResponseToken)) ?? await AsyncStorage.getItem("tokenLogin");
      console.log("check",tokend);
     // this.setState({ token: tokend});
      //console.log("tokendddd",this.state.token)
    }
    if (getName(MessageEnum.NavigationTabMessage) === message.id) {
      let token: any = await AsyncStorage.getItem("tokenLogin");
      console.log("Inside Navigation Menu");
      if (token) {
        console.log("InsideToken");
        this.getNavigation(token);
      }
    }

    if (getName(MessageEnum.NavigationMenuToMessage) === message.id) {
      var response = message.getData(
        getName(MessageEnum.NavigationMenuTargetMessage)
      );

      if (response) {
        console.log("RESPONSE: ", response);
        const msg: Message = new Message(
          getName(MessageEnum.AccountsNavigate)
        );

        msg.addData(
          getName(MessageEnum.NavigationTargetMessage), 
          response.data.screen_name
        )

        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          response.navigationProp
        );  
        this.send(msg); 
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
     
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if ((apiRequestCallId === this.navigationcallApi)) {
      if (responseJson && !responseJson.errors) {
        if (responseJson.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
          });
        } else {
          this.setState({
            //navigationData: responseJson.dashboardData,
            errorMsg: "",
           
          });
          console.log('Rest api response length', responseJson[0]?.data?.attributes?.items?.length);
          const navigationResponseAdapter = new NavigationResponseAdapter();
          const uploadData = {
            data: responseJson,
          };
          
          const msg: Message = new Message(
              getName(MessageEnum.NavigationResponseMessage)
            );
            msg.addData(
              getName(MessageEnum.NavigationTabDataMessage),
              uploadData
            );

            console.log("Broadcasting message");
            runEngine.sendMessage(msg.id, msg);
          // this.props.screenProps.getNavigationMenu(responseJson);
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        console.log("error resp", errorReponse, responseJson);
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
           
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            
          });
        }
      }
    }
    }
   
    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });
    // }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
   
    onPress: () => {
      console.log("text",this.state.txtInputValue)
      //this.()
    }
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  // Customizable Area End
}
