//@ts-nocheck
import {
    DeleteOutlined,
    FormOutlined
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Collapse,
    Form,
    Input,
    Menu,
    Modal,
    Pagination,
    Row,
    Select
} from "antd";
import React from 'react';
import { Link } from "react-router-dom";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import SideBar from "../../../components/src/SideBar";
import AddCategoriesController, { Props } from "./AddCategoriesController";
export const configJSON = require("./config");
const { Option } = Select;
const { Panel } = Collapse;


export default class AddCategories extends AddCategoriesController {
    constructor(props: Props) {
        super(props);
    }

    returnPagination(range: any, total: any) {
        if (range[0] > 0) {
            return `Showing ${range[0]}-${range[1]} of ${total} `;
        } else {
            return `Showing 1-5 of ${total} `;
        }
    }

    returnTotalBudgetAmountValue(item: any) {
        if (item.total_budget_amount) {
            return item.total_budget_amount;
        } else {
            return 0;
        }
    }

    returnShowInputData(item: any) {
        if (this.state.showItemInput && parseInt(this.state.editId) == item.category_id) {
            return (
                <Form
                    layout="vertical"
                    style={{ width: "100%" }}
                >
                    <Row>
                        <Col span={16}>
                            <Form.Item style={{ width: "80%" }} name="name" initialValue={item.category_name} >
                                <Input
                                    placeholder={configJSON.categoryName}
                                    id={"category_name" + item.category_id.toString()}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={10}>
                                    <Form.Item style={{ width: "80%" }} name="amount" initialValue={item.total_budget_amount} >
                                        <Input
                                            className="balance_text"
                                            type="number"
                                            placeholder={configJSON.yourAmount}
                                            id={"category_amount" + item.category_id.toString()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={14}>
                                    <Row
                                        justify="space-around"
                                        align="middle"
                                        style={{ fontSize: "20px" }}
                                    >
                                        <Button
                                            onClick={(e) => {
                                                this.onFinishItem(
                                                    { category_id: e.target.parentElement.id.split("category_save")[1] },
                                                    {
                                                        name: document.getElementById("category_name" + e.target.parentElement.id.split("category_save")[1]).value,
                                                        amount: document.getElementById("category_amount" + e.target.parentElement.id.split("category_save")[1]).value,
                                                    }
                                                )
                                            }}
                                            id={"category_save" + item.category_id.toString()}
                                            loading={this.state.itemLoading}
                                            type="primary"
                                            data-test-id={"submitForm"}
                                        >
                                            {configJSON.saveCaps}
                                        </Button>
                                        <FormOutlined
                                            disabled
                                            translate
                                            style={styles.iconBtns}
                                        />
                                        <DeleteOutlined
                                            disabled
                                            translate
                                            style={styles.iconBtns}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            );
        } else {
            return (
                <>
                    <Col span={16}>{item.category_name}</Col>
                    <Col span={8}>
                        <Row>
                            <Col span={10} className="balance_text">
                                ${this.returnTotalBudgetAmountValue(item)}
                            </Col>
                            <Col span={14}>
                                <Row
                                    justify="space-around"
                                    align="middle"
                                    style={{ fontSize: "20px" }}
                                    id={item.category_id.toString()}
                                    data-test-id={"parentRow"}
                                >
                                    <Button disabled>
                                        {configJSON.saveCaps}
                                    </Button>
                                    <FormOutlined
                                        translate
                                        style={styles.editBtn}
                                        data-test-id={"editData"}
                                        id={item.category_id.toString()}
                                        onClick={(e) => (
                                            this.setState({
                                                editData: item,
                                                showItemInput: true,
                                                editId: e.currentTarget.parentElement.id,
                                                itemLoading: false
                                            })
                                        )}
                                        rev={undefined}
                                    />
                                    <Button
                                        type="text"
                                        icon={
                                            <DeleteOutlined
                                                translate
                                                style={styles.editBtn}
                                                rev={undefined}
                                            />
                                        }
                                        id={item.category_id.toString()}
                                        onClick={(e) => (
                                            this.setState({
                                                editData: item,
                                                openDeleteModal: true,
                                                deleteId: e.currentTarget.parentElement.id
                                            })
                                        )}
                                        data-test-id={"deleteData"}
                                    />
                                    <Modal
                                        centered
                                        footer={false}
                                        visible={this.state.openDeleteModal}
                                        closeIcon={true}
                                        width="35%"
                                        title={
                                            <Row
                                                justify="center"
                                                style={styles.confirmDelete}
                                            >
                                                {configJSON.confirmDelete}
                                            </Row>
                                        }
                                        data-test-id={"confirmDelete"}
                                    >
                                        <Row justify="center">
                                            <Col span={18}>
                                                <Row justify="center">
                                                    <Col style={{ textAlign: "center" }} span={18}>
                                                        {configJSON.confirmDeleteMsg}
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: "4em" }}>
                                                    <Button
                                                        htmlType="submit"
                                                        style={styles.deletBtn}
                                                        onClick={() => this.handleDelete(item)}
                                                        data-test-id={"deleteBtn"}
                                                    >
                                                        {configJSON.deleteBtn}
                                                    </Button>
                                                </Row>
                                                <Row>
                                                    <Button
                                                        onClick={() => this.setState({ openDeleteModal: false })}
                                                        type="primary"
                                                        style={styles.cancelBtn}
                                                        data-test-id={"cancelBtn"}
                                                    >
                                                        {configJSON.cancelCaps}
                                                    </Button>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Modal>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </>
            )
        }
    }

    returnNestedSubDetails(dd: any) {
        if (dd?.sub_details?.length > 0) {
            return (
                dd?.sub_details.map((item: any) => {
                    return (
                        // Item component 
                        <Row
                            key={item.category_id}
                            style={{
                                padding: "10px",
                                borderTop: "1px solid #d9d9d9",
                            }}
                        >
                            {this.returnShowInputData(item)}
                        </Row >
                    );
                })
            )
        }
    }

    returnClassName() {
        if (this.state.openAddModal) {
            return "d-none";
        } else {
            return "";
        }
    }

    returnSubDetails(dd: any) {
        if (dd?.sub_details?.length > 0) {
            return (
                <Panel
                    header={
                        <Row style={{ color: "black", fontWeight: "bold" }}>
                            <Col span={16}>{dd.display_name}</Col>
                            <Col span={7}>
                                <Row justify="space-between"> <Col><span className="balance_text">
                                    ${dd.total_budget_amount}
                                </span></Col><Col>
                                        <Button
                                            style={styles.addCategoryBtn}
                                            onClick={() => (
                                                this.setState({
                                                    openAddModal: true,
                                                    expenseName: dd.category_name
                                                })
                                            )}
                                            data-test-id={"add-nested-category"}
                                        >
                                            {configJSON.addCategoryCaps}
                                        </Button>
                                        <Modal
                                            centered
                                            title={
                                                <Row
                                                    justify="center"
                                                    style={styles.addCategory}
                                                >
                                                    {configJSON.addCategory}
                                                </Row>
                                            }
                                            footer={false}
                                            visible={this.state.openAddModal}
                                            onOk={() => this.handleOk()}
                                            destroyOnClose
                                            onCancel={() => this.handleCancel()}
                                            width="35%"
                                            closeIcon={true}
                                            data-test-id={"add-nested-category-modal"}
                                        >
                                            <Row justify="center">
                                                <Col span={18}>
                                                    <Form
                                                        ref={this.form}
                                                        layout="vertical"
                                                        onFinish={(e) => this.onFinish(e)}
                                                    >
                                                        <Row>
                                                            <Form.Item style={{ width: "100%" }}>
                                                                <Select
                                                                    size="large"
                                                                    placeholder="Select Category"
                                                                    allowClear
                                                                    onChange={(e, index) => this.change(e, index)}>
                                                                    {this.state.categoryData?.map((values: any) => (
                                                                        <Option key={values?.attributes?.generic_name} value={values?.attributes?.id}>{values?.attributes?.generic_name}</Option>
                                                                    ))}
                                                                    <Option value="admin">{configJSON.admin}</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Row>
                                                        <Row style={{ marginTop: "2em" }}>
                                                            <Form.Item
                                                                style={{ width: "100%" }}
                                                                shouldUpdate
                                                            >
                                                                {() => (
                                                                    <Button
                                                                        type="primary"
                                                                        loading={this.state.loading}
                                                                        htmlType="submit"
                                                                        style={{
                                                                            borderRadius: "15px",
                                                                            width: "100%",
                                                                        }}

                                                                    >
                                                                        {configJSON.labelTitleText}
                                                                    </Button>
                                                                )}
                                                            </Form.Item>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Modal>
                                    </Col></Row>
                            </Col>
                        </Row>
                    }
                    className={this.returnClassName()}
                    key={dd.category_id}
                >
                    {this.returnNestedSubDetails(dd)}
                </Panel>
            )
        }
    }

    returnData() {
        if (this.state.data) {
            return this.state.data?.map((dd: any) => (
                <>
                    {this.returnSubDetails(dd)}
                </>
            ))
        }
    }

    returnCardLoading() {
        if (this.state.cardLoading) {
            return <LoadingWeb />;
        } else {
            return (
                <><Row style={{ marginBottom: "5em" }}>
                    <Col span={24}>
                        <Row
                            style={styles.categoryStyle}
                        >
                            <Col span={16}>{configJSON.category}</Col>
                            <Col span={8}></Col>
                        </Row>
                        <Collapse
                            accordion
                            collapsible="header"
                            bordered={false}
                            expandIconPosition="right"
                        >
                            {this.returnData()}
                        </Collapse>

                    </Col>
                </Row>
                    <Row justify="end">
                        <Pagination
                            total={5}
                            showTotal={(total, range) => this.returnPagination(range, total)}
                            defaultPageSize={20}
                            defaultCurrent={1}
                        />
                    </Row>
                </>
            )
        }
    }

    render() {
        return (
            <>
                <div>
                    <div><SideBar /></div>
                    <div><Row style={{ marginLeft: "79px" }}>
                        <Col span={24}>

                            <Card style={{ margin: "2em", padding: "24px" }}>
                                <Row style={styles.rowStyle}>
                                    <Row
                                        style={styles.categoryRow}
                                    >
                                        {configJSON.categories}
                                    </Row>
                                    <Row
                                        align="middle"
                                        justify="end"
                                        style={{ marginBottom: "16px" }}
                                    >
                                        <button
                                            onClick={() => this.setState({ openCategoryModal: true })}
                                            style={styles.addCategoryButton}
                                            data-test-id={"add-category-button"}
                                        >
                                            <Link
                                                to="/AddCategories"
                                                style={{
                                                    color: "#ffffff",
                                                }}
                                            >
                                                {configJSON.addCategoryCaps}
                                            </Link>
                                        </button>
                                        <Modal
                                            centered
                                            title={
                                                <Row
                                                    justify="center"
                                                    style={{
                                                        color: "#545861",
                                                        fontSize: "28px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {configJSON.addCategory}
                                                </Row>
                                            }
                                            destroyOnClose
                                            footer={false}
                                            visible={this.state.openCategoryModal}
                                            onOk={() => this.handleCategoryOk()}
                                            onCancel={() => this.handleCategoryCancel()}
                                            width="35%"
                                            closeIcon={true}
                                            data-test-id={"add-category-modal"}
                                        >
                                            <Row justify="center">
                                                <Col span={18}>
                                                    <Form
                                                        // form={form}
                                                        name="control-ref"
                                                        layout="vertical"
                                                        onFinish={(e) => this.onFinishAddCategory(e)}
                                                        data-test-id={"add-category-form"}
                                                    >
                                                        <Row>
                                                            <Form.Item
                                                                style={{ width: "100%" }}
                                                                name="categoryName"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please input your Category Name!",
                                                                    },
                                                                ]}
                                                                label={<span style={{ color: "gray" }}>Category Name</span>}
                                                            >
                                                                <Select
                                                                    size="large"
                                                                    placeholder="Select Category"
                                                                    allowClear
                                                                    onChange={(e, item) => this.change(e, item)}
                                                                    data-test-id={"select-category"}
                                                                >
                                                                    {this.state.categoryData?.map((values: any) => (
                                                                        <Option key={values?.attributes?.generic_name} value={values?.attributes?.id}>{values?.attributes?.generic_name}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Row>
                                                        <Row style={{ marginTop: "2em" }}>
                                                            <Form.Item style={{ width: "100%" }} shouldUpdate>
                                                                <Button
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    loading={this.state.loading}
                                                                    style={{ borderRadius: "15px", width: "100%" }}
                                                                >
                                                                    {configJSON.labelTitleText}
                                                                </Button>
                                                            </Form.Item>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Modal>
                                    </Row>
                                </Row>
                                {this.returnCardLoading()}</Card></Col> </Row>
                    </div>
                </div>
            </>
        )
    }
}

const styles = {
    addCategoryButton: {
        fontSize: 16,
        borderRadius: "40px",
        fontWeight: 600,
        backgroundColor: "#2DADE1",
        padding: "12px",
        display: "flex",
        alignItems: "center",
    },
    iconBtns: {
        padding: "5px",
        backgroundColor: "#f2f2f2",
        color: "gray",
    },
    editBtn: {
        padding: "5px",
        backgroundColor: "#f2f2f2",
        color: '#2dade1',
    },
    confirmDelete: {
        color: "#545861",
        fontSize: "28px",
        fontWeight: "bold",
    },
    deletBtn: {
        borderRadius: "15px",
        color: "#2dade1",
        border: "none",
        fontWeight: "bold",
        width: "100%",
        backgroundColor: "#f2f2f2",
        marginBottom: "2em",
    },
    cancelBtn: {
        borderRadius: "15px",
        width: "100%",
    },
    addCategoryBtn: {
        color: "#00ade2",
        border: "none",
        marginLeft: "10px",
        borderRadius: "5px",
        fontWeight: 600,
        backgroundColor: "#f2f2f2",
    },
    addCategory: {
        color: "#545861",
        fontSize: "28px",
        fontWeight: "bold",
    },
    categoryStyle: {
        borderTop: "1px solid #d9d9d9",
        fontWeight: "bolder",
        backgroundColor: "#f2f2f2",
        padding: "12px 16px",
        paddingRight: "40px",
        color: "black",
    },
    rowStyle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "2em",
    },
    categoryRow: {
        color: "#545861",
        fontSize: "22px",
        fontWeight: 700,
        lineHeight: " 1.2",
    }
}