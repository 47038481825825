//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  subCatagorymount: any;
  token: any;
  errorMsg: string;
  loading: boolean;
  disable: boolean;
  textInputs: any;
  finalData: any;
  necessityExpensesData: any;
  totalAmount: number;
  expensesAmount: string;
  accountId: string;
  discretioneryIncome: string;
  catagoryId: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class MonthlyNecessityExpensesController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiNecessityItemCallId: string = "";
  insertNecessityAmountApiCallId: string = "";
  _unsubscribe: any;
  loadingButton: any;
  IdApiCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionSaveMessage),
      // getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      textInputs: [],
      finalData: [],
      necessityExpensesData: [],
      subCatagorymount: [],
      errorMsg: "",
      expensesAmount: "$0",
      totalAmount: 0,
      token: "",
      loading: false,
      disable: true,
      accountId: "",
      discretioneryIncome: "",
      catagoryId: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    console.log("componentDidMount...");
    if (Platform.OS !== 'web') {
      this._unsubscribe = this.props.navigation.addListener("focus", () => {
        this.getToken();
      });
    }
  }

  getToken = async () => {
    const token = await AsyncStorage.getItem("tokenLogin");
    const data: any = await AsyncStorage.getItem("userData");
    const userData = JSON.parse(data);

    if (userData === null) {
      console.warn("userData null ============>>>>>>>>>>", userData);
    } else {
      console.warn("userData ============>>>>>>>>>>", userData);
    }

    // console.log("Touch Token", token);
    this.setState(
      { token: token, accountId: userData.data.id, loading: true },
      () => {
        // console.log("ACCOUNT ID:::", this.state.accountId)
        this.getCataId();
      }
    );
  };

  async componentWillUnmount() {
    if (Platform.OS !== 'web') {
      this._unsubscribe();
      runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        // subscription_expired?
        if (responseJson["subscription_expired?"] === true) {
          this.props.navigation.navigate("TrialExpiry");
        }
        if (apiRequestCallId === this.IdApiCallId) {
          this.getIdAPIData(responseJson);
        } else if (apiRequestCallId === this.insertNecessityAmountApiCallId) {
          this.getInsertNeccessityAPIData(responseJson);
        } else if (apiRequestCallId === this.apiNecessityItemCallId) {
          this.getNeccessityItemAPIData(responseJson, errorReponse);
        }
      }
    }
  }

  getNeccessityItemAPIData = (response: any, errorResponse: any) => {
    if (!response.error && response.data) {
      if (response.data.length === 0) {
        this.setState({
          errorMsg: "Data Not Found",
          dashboardData: response.data,
          loading: false,
        });
      } else {
        console.warn(
          "category list =======>>>>>>>>>>>>>>>>>>>>>>>>>",
          response.data
        );
        this.setState({
          dashboardData: response.data,
          errorMsg: "",
          loading: false,
        });
        let sum = 0;
        for (let i = 0; i < this.state.dashboardData.length; i++) {
          sum = sum + this.state.dashboardData[i].amount;
        }
        this.setState({ totalAmount: sum, disable: false });
      }
    } else {
      console.log("error resp", errorResponse);
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false,
        });
        // console.log(responseJson.data)
      } else {
        this.setState({
          errorMsg: errorResponse,
          loading: false,
        });
      }
    }
  };

  getInsertNeccessityAPIData = (response: any) => {
    if (!response.error) {
      this.props.navigation.navigate("OnboardingScreen", {
        discretioneryIncome: this.state.discretioneryIncome,
      });
      this.loadingButton.showLoading(false);
    } else {
      //Check Error Response
      alert(response.error.message);
      this.parseApiErrorResponse(response);
      this.loadingButton.showLoading(false);
    }
  };

  getIdAPIData = (response: any) => {
    if (!response.errors) {
      console.warn(
        "necessity_expense ===========================>>>>>>>>>>>>>>>>>>>>>>",
        response
      );
      this.setState({ catagoryId: response.data.id }, () => {
        this.getNecessityData();
      });
    } else {
      //Check Error Response
      console.warn("Error ===========================>>>>>>>>>>>>>>>>>>>>>>");
      this.parseApiErrorResponse(response);
    }
  };

  getNecessityData(): boolean {
    // Customizable Area Start

    console.log("accountsss", this.state.accountId);
    const header = {
      "Content-Type": configJSON.necessityContentType,
      token: this.state.token,
    };
    const httpBody = {
      data: {
        // category_id: this.state.catagoryId,
        category_type: "necessity_expense",
        // account_id: String(this.state.accountId),
        account_id: this.state.accountId,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);
    this.apiNecessityItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.necessityPostUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  saveAndContinue = () => {
    const header = {
      "Content-Type": configJSON.necessityContentType,
      token: this.state.token,
    };
    const data = {
      //type: "email_account",
      //attributes: attrs
    };

    const httpBody = {
      data: {
        // category_id: this.state.catagoryId,
        attributes: {
          category_type: "necessity_expense",
          category_name: "necessity_expense",
          account_id: this.state.accountId,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.insertNecessityAmountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveNecessityturl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCataId = () => {
    const header = {
      "Content-Type": configJSON.necessityContentType,
      token: this.state.token,
    };
    const httpBody = {
      data: {
        category_name: "necessity_expense",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.IdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIdurl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area Start
  onPressSubmit = {
    onPress: () => {
      this.saveAndContinue();
      console.log("parameter", this.state.discretioneryIncome);
    },
  };
  expensesAmountPropst = {};

  _onPressHandler() {
    this.loadingButton.showLoading(true);
  }

  handleSave = () => {
    this.props.navigation.navigate("AddMonthlyNecessityExpensesWeb")
  }

  handleBack = () => {
    this.props.navigation.navigate("OnboardingScreenWeb")
  }
  // Customizable Area End
}