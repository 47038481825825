//@ts-nocheck
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";

// Customizable Area Start
import { columnData } from "./AddNecessityExpensesCategoryController";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string | null;
  errorMsg: string;
  loading: boolean;
  accountId: any;
  Category: string;
  availableAmount: any,
  budgetedAmount: any,
  cardLoading: any,
  Categoryamt: any;
  subcatId: any;
  columnData: any;
  tableData: any;
  filterKeyword: any;
  loadingback: any;
  overflow: any;
  zIndexing: any;
  progress: any;
  displayFlex: any;
  showCustom: any;
  fromDate: any;
  toDate: any;

  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DiscretioneryExpensesController extends BlockComponent<Props, S, SS> {
  apiNecessityItemCallId: string = "";
  insertNecessityAmountApiCallId: string = "";
  apiAddnewSubcategery: string = "";
  // dashboardApiCallId: string = "";
  //apiGetQueryStrinurl: string = "";
  categoryDataApi: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      showCustom: false,
      loadingback: "none",
      overflow: "hidden",
      zIndexing: 2,
      progress: 0,
      fromDate: "",
      toDate: "",
      displayFlex: "flex",
      cardLoading: true,
      dashboardData: [],
      errorMsg: "",
      availableAmount: "",
      budgetedAmount: "",
      token: "",
      loading: false,
      Category: "",
      Categoryamt: 0,
      accountId: 0,
      subcatId: 0,
      filterKeyword: "",
      columnData: columnData,
      tableData: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  getHistoryForDashboard = async () => {
    this.setState({ cardLoading: true })
    const result = await axios.get(
      `${configJSON.baseURL}bx_block_categories/history_for_budgets?category_id=${localStorage.getItem("category_id") || ""}&keyword=${this.state.filterKeyword}&token=${localStorage.getItem("loginToken")}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "plaid-account-id": localStorage.getItem("plaid_acc_id"),
          "access-token": localStorage.getItem("access_token"),
          "token": localStorage.getItem("loginToken")
        },
      }
    ).then((response: any) => {
      this.setState({ cardLoading: false })

    }).catch((error: any) => {
      if (error.response.status == "404") {
        this.setState({ cardLoading: false });
        openNotification(error?.response?.data?.error, "error")

      }
    });
  }

  keywordFilter = (values: any) => {
    console.log("Filter", values)
    if (values == "custom") {
      this.setState({ showCustom: true })
    } else {
      this.setState({ filterKeyword: values, showCustom: false, cardLoading: true }, () => {
        this.onMount();
      })
    }
  }
  handlerCustomDate = (dates: any, dateString: any) => {
    console.log("Dates", dates)
    console.log("Dates dateString", dateString)
    this.setState({ fromDate: dateString[0], toDate: dateString[1], cardLoading: true }, () => {
      this.getAllCustomTransaction(this.state.fromDate, this.state.toDate);
    })
  }

  getAllCustomTransaction = async (fromDate: any, toDate: any) => {
    const result = await axios.get(
      `${configJSON.baseURL}bx_block_categories/history_for_budgets?fromDate=${fromDate}&toDate=${toDate}&category_id=1`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "plaid-account-id": localStorage.getItem("plaid_acc_id"),
          "access-token": localStorage.getItem("access_token"),
          "token": localStorage.getItem("loginToken")
        },
      }
    ).then((response: any) => {
      const data = response?.data?.data?.map((values: any) => ({
        key: values.id,
        account: <div>Account {values.account_id}
          <br />
          <span>{values.transaction_date}</span>
        </div>,
        desc: values.description == null ? "No Description" : values.description,
        amount: values.amount,
      }))
      this.setState({
        tableData: data, cardLoading: false, showCustom: false
      })
    }).catch((error: any) => {
      if (error.response.status == "404") {
        this.setState({ cardLoading: false });
        openNotification(error?.response?.data?.error, "error")

      }
    });
  }

  async componentDidMount() {
    this.onMount();
    // this.getHistoryForDashboard()
    var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
  }

  onMount = async () => {
    this.setState({ cardLoading: true })
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      "token": localStorage.getItem("loginToken"),
      "plaid-account-id": localStorage.getItem("plaid_acc_id"),
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.categoryDataApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseURL}bx_block_categories/history_for_budgets?category_id=${localStorage.getItem("category_id")}&keyword=${this.state.filterKeyword}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  categoryDataSuccess = (response: any) => {
    this.setState({ availableAmount: response.total_available_amount_cat })
    this.setState({ budgetedAmount: response.budgeted_amount })
    const responseData = response.data
    if (Array.isArray(responseData)) {
      const data = responseData.map((values: any) => ({
        key: values.category_id,
        category: values.category_name,
        budgeted: values.budgeted_amount,
        available: values.availableamount,
      }))
      this.setState({ tableData: data, cardLoading: false })
    }
  }

  categoryDataFailure = (error: any) => {
    if (error.response.status == "404") {
      this.setState({ cardLoading: false });
      openNotification(error?.response?.data?.error, "error")
    }
  };

  addcatagoryData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.addcatagoryContentType,
      token: this.state.token
    };
    console.log("header  ", JSON.stringify(header));

    const httpBody = {
      "data":
      {
        "attributes":
        {
          "category_id": this.state.subcatId,
          "name": this.state.Category,
          "amount": this.state.Categoryamt,
          "account_id": this.state.accountId,

        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);
    this.apiAddnewSubcategery = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddnewSubcategoryPostUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.necessityApiMethodType
    );
    //console.log('requestMessage@, ',requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  apiAddnewSubcategerySuccess = () => {
    this.props.navigation.navigate("MonthlyDiscretionaryExpenses")
  }

  apiAddnewSubcategeryFailure = (responseJson: any) => {
    this.parseApiErrorResponse(responseJson);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("receive...");
    console.log("hi")
    console.log(this.props)
    //runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.setState({ accountId: localStorage.getItem("accountId") });
      this.setState({ token: localStorage.getItem("loginToken")});
      // token: localStorage.getItem("loginToken"),
      // accountId: localStorage.getItem("accountId"),
      // subcatId: localStorage.getItem("subCategory_id")
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.successCallBack(apiRequestCallId, responseJson);
      } else {
        this.failureCallBack(apiRequestCallId, responseJson);
      }
    }
  }
  // Customizable Area Start
  successCallBack = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiAddnewSubcategery) {
      this.apiAddnewSubcategerySuccess();
    } else if (apiRequestCallId === this.categoryDataApi) {
      this.categoryDataSuccess(responseJson);
    }
  }

  failureCallBack = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiAddnewSubcategery) {
      this.apiAddnewSubcategeryFailure(responseJson);
    } else if (apiRequestCallId === this.categoryDataApi) {
      this.categoryDataFailure(responseJson);
    }
  }
  onPressSubmit = {

    onPress: () => this.addcatagoryData(),

  }
  setCategoryTxt = (text: string) => {
    this.setState({ Category: text });
  };
  setCategoryAmt = (text: any) => {
    this.setState({ Categoryamt: text });
  };

  // Customizable Area End
}