import React from "react";

import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
export const configJSON = require("./config");
// @ts-ignore
import TopNav from "../../../components/src/TopNav.js";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import { BlockComponent } from "../../../framework/src/BlockComponent";


export interface Props {
}
interface S {
  cardLoading: boolean;
  loading: boolean;
}
interface SS {
  id: any;
}

export default class VerifyEmailWeb extends BlockComponent<Props, S, SS>{
  async componentDidMount() {
    this.verifyAcc();
  }
  verifyAcc = async () => {
    const vToken = location.pathname.split("/").pop();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email')
    const result = await axios.get(
      `${configJSON.baseURL
      }account_block/accounts/email_confirmations?token=${vToken}&device_type=web`
    );
    if (result) {
      openNotification(result.data.message, "success");
      window.location.href = `/NewPasswordWeb/${email}/${vToken}`
    } else {
      window.location.href = "/EmailAccountLoginWeb";
    }
  };
  render() {
    return (
      <>
        <TopNav />
        <LoadingWeb />
      </>
    );
  }
};
