// @ts-nocheck
import {
  Button,
  Card,
  Col,
  DatePicker,
  Image,
  Pagination,
  Row,
  Select,
} from "antd";
import {
  RightOutlined
} from "@ant-design/icons";
import React from "react";
import { Redirect } from "react-router-dom";
import SideBar from "../../../components/src/SideBar";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import AccountDetailsController, { Props } from "./AccountDetailsController";
export const configJSON = require("./config");
const linkLogoIcon = require("../assets/linked_acc__btn_logo.png");
const { Option } = Select;

const { RangePicker } = DatePicker;

export default class AccountDetailsWeb extends AccountDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const { redirect, cardLoading, showCal, apiData } = this.state;
    return (
      //Merge Engine DefaultContainer
      <>
        {redirect ? (<Redirect push to="/EmailAccountLoginWeb" />) : null}
        < div >
          <div><SideBar /></div>
          <div>
            <Row style={{ marginLeft: "79px" }}>
              <Col span={24}>
                <Card style={{ margin: "2em" }}>
                  {cardLoading ?
                    <LoadingWeb />
                    : <>
                      <Row align="middle" justify="center">
                        <Col xs={0} sm={0} md={1} lg={1} />
                        <Col style={{ marginTop: "40px", marginBottom: "25px" }} xs={24} sm={24} md={22} lg={22}>
                          <Row
                            justify="space-between"
                            style={{
                              color: "#545861",
                              fontSize: "22px",
                              fontWeight: 700,
                              lineHeight: " 1.2",
                              // paddingBottom: "2em",
                              marginBottom: "18px"
                            }}
                          >
                            <Col style={{ marginBottom: "18px" }}>{configJSON.accounts}</Col>
                            <Col style={{ marginBottom: "16px" }}>
                              <Row style={{ padding: "0px 10px", background: "rgb(45,173,255)" }}>
                                <Button type="link" data-test-id={"navigate-linked-acc"}
                                  onClick={() => window.location.href = "LinkedAccountsWelcomeScreenWeb?page=linking_account"}
                                >
                                  <Col span={21} style={{ fontWeight: "normal", fontSize: 15, color: "white", display: "contents" }}>
                                    <Image src={linkLogoIcon}
                                      preview={false}
                                      height="15px"
                                      width="15px"
                                      style={{ marginTop: "3px", marginRight: "10px" }}
                                    />
                                    <span style={{ marginLeft: "10px" }}>{configJSON.linkAccount}</span>
                                  </Col>
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Select
                              defaultValue="Month to date"
                              style={{ width: 220, marginBottom: "20px" }}
                              data-test-id={"change-sort"}
                              onChange={(value) => this.handleChange(value)}
                            >
                              <Option value="month_to_date">{configJSON.monthToDate}</Option>
                              <Option value="year_to_date">{configJSON.yearToDate}</Option>
                              <Option value="last_month">{configJSON.lastMonth}</Option>
                              <Option value="custom">{configJSON.custom}</Option>
                            </Select>
                            {showCal ? <RangePicker style={{ height: "32px", marginLeft: "5px" }}
                              onChange={(dates) => this.handleCustomDate(dates)}
                              data-test-id={"range-picker-component"}
                              // defaultValue={[moment('2015/01/01', "YYYY-MM-DD"), moment('2015/01/01', "YYYY-MM-DD")]}
                              format={"YYYY-MM-DD"}
                            /> : ""}
                          </Row>
                          <Row style={{ marginBottom: "5em" }}>
                            <Col span={24}>
                              <Row
                                style={{
                                  borderTop: "1px solid #d9d9d9",
                                  fontWeight: "bolder",
                                  backgroundColor: "#f2f2f2",
                                  padding: "12px 16px",
                                  paddingRight: "40px",
                                  color: "black",
                                }}
                              >
                                <Col span={16}>{configJSON.account}</Col>
                                <Col span={8}>{configJSON.availableBalance}</Col>
                              </Row>
                              {apiData &&
                                apiData.map((item: any) => (

                                  <Row
                                    key={item?.account_id}
                                    style={{
                                      borderTop: "1px solid #d9d9d9",
                                      color: "black",
                                      fontWeight: "bold",
                                      padding: "12px 16px",
                                      paddingRight: "40px",
                                    }}
                                  >
                                    <Col span={16}>{item.name}</Col>
                                    <Col span={8} className="balance_text">
                                      <Row justify="space-between">
                                        <Col> ${item?.balance ? item?.balance : 0}</Col>
                                        <Col>
                                          <Button
                                            onClick={() =>
                                              this.props.navigation.navigate(
                                                "AccountsPageWeb", { accountId: item?.id }
                                              )
                                            }
                                            style={{
                                              border: "none",
                                              backgroundColor: "#f2f2f2",
                                              color: "#2dade1",
                                            }}
                                            icon={<RightOutlined translate rev={undefined} />}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                ))}
                            </Col>
                          </Row>
                          <Row justify="end">
                            <Pagination
                              total={apiData?.length}
                              showTotal={(total, range) =>
                                range[0] > 0
                                  ? `Showing ${range[0]}-${range[1]} of ${total} `
                                  : `Showing 1-${total > 10 ? 10 : total} of ${total} `
                              }
                              showSizeChanger
                              pageSizeOptions={["10", "20", "30", "40"]}
                              defaultPageSize={10}
                              defaultCurrent={1}
                            />
                          </Row>
                        </Col>
                        <Col xs={0} sm={0} md={1} lg={1} />
                      </Row>

                    </>} </Card>
              </Col>
            </Row></div>
        </div>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}
