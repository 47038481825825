//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  route: any;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  transactionid: any;
  data: any;
  bankData: any;
  availableBalanceAmount: string;
  withdrawalsOfTheDay: string;
  DepositOTheDay: string;
  transData: any;
  sortTxtTitle: any;
  dateDisplay: any;
  dateDisplay001: any;
  visibility: any;
  visibility001: any;
  loading: boolean;
  modalVisible: any;
  showCal: boolean;
  selectSort: string;
  cardLoading: boolean;
  redirect: boolean;
  apiData: any;
  customDate: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

class AccountDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  finicity_customer_id: any;
  token: any;
  plaid_acc_id: any;
  create_date: any = "";
  finicity_token: any;
  apiFilterCallId: any;
  accountDetailsAPIcallId: any;
  customerDataApiId: any;
  //   customerData: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    this.handleCustomDate = this.handleCustomDate.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      transactionid: "",
      data: [
        {
          value: "Bank Of India ",
          id: 1,
        },
        {
          value: "SBI",
          id: 2,
        },
        {
          value: "PNB",
          id: 3,
        },
      ],
      bankData: [],
      availableBalanceAmount: "",
      withdrawalsOfTheDay: "",
      DepositOTheDay: "",
      transData: [],
      sortTxtTitle: "Month to Date",
      dateDisplay: "",
      dateDisplay001: "",
      visibility: false,
      visibility001: false,
      loading: true,
      modalVisible: false,
      showCal: false,
      selectSort: "month_to_date",
      cardLoading: true,
      redirect: false,
      apiData: "",
      customDate: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    this.getData();
  }

  async componentDidUpdate(prevProps: any, prevState: { selectSort: string; }) {
    if (prevState.selectSort !== this.state.selectSort) this.getData();
  }

  getToken = async () => {
    try {
      const token = await AsyncStorage.getItem("tokenLogin");
      const plaid_acc_id = await AsyncStorage.getItem("plaid_acc_id");
      const finicity_customer_id = await AsyncStorage.getItem(
        "finicity_customer_id"
      );
      const finicity_token = await AsyncStorage.getItem("finicity_token");
      const create_date = await AsyncStorage.getItem("create_date");
      this.token = token;
      this.plaid_acc_id = plaid_acc_id;
      this.finicity_customer_id = finicity_customer_id;
      this.create_date = create_date;
      this.finicity_token = finicity_token;
      this.accountsDetailsPageAPIcall();
      this.filterApiCall("month_to_date");
    } catch (e) {
      // error reading value
    }
  };

  async componentWillUnmount() {
    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiFilterCallId) {
        this.getFilterData(responseJson);
      } else if (apiRequestCallId === this.accountDetailsAPIcallId) {
        this.getAccountDetailData(responseJson, errorReponse);
      } else if (apiRequestCallId === this.customerDataApiId) {
        this.getCustomerResponse(responseJson, errorReponse);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleChange = (value: any) => {
    if (value === "custom") {
      this.setState({ showCal: true })
    }
    else {
      this.setState({
        selectSort: value
      })
      this.setState({ showCal: false })
    }
  };


  getData = async () => {
    let startDate = moment().startOf("month").unix();
    let endDate = moment().unix();
    if (this.state.selectSort === "year_to_date") {
      startDate = moment().startOf("year").unix();
      endDate = moment().unix();
    } else if (this.state.selectSort === "month_to_date") {
      startDate = moment().startOf("month").unix();
      endDate = moment().unix();
    } else if (this.state.selectSort === "last_month") {
      startDate = moment().subtract(1, "months").startOf("month").unix();
      endDate = moment().subtract(1, "months").endOf("month").unix()
    } else if (this.state.selectSort === "custom") {
      startDate = moment(this.state.customDate[0]).unix()
      endDate = moment(this.state.customDate[1]).unix()
    }
    const customerDataEndpoint = `${configJSON.baseURL}bx_block_finicity/customer/${localStorage.getItem("customerId")}/all_accounts?fromDate=${startDate}&toDate=${endDate}&start=1&limit=1000&sort=desc&includePending=false&send_by_email=false`;
    // headers of API
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("loginToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // Matches ID's
    this.customerDataApiId = requestMessage.messageId;
    // Added API URL
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customerDataEndpoint
    );
    // Attached header in API
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Attached API method in API
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  handleCustomDate = (dates: any) => {
    this.setState({
      customDate: dates,
      selectSort: "custom"
    })
  }

  getCustomerResponse = (response: any, errorResponse: any) => {
    if (response && !response.errors) {
      console.warn(
        "response of Accounts Details ====================>>>>>>>>>>>>>>>>>>>>>>",
        response
      );
      this.setState({
        cardLoading: false,
        apiData: response.data
      })
    } else {
      console.log("ERROR in API");
      this.setState({
        cardLoading: false,
        redirect: false
      })
    }
  }
  getFilterData = (response: any) => {
    if (response && !response.errors) {
      console.log(
        "responseJson ===============>>>>>>>>>>>>>>>>>>>>",
        JSON.stringify(response)
      );
      // subscription_expired?
      if (response["subscription_expired"] === true) {
        this.props.navigation.navigate("TrialExpiry");
      }
      this.setState({
        transData: response.data,
      });
    } else {
      console.log("ERROR in API");
    }
  };

  getAccountDetailData = (response: any, errorResponse: any) => {
    if (response && !response.errors) {
      console.warn(
        "response of Accounts Details ====================>>>>>>>>>>>>>>>>>>>>>>",
        response
      );
      // subscription_expired?
      if (response["subscription_expired"] === true) {
        this.props.navigation.navigate("TrialExpiry");
      }
      const availableBalanceAmount = response?.data?.table?.balance;
      const withdrawalsOfTheDay = response?.data?.table?.balanceDate;
      const DepositOTheDay = response?.data?.table?.createdDate;
      this.setState({
        loading: false,
        availableBalanceAmount: availableBalanceAmount,
        withdrawalsOfTheDay: withdrawalsOfTheDay,
        DepositOTheDay: DepositOTheDay,
      });
    } else {
      console.log("ERROR in API");
    }

    if (errorResponse) {
      console.log(errorResponse);
    }
  };

  ActionSheet: any;
  handleConfirm = (date: any) => {
    console.warn("A date has been picked: ", date);

    const event = new Date(date);
    let date001 = JSON.stringify(event);
    date001 = date001.slice(1, 11);
    console.log("date001====>>>>>>>>>>>>>>", date001);

    this.setState(
      {
        // dateDisplay: Math.round(date.getTime() / 1000),
        dateDisplay: date001,
        visibility: false,
      }
      // () => this.customFilterCall()
    );
  };

  handleConfirm001 = (date: any) => {
    console.warn("A date has been picked: ", date);

    const event = new Date(date);
    let date001 = JSON.stringify(event);
    date001 = date001.slice(1, 11);
    console.log("date001====>>>>>>>>>>>>>>", date001);

    this.setState(
      {
        // dateDisplay: Math.round(date.getTime() / 1000),
        dateDisplay001: date001,
        visibility001: false,
      },

      () => this.customFilterCall()
    );
    setTimeout(() => {
      this.setState({
        modalVisible: false,
      });
    }, 10000);
  };

  onPressCancel = () => {
    this.setState({ visibility: false });
  };

  onPressButton = () => {
    console.log("Yes;");
    this.setState({ visibility: !this.state.visibility });
  };

  onPressButton001 = () => {
    console.log("Yes;");
    this.setState({ visibility001: !this.state.visibility001 });
  };

  sleep = (ms: any) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // Abhijeet
  accountsDetailsPageAPIcall = () => {
    // Customizable Area Start

    let account_id = this.props.route.params.item.id;
    const accountDetailsAPIEndPoint =
      configJSON.accountPageAPIEndPoint +
      `${this.finicity_customer_id}/accounts/${account_id}`;
    // headers of API
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: this.token,
      fincity_token: this.finicity_token,
      date: this.create_date,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // Matches ID's
    this.accountDetailsAPIcallId = requestMessage.messageId;
    // Added API URL
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      accountDetailsAPIEndPoint
    );
    // Attached header in API
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Attached API method in API
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    // Comments
    console.warn(
      "header ================>>>>>>>>>>>>>>>",
      JSON.stringify(header)
    );
    // console.warn(
    //   "httpBody ================>>>>>>>>>>>>>> ",
    //   JSON.stringify(httpBody)
    // );
    console.warn(
      "API Method ================>>>>>>>>>>>>>> ",
      configJSON.getMethod
    );
    console.warn(
      "requestMessage ================>>>>>>>>>>>>>> ",
      requestMessage
    );
    // Customizable Area End
    return true;
  };

  filterApiCall = (val: any) => {
    console.warn("I am in filter ====>>>>>>");
    this.ActionSheet?.hide();
    const dateAndTime = moment().unix();
    console.warn("dateAndTime=====>>>>>>>>", dateAndTime);
    let account_id = this.props.route.params.item.id;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token,
      "Finicity-App-Key": "5fa04c246b7bbb69d929e3642d1cfb91",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);

    this.apiFilterCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterApiEndPoint +
      this.finicity_customer_id +
      "/accounts/" +
      account_id +
      "/transactions?fromDate=1607450357&toDate=" +
      dateAndTime +
      "&start=1&limit=1000&sort=desc&includePending=false&keyword=" +
      val +
      "&send_by_email=false"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  customFilterCall = () => {
    let account_id = this.props?.route?.params?.item.id;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token,
      "Finicity-App-Key": "5fa04c246b7bbb69d929e3642d1cfb91",
    };
    console.log("header", JSON.stringify(header));

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);

    this.apiFilterCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterApiEndPoint +
      this.finicity_customer_id +
      "/accounts/" +
      account_id +
      "/transactions?fromDate=" +
      moment(this.state.dateDisplay, "YYYY-MM-DD").unix() +
      "&toDate=" +
      moment(this.state.dateDisplay001, "YYYY-MM-DD").unix() +
      "&start=1&limit=1000&sort=desc&includePending=false&keyword=custom&send_by_email=false&date=" +
      moment(this.state.dateDisplay001, "YYYY-MM-DD").unix()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  toggleModal(visible: any) {
    this.setState({ modalVisible: visible });
  }

  // Customizable Area End
}

export default AccountDetailsController