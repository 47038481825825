import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import axios from "axios";

import AsyncStorage from "@react-native-async-storage/async-storage";

//@ts-ignore
import { openNotification } from "../../../components/src/Notification.web";
import React from "react";
// import ImageUploader from "react-images-upload";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  phoneNumber: string;
  comments: string;
  enableField: boolean;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  notification_status: boolean;
  cardLoading: boolean;
  activeCreatedAt: string;
  isVisible: boolean;
  previewVisible: boolean,
  previewTitle: any,
  fileList: any,
  file: any;
  name: string;
  description: string;
  token: string;
  expandIconPosition: string;
  loading: any;
  loadingback: any;
  overflow: any;
  zIndexing: any;
  progress: any;
  fileDataURL: any;
  first_name: any;
  last_name: any;
  email: string;
  pictures: any;
  pictureFiles: string;
  pictureDataURLs: string;
  image: any;
  previewImage: any;
  profile: any;
  previewImageUpload: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // contactUsApiCallId: any;
  token: any;
  contactUsAPIcallId: any;
  loadingButton: any;

  deleteContactApiCallId: any;
  inputRef = React.createRef<HTMLInputElement>();
  addContactApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      previewImageUpload: "",
      profile: "1",
      image: null,
      cardLoading: true,
      notification_status: true,
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: '',
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      file: "",
      name: "",
      email: "",
      description: "",
      expandIconPosition: "right",
      loading: false,
      loadingback: "transparent",
      overflow: "hidden",
      zIndexing: 0,
      progress: 0,
      fileDataURL: "",
      first_name: "",
      last_name: "",
      pictures: [],
      pictureFiles: "",
      pictureDataURLs: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentWillUnmount() {
    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    //
    this.getToken();

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {});
    }
    this.getprofiledetails();
    this.getProfileImage();
    // this.getToken();

    // if (this.isPlatformWeb() === false) {
    //   this.props.navigation.addListener("willFocus", () => {
    //   });
    // }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // Abhijeet
      if (apiRequestCallId === this.contactUsAPIcallId) {
        // if (responseJson && !responseJson.errors) {
        if (responseJson) {
          console.warn(
            "response of contact Us API call ====================>>>>>>>>>>>>>>>>>>>>>>",
            responseJson
          );
          // subscription_expired?
          if (responseJson["subscription_expired?"] === true) {
            this.props.navigation.navigate("TrialExpiry");
          }
          this.props.navigation.navigate("Dashboard");
          this.loadingButton.showLoading(false);

          alert("Feedback submitted successfully !!!");
        } else {
          console.warn(
            "I am in Error ===================>>>>>>>>>>>>>>>>>>>>>>",
            errorReponse
          );
        }
      }
    }

    // Customizable Area End
  }

  // Added----------------------------------------------------------
  //email
  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };
  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  //name
  txtInputNameWebProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });
      //@ts-ignore
      this.txtInputNameProps.value = text;
    },
  };






  txtInputNameMobileProps = {
    ...this.txtInputNameWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  txtInputNameProps = this.isPlatformWeb()
    ? this.txtInputNameWebProps
    : this.txtInputNameMobileProps;

  txtInputDescriptionWebProps = {
    onChangeText: (text: string) => {
      this.setState({ description: text });
      //@ts-ignore
      this.txtInputDescriptionProps.value = text;
    },
  };
  txtInputDescriptionMobileProps = {
    ...this.txtInputDescriptionWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  txtInputDescriptionProps = this.isPlatformWeb()
    ? this.txtInputDescriptionWebProps
    : this.txtInputDescriptionMobileProps;
  //----------------------------------------------------------------

  // Customizable Area Start

  // onDrop(pictureFiles, pictureDataURLs) {
  //   this.setState({
  //     pictures: this.state.pictures.concat(pictureFiles)
  //   });
  // }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handleSwitch = async (checked:any) => {  
    const data = {
      "notification_enabled": checked
    };
    await axios.put(`${configJSON.baseURL}bx_block_notifications/user_notification`, data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("loginToken"),
        },
      })
      .then((result) => {
        this.getprofiledetails()
        openNotification(result.data.message,"success")
      })
      .catch((err) => {
        console.log(err); 
      })
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    if (files && files[0]) {
      // const image = await this.getBase64(files[0]);
      // this.setState({ previewImage: image }, () => {
      //   this.imageProfileUpload(this.state.previewImage.split(",")[1]);
      // })
      const image = files[0]
      this.imageProfileUpload(image)
    }
  }


  onFinish = async (value: any) => {
    this.setState({ loading: true })
    const data = {
      data: value
    };
    const result = await axios.post(
      `${configJSON.baseUrl}bx_block_contact_us/contacts`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("loginToken")
        }
      }
    );
    if (result.data.success) {
      this.setState({ loading: false })
      openNotification("Your message has been sent.", "success");
    }

  };


  onFinishProfile = async (value: any) => {
    this.setState({ loading: true })
    const data = {
      data: {
        attributes: value,
      },
    };
    const result = await axios.put(
      `${configJSON.baseURL}account_block/update_profile`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("loginToken")
        }
      }
    );
    if (result.data) {
      localStorage.setItem("name", result.data.data.first_name)
      this.setState({ profile: "1" }, () => {
        this.getprofiledetails();
        this.setState({ loading: false })
      })
      openNotification("Your profile has been updated", "success");
    }
  };

  getprofiledetails = async () => {
    this.setState({cardLoading:true})
    const result = await axios.get(`${configJSON.baseURL}bx_block_profile/profile_info`,
      {
        headers: {
          token: localStorage.getItem("loginToken")
        }
      }
    )
    if (result) {
      // console.log('result from getprofiledetails', result);
      this.setState({ first_name: result?.data?.data?.attributes?.first_name, last_name: result?.data?.data?.attributes?.last_name, email: result?.data?.data?.attributes?.email, notification_status: result?.data?.data?.attributes?.notification_enabled, cardLoading: false })
    }
  };
  getProfileImage = async () => {
    const result = await axios.get(`${configJSON.baseURL}bx_block_profile/get_profile_image`,
      {
        headers: {
          token: localStorage.getItem("loginToken")
        }
      }
    )

    if (result) {
      this.setState({ previewImage: result?.data?.image_url }, () => {
        // localStorage.setItem("imagePic", result?.data?.image_url)
      })
    }
  }
  imageProfileUpload = async (data64: any) => {
    console.log("Data from the function", data64)
    // const req = {
    //   file: { data: data64 }
    // };
    const req = new FormData();
    req.append('file', data64);
    let result = await axios.post(
      `${configJSON.baseURL}bx_block_profile/upload_profile_image`,
      req,
      {
        headers: {
          token: localStorage.getItem("loginToken")
        }
      }
    )
    if (result) {
      // localStorage.setItem("imagePic", result?.data?.url)
      openNotification("Profile image is uploaded successfully", "success");
      window.location.reload();
    }


  }
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email)
      === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.contactUsList)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          attributes: {
            name: this.state.name.trim(),
            email: this.state.email.trim(),
            phone_number: this.state.phoneNumber.trim(),
            description: this.state.comments.trim(),
          },
        },
      };
      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getToken = async () => {
    try {
      const tokenVar = await AsyncStorage.getItem("tokenLogin");
      this.token = tokenVar;
      console.log(
        "this.token ######################>>>>>>>>>>>>>>>>>>",
        this.token
      );
      if (this.token !== null) {
        // We have data!!
        console.log(
          "this.token ######################>>>>>>>>>>>>>>>>>>",
          this.token
        );
      }
    } catch (error) {
      // Error retrieving data
    }
  };

  // Abhijeet
  contactUsAPICall = () => {
    // Customizable Area Start
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.description === ""
    ) {
      alert("Enter name, email & feedback !!");
      this.loadingButton.showLoading(false);
      return;
    }

    // headers of API
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: this.token,
    };

    // httpBody of API
    const httpBody = {
      data: {
        name: this.state.name,
        email: this.state.email,
        description: this.state.description,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // Matches ID's
    this.contactUsAPIcallId = requestMessage.messageId;

    // ================== API URL ====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsAPIEndPoint
    );
    console.warn(
      "API URL ================>>>>>>>>>>>>>> ",
      configJSON.contactUsAPIEndPoint
    );

    // ================== API method =================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    console.warn(
      "API Method ================>>>>>>>>>>>>>> ",
      configJSON.postMethod
    );

    // ================== httpBody =====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    console.warn(
      "httpBody ================>>>>>>>>>>>>>> ",
      JSON.stringify(httpBody)
    );

    // ================== header ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    console.warn(
      "header ================>>>>>>>>>>>>>>>",
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  };

  _onPressHandler() {
    this.loadingButton.showLoading(true);
  }

  // _errorMsg() {
  //   alert("Enter the name, email and feedback !!!");
  //   this.loadingButton.showLoading(false);
  // }
  // Customizable Area End
}