import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef } from "react";
import { openErrorNotification, openNotification } from "../../../components/src/Notification.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  data: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  token: any;
  customer: string;
  accountId: string;
  tabData: any;
  dashboarddrawerData: any;
  userName: any;
  drawerData: any;
  categoryData: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class OnBoardingScreenWebController extends BlockComponent<Props, S, SS> {
  apiBoardingCallId: string = "";
  _unsubscribe: any;
  dashboardApiiCallId: any;
  getAllAccountApiId: any;
  getDashboardDataApiId: any;
  checkSubsscriptionApiId: any;
  form: any = createRef();


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.onFinish = this.onFinish.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationRMessage),
    ];

    this.state = {
      loading: false,
      token: "",
      customer: "",
      accountId: "",
      tabData: [],
      dashboarddrawerData: [],
      userName: "",
      drawerData: "",
      categoryData: []
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getAllAccounts();
  }


  getAllAccounts = async () => {

    // Endpoint of update item API
    const apiEndpoint = `${configJSON.baseURL}bx_block_finicity/customer/${localStorage.getItem("customerId")}/all_accounts`;

    // headers of API
    const header = {
      "Content-Type": configJSON.contentTypeApi,
      token: localStorage.getItem("loginToken"),
    };

    // httpBody of API

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // ================== Matches ID's ====================================
    this.getAllAccountApiId = requestMessage.messageId;

    // ================== API URL ====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );

    // ================== API method =================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    // ================== header ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // ================== Http Body ========================================

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getAllAccountResponse = async (response: any, errorResponse: any) => {
    if (response && !response.errors) {
      await response?.data?.data?.filter((e: any) => {
        if (e?.lastTransactionDate) {
          console.log('res', response)
          localStorage.setItem("lastTransactionDate", e?.lastTransactionDate)
        }
      })[0]
      this.checkSubscriptionSession();
    }
  };

  async checkSubscriptionSession() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let sessionId = params.get('session_id');

    // Endpoint of update item API
    const apiEndpoint = `${configJSON.baseURL}bx_block_subscriptions/subscriptions`;

    // headers of API
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("loginToken"),
    };

    // httpBody of API
    const httpBody = {
      "data": {
        "attributes": {
          "session_id": sessionId
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // ================== Matches ID's ====================================
    this.checkSubsscriptionApiId = requestMessage.messageId;

    // ================== API URL ====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );

    // ================== API method =================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    // ================== header ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // ================== Http Body ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async onFinish(value: any) {
    this.setState({
      loading: true
    })

    // Endpoint of update item API
    const apiEndpoint = `${configJSON.baseURL}bx_block_dashboard/dashboards`;

    // headers of API
    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("loginToken"),
    };

    // httpBody of API
    const httpBody = {
      data: {
        title: "Take Home Pay",
        value: parseFloat(this.form?.current?.getFieldsValue("homePay")?.homePay ? this.form?.current?.getFieldsValue("homePay")?.homePay : 0),
        account_id: localStorage.getItem("accountId"),
      },
    }
    localStorage.setItem("take_home_pay", this.form?.current?.getFieldsValue("homePay")?.homePay ? this.form?.current?.getFieldsValue("homePay")?.homePay : 0)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // ================== Matches ID's ====================================
    this.getDashboardDataApiId = requestMessage.messageId;

    // ================== API URL ====================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );

    // ================== API method =================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    // ================== header ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // ================== Http Body ========================================
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getDashboardResponse = async (response: any, errorResponse: any) => {
    if (response && !response.errors) {
      this.setState({
        loading: false
      })
      window.location.href = "/MonthlyNecessityExpensesWeb";
    } else {
      this.setState({
        loading: false
      })
      if (response?.errors?.status == 401) {
        window.location.href = "/EmailAccountLoginWeb"
      }
      openErrorNotification(response?.errors?.account, "error")
    }
  };

  getCheckSubscriptionResponse = async (response: any, errorResponse: any) => {
    if (response && !response.errors) {
      openNotification(response?.data?.message, "success")
    } else {
      console.log(response?.errors);
    }
    localStorage.removeItem("subscription_type")
    localStorage.removeItem("if_account_linked")
    localStorage.removeItem("is_onboarding_completed")
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getAllAccountApiId) {
        this.getAllAccountResponse(responseJson, errorReponse);
      } else if (apiRequestCallId === this.getDashboardDataApiId) {
        this.getDashboardResponse(responseJson, errorReponse)
      } else if (apiRequestCallId === this.checkSubsscriptionApiId) {
        this.getCheckSubscriptionResponse(responseJson, errorReponse)
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
}
