import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createRef } from "react";
import { openErrorNotification, openNotification } from "../../../components/src/Notification.web";
import axios from "axios";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    data: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: any;
    access_token: any;
    tabData: any;
    drawerData: any;
    totalSavings: any;
    itemLoading: boolean;
    showItemInput: boolean;
    editData: any;
    cardLoading: boolean;
    showInput: any;
    categoryData: Array<any>;
    showAlert: boolean;
    openAddModal: boolean;
    homeInput: string;
    expenseName: string;
    catName: string;
    data: any;
    catNameID: string;
    openDeleteModal: boolean;
    redirect: boolean;
    webLoading: boolean;
    deleteId: string;
    editId: string;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class BudgetWebController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiBudgetCallId: string = "";
    _unsubscribe: any;
    updateItemApiId: any;
    getDropdownApiCallId: any;
    takeHomePayApiCallId: any;
    submitDataApiCallId: any;
    getDataApiCallId: any;
    form: any = createRef();
    itemForm: any = createRef();

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationRMessage),
        ];

        this.state = {
            token: "",
            access_token: "",
            tabData: null,
            drawerData: null,
            totalSavings: "",
            itemLoading: true,
            showItemInput: false,
            editData: "",
            cardLoading: true,
            showInput: "",
            categoryData: [],
            showAlert: false,
            openAddModal: false,
            homeInput: "",
            expenseName: "",
            catName: "",
            data: [],
            catNameID: "",
            openDeleteModal: false,
            redirect: false,
            webLoading: false,
            deleteId: "",
            editId: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        this.getToken();
        this.getData();
        this.dropdownAPI();
    }

    getData = async () => {
        // Endpoint of API
        const apiEndpoint = `${configJSON.baseURL}bx_block_categories/get_categories_with_amount`;

        // headers of API
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
            "Access-Control-Allow-Origin": configJSON.allowOriginType,
            "plaid-account-id": localStorage.getItem("plaid_acc_id"),
        };

        // httpBody of API
        const httpBody = {
            data: {
                account_id: localStorage.getItem("accountId"),
            },
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // ================== Matches ID's ====================================
        this.getDataApiCallId = requestMessage.messageId;

        // ================== API URL ====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        // ================== API method =================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        // ================== httpBody =====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        // ================== header ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    updateItemResponseData = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            this.setState({
                cardLoading: true,
                editData: "",
                showInput: !this.state.showInput,
                webLoading: false
            });
            this.getData();
            openNotification(response.data.message, "success")
        } else {
            this.setState({
                cardLoading: false,
            });
            openErrorNotification(response.errors.updated_name[0], "error")
        }
    };

    getDropdownResponseData = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            // subscription_expired?
            this.setState({
                categoryData: response?.data,
            });
        }
    }

    getHomePayResponseData = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            this.getData();
            this.setState({
                showInput: false,
            });
        } else {
            this.setState({
                showInput: false,
            });
            if (response.errors.status == 401) {
                this.props.navigation.navigate("/EmailAccountLoginWeb")
            }
            openErrorNotification(response.errors.message, "error");
        }
    }

    getSubmitResponseData = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            this.getData();
            this.setState({
                openAddModal: false,
                webLoading: false,
                cardLoading: false,
            });
            openNotification(response.data.message, "success")
        } else {
            this.setState({
                webLoading: false,
                cardLoading: false
            });
            openErrorNotification(response.errors.message, "error")
        }
    }

    getDataResponse = async (response: any, errorResponse: any) => {
        if (response && !response.errors) {
            this.setState({
                cardLoading: false,
                data: response
            });
            this.checkValidIncome(response)
        } else {
            this.setState({
                cardLoading: false,
                data: response?.errors?.data
            });
            if (response?.errors?.status == 401) {
                this.props.navigation.navigate("/EmailAccountLoginWeb")
            }
            openErrorNotification(response.errors.message, "error");
        }
    }


    onFinishItem = async (value: any, item: any) => {
        let updatedCat = undefined;
        for (const data of this.state.data.dashboardData) {
            updatedCat = data.sub_details?.find((cat: any) => cat.category_id == item.category_id);
            if (updatedCat) {
                break;
            }
        }
        const updatedAmount = this.state.data.total_budget_amount - updatedCat.total_budget_amount + parseInt(value.amount);
        if (updatedAmount > this.state.data.take_home_pay){
            openErrorNotification("Budget must be less then take home pay", "error");
            return
        }
        this.setState({
            itemLoading: true,
            showItemInput: false
        })
        this.setState({
            webLoading: true
        });
        // Endpoint of update item API
        const apiEndpoint = `${configJSON.baseURL}bx_block_categories/update_name_amount_to_subcategory`;

        // headers of API
        const header = {
            "Content-Type": configJSON.addcatagoryContentType,
            token: localStorage.getItem("loginToken"),
            "Access-Control-Allow-Origin": configJSON.allowOriginType,
        };

        // httpBody of API
        const httpBody = {
            data: {
                attributes: {
                    category_id: item.category_id,
                    "name": value.name,
                    amount: parseInt(value.amount),
                    account_id: localStorage.getItem("accountId"),
                },
            },
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // ================== Matches ID's ====================================
        this.updateItemApiId = requestMessage.messageId;

        // ================== API URL ====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        // ================== API method =================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editApiMethodType
        );

        // ================== httpBody =====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        // ================== header ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    dropdownAPI = async () => {
        const apiEndpoint = `${configJSON.baseURL}bx_block_categories/get_all_categories`;
        // headers of API
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            "token": localStorage.getItem("loginToken"),
            "plaid-account-id": localStorage.getItem("plaid_acc_id"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        // Matches ID's
        this.getDropdownApiCallId = requestMessage.messageId;
        // Added API URL
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );
        // Attached header in API
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        // Attached API method in API
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    handleChangeTakePay = async () => {
        this.setState({
            cardLoading: true
        })

        // Endpoint of API
        const apiEndpoint = `${configJSON.baseURL}bx_block_dashboard/dashboards`;

        // headers of API
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        };

        // httpBody of API
        const httpBody = {
            data: {
                "title": "Take Home Pay",
                "value": this.state.homeInput,
                "account_id": localStorage.getItem("accountId"),
            },
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // ================== Matches ID's ====================================
        this.takeHomePayApiCallId = requestMessage.messageId;

        // ================== API URL ====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        // ================== API method =================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        // ================== httpBody =====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        // ================== header ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    }

    onFinish = async (value: any) => {
        const updatedAmount = this.state.data.total_available_amount + parseInt(value.amount);
        if (updatedAmount > this.state.data.take_home_pay){
            openErrorNotification("Budget must be less then take home pay", "error");
            return
        }

        this.setState({
            webLoading: true,
            cardLoading: true
        });

        // Endpoint of API
        const apiEndpoint = `${configJSON.baseURL}bx_block_categories/add_amount_to_subcategory`;

        // headers of API
        const header = {
            "Content-Type": "application/json;charset=UTF-8",
            token: localStorage.getItem("loginToken"),
        };

        // httpBody of API
        const httpBody = {
            data: {
                attributes: {
                    category_type: this.state.expenseName,
                    name: this.state.catName,
                    amount: value.amount,
                    account_id: localStorage.getItem("accountId"),
                },
            },
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // ================== Matches ID's ====================================
        this.submitDataApiCallId = requestMessage.messageId;

        // ================== API URL ====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        // ================== API method =================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        // ================== httpBody =====================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        // ================== header ========================================
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    };

    handleDelete = async (item: any) => {
        this.setState({
            cardLoading: true
        })
        const data = {
            data: {
                attributes: {
                    category_id: this.state.deleteId,
                },
            },
        };

        const result = await axios.delete(
            `${configJSON.baseURL}bx_block_categories/delete_sub_category`,
            {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    token: localStorage.getItem("loginToken"),
                },
                data: data,
            }
        );
        if (result) {
            this.getData();
            this.setState({
                openDeleteModal: false
            })
        }
    };

    checkValidIncome = (data: any) => {
        data?.take_home_pay !== data?.dashboardData[0].total_budget_amount + data?.dashboardData[1].total_budget_amount ? this.setState({ showAlert: true }) : this.setState({ showAlert: false })
    }

    handleOk = () => {
        this.setState({
            openAddModal: false
        })
    };
    handleCancel = () => {
        this.setState({
            openAddModal: false
        })
    };

    change = (value: any, index: any) => {
        this.setState({
            catName: index?.key,
            catNameID: value
        })
        localStorage.setItem('categoryID', JSON.stringify(value))
    }

    getToken = async () => {
        const token = await AsyncStorage.getItem("loginToken");
        const access_token = await AsyncStorage.getItem("access_token");
        console.log(
            "access_token ========>>>>>>>>>>>>>>>>>>>>>>",
            access_token,
            token
        );
        this.setState(
            {
                token: token,
                access_token: access_token,
                webLoading: true,
            }
        );
    };

    async componentWillUnmount() {
        runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.NavigationRMessage) === message.id) {
            const data = message.getData(
                getName(MessageEnum.NavigationTabDataMessage)
            );
            runEngine.unSubscribeFromMessages(this, [
                getName(MessageEnum.NavigationRMessage),
            ]);

            this.setState({
                tabData: data.data[0].data.attributes,
                drawerData: data.data[2].data.attributes,
            });
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId === this.updateItemApiId) {
                this.updateItemResponseData(responseJson, errorReponse);
            } else if (apiRequestCallId === this.getDropdownApiCallId) {
                this.getDropdownResponseData(responseJson, errorReponse);
            } else if (apiRequestCallId === this.takeHomePayApiCallId) {
                this.getHomePayResponseData(responseJson, errorReponse);
            } else if (apiRequestCallId === this.submitDataApiCallId) {
                this.getSubmitResponseData(responseJson, errorReponse);
            } else if (apiRequestCallId === this.getDataApiCallId) {
                this.getDataResponse(responseJson, errorReponse);
            }

        }
        // Customizable Area End
    }

    addCategoryDataUpdate = (categoryName: string) => {
        this.setState({
            openAddModal: true,
            expenseName: categoryName
        })
    }
    // Customizable Area End
}