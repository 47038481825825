//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Image, Button } from "antd";
//@ts-ignore
import bgImage from "../../../components/src/assets/web_bg.png";
import axios from "axios";
import SubscribePlanCard from './SubscribePlanCard'
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import {
  openErrorNotification,
  openNotification,
} from "../../../components/src/Notification.web";
import Cookies from "js-cookie";
import { Redirect } from "react-router-dom";
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  redirect: boolean;
  cardLoading: boolean;
  listData: any[];
}
interface SS {
  id: any;
}


export default class SubScribePlanWeb extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
      super(props);
      this.state = {
        redirect: false,
        cardLoading: true,
        listData: []
      };
    }
    setCardLoading = (cardLoading: boolean) => this.setState({cardLoading});
    setListData = (listData: any[]) => this.setState({listData});
    setRedirect = (redirect: boolean) => this.setState({redirect});

    async componentDidMount() {
      this.getProduct();
    }

  getProduct = async () => {
    this.setCardLoading(true)
    const result = await axios
      .get(`${configJSON.baseURL}bx_block_stripes/product_listing`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("loginToken"),
        },
      })
      .then((result) => {
        console.log(result.data.product_details);
        this.setListData(result.data.product_details);
        this.setCardLoading(false)
        openNotification(result.data.message, "success")
      })
      .catch((err) => {
        if (err?.response?.status == 401 || err?.response?.status == 400){
          Cookies.remove("accountId");
          Cookies.remove("name");
          Cookies.remove("loginToken");
          this.setRedirect(true)
        }
        openErrorNotification(err?.response?.data?.error, "error")
        this.setCardLoading(false)
      });
  };
  render() {
    return (<>
        {this.state.redirect ? (<Redirect push to="/EmailAccountLoginWeb" />) : null}

      {this.state.cardLoading ?
        <LoadingWeb /> :
        <>
          <Row
            align="middle"
            justify="center"
            style={{
              backgroundImage: `url(${bgImage})`,
              height: "100vh",
              width: "100vw",
              backgroundSize: "cover",
              position: "fixed",
              overflow: "auto",
              paddingBottom: "20px",
              marginBottom: "30px",
            }}
          >
            <Col
              span={20}
              style={{
                padding: "8px",
                marginTop: "10%",
                marginBottom: "10%",
                backgroundSize: "cover",
              }}
            >
              <Row gutter={[20, 16]} align="middle" justify="center" style={{ marginTop: "40px" }}>
                <SubscribePlanCard listData={this.state.listData} setListData={this.setListData} />
              </Row>
            </Col>
          </Row>
        </>
      }
    </>
    );
  }
};
