import React from "react";
import {
  Card,
  Col,
  Button,
  Row,
  Modal,
  Pagination,
  Divider,
} from "antd";
import { Redirect } from "react-router-dom";
import SideBar from "../../../components/src/SideBar";
//@ts-ignore
import alert from "../assets/alert.png";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import AlertController from "./AlertController";


export default class AlertWeb extends AlertController {

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/EmailAccountLoginWeb"/>
    }
    if (this.state.cardLoading) {
      return <LoadingWeb />
    }
    return (
      <div>
        <div>
          <SideBar />
        </div>
        <div>
          <Row style={{ marginLeft: "79px" }}>
            <Col span={24}>
              <Card style={{ margin: "2em", padding: "4px" }}>
              <Row align="middle" justify="center">
              <Col xs={0} sm={0}  md={1} lg={1} />
              <Col style={{marginTop:"40px",marginBottom:"25px"}} xs={24} sm={24} md={22} lg={22}>
              <Row
                  style={{
                    color: "#545861",
                    fontSize: "22px",
                    fontWeight: 700,
                    lineHeight: " 1.2",
                    paddingBottom: "2em",
                  }}
                >
                  Alerts
                </Row>
                    <Row
                      align="middle"
                      style={{
                        borderRadius: 6.4,
                        background: "#f2f2f2",
                        height: "60px",
                        fontSize: "17px",
                        fontWeight: "bold",
                        padding: "0px 0px 0px 16px",
                      }}
                    >
                      <Col span={24}>Alerts</Col>
                    </Row>
                    <Row
                      justify="center"
                      style={{ backgroundColor: "rgb(233, 247, 252)" }}
                    >
                      <Col xs={5} sm={1} md={0} xl={0} />
                      <Col xs={20} sm={21} md={22} xl={23}>
                        {" "}
                        <Divider
                          style={{
                            fontSize: "1px",
                            // backgroundColor: "#f5f5f5",
                            marginBottom: "0px",
                            marginTop: "0px",
                          }}
                        />
                      </Col>
                    </Row>
                    {this.state.alertDetails &&
                      this.state.alertDetails.map((message: any, id) => (
                        <>
                          {!message.attributes.is_read ? (
                            <>
                              <Row
                                key={id}
                                test-id="row-id-is-not-read"
                                onClick={() => this.showModal(message)}
                                align="middle"
                                justify="center"
                                style={{
                                  background: "#E9F7FC",
                                  minHeight: "60px",
                                }}
                              >
                                <Col
                                  xs={4}
                                  sm={3}
                                  md={2}
                                  xl={1}
                                  style={{ textAlign: "center" }}
                                >
                                  <img src={alert} />
                                </Col>
                                <Col xs={20} sm={21} md={22} xl={23}>
                                  <Row className="cursor_pointer"
                                    style={{
                                      marginBottom: "0",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    <Col span={24}>
                                      {message.attributes.title}
                                    </Col>
                                  </Row>
                                  <Row className="cursor_pointer"
                                    style={{
                                      fontSize: 10,
                                      color: "#99999",
                                      marginBottom: 0,
                                    }}
                                  >
                                    <Col span={24}>
                                      {message.attributes.message}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
  
                              <Row
                                justify="center"
                                style={{
                                  backgroundColor: "rgb(233, 247, 252)",
                                }}
                              >
                                <Col xs={5} sm={1} md={0} xl={0} />
                                <Col xs={20} sm={21} md={22} xl={23}>
                                  <Divider
                                    style={{
                                      fontSize: "1px",
                                      backgroundColor: "f5f5f5",
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                    }}
                                  />
                                </Col>
                                <Col xs={5} sm={1} md={0} xl={0} />
                              </Row>
  
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    {this.state.alertDetails &&
                      this.state.alertDetails.map((message: any, id) => (
                        <>  
                          {message.attributes.is_read ? (
                            <>
                              <Row 
                                test-id="row-id-is-read"
                                key={id} 
                                onClick={() => this.showModal(message)}
                                align="middle"
                                justify="center"
                                style={{
                                  minHeight: "60px",
                                }}
                              >
                                <Col
                                  xs={4}
                                  sm={3}
                                  md={2}
                                  xl={1}
                                  style={{ textAlign: "center" }}
                                >
                                  <img src={alert} />
                                </Col>
                                <Col xs={20} sm={21} md={22} xl={23}>
                                  <Row className="cursor_pointer"
                                    style={{
                                      marginBottom: "0",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    <Col span={24}>
                                      {message.attributes.title}
                                    </Col>
                                  </Row>
                                  <Row className="cursor_pointer"
                                    style={{
                                      fontSize: 10,
                                      color: "#99999",
                                      marginBottom: 0,
                                    }}
                                  >
                                    <Col span={24}>
                                      {message.attributes.message}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row justify="center">
                                <Col xs={5} sm={1} md={0} xl={0} />
                                <Col xs={20} sm={21} md={22} xl={23}>
                                  {" "}
                                  <Divider
                                    style={{
                                      backgroundColor: "f5f5f5",
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                    }}
                                  />
                                </Col>
                                <Col xs={5} sm={1} md={0} xl={0} />
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    <Modal
                      maskClosable={false}
                      centered
                      title={this.state.modalData?.attributes?.title}
                      // title={<h2>{message.attributes.title}</h2>}
                      visible={this.state.visible}
  
                      confirmLoading={this.state.confirmLoading}
                      onCancel={this.handleCancel}
                      // width="35%"
                      className="alert_modal"
                    >
                      <Row
                        align="middle"
                        style={{
                          fontSize: 14,
                          color: "#99999",
                          marginBottom: 0,
                        }}
                      >
                        <Col span={24}>{this.state.modalData?.attributes?.message}</Col>
                      </Row>
                      <Row align="middle" justify="center" style={{ marginTop: "14px" }}>
                        <Button 
                          test-id="button-ok"
                          type="primary"
                          style={{ borderRadius: "15px", width: "100px" }}
                          onClick={() => (this.state.modalData?.attributes?.is_read ? this.setVisible(false) : (this.setVisible(false), this.getData(1)))}
                        // onClick={() => (setVisible(false), getData(1))}
                        >Ok </Button>
                      </Row>  </Modal>
  
                    <Row
                      justify="end"
                      style={{ marginTop: "40px", marginBottom: "1rem" }}
                    >
                      <Pagination
                        test-id="pagination"
                        total={this.state.paginationData.total_count}
                        showTotal={(total, range) =>
                          range[0] > 0
                            ? `Showing ${range[0]}-${range[1]} of ${total} `
                            : `Showing 1-10 of ${total} `
                        }
                        defaultPageSize={10}
                        defaultCurrent={this.state.paginationData.current_page}
                        onChange={this.onPageChange}
                      />
                    </Row>
              </Col>
              <Col xs={0} sm={0}  md={1} lg={1} />
              </Row>
              
              </Card>
            </Col>{" "}
          </Row>
        </div>
      </div>
    );
  }
};
