const defaultState = {
  usersEmail: {},
  loginToken: '',
  reallocateFromData: {},
  reallocateToData: {},
  amount: {},
  splitTransactionRes:{}

};

const usersReducer = (state = defaultState, action: any) => { 
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        usersEmail: { ...action.payload }
      };
    case 'LOGIN_TOKEN':
      return {
        ...state,
        loginToken: action.payload
      };
    case 'REALLOCATE_FROM_DATA':
      return {
        ...state,
        reallocateFromData: action.payload
      }
    case 'REALLOCATE_TO_DATA':
      return {
        ...state,
        reallocateToData: action.payload
      }
    case 'AMOUNT':
      return {
        ...state,
        amount: action.payload
      }
    case 'SPLIT_TRANSACTIONS_RES':
      return {
        ...state,
        splitTransactionRes: action.payload
      }
    default:
      return state;
  }
};

export default usersReducer;
