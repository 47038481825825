import React from "react";
import { View, Button, StyleSheet } from "react-native";
import { useHistory, withRouter } from "react-router-dom";
import AppSideBar from "./AppSideBar";

const SideBar = () => {
  // const history = useHistory();
  return <AppSideBar  />;
};

export default withRouter(SideBar);
