//@ts-nocheck
import React, { useEffect, useState } from "react";
const logo = require("../../../components/src/assets/Componente.png");
import { Row, Col, Card, Image, Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
//@ts-ignore
import bgImage from "../../../components/src/assets/web_bg.png";
// @ts-ignore
import "./style.css";
export const configJSON = require("./config");
import Cookies from "js-cookie";
import { LoadingWeb } from "../../../components/src/LoadingWeb";
import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  cardLoading: boolean;
}
interface SS {
  id: any;
}

export default class SubscriptionTrialWeb extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      cardLoading: true
    };
  }

  setLoading = (loading: boolean) => this.setState({ loading });
  setCardLoading = (cardLoading: boolean) => this.setState({ cardLoading });

  componentDidMount() {
    setTimeout(() => {
      this.setCardLoading(false);
    }, 2000);
  }

  render() {
    const subscription_type = localStorage.getItem("subscription_type");
    return (
      <>
        {this.state.cardLoading ? (
          <LoadingWeb />
        ) : (
          <Row
            align="middle"
            justify="center"
            style={{
              height: "100vh",
              width: "100vw",
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              position: "fixed",
              overflow: "auto",
              paddingBottom: "20px",
              marginBottom: "30px"
            }}
            test-id="row-id"
          >
            <Col lg={10} md={13} sm={16} xs={21}>
              <Card>
                <Row justify="center">
                  <Image src={logo} preview={false} />
                </Row>
                <Row
                  justify="center"
                  style={{
                    color: "rgb(243 74 74)",
                    fontWeight: 700,
                    fontSize: "25px",
                    marginTop: "12px",
                    textTransform: "capitalize"
                  }}
                >
                  {subscription_type} period expired
                </Row>
                <Row
                  justify="center"
                  style={{
                    fontSize: "16px",
                    color: "#685e5e",
                    marginTop: "5px"
                  }}
                >
                  Your {subscription_type} period has expired,
                </Row>
                <Row
                  justify="center"
                  style={{
                    fontSize: "16px",
                    color: "#685e5e",
                    marginBottom: "18px"
                  }}
                >
                  but it's easy to get connected again
                </Row>

                <Row>
                  <Col span={2}>
                    <CheckOutlined translate className="CheckOutlined" />
                  </Col>
                  <Col span={1} />
                  <Col span={21} className="chek_para">
                    You need a plan to make your money work for you. We've
                    create a simple solution.
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col span={2}>
                    <CheckOutlined translate className="CheckOutlined" />
                  </Col>
                  <Col span={1} />
                  <Col span={21} className="chek_para">
                    Set your budget once and let the app take care of the rest.
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col span={2}>
                    <CheckOutlined translate className="CheckOutlined" />
                  </Col>
                  <Col span={1} />
                  <Col span={21} className="chek_para">
                    Put your plan into action with automation.
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col span={2}>
                    <CheckOutlined translate className="CheckOutlined" />
                  </Col>
                  <Col span={1} />
                  <Col span={21} className="chek_para">
                    Easily track where your money is going.
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col span={2}>
                    <CheckOutlined translate className="CheckOutlined" />
                  </Col>
                  <Col span={1} />
                  <Col span={21} className="chek_para">
                    Reach your goals with personalized notifications.
                  </Col>
                </Row>
                <Row>
                  <Col span={2} />
                  <Col span={20}>
                    {" "}
                    <Button
                      danger
                      shape="round"
                      size="large"
                      loading={this.state.loading}
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        color: "white",
                        backgroundColor: "rgb(243 74 74)"
                      }}
                      // onClick={handleSubscribe}
                      onClick={() =>
                        (window.location.href = "/SubscribePlanWeb")
                      }
                    >
                      Subscribe
                    </Button>
                  </Col>
                  <Col span={2} />
                </Row>

                <Row>
                  <Col span={2} />
                  <Col span={20}>
                    <Button
                      danger
                      className="Sbtn"
                      shape="round"
                      size="large"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        marginBottom: "12px"
                      }}
                      onClick={() => {
                        localStorage.clear(),
                          Cookies.remove("accountId"),
                          Cookies.remove("name"),
                          Cookies.remove("loginToken"),
                          (window.location.href = "/EmailAccountLoginWeb");
                      }}
                    >
                      Sign Out
                    </Button>
                  </Col>
                  <Col span={2} />
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </>
    );
  }
}
