import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { openErrorNotification } from "../../../components/src/Notification.web";
import { FormInstance } from "antd/lib/form";
import { setUserData } from "../../../web/src/redux/actions/users";
import React from "react";
import { connect } from "react-redux";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  errMsg: string;
  loading: boolean;
  email: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formRef = React.createRef<FormInstance<any>>();
  forgetPasswordId: string = "";
  emailVerficationId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Added
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      //------------------------------------------------------------------------------------
    ];
    this.formRef = React.createRef();
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start

    this.state = {
      errMsg: "",
      loading: false,
      email: ""
    };
    // Customizable Area End
  }

  receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.forgetPasswordId) {
        this.forgetPasswordResponseHandler(response)
      }

      if (apiRequestCallId === this.emailVerficationId) {
        this.emailVerificationResponseHandler(response)
      }
    }

    // Customizable Area End
  }

  emailVerificationResponseHandler = (response: any) => {
    const error = response.error;
    if (error) {
      this.setState({
        loading: false
      });
      openErrorNotification(error, "error");
    } else {
      this.setState({
        loading: false
      });
      this.props.navigation.navigate("CheckYourEmailWeb");
    }
  }

  forgetPasswordResponseHandler = (response: any) => {
    const data = response.data;
    if (data) {
      this.emailVerfication(this.state.email, response.meta.token);
      this.setState({ loading: false });
      setUserData(this.state.email);

      if (this.formRef.current) {
        this.formRef.current.resetFields();
      }
    } else {
      this.setState({ loading: false });
      openErrorNotification(response.errors[0].message, "error");
      this.setState({
        errMsg: response.errors[0].message
      });
      if (response.status == 401) {
        this.props.navigation.native("EmailAccountLoginWeb");
      }
    }
  }
  onFinish = (value: any) => {
    this.setState({ loading: true, email: value.email });
    const data = {
      data: {
        type: "email_account",
        attributes: value
      }
    };
    const endPoint = `${configJSON.baseURL}${configJSON.forgetPasswordEndpoint
      }`;
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",
    };
    this.forgetPasswordId = this.apiCall(
      configJSON.postMethod,
      endPoint,
      headers,
      data
    );
  };

  emailVerfication = (email: string, forgotApiToken: string) => {
    this.setState({ loading: true });
    const endPoint = `bx_block_forgot_password/otp_confirmations?email=${email}&token=${forgotApiToken}&device_type=${"web"}`;
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",
    };
    this.emailVerficationId = this.apiCall(
      configJSON.httpGetMethod,
      `${configJSON.baseURL}${endPoint}`,
      headers
    );
  };

  apiCall = (
    method: string,
    endPoint: string,
    header: object,
    httpBody?: object
  ) => {
    const RequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const messageId = RequestMessage.messageId;
    RequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    RequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    RequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    RequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(RequestMessage.id, RequestMessage);
    return messageId;
  };
}

connect(
  null,
  { setUserData }
)(ForgotPasswordController);
